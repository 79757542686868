import { useState, useRef } from "react";
import emailjs from "emailjs-com";
import React from "react";
import styled from "styled-components";
// Assets
import ContactImg1 from "../../assets/img/contact-1.png";
import ContactImg2 from "../../assets/img/contact-2.png";
import ContactImg3 from "../../assets/img/contact-3.png";
import ContactImg4 from "../../assets/img/contact-4.jpg";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

export const Contact = (props) => {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prevState) => ({ ...prevState, [name]: value }));
  };

  const clearState = () => setState({ ...initialState });

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    console.log(name, email, phone, message);
    emailjs
      .sendForm("service_7zchvh7", "template_9xsiohc", e.target, "ILtXxM-667kxZckBg")
      .then(
        (result) => {
          console.log(result.text);
          clearState();
          e.target.reset(); // Reset the form
          setIsSubmitting(false);
        },
        (error) => {
          console.log(error.text);
          setIsSubmitting(false);
        }
      );
  };

  const triggerSubmit = () => {
    formRef.current.dispatchEvent(new Event("submit", { bubbles: true, cancelable: true }));
  };

  return (
    <Wrapper id="contact">
      <div className="lightBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">Let's get in touch</h1>
            <p className="font13">
              Have a question or need assistance? Leave me a message, and I'll respond as soon as possible!
              <br />
            </p>
          </HeaderInfo>
          <div className="row" style={{ paddingBottom: "30px" }}>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
              <Form ref={formRef} name="sentMessage" validate onSubmit={handleSubmit}>
                <label className="font13">First name:</label>
                <input type="text" id="name" name="name" className="font20 extraBold" value={name} onChange={handleChange} />
                <label className="font13">Email:</label>
                <input type="email" id="email" name="email" className="font20 extraBold" value={email} onChange={handleChange} />
                <label className="font13">Phone:</label>
                <input type="text" id="phone" name="phone" className="font20 extraBold" value={phone} onChange={handleChange} />
                <label className="font13">Your Message:</label>
                <textarea rows="4" cols="50" id="message" name="message" className="font20 extraBold" value={message} onChange={handleChange} />
              </Form>
              <SumbitWrapper className="flex">
                <ButtonInput type="button" value="Send Message" onClick={triggerSubmit} className="pointer animate radius8" style={{ maxWidth: "220px" }} disabled={isSubmitting} />
              </SumbitWrapper>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 flex">
              <div style={{ width: "50%" }} className="flexNullCenter flexColumn">
                <ContactImgBox>
                  <img src={ContactImg1} alt="office" className="radius6" />
                </ContactImgBox>
                <ContactImgBox>
                  <img src={ContactImg2} alt="office" className="radius6" />
                </ContactImgBox>
              </div>
              <div style={{ width: "50%" }}>
                <div style={{ marginTop: "100px" }}>
                  <img src={ContactImg4} alt="office" className="radius6" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  padding: 70px 0 30px 0;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Form = styled.form`
  padding: 70px 0 30px 0;
  input,
  textarea {
    width: 100%;
    background-color: transparent;
    border: 0px;
    outline: none;
    box-shadow: none;
    border-bottom: 1px solid #707070;
    height: 30px;
    margin-bottom: 30px;
  }
  textarea {
    min-height: 100px;
  }
  @media (max-width: 860px) {
    padding: 30px 0;
  }
`;
const ButtonInput = styled.input`
  border: 1px solid #7620ff;
  background-color: #7620ff;
  width: 100%;
  padding: 15px;
  outline: none;
  color: #fff;
  :hover {
    background-color: #580cd2;
    border: 1px solid #7620ff;
    color: #fff;
  }
  @media (max-width: 991px) {
    margin: 0 auto;
  }
`;
const ContactImgBox = styled.div`
  max-width: 180px; 
  align-self: flex-end; 
  margin: 10px 30px 10px 0;
`;
const SumbitWrapper = styled.div`
  @media (max-width: 991px) {
    width: 100%;
    margin-bottom: 50px;
  }
`;
