import React from "react";
import styled from "styled-components";
// Components
import BlogBox from "../Elements/BlogBox";
import FullButton from "../Buttons/FullButton";

import laurendPdf from "../../assets/feedback/lauren.pdf";
import angelaPdf from "../../assets/feedback/angela.pdf";
import deidrePdf from "../../assets/feedback/deidre.pdf";
import irenePdf from "../../assets/feedback/irene.pdf";
import unaPdf from "../../assets/feedback/una.pdf";
import katiePdf from "../../assets/feedback/katie.pdf";

export default function Blog() {
  // Function to open PDF in a new window or tab
  const openPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank");
  };

  // Blog data
  const blogPosts = [
    {
      title: "Angela Marcus",
      text: "Kim provide a stimulating environment with varied age-appropriate activities and Asher has learnt and continues to practise so many important social skills.",
      tag: "Read More",
      pdf: angelaPdf,
    },
    {
      title: "Deidre Mellote",
      text: "She is so wonderful with the kids. Maeve loves going to her house, and she does fantastic activities with them.",
      tag: "Read More",
      pdf: deidrePdf,
    },
    {
      title: "Irene Ellis",
      text: "I have had multiple previous experiences with family day care, daycare centers and schools in the past and I believe that Dreamhouse provides a superior childcare alternative.",
      tag: "Read More",
      pdf: irenePdf,
    },
    {
      title: "Una Hegarty",
      text: "Kim is the most kind, caring, gentle, patient, passionate person and absolutely adores all these kids in her care.",
      tag: "Read More",
      pdf: unaPdf,
    },
    {
      title: "Katie Hodkin",
      text: "Kim has created an incredibly warm, nurturing environment for the children in her care. Not only is there plenty of play and fun, but a great range of activities designed to increase learning and skill development.",
      tag: "Read More",
      pdf: katiePdf,
    },
    {
      title: "Employee Spotlight",
      text: "\"Kimmy's house\" is his safe space away from home and he is always excited to go there and never wants to leave had no doubt that his younger brother would settle with Kim too and he runs to her for a cuddle every morning. We call her 'Auntie Kim' because she is not just our educator, we really see her as part of the family.",
      tag: "Read More",
      pdf: laurendPdf,
    },
  ];

  // Function to split blogPosts into rows
  const splitIntoRows = (arr, rowSize) => {
    const rows = [];
    for (let i = 0; i < arr.length; i += rowSize) {
      rows.push(arr.slice(i, i + rowSize));
    }
    return rows;
  };

  const rows = splitIntoRows(blogPosts, 3); // Split into rows of 3

  return (
    <Wrapper id="blog">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold">What our parents say</h1>
            
          </HeaderInfo>
          {rows.map((row, rowIndex) => (
            <div key={rowIndex} className="row textCenter">
              {row.map((post, colIndex) => (
                <div key={colIndex} className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                  <BlogBox
                    title={post.title}
                    text={post.text}
                    tag={post.tag}
                    author={post.author}
                    action={() => openPdf(post.pdf)}
                  />
                </div>
              ))}
            </div>
          ))}
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
            {/*  <FullButton title="Load More" action={() => alert("clicked")} /> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding-top: 20px;
`;

const HeaderInfo = styled.div`
  margin-bottom: 30px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
