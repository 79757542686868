import * as React from "react";

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40" // Fixed width
      height="40" // Fixed height
      viewBox="0 0 451 417" // Original viewBox
      {...props}
    >
<path fill="#FFFEFE" opacity="1.000000" stroke="none" 
	d="
M0.999999,193.000000 
	C1.000000,129.074524 1.000000,65.149048 1.000000,1.000000 
	C62.020714,1.000000 123.041695,1.000000 184.264252,1.278766 
	C180.857559,2.577646 177.030075,3.141634 173.700562,4.741784 
	C170.127243,6.459104 170.217270,9.068001 173.805634,10.764977 
	C176.749466,12.157148 180.051743,13.105154 183.287338,13.506236 
	C192.067673,14.594642 200.879730,14.714532 209.253326,11.245203 
	C210.726532,10.634829 212.614258,8.828177 212.638550,7.542408 
	C212.662292,6.285656 210.776474,4.517682 209.322296,3.812164 
	C206.708618,2.544112 203.790756,1.903039 201.000000,1.000000 
	C284.608246,1.000000 368.216522,1.000000 452.000000,1.000000 
	C452.000000,126.020844 452.000000,251.041763 451.691162,376.339844 
	C449.185547,371.484619 446.988800,366.352234 444.773315,360.968109 
	C444.504852,360.510529 444.255157,360.304688 443.958923,360.081604 
	C443.912354,360.064301 443.913483,359.964966 443.944153,359.621368 
	C443.331726,358.561584 442.688629,357.845398 441.963135,357.110565 
	C441.880737,357.091919 441.868652,356.923370 441.824554,356.725891 
	C441.635376,356.277252 441.419525,356.128937 441.041260,356.066925 
	C440.949615,356.050323 440.918152,355.866699 440.854187,355.680542 
	C440.637970,355.260559 440.424591,355.118530 439.856323,354.931488 
	C439.012878,354.487396 438.463165,354.180054 437.861176,353.625244 
	C437.514038,352.967834 437.219208,352.557953 436.966553,351.671753 
	C436.681366,340.404602 436.159973,329.614380 436.126160,318.822632 
	C436.113373,314.748657 434.762573,312.180542 430.804596,311.936859 
	C427.883514,311.756989 424.898224,312.620697 421.737305,313.115112 
	C421.278351,313.392883 421.103302,313.633057 420.670166,314.148071 
	C419.889404,316.024567 419.108582,317.675415 419.064392,319.345764 
	C418.909088,325.216949 419.007019,331.094849 419.000122,336.981262 
	C418.991455,336.992310 418.974609,337.014801 418.689026,337.049438 
	C418.219147,337.137512 418.034821,337.190918 417.511353,337.141724 
	C412.112610,334.906769 407.053009,332.774384 402.030151,330.522766 
	C402.066895,330.403534 402.036835,330.155792 402.027283,329.870300 
	C402.003906,329.394928 401.990051,329.204987 401.991058,328.550049 
	C402.004761,325.757385 401.635712,323.358490 402.070129,321.115173 
	C403.475769,313.855896 397.792267,309.887512 389.709595,312.021667 
	C388.949615,312.339386 388.483643,312.643616 387.685455,313.126892 
	C383.209167,316.196594 385.781342,320.666260 384.369293,324.311462 
	C379.630341,323.452820 375.292267,322.576202 370.680237,321.483582 
	C369.615570,320.589020 368.381287,320.055481 368.104523,319.208954 
	C365.965485,312.666107 364.410675,311.452881 356.998749,310.585693 
	C356.670258,305.409760 356.342834,300.704834 356.012634,295.533997 
	C356.024170,290.389679 356.038452,285.711304 356.442749,280.928284 
	C357.221313,279.816315 357.915466,278.820312 357.953217,277.800079 
	C358.681152,258.119354 359.407318,238.437988 359.975159,218.752167 
	C360.429077,203.017059 360.590210,187.273438 361.055511,171.538757 
	C361.198242,166.712784 361.916473,161.903854 362.576660,157.163300 
	C362.782166,157.239471 363.020477,157.607407 363.120575,157.924683 
	C363.799347,158.494324 364.378021,158.746704 364.983490,158.996338 
	C365.010284,158.993607 365.007690,159.047440 365.137238,159.275513 
	C365.580994,159.636032 365.895172,159.768494 366.500854,159.979523 
	C367.499603,160.415039 368.206818,160.772003 368.949707,161.082336 
	C368.985321,161.035690 368.881561,161.090591 368.999817,161.414490 
	C371.693542,163.232422 374.136200,165.064148 376.875610,166.141403 
	C380.997894,167.762512 384.841644,166.960052 387.304413,162.949524 
	C389.752075,158.963593 389.000336,154.264221 385.626709,151.248474 
	C381.784210,147.813568 377.862610,144.467148 373.979126,141.077972 
	C356.321472,125.667755 338.665192,110.256004 320.937531,94.631821 
	C320.686798,94.144333 320.432800,94.006516 319.961182,93.696442 
	C305.558716,80.856758 291.299652,68.326027 276.969452,55.590160 
	C276.718506,55.127853 276.471802,54.993477 276.025574,54.664677 
	C273.314087,52.014709 270.693481,49.726292 268.163666,47.341534 
	C257.222137,37.027390 248.893585,36.799919 237.196808,46.584423 
	C227.661011,54.561268 218.149704,62.567398 208.279739,70.349182 
	C207.302307,68.485687 206.672668,66.833542 206.365738,65.017906 
	C212.293594,62.327213 213.760773,59.347961 212.977356,52.029362 
	C212.567123,48.197224 210.645859,45.917286 206.571625,45.961864 
	C201.771530,46.014381 196.911423,45.646397 192.184875,46.272564 
	C183.157562,47.468502 174.206406,49.239521 165.172943,50.772797 
	C165.122253,50.773758 165.066406,50.858395 164.679626,50.878662 
	C160.172714,51.989487 159.565704,54.949852 159.983032,58.642841 
	C160.568451,63.823597 162.280670,68.030586 168.283081,69.466202 
	C169.798065,71.018211 171.894104,72.163864 172.191925,73.666023 
	C173.727890,81.413231 174.802948,89.251808 175.900391,97.366257 
	C172.138657,101.087296 168.515320,104.500580 164.942505,107.945602 
	C164.993042,107.977341 164.918228,107.884361 164.610657,107.957916 
	C163.419281,108.890472 162.535461,109.749481 161.667603,110.674034 
	C161.683548,110.739586 161.581329,110.651512 161.265564,110.740562 
	C158.798615,112.702164 156.647446,114.574715 154.247131,116.570015 
	C153.633057,117.094223 153.268143,117.495682 152.581177,117.997543 
	C145.787415,123.677696 139.315704,129.257446 132.544434,134.916443 
	C131.308563,135.803207 130.372238,136.610733 129.200012,137.552948 
	C128.616150,138.096481 128.268219,138.505310 127.611633,139.003723 
	C125.933006,140.149948 124.563042,141.206604 122.827942,142.175247 
	C113.025314,138.809189 103.777351,134.671234 94.088058,132.535629 
	C86.837639,130.937576 78.739449,130.572723 71.517273,132.067490 
	C60.477783,134.352371 49.692226,138.270050 39.172638,142.437958 
	C35.554321,143.871536 33.055450,148.130585 29.943947,151.317596 
	C29.636358,151.807587 29.364220,151.965561 28.659920,151.990097 
	C22.628391,155.281616 17.335783,158.918549 16.009331,166.013824 
	C15.999163,166.000290 16.027409,166.018265 15.635210,166.010834 
	C14.531449,167.396255 13.819888,168.789108 13.058851,170.393478 
	C13.009376,170.605011 12.773884,170.970123 12.563580,171.078827 
	C12.093879,171.415726 11.985819,171.701233 12.013683,172.023041 
	C11.998273,172.002060 12.043228,172.027924 11.681839,172.051025 
	C10.524970,173.001389 9.464351,173.807861 8.977924,174.875870 
	C6.242194,180.882446 3.644668,186.951950 0.999999,193.000000 
M185.660522,38.978039 
	C193.301895,37.736340 200.999054,36.747738 208.547394,35.083958 
	C210.561356,34.640045 212.119888,32.129921 213.887482,30.568411 
	C212.206924,28.868414 210.817932,26.266712 208.798798,25.613840 
	C195.061050,21.171848 181.133789,20.811056 167.383224,25.514734 
	C165.279175,26.234472 163.847015,28.918446 162.104095,30.693951 
	C163.817505,32.322712 165.279007,34.840569 167.292282,35.410999 
	C172.987579,37.024654 178.908905,37.840485 185.660522,38.978039 
z"/>
<path fill="#67A703" opacity="1.000000" stroke="none" 
	d="
M1.000000,216.000000 
	C1.000000,214.611008 1.000000,213.222000 1.278316,211.175766 
	C3.356075,203.729828 4.508291,196.679657 7.118600,190.218567 
	C10.390067,182.120972 14.622197,174.374390 18.908133,166.739258 
	C21.081245,162.867981 24.283154,159.574219 27.362579,155.977768 
	C28.802422,155.612747 29.899637,155.289337 31.170780,155.228638 
	C32.563576,155.652008 33.782444,155.812637 35.365650,156.021072 
	C37.153553,156.382202 38.577122,156.695511 40.089638,157.176361 
	C40.455685,157.232132 40.732780,157.120346 41.401020,157.007095 
	C43.529327,156.667770 45.266495,156.329926 47.376877,155.916702 
	C48.291611,153.856812 48.833126,151.872284 49.422993,149.710556 
	C47.077869,149.420822 45.523670,149.228806 43.683010,148.907349 
	C42.281788,148.474747 41.167030,148.171555 40.011517,147.857285 
	C40.716125,146.350937 41.302601,145.097137 41.945744,143.875122 
	C42.002411,143.906921 41.874477,143.884186 42.285934,143.930145 
	C52.203140,141.455902 61.637524,138.610474 71.234222,136.504791 
	C81.308403,134.294327 91.457260,133.723846 100.672256,139.033875 
	C99.231255,139.703308 98.121132,140.341812 97.005829,140.990448 
	C97.000648,141.000610 96.977905,141.002441 96.735794,141.003143 
	C96.493690,141.003830 96.009560,141.012207 95.753624,141.010330 
	C95.327080,141.005844 95.156471,141.003250 94.958336,140.730286 
	C93.637550,141.279755 92.344284,142.099594 90.778557,143.055084 
	C89.999794,143.805344 89.493507,144.419968 88.770828,145.181885 
	C88.551964,145.718872 88.549484,146.108521 88.547012,146.498199 
	C89.017838,146.342361 89.488663,146.186523 90.328064,146.067398 
	C92.806129,146.731628 94.915619,147.359177 97.228561,147.894043 
	C97.691872,147.630722 97.866333,147.396469 98.303131,147.081543 
	C99.115036,147.061874 99.579193,147.059280 100.212410,147.274658 
	C100.915337,147.005157 101.449203,146.517700 102.102966,145.758896 
	C104.925766,143.133362 101.266464,142.550278 100.986259,140.689270 
	C100.993065,139.899414 100.994980,139.448166 101.415672,138.993698 
	C106.816513,140.335037 111.798569,141.679565 118.211243,143.410202 
	C114.917549,146.350693 112.929909,148.525345 110.566635,150.155365 
	C104.882912,154.075577 102.413765,160.199905 107.695099,166.605621 
	C108.704582,167.830017 108.849098,169.782318 109.341888,171.414139 
	C109.933319,173.372604 110.470322,175.347504 111.030899,177.315277 
	C113.005257,176.541382 114.979614,175.767502 117.107033,174.686615 
	C118.811546,171.898682 120.363007,169.417725 121.930855,166.958344 
	C121.947243,166.979919 121.954239,166.926208 122.175003,166.863007 
	C122.684593,166.639343 122.850372,166.395325 122.900406,165.991653 
	C122.907730,165.915573 123.058624,165.891144 123.350021,165.801636 
	C124.433571,164.891251 125.225708,164.070374 126.123703,162.968872 
	C126.868729,162.300751 127.507904,161.913239 128.282898,161.597809 
	C128.764893,161.624420 128.955368,161.416702 128.990540,161.018951 
	C128.990891,160.991196 129.045914,160.998596 129.442032,161.019867 
	C130.585190,162.083008 131.925262,163.089920 131.989044,164.172089 
	C132.768463,177.391296 133.454224,190.617905 133.973221,203.850082 
	C134.434647,215.614899 134.565262,227.392807 135.043137,239.156784 
	C135.181641,242.566284 134.392715,244.855286 130.983032,246.182678 
	C129.818527,246.636017 129.123413,248.295044 128.213837,249.403198 
	C129.316910,250.235046 130.544891,250.946365 131.492584,251.928192 
	C132.779404,253.261398 134.268143,254.641800 134.903183,256.296356 
	C135.586731,258.077301 135.155426,260.253235 135.612579,262.160431 
	C136.738449,266.857758 135.581512,270.643738 131.818481,273.714935 
	C130.715790,274.614899 129.886002,275.849274 128.570633,276.964142 
	C120.160385,280.970337 112.110657,284.942810 104.041748,288.496216 
	C101.605766,272.840149 99.215714,257.632935 104.980194,242.526993 
	C106.198830,239.333511 107.163940,236.744415 110.886520,235.282547 
	C113.918709,234.091812 116.245110,231.103821 118.903458,228.923309 
	C118.922447,228.933838 118.963310,228.944122 119.198074,228.840942 
	C119.600388,228.447525 119.767937,228.157288 120.107681,227.537262 
	C121.026009,224.153702 121.772156,221.099899 122.656952,217.478622 
	C115.108849,218.793762 115.804893,224.722900 113.583206,228.925751 
	C110.670692,229.029160 109.494659,228.612457 110.823494,225.553436 
	C112.496590,221.701920 114.945847,217.527954 110.956161,213.495605 
	C109.542130,214.077164 108.354820,214.565475 107.118118,214.753464 
	C106.607384,212.844330 106.146034,211.235504 105.381325,208.568771 
	C103.854233,209.830200 102.304710,211.110153 101.523109,211.755783 
	C103.454063,214.461182 104.726440,216.243851 105.802277,218.295013 
	C101.027908,225.527374 96.450081,232.491257 91.588676,239.886536 
	C91.073120,237.558090 90.421707,235.129395 90.008629,232.660828 
	C88.299660,222.448029 86.662827,212.223145 85.227753,201.832016 
	C84.978104,201.105927 84.500191,200.550507 84.010376,199.670258 
	C83.991875,198.912109 83.985275,198.478806 83.921036,197.675766 
	C79.644958,195.033737 77.976212,195.950287 77.033806,201.510895 
	C76.341530,208.640884 75.648041,215.317749 74.617508,222.055008 
	C74.194244,223.413284 74.108025,224.711197 74.021217,226.435455 
	C73.686409,229.573837 73.352188,232.285858 72.752754,235.105682 
	C71.981979,235.480774 71.476410,235.748062 70.770905,235.743744 
	C69.705566,233.997620 68.840157,232.523071 67.951950,230.694244 
	C66.460587,228.586075 65.246346,226.487320 63.457283,225.168076 
	C61.356133,223.618698 58.701317,222.365051 56.852295,226.352936 
	C56.804119,229.129517 56.906990,231.561401 56.779922,234.163605 
	C57.027550,234.890594 57.505116,235.447281 57.999104,236.387604 
	C59.678005,242.846878 61.340485,248.922531 62.726768,255.192413 
	C56.979549,260.472382 59.610580,252.988281 57.238529,251.986679 
	C56.527523,253.408020 55.171711,254.823441 55.318176,256.061066 
	C55.817444,260.279877 60.507366,263.898499 64.978722,264.441467 
	C65.306923,271.245850 65.622482,277.616791 65.578781,284.065002 
	C64.496353,285.642853 63.773178,287.143463 62.949951,288.851654 
	C58.608688,286.734833 52.592201,288.446564 50.996624,284.260529 
	C46.613670,282.375763 43.392002,281.299225 40.498375,279.647888 
	C38.200569,278.336578 36.349903,276.261627 34.213646,274.640472 
	C32.767796,273.543243 30.786608,272.953674 29.705141,271.606842 
	C23.555080,263.947632 17.339792,256.313324 11.719893,248.270020 
	C9.591014,245.223129 8.899713,241.137299 7.726824,237.469482 
	C6.984205,235.147156 6.539815,232.727341 6.001289,230.342316 
	C4.878615,225.370178 5.819750,219.682327 1.000000,216.000000 
M34.744389,180.466446 
	C34.187126,180.322189 33.629868,180.177917 32.942265,179.340271 
	C32.197899,179.020782 31.453529,178.701309 30.709160,178.381836 
	C30.604933,179.108765 30.155293,180.237198 30.454781,180.494827 
	C31.949705,181.780884 33.641338,182.838272 35.509949,183.970901 
	C35.509949,183.970901 35.755905,183.982742 35.965530,184.716476 
	C37.530689,185.442169 39.095848,186.167847 40.661003,186.893539 
	C40.461037,186.251419 40.261070,185.609314 40.291817,184.341034 
	C40.637245,179.866882 36.113327,183.145035 35.000000,181.000000 
	C35.000000,181.000000 34.990036,181.010910 34.744389,180.466446 
M56.476238,219.633911 
	C54.999180,216.500412 52.380032,216.242477 48.245441,218.947495 
	C45.915081,219.722778 43.584724,220.498062 41.254364,221.273331 
	C42.997017,222.891495 44.726288,224.116409 45.958233,223.734985 
	C49.349934,222.684906 54.642597,226.415863 56.476238,219.633911 
M36.983940,268.874573 
	C37.516968,269.920502 37.819641,271.556427 38.636501,271.875336 
	C39.891418,272.365265 41.499699,271.950073 43.327400,272.414276 
	C43.889088,271.652435 44.450775,270.890594 45.574638,269.823090 
	C46.352161,269.500061 47.129684,269.177032 48.323200,269.452423 
	C49.675568,269.961670 51.027931,270.470886 52.380295,270.980133 
	C52.735031,270.427826 53.089764,269.875519 53.444500,269.323242 
	C52.003159,268.214996 50.561821,267.106750 48.569668,265.433105 
	C45.743938,265.640289 42.918205,265.847473 39.349873,266.028198 
	C38.594212,266.738983 37.838551,267.449768 36.983940,268.874573 
M23.957067,230.023911 
	C20.194361,230.251617 15.841166,228.696259 13.572188,233.450912 
	C18.038446,238.326370 23.322786,238.106461 29.012783,232.361282 
	C27.489054,231.552277 26.128996,230.830185 23.957067,230.023911 
M74.087814,192.410355 
	C75.523361,191.147156 76.958916,189.883972 78.394470,188.620789 
	C78.101578,187.910995 77.808693,187.201202 77.515808,186.491409 
	C74.076683,186.711029 70.599937,186.714096 67.224525,187.300476 
	C66.366539,187.449509 65.830612,189.452454 65.146698,190.603577 
	C66.313919,191.171783 67.428902,191.964783 68.661591,192.251236 
	C70.200302,192.608826 71.835953,192.549301 74.087814,192.410355 
M59.231308,156.772537 
	C61.072395,154.251831 63.139755,151.854141 64.617760,149.136002 
	C65.065758,148.312119 63.883965,146.602020 63.448666,145.297836 
	C62.112362,146.006210 59.878345,146.472412 59.609566,147.468765 
	C58.830799,150.355545 58.841530,153.455307 59.231308,156.772537 
M109.991852,264.875610 
	C110.760956,267.994171 111.530060,271.112701 112.437263,274.791229 
	C116.958466,271.085693 116.546127,266.895782 111.804184,260.702545 
	C111.114685,261.999756 110.569153,263.026093 109.991852,264.875610 
M21.493038,202.949081 
	C20.340170,202.235382 19.187302,201.521683 17.827503,200.679871 
	C16.561619,206.614914 18.894150,210.220673 24.412550,211.657669 
	C25.986959,208.234833 24.625492,205.690308 21.493038,202.949081 
z"/>
<path fill="#FEFFFD" opacity="1.000000" stroke="none" 
	d="
M84.000008,418.000000 
	C76.979050,418.000000 69.958107,418.000000 62.425407,417.638275 
	C59.472736,413.678223 57.488998,413.817017 56.000000,418.000000 
	C48.979053,418.000000 41.958103,418.000000 34.408142,417.644165 
	C32.464775,416.639893 31.081078,415.522827 29.630520,415.427948 
	C24.589764,415.098175 19.504730,414.813416 14.480363,415.155426 
	C12.265962,415.306152 10.156893,417.004364 8.000001,418.000000 
	C6.285642,418.000000 4.571283,418.000000 2.320482,417.780640 
	C1.585159,417.286957 1.323812,417.099854 1.000000,417.000000 
	C1.000000,354.645782 1.000000,292.291534 1.345849,229.615692 
	C2.769499,232.369308 3.811437,235.457642 4.930690,238.517700 
	C15.227700,266.670074 34.459850,284.907196 64.287308,291.833496 
	C65.006783,292.020874 65.433769,292.071686 65.885239,292.548584 
	C65.206215,300.314789 64.502701,307.654968 63.445896,314.942322 
	C59.996849,311.618286 55.996853,311.827118 51.645504,312.006470 
	C50.519833,312.686249 49.752705,313.367828 48.883179,314.252136 
	C48.595615,314.707916 48.354500,314.884674 47.650711,315.065918 
	C46.826206,316.964539 46.119034,318.769623 46.038296,320.602325 
	C45.565399,331.336334 45.238968,342.076813 44.482052,352.858063 
	C42.409355,353.504761 40.295761,353.658081 39.093639,354.784302 
	C30.392870,362.935913 23.581369,372.071747 24.692219,385.042847 
	C23.829224,386.571869 22.476744,388.891998 22.954597,389.365723 
	C24.248917,390.648743 26.320387,391.147858 28.363216,392.104736 
	C29.765717,393.492279 30.888630,394.732941 32.007500,396.391052 
	C32.098286,403.772675 33.441490,404.631012 40.432758,401.997070 
	C45.863319,403.696838 50.931454,405.362915 56.042488,407.385925 
	C59.323540,411.347015 62.925877,412.260315 65.439537,409.998566 
	C72.585487,410.675293 79.291550,411.356323 86.033112,412.380249 
	C85.379074,414.482086 84.689545,416.241058 84.000008,418.000000 
M40.045219,408.748322 
	C39.920193,408.009186 39.795166,407.270020 39.670143,406.530884 
	C39.253532,406.607605 38.836918,406.684296 38.420315,406.761017 
	C38.746346,407.456818 39.072372,408.152618 40.045219,408.748322 
z"/>
<path fill="#B5D622" opacity="1.000000" stroke="none" 
	d="
M437.913483,353.872711 
	C438.463165,354.180054 439.012878,354.487396 439.864746,355.152527 
	C440.328217,355.816742 440.578644,355.935516 440.918152,355.866699 
	C440.918152,355.866699 440.949615,356.050323 441.050751,356.284424 
	C441.293976,356.824738 441.532898,356.959686 441.868652,356.923370 
	C441.868652,356.923370 441.880737,357.091919 441.962830,357.408600 
	C442.667755,358.471832 443.290619,359.218414 443.913483,359.964966 
	C443.913483,359.964966 443.912354,360.064301 443.978394,360.330566 
	C444.293640,360.804535 444.542816,361.012177 444.792053,361.219849 
	C446.988800,366.352234 449.185547,371.484619 451.691162,376.808533 
	C452.000000,381.024536 452.000000,385.049103 451.718231,389.736389 
	C449.335327,395.317139 447.234192,400.235107 445.108734,405.079773 
	C445.084412,405.006409 445.174072,405.132294 444.799744,405.113953 
	C443.409088,406.187378 442.392761,407.279114 441.357910,408.293762 
	C441.339386,408.216675 441.310211,408.372559 440.901672,408.253815 
	C439.046997,408.422974 437.475922,408.440033 436.173981,409.040527 
	C422.968201,415.132019 408.954956,416.321198 394.694092,415.987732 
	C383.127075,415.717316 371.564117,415.216827 359.996185,415.074280 
	C355.979797,415.024811 351.953949,415.742950 347.611450,416.149475 
	C346.865143,416.128387 346.439758,416.068298 345.922424,415.610474 
	C344.832458,414.809814 343.853943,414.120056 342.833313,414.050751 
	C336.570679,413.625671 330.299805,413.121704 324.029083,413.084686 
	C319.670227,413.058960 315.307190,413.739258 310.619568,414.137634 
	C309.861755,414.106354 309.430359,414.041595 308.803101,413.633728 
	C303.382080,413.234711 298.156921,413.178741 292.538879,413.162842 
	C291.098602,413.127777 290.051270,413.052643 288.754181,412.695099 
	C287.258301,411.975830 286.023743,411.197632 284.764160,411.154816 
	C272.050262,410.722229 259.332520,410.397308 246.614395,410.107361 
	C246.070938,410.094971 245.512970,410.718384 244.632172,411.081970 
	C243.865631,411.059662 243.428680,411.001038 242.709839,410.662903 
	C229.576797,409.589355 216.735229,408.475464 203.871841,408.089874 
	C190.593811,407.691803 177.292679,407.883881 164.005951,408.092255 
	C160.994400,408.139465 158.000595,409.318726 154.542831,410.043884 
	C150.410492,410.107544 146.733917,410.104309 142.770828,409.782410 
	C126.833038,409.338196 111.181831,409.201996 95.530334,409.132080 
	C94.997032,409.129730 94.460754,409.792969 93.607010,410.179535 
	C92.865974,410.156799 92.443794,410.100525 91.791801,409.736755 
	C88.022240,409.318726 84.482491,409.208191 80.610184,409.114197 
	C79.843063,409.047180 79.408493,408.963623 78.742569,408.610291 
	C71.960648,406.900848 65.407814,405.471375 58.860313,404.017853 
	C54.541325,403.059021 50.229996,402.065704 45.935555,401.107056 
	C45.955875,401.126282 45.900146,401.131470 45.908245,400.772583 
	C45.610844,399.275818 45.305344,398.137909 45.410583,397.008484 
	C48.609863,397.315186 51.398403,397.613373 54.399033,397.902832 
	C54.611126,397.894104 55.023590,397.994659 55.191597,398.326233 
	C57.910305,398.851471 60.460999,399.045135 63.335571,399.192444 
	C64.094437,399.118927 64.529427,399.091766 64.994766,399.237122 
	C65.025131,399.409668 65.048645,399.759308 65.270386,400.110657 
	C67.479599,400.581573 69.467079,400.701141 71.705826,400.776306 
	C72.291428,400.784546 72.625740,400.837219 73.219879,401.201080 
	C80.426224,401.963318 87.372749,402.414337 94.575836,402.803314 
	C95.183418,402.745941 95.534431,402.750671 96.160721,403.096313 
	C103.334335,403.957886 110.229042,404.862274 117.131699,404.927704 
	C138.393692,405.129333 159.658722,405.007050 180.922577,405.022095 
	C182.581192,405.023285 184.242615,405.200836 185.897842,405.146698 
	C193.566803,404.895813 201.234009,404.591949 209.351044,404.241638 
	C212.206741,404.257202 214.613281,404.338226 217.141113,404.757111 
	C219.842056,405.394196 222.421692,405.693420 225.461853,405.993225 
	C229.614670,405.992706 233.306961,405.991577 237.285873,405.975952 
	C238.016235,405.661194 238.459961,405.360931 238.932465,404.941071 
	C238.961227,404.821503 239.011963,404.580811 239.338867,404.491150 
	C240.108658,404.465118 240.551544,404.528778 241.155731,404.916382 
	C242.571136,405.492310 243.828827,405.980957 245.078888,405.962219 
	C300.450623,405.132080 355.938995,408.745575 411.143158,401.862579 
	C417.192841,401.108307 423.021667,398.582764 428.968933,396.927185 
	C428.984955,396.975616 428.908997,396.907562 429.221130,396.922119 
	C430.016113,396.607056 430.498932,396.277435 430.989197,395.974640 
	C430.996643,396.001434 430.954895,395.964630 431.265930,395.960815 
	C432.059906,395.620178 432.542816,395.283325 433.222870,394.788208 
	C433.887665,394.155548 434.355316,393.681183 435.070129,393.016296 
	C435.609161,392.531372 435.901093,392.237000 436.492310,391.769073 
	C440.340210,387.546021 439.188141,383.707550 436.205505,379.496979 
	C436.343994,370.665802 436.545685,362.286011 436.939453,353.894043 
	C437.392120,353.872925 437.652771,353.869873 437.913483,353.872711 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M84.468666,418.000000 
	C84.689545,416.241058 85.379074,414.482086 86.462143,412.350464 
	C93.243652,412.321472 99.629112,412.772827 106.021622,412.921448 
	C107.362122,412.952606 108.728531,411.869263 110.483452,411.242798 
	C112.229187,411.226501 113.574471,411.266846 114.914963,411.520447 
	C114.910156,411.733765 114.972092,412.155945 115.071716,412.512360 
	C117.657883,414.498932 120.041748,415.177399 122.489914,412.102692 
	C157.077347,412.363617 191.201187,412.473969 225.318481,413.005371 
	C245.910324,413.326080 266.492584,414.283264 287.077881,414.990356 
	C295.539612,415.280975 304.008057,415.515991 312.453308,416.078766 
	C315.995789,416.314880 319.486023,417.335846 323.000000,418.000000 
	C243.645767,418.000000 164.291550,418.000000 84.468666,418.000000 
z"/>
<path fill="#D6E4FA" opacity="1.000000" stroke="none" 
	d="
M200.533142,1.000000 
	C203.790756,1.903039 206.708618,2.544112 209.322296,3.812164 
	C210.776474,4.517682 212.662292,6.285656 212.638550,7.542408 
	C212.614258,8.828177 210.726532,10.634829 209.253326,11.245203 
	C200.879730,14.714532 192.067673,14.594642 183.287338,13.506236 
	C180.051743,13.105154 176.749466,12.157148 173.805634,10.764977 
	C170.217270,9.068001 170.127243,6.459104 173.700562,4.741784 
	C177.030075,3.141634 180.857559,2.577646 184.732910,1.278766 
	C190.022095,1.000000 195.044189,1.000000 200.533142,1.000000 
z"/>
<path fill="#F2FBBE" opacity="1.000000" stroke="none" 
	d="
M114.972092,412.155945 
	C114.972092,412.155945 114.910156,411.733765 115.376328,411.396301 
	C117.668037,411.026459 119.494736,411.025085 121.318893,410.956207 
	C128.565475,410.682617 135.811264,410.387939 143.057358,410.101013 
	C146.733917,410.104309 150.410492,410.107544 155.006561,410.121552 
	C184.947952,410.402313 213.969849,410.672363 242.991745,410.942413 
	C243.428680,411.001038 243.865631,411.059662 244.961700,411.330933 
	C260.081879,412.021515 274.542877,412.499542 289.003906,412.977539 
	C290.051270,413.052643 291.098602,413.127777 292.881897,413.393005 
	C298.744904,413.714325 303.871948,413.845581 308.998993,413.976837 
	C309.430359,414.041595 309.861755,414.106354 310.960785,414.370056 
	C323.090424,415.048767 334.552429,415.528503 346.014404,416.008240 
	C346.439758,416.068298 346.865143,416.128387 347.869141,416.372070 
	C349.631836,417.037079 350.815918,417.518555 352.000000,418.000000 
	C342.645782,418.000000 333.291534,418.000000 323.468658,418.000000 
	C319.486023,417.335846 315.995789,416.314880 312.453308,416.078766 
	C304.008057,415.515991 295.539612,415.280975 287.077881,414.990356 
	C266.492584,414.283264 245.910324,413.326080 225.318481,413.005371 
	C191.201187,412.473969 157.077347,412.363617 122.050110,412.065125 
	C119.086678,412.088196 117.029388,412.122070 114.972092,412.155945 
z"/>
<path fill="#6BA50A" opacity="1.000000" stroke="none" 
	d="
M63.994812,291.696960 
	C34.459850,284.907196 15.227700,266.670074 4.930690,238.517700 
	C3.811437,235.457642 2.769499,232.369308 1.345849,229.147034 
	C1.000000,224.975449 1.000000,220.950912 1.000000,216.463181 
	C5.819750,219.682327 4.878615,225.370178 6.001289,230.342316 
	C6.539815,232.727341 6.984205,235.147156 7.726824,237.469482 
	C8.899713,241.137299 9.591014,245.223129 11.719893,248.270020 
	C17.339792,256.313324 23.555080,263.947632 29.705141,271.606842 
	C30.786608,272.953674 32.767796,273.543243 34.213646,274.640472 
	C36.349903,276.261627 38.200569,278.336578 40.498375,279.647888 
	C43.392002,281.299225 46.613670,282.375763 50.996624,284.260529 
	C52.592201,288.446564 58.608688,286.734833 62.949951,288.851654 
	C63.773178,287.143463 64.496353,285.642853 65.597481,284.447937 
	C65.315231,287.068024 64.655022,289.382507 63.994812,291.696960 
z"/>
<path fill="#FBFDED" opacity="1.000000" stroke="none" 
	d="
M452.000000,396.000000 
	C452.000000,403.275543 452.000000,410.551117 452.000000,418.000000 
	C440.979584,418.000000 429.958466,418.000000 418.669556,417.665710 
	C426.496887,415.518646 434.759064,414.133240 441.310211,408.372559 
	C441.310211,408.372559 441.339386,408.216675 441.681274,408.262939 
	C443.073486,407.250214 444.123779,406.191254 445.174072,405.132294 
	C445.174072,405.132294 445.084412,405.006409 445.434509,404.972717 
	C447.856384,401.959351 449.928192,398.979675 452.000000,396.000000 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M440.901672,408.253815 
	C434.759064,414.133240 426.496887,415.518646 418.200928,417.665710 
	C396.312439,418.000000 374.624878,418.000000 352.468658,418.000000 
	C350.815918,417.518555 349.631836,417.037079 348.190063,416.333069 
	C351.953949,415.742950 355.979797,415.024811 359.996185,415.074280 
	C371.564117,415.216827 383.127075,415.717316 394.694092,415.987732 
	C408.954956,416.321198 422.968201,415.132019 436.173981,409.040527 
	C437.475922,408.440033 439.046997,408.422974 440.901672,408.253815 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M27.019951,156.019363 
	C24.283154,159.574219 21.081245,162.867981 18.908133,166.739258 
	C14.622197,174.374390 10.390067,182.120972 7.118600,190.218567 
	C4.508291,196.679657 3.356075,203.729828 1.278316,210.759262 
	C1.000000,207.969406 1.000000,204.938812 1.335831,201.284363 
	C5.128851,191.116302 8.586040,181.572113 12.043229,172.027924 
	C12.043228,172.027924 11.998273,172.002060 12.249286,171.939026 
	C12.797786,171.636337 12.888981,171.334381 12.773884,170.970123 
	C12.773884,170.970123 13.009376,170.605011 13.353310,170.251450 
	C14.473964,168.604675 15.250685,167.311478 16.027407,166.018265 
	C16.027409,166.018265 15.999163,166.000290 16.318958,165.882843 
	C19.856400,162.280533 23.074047,158.795670 26.473747,155.487946 
	C26.777182,155.783173 26.898563,155.901260 27.019951,156.019363 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M8.468653,418.000000 
	C10.156893,417.004364 12.265962,415.306152 14.480363,415.155426 
	C19.504730,414.813416 24.589764,415.098175 29.630520,415.427948 
	C31.081078,415.522827 32.464775,416.639893 33.939568,417.644165 
	C25.645767,418.000000 17.291536,418.000000 8.468653,418.000000 
z"/>
<path fill="#E6F7C9" opacity="1.000000" stroke="none" 
	d="
M11.681841,172.051025 
	C8.586040,181.572113 5.128851,191.116302 1.335831,200.830246 
	C1.000000,198.629440 1.000000,196.258896 0.999999,193.444168 
	C3.644668,186.951950 6.242194,180.882446 8.977924,174.875870 
	C9.464351,173.807861 10.524970,173.001389 11.681841,172.051025 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M452.000000,395.571533 
	C449.928192,398.979675 447.856384,401.959351 445.458801,405.046051 
	C447.234192,400.235107 449.335327,395.317139 451.718231,390.199585 
	C452.000000,391.714355 452.000000,393.428711 452.000000,395.571533 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M56.428463,418.000000 
	C57.488998,413.817017 59.472736,413.678223 61.956833,417.638275 
	C60.285641,418.000000 58.571285,418.000000 56.428463,418.000000 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M1.000000,417.500000 
	C1.323812,417.099854 1.585159,417.286957 1.892020,417.780640 
	C1.666667,418.000000 1.333333,418.000000 1.000000,417.500000 
z"/>
<path fill="#DA034A" opacity="1.000000" stroke="none" 
	d="
M320.104614,94.005402 
	C320.432800,94.006516 320.686798,94.144333 320.921173,95.011536 
	C322.155609,97.303543 323.123016,99.217003 324.567139,100.650055 
	C326.542175,102.609940 328.941956,104.133011 331.051544,105.967636 
	C342.370880,115.811531 353.680206,125.667213 364.945160,135.573227 
	C371.660736,141.478699 378.411804,147.353622 384.908142,153.494858 
	C388.641235,157.023911 387.200470,162.797836 382.204346,163.920685 
	C379.775299,164.466599 376.924835,163.332947 374.305634,162.771011 
	C372.462280,162.375519 370.687164,161.662064 368.881561,161.090591 
	C368.881561,161.090591 368.985321,161.035690 368.963318,160.721375 
	C367.330597,156.990067 364.802307,154.969849 360.913574,155.071747 
	C360.965424,155.043884 360.903412,155.143951 360.937378,154.758499 
	C360.575378,150.587708 357.854156,150.151016 354.914093,150.086548 
	C354.978485,150.050858 354.880646,150.160904 354.897217,149.796143 
	C352.908020,147.979340 350.902283,146.527298 348.939270,145.046722 
	C348.982056,145.018188 348.933197,145.108673 348.929932,144.753113 
	C346.253906,142.285645 343.581116,140.173737 340.948090,138.038544 
	C340.987823,138.015228 340.943726,138.096115 340.944580,137.742126 
	C338.019623,134.871170 335.093781,132.354202 332.074127,129.513977 
	C329.369202,127.071053 326.758087,124.951347 324.014069,122.639755 
	C323.679077,122.204796 323.438904,122.008316 323.032715,121.654533 
	C322.690765,121.205460 322.436676,121.013199 322.068237,120.540222 
	C320.840271,118.821487 319.802521,117.316483 318.514008,116.070831 
	C310.565247,108.386459 302.576294,100.743370 294.567963,93.120964 
	C288.824799,87.654579 283.089783,82.176224 277.205719,76.864326 
	C276.116943,75.881432 274.334320,75.667114 272.909485,75.066086 
	C272.946991,75.034111 272.933777,75.131828 272.858582,74.867737 
	C272.477539,74.425041 272.171661,74.246437 271.915405,74.059448 
	C271.964966,74.051071 271.900269,74.128044 271.936584,73.739746 
	C270.927460,72.615105 269.882019,71.878777 268.878601,71.098953 
	C268.920624,71.055458 268.899048,71.174484 268.831055,70.902077 
	C268.446503,70.457542 268.129913,70.285416 267.860077,70.102234 
	C267.906830,70.091171 267.888245,70.185448 267.820190,69.911133 
	C267.430939,69.471199 267.109680,69.305588 266.837219,69.127068 
	C266.886017,69.114166 266.861481,69.212074 266.803772,68.932907 
	C266.423096,68.487335 266.100159,68.320938 265.826660,68.140747 
	C265.876068,68.126945 265.848175,68.225723 265.793152,67.945152 
	C265.415558,67.495392 265.092957,67.326202 264.836670,67.150116 
	C264.902954,67.143227 264.801514,67.229675 264.841980,66.848663 
	C263.533661,65.696976 262.184875,64.926300 260.886627,64.122101 
	C260.937134,64.088570 260.895935,64.202606 260.787201,63.838394 
	C254.789444,59.104885 246.019318,59.979912 243.082062,65.180344 
	C242.992813,65.139755 243.183411,65.185524 242.806198,65.114883 
	C240.974762,66.397949 239.520554,67.751648 238.042480,69.070045 
	C238.018631,69.034744 238.102936,69.047119 237.845734,69.115868 
	C237.393768,69.469521 237.198975,69.754433 237.002655,70.017014 
	C237.001144,69.994675 237.040344,70.016319 236.672806,70.001610 
	C235.580475,70.718124 234.855682,71.449348 234.101700,72.138969 
	C234.072495,72.097374 234.173889,72.104607 233.905609,72.165634 
	C233.440582,72.522377 233.243835,72.818092 233.040878,73.069427 
	C233.034653,73.025055 233.105865,73.079468 232.732620,73.044357 
	C228.422318,74.759605 224.346313,76.353676 223.110794,81.153984 
	C223.066238,81.115349 223.184021,81.121635 222.911331,81.174133 
	C222.445160,81.526978 222.251678,81.827316 222.050461,82.086777 
	C222.042725,82.045898 222.124100,82.063263 221.865692,82.127563 
	C221.404114,82.471550 221.200928,82.751244 220.997833,83.012009 
	C220.997925,82.993088 221.033234,83.006676 220.660339,82.992897 
	C219.225220,84.034447 218.162994,85.089783 217.072357,86.106865 
	C217.043915,86.068619 217.139023,86.074974 216.875916,86.141434 
	C216.418213,86.499786 216.223633,86.791672 216.022385,87.046089 
	C216.015717,87.008614 216.078186,87.052124 215.704895,87.022560 
	C213.922684,88.378670 212.513748,89.764336 211.061462,91.097183 
	C211.018112,91.044357 211.136597,91.112434 210.756607,91.068069 
	C207.235382,91.958611 203.995834,92.790176 204.019806,97.031036 
	C204.008881,96.999420 204.065277,97.035446 203.690186,97.012596 
	C200.249954,97.957756 197.106369,98.841484 196.963776,102.963638 
	C196.988403,103.003860 196.921005,102.937317 196.562180,102.946106 
	C195.509094,103.692032 194.814819,104.429184 194.063278,105.113152 
	C194.006012,105.059967 194.145416,105.130646 193.775452,105.081375 
	C191.607132,106.020592 189.808792,107.009087 187.766418,108.155075 
	C187.337234,108.846718 187.152069,109.380867 186.728058,109.943497 
	C186.489212,109.971977 186.009079,110.002609 185.840027,110.080872 
	C185.599335,110.258133 185.445145,110.402969 185.470276,110.448196 
	C185.582382,110.650024 185.745697,110.823425 185.919586,111.268082 
	C185.975418,111.702087 186.002213,111.875099 186.017303,112.021973 
	C186.005600,111.995834 186.058609,112.017609 185.811646,112.088272 
	C185.366241,112.428329 185.167816,112.697739 184.984695,112.983421 
	C185.000000,112.999695 184.972778,112.964348 184.638367,112.993759 
	C183.914581,113.500725 183.525192,113.978287 183.043488,114.621338 
	C182.765320,114.975922 182.540222,115.080826 181.991745,115.157654 
	C181.487930,115.516235 181.268265,115.818703 181.044785,116.080734 
	C181.040970,116.040283 181.119843,116.059738 180.860870,116.126396 
	C180.403046,116.477203 180.204193,116.761353 180.002106,117.021027 
	C179.998871,116.996552 180.044296,117.015930 179.673569,116.995438 
	C178.561066,116.998589 177.819305,117.022232 176.815308,117.187248 
	C176.358154,117.875084 176.163223,118.421532 175.601486,118.980270 
	C171.156662,121.995453 167.078629,124.998337 162.862015,127.680733 
	C163.487976,124.903404 164.252533,122.446564 165.389206,119.982895 
	C166.371521,119.138107 166.981720,118.300156 167.591919,117.462204 
	C165.734909,117.304443 163.877884,117.146675 161.637024,116.992012 
	C160.484497,116.977051 159.715805,116.958984 158.948929,116.607002 
	C159.300186,115.514511 159.649628,114.755943 160.319122,113.958115 
	C161.095123,113.284996 161.551102,112.651146 161.938690,111.663986 
	C161.774002,111.090958 161.677673,110.871231 161.581329,110.651512 
	C161.581329,110.651512 161.683548,110.739586 162.029724,110.783562 
	C163.223343,109.846481 164.070786,108.865425 164.918228,107.884369 
	C164.918228,107.884361 164.993042,107.977341 165.319138,107.984222 
	C166.816971,107.480309 168.147720,107.177048 169.132599,106.422371 
	C173.137329,103.353699 177.035980,100.146591 181.200897,96.885605 
	C181.615295,96.510994 181.802536,96.241302 181.998901,95.658218 
	C182.042542,94.928230 182.077087,94.511642 182.316559,93.935997 
	C182.654633,93.464882 182.787796,93.152824 182.952606,92.866951 
	C182.984268,92.893143 182.905045,92.914948 183.177078,92.962387 
	C183.631546,93.025070 183.813980,93.040314 184.043091,93.366302 
	C186.553482,95.618523 186.614380,93.534904 187.145386,91.712311 
	C187.867889,91.295349 188.420578,91.120422 189.035431,91.299637 
	C190.088669,92.659904 190.947296,93.881935 192.115814,94.598816 
	C192.941727,95.105522 194.187454,94.927956 195.245758,95.055870 
	C194.955307,94.109810 194.854630,93.039330 194.333557,92.244446 
	C193.350143,90.744293 192.124802,89.402748 191.295868,87.841003 
	C194.672729,84.390305 197.753723,81.093887 200.874496,77.838577 
	C200.914276,77.879684 200.800034,77.873383 201.072739,77.815704 
	C201.539307,77.455833 201.733185,77.153633 201.932983,76.894058 
	C201.938904,76.936691 201.854752,76.918587 202.118301,76.856438 
	C202.581497,76.506485 202.781158,76.218689 202.987335,75.963165 
	C202.993881,75.995438 202.930435,75.977722 203.176178,75.904266 
	C203.624176,75.564056 203.826416,75.297287 204.014328,75.015411 
	C204.000000,75.000305 204.025925,75.033058 204.390839,75.030579 
	C205.443558,74.277229 206.131378,73.526367 206.864365,72.818161 
	C206.909531,72.860809 206.785797,72.849640 207.061508,72.806122 
	C207.538940,72.466522 207.740662,72.170448 207.942169,71.919983 
	C207.941971,71.965584 207.872879,71.906059 208.245743,71.947281 
	C209.404984,71.774521 210.379288,71.786674 210.949585,71.312706 
	C219.683258,64.054131 228.523178,56.911129 237.011520,49.373184 
	C245.043854,42.240177 255.180328,37.293297 265.762543,47.982166 
	C268.644348,50.893005 272.661896,52.679428 276.158203,54.981895 
	C276.471802,54.993477 276.718506,55.127853 276.971313,55.951698 
	C277.539398,57.352654 277.866486,58.373505 278.554718,58.993347 
	C289.700165,69.031174 300.831787,79.086273 312.121185,88.960709 
	C314.462158,91.008308 317.427185,92.342453 320.104614,94.005402 
z"/>
<path fill="#EFDDD3" opacity="1.000000" stroke="none" 
	d="
M356.052704,281.032928 
	C356.038452,285.711304 356.024170,290.389679 355.713440,295.825409 
	C355.275940,301.719391 355.134918,306.856049 354.989807,312.231079 
	C354.985718,312.469452 354.976990,312.946228 354.662354,313.119080 
	C353.231781,315.913910 351.346466,318.479706 351.150024,321.168854 
	C350.643555,328.101685 350.884064,335.089020 350.369507,342.040588 
	C345.277954,342.017731 340.639862,342.091583 336.011719,341.910736 
	C335.319244,341.883667 334.673492,340.661804 334.004272,339.524780 
	C334.001282,334.594147 334.018555,330.131317 333.994812,325.668701 
	C333.951416,317.510956 331.126373,314.272064 323.000580,312.748657 
	C323.005524,312.310394 323.008698,312.135162 323.431183,311.906189 
	C326.370270,312.438507 329.021820,312.705658 331.380951,313.680420 
	C335.625916,315.434265 339.695618,317.612335 343.838562,319.613190 
	C344.431793,319.249695 345.025055,318.886169 345.618286,318.522675 
	C345.618286,312.005676 345.618286,305.488647 345.708160,298.638977 
	C345.774872,297.849487 345.751709,297.392639 345.833038,296.484131 
	C345.937531,281.902252 345.937531,267.772034 345.937531,253.549149 
	C342.895233,253.964767 339.490753,254.429886 336.040100,254.498291 
	C333.961212,249.216675 331.928497,244.331772 329.919128,238.952805 
	C330.000732,233.612320 330.058990,228.765930 330.514954,223.931595 
	C332.679810,224.310501 334.433380,224.775467 336.217133,225.022003 
	C339.157776,225.428391 342.117920,225.693527 345.458252,225.916016 
	C346.225739,224.307053 346.886139,222.808762 346.931763,221.291977 
	C347.076294,216.489624 347.161255,211.672409 346.936707,206.876495 
	C346.778687,203.501892 347.578369,201.160919 351.011566,199.887650 
	C352.181580,199.453705 353.671112,198.006973 353.722595,196.959534 
	C353.773865,195.916260 352.394623,194.149170 351.308533,193.827545 
	C348.198547,192.906540 347.826935,190.897995 347.956696,188.170959 
	C348.325256,180.427048 348.592743,172.677216 348.754028,164.926666 
	C348.767334,164.285721 347.678802,163.621841 346.814880,162.676010 
	C343.261383,162.671326 339.995148,162.959259 336.415833,163.077576 
	C334.871887,160.369080 333.641052,157.830215 332.775452,155.050629 
	C333.789368,147.813904 334.437988,140.817917 335.390991,133.958694 
	C337.444855,135.429016 339.194275,136.762573 340.943726,138.096115 
	C340.943726,138.096115 340.987823,138.015228 341.081177,138.346283 
	C343.760742,140.821121 346.346954,142.964905 348.933197,145.108673 
	C348.933197,145.108673 348.982056,145.018188 349.082611,145.346008 
	C351.082336,147.169525 352.981476,148.665222 354.880646,150.160904 
	C354.880646,150.160904 354.978485,150.050858 355.022522,150.382629 
	C356.425812,151.940781 357.785095,153.167175 359.109070,154.898911 
	C358.717896,163.862671 358.291504,172.318848 358.022675,180.780045 
	C357.626923,193.237122 357.261017,205.696243 357.030701,218.157242 
	C356.643311,239.114685 356.372528,260.074249 356.052704,281.032928 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M436.747345,353.906250 
	C436.545685,362.286011 436.343994,370.665802 436.091370,379.953979 
	C436.091278,384.555817 436.142120,388.249207 436.192993,391.942627 
	C435.901093,392.237000 435.609161,392.531372 434.624054,392.931274 
	C429.081726,392.700073 424.232574,392.363342 419.781616,391.994995 
	C423.337402,392.173035 423.063263,389.945007 423.003754,387.930542 
	C422.689941,377.307983 422.226288,366.687988 422.061951,356.063293 
	C421.894531,345.237152 421.906555,334.404938 422.070374,323.578857 
	C422.122925,320.102936 422.908142,316.638062 423.369141,313.083344 
	C423.245239,313.077789 422.593384,313.048645 421.941528,313.019470 
	C424.898224,312.620697 427.883514,311.756989 430.804596,311.936859 
	C434.762573,312.180542 436.113373,314.748657 436.126160,318.822632 
	C436.159973,329.614380 436.681366,340.404602 436.771423,351.844452 
	C436.605194,352.964386 436.676270,353.435333 436.747345,353.906250 
z"/>
<path fill="#DAE5EB" opacity="1.000000" stroke="none" 
	d="
M402.036835,330.155792 
	C402.036835,330.155792 402.066895,330.403534 402.017639,331.005402 
	C401.859009,334.097290 401.749664,336.587341 401.425537,339.404480 
	C400.806824,346.815277 400.375122,353.897552 400.006653,360.983063 
	C399.629211,368.239899 399.465912,375.511749 398.904144,382.753387 
	C398.639984,386.158264 397.661438,389.507690 396.565948,392.955780 
	C393.748749,392.958496 391.372192,392.887665 388.992371,392.360596 
	C388.657959,366.271667 388.326874,340.638977 387.997925,315.003174 
	C388.000000,315.000000 388.004669,314.995026 388.351532,314.952820 
	C389.133453,313.943115 389.568512,312.975677 390.003540,312.008209 
	C397.792267,309.887512 403.475769,313.855896 402.070129,321.115173 
	C401.635712,323.358490 402.004761,325.757385 401.819977,328.651733 
	C401.768280,329.530914 401.902557,329.843353 402.036835,330.155792 
z"/>
<path fill="#DAE4EA" opacity="1.000000" stroke="none" 
	d="
M354.976990,312.946228 
	C354.976990,312.946228 354.985718,312.469452 355.285248,312.217804 
	C356.056458,311.663025 356.528137,311.359863 356.999817,311.056702 
	C364.410675,311.452881 365.965485,312.666107 368.104523,319.208954 
	C368.381287,320.055481 369.615570,320.589020 370.276550,321.575317 
	C369.155579,323.002899 367.411285,324.057098 367.306885,325.254272 
	C366.911041,329.793793 367.046234,334.379547 366.724365,339.193848 
	C366.315430,344.821045 366.284149,350.209015 365.989166,355.582489 
	C365.402069,366.277191 364.734619,376.968903 363.909393,387.647095 
	C363.814697,388.872772 362.504883,390.004517 361.388672,391.147552 
	C359.018494,390.413788 357.018036,389.712280 354.995361,388.587708 
	C354.654694,386.385315 354.105408,384.612549 354.054413,382.825592 
	C353.653748,368.782684 353.031860,354.734955 353.125793,340.693054 
	C353.187683,331.440399 354.322540,322.194916 354.976990,312.946228 
z"/>
<path fill="#D6E4FA" opacity="1.000000" stroke="none" 
	d="
M185.200867,38.974327 
	C178.908905,37.840485 172.987579,37.024654 167.292282,35.410999 
	C165.279007,34.840569 163.817505,32.322712 162.104095,30.693951 
	C163.847015,28.918446 165.279175,26.234472 167.383224,25.514734 
	C181.133789,20.811056 195.061050,21.171848 208.798798,25.613840 
	C210.817932,26.266712 212.206924,28.868414 213.887482,30.568409 
	C212.119888,32.129921 210.561356,34.640045 208.547394,35.083958 
	C200.999054,36.747738 193.301895,37.736340 185.200867,38.974327 
z"/>
<path fill="#B20C34" opacity="1.000000" stroke="none" 
	d="
M168.034683,69.224281 
	C162.280670,68.030586 160.568451,63.823597 159.983032,58.642841 
	C159.565704,54.949852 160.172714,51.989487 164.530212,51.206215 
	C161.348282,54.353050 160.057678,57.633511 162.078064,61.980053 
	C164.145752,66.428391 168.030838,66.674515 171.755386,65.864037 
	C176.340744,64.866219 180.740738,62.984982 185.188156,61.394188 
	C189.176285,59.967670 189.713074,56.638172 188.799774,53.245689 
	C187.926636,50.002266 185.270081,48.627159 181.858383,48.992199 
	C176.313477,49.585491 170.768555,50.178631 165.223648,50.771835 
	C174.206406,49.239521 183.157562,47.468502 192.184875,46.272564 
	C196.911423,45.646397 201.771530,46.014381 206.571625,45.961864 
	C210.645859,45.917286 212.567123,48.197224 212.977356,52.029362 
	C213.760773,59.347961 212.293594,62.327213 205.956573,64.971413 
	C198.337173,65.967590 191.449646,66.846771 184.146179,67.706345 
	C178.498383,68.199249 173.266541,68.711769 168.034683,69.224281 
z"/>
<path fill="#D5074A" opacity="1.000000" stroke="none" 
	d="
M165.172943,50.772797 
	C170.768555,50.178631 176.313477,49.585491 181.858383,48.992199 
	C185.270081,48.627159 187.926636,50.002266 188.799774,53.245689 
	C189.713074,56.638172 189.176285,59.967670 185.188156,61.394188 
	C180.740738,62.984982 176.340744,64.866219 171.755386,65.864037 
	C168.030838,66.674515 164.145752,66.428391 162.078064,61.980053 
	C160.057678,57.633511 161.348282,54.353050 164.916992,51.185947 
	C165.066406,50.858395 165.122253,50.773758 165.172943,50.772797 
z"/>
<path fill="#F2D7D5" opacity="1.000000" stroke="none" 
	d="
M184.562119,67.725960 
	C191.449646,66.846771 198.337173,65.967590 205.633881,65.134903 
	C206.672668,66.833542 207.302307,68.485687 208.135132,70.566345 
	C208.183167,71.298599 208.028015,71.602333 207.872879,71.906059 
	C207.872879,71.906059 207.941971,71.965584 207.672180,71.976311 
	C207.196869,72.274574 206.991333,72.562111 206.785797,72.849640 
	C206.785797,72.849640 206.909531,72.860809 206.565491,72.895966 
	C205.489609,73.631767 204.757767,74.332413 204.025925,75.033058 
	C204.025925,75.033058 204.000000,75.000305 203.753433,75.064651 
	C203.314728,75.411903 203.122589,75.694817 202.930450,75.977722 
	C202.930435,75.977722 202.993881,75.995438 202.726669,76.030396 
	C202.257889,76.349770 202.056320,76.634178 201.854752,76.918587 
	C201.854752,76.918587 201.938904,76.936691 201.660858,76.955818 
	C201.188538,77.274422 200.994278,77.573898 200.800018,77.873383 
	C200.800034,77.873383 200.914276,77.879684 200.550888,77.935631 
	C196.400742,81.259903 192.613983,84.528229 188.846405,87.802132 
	C188.865570,87.807701 188.847870,87.811974 188.530029,87.901520 
	C186.791977,89.317642 185.371750,90.644226 183.967438,91.983269 
	C183.983368,91.995728 183.974548,91.961540 183.720520,92.023453 
	C183.279358,92.361893 183.092209,92.638428 182.905060,92.914948 
	C182.905045,92.914948 182.984268,92.893143 182.715424,92.864250 
	C182.446594,92.835358 181.973343,92.805878 181.978867,92.423645 
	C183.573807,85.481812 185.336624,78.955544 186.609131,72.335037 
	C186.869614,70.979897 185.292297,69.271515 184.562119,67.725960 
z"/>
<path fill="#FDEEE4" opacity="1.000000" stroke="none" 
	d="
M184.146179,67.706345 
	C185.292297,69.271515 186.869614,70.979897 186.609131,72.335037 
	C185.336624,78.955544 183.573807,85.481812 181.963669,92.502510 
	C181.942947,92.963615 181.782532,92.955292 181.614441,93.024155 
	C181.230515,93.227974 181.087433,93.417778 180.738098,93.861214 
	C178.985657,95.059502 177.512238,96.059006 176.038803,97.058510 
	C174.802948,89.251808 173.727890,81.413231 172.191925,73.666023 
	C171.894104,72.163864 169.798065,71.018211 168.283081,69.466202 
	C173.266541,68.711769 178.498383,68.199249 184.146179,67.706345 
z"/>
<path fill="#FAF1EA" opacity="1.000000" stroke="none" 
	d="
M356.442749,280.928284 
	C356.372528,260.074249 356.643311,239.114685 357.030701,218.157242 
	C357.261017,205.696243 357.626923,193.237122 358.022675,180.780045 
	C358.291504,172.318848 358.717896,163.862671 359.401154,155.033875 
	C360.120117,154.823639 360.511780,154.983795 360.903412,155.143951 
	C360.903412,155.143951 360.965424,155.043884 360.978149,155.323303 
	C361.450989,156.097519 361.911072,156.592316 362.371155,157.087112 
	C361.916473,161.903854 361.198242,166.712784 361.055511,171.538757 
	C360.590210,187.273438 360.429077,203.017059 359.975159,218.752167 
	C359.407318,238.437988 358.681152,258.119354 357.953217,277.800079 
	C357.915466,278.820312 357.221313,279.816315 356.442749,280.928284 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M368.999817,161.414490 
	C370.687164,161.662064 372.462280,162.375519 374.305634,162.771011 
	C376.924835,163.332947 379.775299,164.466599 382.204346,163.920685 
	C387.200470,162.797836 388.641235,157.023911 384.908142,153.494858 
	C378.411804,147.353622 371.660736,141.478699 364.945160,135.573227 
	C353.680206,125.667213 342.370880,115.811531 331.051544,105.967636 
	C328.941956,104.133011 326.542175,102.609940 324.567139,100.650055 
	C323.123016,99.217003 322.155609,97.303543 320.992035,95.224518 
	C338.665192,110.256004 356.321472,125.667755 373.979126,141.077972 
	C377.862610,144.467148 381.784210,147.813568 385.626709,151.248474 
	C389.000336,154.264221 389.752075,158.963593 387.304413,162.949524 
	C384.841644,166.960052 380.997894,167.762512 376.875610,166.141403 
	C374.136200,165.064148 371.693542,163.232422 368.999817,161.414490 
z"/>
<path fill="#F6FCFA" opacity="1.000000" stroke="none" 
	d="
M421.737305,313.115112 
	C422.593384,313.048645 423.245239,313.077789 423.369141,313.083344 
	C422.908142,316.638062 422.122925,320.102936 422.070374,323.578857 
	C421.906555,334.404938 421.894531,345.237152 422.061951,356.063293 
	C422.226288,366.687988 422.689941,377.307983 423.003754,387.930542 
	C423.063263,389.945007 423.337402,392.173035 419.678772,391.655640 
	C419.213440,390.881714 419.249084,390.415619 419.528168,389.622498 
	C419.853271,383.228333 420.057404,377.159546 419.977020,371.094543 
	C419.914795,366.398376 419.496552,361.706940 419.149292,356.709747 
	C419.023743,355.986633 418.985413,355.567200 418.992828,354.675110 
	C419.029541,350.127716 419.020508,346.052917 419.013306,341.740967 
	C419.015167,341.503815 418.987976,341.030304 418.995300,340.742828 
	C418.981323,340.264801 418.960022,340.074249 418.953552,339.571564 
	C418.968811,338.842712 418.969269,338.426056 418.983887,337.760803 
	C418.998077,337.512207 418.974609,337.014801 418.974609,337.014801 
	C418.974609,337.014801 418.991455,336.992310 419.299805,336.694641 
	C420.074768,328.908417 420.541351,321.419891 421.007935,313.931366 
	C421.103302,313.633057 421.278351,313.392883 421.737305,313.115112 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M208.245743,71.947281 
	C208.028015,71.602333 208.183167,71.298599 208.482910,70.777710 
	C218.149704,62.567398 227.661011,54.561268 237.196808,46.584423 
	C248.893585,36.799919 257.222137,37.027390 268.163666,47.341534 
	C270.693481,49.726292 273.314087,52.014709 276.025574,54.664680 
	C272.661896,52.679428 268.644348,50.893005 265.762543,47.982166 
	C255.180328,37.293297 245.043854,42.240177 237.011520,49.373184 
	C228.523178,56.911129 219.683258,64.054131 210.949585,71.312706 
	C210.379288,71.786674 209.404984,71.774521 208.245743,71.947281 
z"/>
<path fill="#E6F7C9" opacity="1.000000" stroke="none" 
	d="
M30.045185,151.098877 
	C33.055450,148.130585 35.554321,143.871536 39.172638,142.437958 
	C49.692226,138.270050 60.477783,134.352371 71.517273,132.067490 
	C78.739449,130.572723 86.837639,130.937576 94.088058,132.535629 
	C103.777351,134.671234 113.025314,138.809189 122.802994,142.393982 
	C123.143188,142.700714 123.014885,143.124863 122.730423,143.200745 
	C121.917786,143.511169 121.389626,143.745712 120.650665,143.724915 
	C110.258041,137.485977 99.183853,134.247955 87.484383,133.421677 
	C71.270477,132.276581 55.873238,135.148544 41.874477,143.884186 
	C41.874477,143.884186 42.002411,143.906921 41.598343,143.919662 
	C37.477913,146.321228 33.761551,148.710052 30.045185,151.098877 
z"/>
<path fill="#B5D255" opacity="1.000000" stroke="none" 
	d="
M366.982819,338.948029 
	C367.046234,334.379547 366.911041,329.793793 367.306885,325.254272 
	C367.411285,324.057098 369.155579,323.002899 370.550476,321.791321 
	C375.292267,322.576202 379.630341,323.452820 384.385315,324.292694 
	C384.802216,324.255920 384.847260,324.236450 384.856628,324.632843 
	C384.834229,326.371552 384.802460,327.713867 384.440643,328.979645 
	C383.462433,328.168793 382.858459,327.389069 382.158325,326.708191 
	C379.362274,323.989014 370.765198,322.922424 370.074982,325.387054 
	C369.177979,328.590118 368.191620,333.333130 369.809021,335.259735 
	C371.629944,337.428711 376.270599,337.610138 379.748535,337.906006 
	C383.548492,338.229309 382.761292,334.877472 383.174683,332.540009 
	C383.281921,331.933411 384.220642,331.473877 384.893921,331.377502 
	C384.684601,334.589142 384.358795,337.370056 383.568604,340.109375 
	C377.730408,339.694550 372.356598,339.321289 366.982819,338.948029 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M319.961182,93.696442 
	C317.427185,92.342453 314.462158,91.008308 312.121185,88.960709 
	C300.831787,79.086273 289.700165,69.031174 278.554718,58.993347 
	C277.866486,58.373505 277.539398,57.352654 277.042450,56.156837 
	C291.299652,68.326027 305.558716,80.856758 319.961182,93.696442 
z"/>
<path fill="#B7D142" opacity="1.000000" stroke="none" 
	d="
M418.969727,338.009369 
	C418.969269,338.426056 418.968811,338.842712 418.491119,339.584869 
	C412.556000,339.632660 407.098145,339.355011 401.640320,339.077332 
	C401.749664,336.587341 401.859009,334.097290 401.980896,331.124634 
	C407.053009,332.774384 412.112610,334.906769 417.627747,337.353943 
	C418.323334,337.926392 418.618805,338.039948 418.969727,338.009369 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M29.943951,151.317596 
	C33.761551,148.710052 37.477913,146.321228 41.541676,143.887878 
	C41.302601,145.097137 40.716125,146.350937 40.011517,147.857285 
	C41.167030,148.171555 42.281788,148.474747 43.689438,149.147919 
	C43.982330,149.517899 43.981213,150.000000 43.682098,150.035828 
	C40.591648,151.374344 37.800312,152.677032 35.005714,153.990021 
	C35.002445,154.000305 35.017879,153.993759 34.852207,153.747040 
	C34.130726,153.337753 33.574917,153.175217 32.698330,153.047516 
	C31.917324,153.710220 31.457090,154.338074 30.996857,154.965927 
	C29.899637,155.289337 28.802422,155.612747 27.362576,155.977768 
	C26.898563,155.901260 26.777182,155.783173 26.640036,155.226868 
	C27.424948,153.862503 28.225626,152.936371 29.026300,152.010223 
	C29.364220,151.965561 29.636358,151.807587 29.943951,151.317596 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M132.843979,134.837189 
	C139.315704,129.257446 145.787415,123.677696 152.666809,118.085480 
	C153.074478,118.073021 153.246414,118.248245 153.139343,118.538445 
	C152.343689,119.864983 151.655090,120.901329 150.669617,122.091797 
	C144.658752,126.573524 138.944778,130.901123 133.133881,135.131058 
	C133.036957,135.033386 132.843979,134.837189 132.843979,134.837189 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M175.900391,97.366257 
	C177.512238,96.059006 178.985657,95.059502 180.912643,93.907196 
	C181.366196,93.754402 181.726929,93.734505 181.822433,93.825363 
	C181.917938,93.916222 182.111633,94.095047 182.111633,94.095047 
	C182.077087,94.511642 182.042542,94.928230 181.778137,95.771286 
	C181.272430,96.400871 181.080902,96.665131 180.973740,96.990524 
	C177.035980,100.146591 173.137329,103.353699 169.132599,106.422371 
	C168.147720,107.177048 166.816971,107.480309 165.268616,107.952484 
	C168.515320,104.500580 172.138657,101.087296 175.900391,97.366257 
z"/>
<path fill="#E6F7C9" opacity="1.000000" stroke="none" 
	d="
M28.659920,151.990097 
	C28.225626,152.936371 27.424948,153.862503 26.457981,155.049713 
	C23.074047,158.795670 19.856400,162.280533 16.329124,165.896378 
	C17.335783,158.918549 22.628391,155.281616 28.659920,151.990097 
z"/>
<path fill="#FAF1EA" opacity="1.000000" stroke="none" 
	d="
M356.998749,310.585693 
	C356.528137,311.359863 356.056458,311.663025 355.289337,311.979431 
	C355.134918,306.856049 355.275940,301.719391 355.716187,296.291351 
	C356.342834,300.704834 356.670258,305.409760 356.998749,310.585693 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M420.670197,314.148071 
	C420.541351,321.419891 420.074768,328.908417 419.308502,336.683563 
	C419.007019,331.094849 418.909088,325.216949 419.064392,319.345764 
	C419.108582,317.675415 419.889404,316.024567 420.670197,314.148071 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M362.576660,157.163300 
	C361.911072,156.592316 361.450989,156.097519 360.926300,155.351166 
	C364.802307,154.969849 367.330597,156.990067 368.927704,160.768005 
	C368.206818,160.772003 367.499603,160.415039 366.382172,159.744446 
	C365.650543,159.303009 365.329132,159.175232 365.007690,159.047455 
	C365.007690,159.047440 365.010284,158.993607 364.870667,158.700073 
	C364.160889,158.140167 363.590668,157.873795 363.020477,157.607407 
	C363.020477,157.607407 362.782166,157.239471 362.576660,157.163300 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M161.265564,110.740562 
	C161.677673,110.871231 161.774002,111.090958 161.670685,111.778625 
	C160.980408,112.830170 160.489731,113.413765 159.999084,113.997375 
	C159.649628,114.755943 159.300186,115.514511 158.961487,116.616669 
	C158.972244,116.960258 158.955246,116.954285 158.565720,116.953140 
	C157.139572,116.999031 156.102951,117.046059 154.927399,116.928467 
	C154.788467,116.763840 154.496262,116.447258 154.496262,116.447266 
	C156.647446,114.574715 158.798615,112.702164 161.265564,110.740562 
z"/>
<path fill="#DAE5EB" opacity="1.000000" stroke="none" 
	d="
M384.786194,324.274719 
	C385.781342,320.666260 383.209167,316.196594 387.679565,313.468750 
	C388.005493,314.086060 388.005066,314.540558 388.004669,314.995026 
	C388.004669,314.995026 388.000000,315.000000 387.659790,315.183624 
	C386.495483,318.323639 385.671356,321.280060 384.847260,324.236450 
	C384.847260,324.236450 384.802216,324.255920 384.786194,324.274719 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M123.014885,143.124863 
	C123.014885,143.124863 123.143188,142.700714 123.168137,142.481995 
	C124.563042,141.206604 125.933006,140.149948 127.687668,139.082184 
	C128.072372,139.071091 128.225174,139.227295 128.121399,139.521301 
	C127.331955,140.865173 126.646301,141.915054 125.640991,143.001434 
	C124.556404,143.421783 123.791473,143.805618 123.009583,143.923462 
	C123.000046,143.479950 123.007462,143.302414 123.014885,143.124863 
z"/>
<path fill="#E6F7C9" opacity="1.000000" stroke="none" 
	d="
M15.635208,166.010834 
	C15.250685,167.311478 14.473964,168.604675 13.402784,170.039917 
	C13.819888,168.789108 14.531449,167.396255 15.635208,166.010834 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M129.435928,137.418259 
	C130.372238,136.610733 131.308563,135.803207 132.544434,134.916443 
	C132.843979,134.837189 133.036957,135.033386 133.089630,135.328644 
	C133.142303,135.623917 133.113861,136.030701 132.937714,136.302246 
	C131.852921,137.065552 130.944275,137.557312 129.890747,137.886597 
	C129.745834,137.724106 129.435928,137.418259 129.435928,137.418259 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M164.610657,107.957916 
	C164.070786,108.865425 163.223343,109.846481 162.013763,110.718010 
	C162.535461,109.749481 163.419281,108.890472 164.610657,107.957916 
z"/>
<path fill="#F3FBF1" opacity="1.000000" stroke="none" 
	d="
M388.351532,314.952820 
	C388.005066,314.540558 388.005493,314.086060 388.011780,313.289734 
	C388.483643,312.643616 388.949615,312.339386 389.709595,312.021667 
	C389.568512,312.975677 389.133453,313.943115 388.351532,314.952820 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M443.944153,359.621368 
	C443.290619,359.218414 442.667755,358.471832 442.045227,357.427246 
	C442.688629,357.845398 443.331726,358.561584 443.944153,359.621368 
z"/>
<path fill="#639A3D" opacity="1.000000" stroke="none" 
	d="
M436.939453,353.894043 
	C436.676270,353.435333 436.605194,352.964386 436.729248,352.320740 
	C437.219208,352.557953 437.514038,352.967834 437.861176,353.625244 
	C437.652771,353.869873 437.392120,353.872925 436.939453,353.894043 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M127.996338,138.992615 
	C128.268219,138.505310 128.616150,138.096481 129.200012,137.552948 
	C129.435928,137.418259 129.745834,137.724106 129.730103,138.114883 
	C129.217972,138.746201 128.721573,138.986755 128.225174,139.227295 
	C128.225174,139.227295 128.072372,139.071091 127.996338,138.992615 
z"/>
<path fill="#FAF1EA" opacity="1.000000" stroke="none" 
	d="
M363.120575,157.924683 
	C363.590668,157.873795 364.160889,158.140167 364.843872,158.702820 
	C364.378021,158.746704 363.799347,158.494324 363.120575,157.924683 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M152.988846,117.985077 
	C153.268143,117.495682 153.633057,117.094223 154.247131,116.570007 
	C154.496262,116.447258 154.788467,116.763840 154.757416,117.146912 
	C154.233047,117.769409 153.739731,118.008827 153.246414,118.248245 
	C153.246414,118.248245 153.074478,118.073021 152.988846,117.985077 
z"/>
<path fill="#BED5D0" opacity="1.000000" stroke="none" 
	d="
M402.027283,329.870300 
	C401.902557,329.843353 401.768280,329.530914 401.805115,329.116760 
	C401.990051,329.204987 402.003906,329.394928 402.027283,329.870300 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M440.854187,355.680542 
	C440.578644,355.935516 440.328217,355.816742 440.158447,355.289307 
	C440.424591,355.118530 440.637970,355.260559 440.854187,355.680542 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M418.983887,337.760803 
	C418.618805,338.039948 418.323334,337.926392 417.966919,337.456543 
	C418.034821,337.190918 418.219147,337.137512 418.689026,337.049438 
	C418.974609,337.014801 418.998077,337.512207 418.983887,337.760803 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M444.773285,360.968109 
	C444.542816,361.012177 444.293640,360.804535 444.024963,360.347870 
	C444.255157,360.304688 444.504852,360.510529 444.773285,360.968109 
z"/>
<path fill="#FAF1EA" opacity="1.000000" stroke="none" 
	d="
M365.137238,159.275513 
	C365.329132,159.175232 365.650543,159.303009 366.090698,159.665878 
	C365.895172,159.768494 365.580994,159.636032 365.137238,159.275513 
z"/>
<path fill="#E6F7C9" opacity="1.000000" stroke="none" 
	d="
M12.563580,171.078827 
	C12.888981,171.334381 12.797786,171.636337 12.264696,171.960022 
	C11.985819,171.701233 12.093879,171.415726 12.563580,171.078827 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M441.824554,356.725891 
	C441.532898,356.959686 441.293976,356.824738 441.142395,356.301025 
	C441.419525,356.128937 441.635376,356.277252 441.824554,356.725891 
z"/>
<path fill="#868814" opacity="1.000000" stroke="none" 
	d="
M64.287308,291.833496 
	C64.655022,289.382507 65.315231,287.068024 65.956741,284.370667 
	C65.622482,277.616791 65.306923,271.245850 64.990692,264.028564 
	C64.327667,260.454193 63.665314,257.726196 63.002960,254.998169 
	C61.340485,248.922531 59.678005,242.846878 57.986782,236.089142 
	C57.641979,234.935806 57.325924,234.464539 57.009865,233.993286 
	C56.906990,231.561401 56.804119,229.129517 57.240322,226.351501 
	C61.177849,227.686432 64.576302,229.367477 67.974754,231.048538 
	C68.840157,232.523071 69.705566,233.997620 70.775131,236.116791 
	C71.215096,237.689651 71.450905,238.617844 71.686714,239.546051 
	C72.130463,239.505936 72.574219,239.465836 73.017967,239.425720 
	C73.017967,237.949783 73.017967,236.473831 73.017967,234.997879 
	C73.352188,232.285858 73.686409,229.573837 74.286133,226.196747 
	C74.685928,224.352676 74.820236,223.173645 74.954544,221.994629 
	C75.648041,215.317749 76.341530,208.640884 77.382156,201.457092 
	C79.812416,199.981934 81.895538,199.013702 83.978668,198.045486 
	C83.985275,198.478806 83.991875,198.912109 84.022049,199.969101 
	C84.363579,201.062744 84.681534,201.532730 84.999489,202.002701 
	C86.662827,212.223145 88.299660,222.448029 90.008629,232.660828 
	C90.421707,235.129395 91.073120,237.558090 91.588676,239.886536 
	C96.450081,232.491257 101.027908,225.527374 106.067917,218.091797 
	C106.742561,216.764648 106.955032,215.909225 107.167511,215.053787 
	C108.354820,214.565475 109.542130,214.077164 110.956161,213.495605 
	C114.945847,217.527954 112.496590,221.701920 110.823494,225.553436 
	C109.494659,228.612457 110.670692,229.029160 113.786362,229.201050 
	C115.879364,229.274765 117.381920,229.093765 118.884476,228.912781 
	C116.245110,231.103821 113.918709,234.091812 110.886520,235.282547 
	C107.163940,236.744415 106.198830,239.333511 104.980194,242.526993 
	C99.215714,257.632935 101.605766,272.840149 104.004898,288.742828 
	C103.987236,289.408478 103.899315,289.897064 103.686913,289.927979 
	C103.474510,289.958923 103.044930,289.983704 102.701309,289.806702 
	C101.702858,286.969208 100.555771,284.322479 100.480591,281.645691 
	C100.193733,271.432007 99.157471,261.059662 100.521019,251.034973 
	C101.654327,242.702896 105.802101,234.789001 108.529022,226.661148 
	C109.494049,223.784805 110.206390,220.823669 111.255379,217.119949 
	C99.843361,224.447464 97.676918,236.605621 92.276680,248.424301 
	C90.671906,245.638474 89.347511,244.291122 89.115097,242.776215 
	C87.912514,234.937546 87.138817,227.032669 85.914749,219.197861 
	C85.125641,214.147079 83.818497,209.177216 82.743515,204.171097 
	C82.227440,204.156631 81.711357,204.142166 81.195274,204.127701 
	C80.494385,205.538239 79.364990,206.889709 79.161140,208.368759 
	C77.671700,219.175140 76.446175,230.018143 74.924103,240.819550 
	C74.687584,242.497971 73.364014,244.023239 72.543121,245.619324 
	C71.624245,244.322479 70.600853,243.086380 69.805496,241.717743 
	C67.098076,237.058853 66.168388,230.975800 59.013157,228.688889 
	C59.293961,230.622131 59.274452,231.882721 59.687660,232.980423 
	C60.207420,234.361206 61.359848,235.528976 61.759567,236.925934 
	C66.439827,253.282928 69.135857,269.909729 67.907822,286.981781 
	C67.781685,288.735352 66.569153,290.410828 65.860748,292.122528 
	C65.433769,292.071686 65.006783,292.020874 64.287308,291.833496 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M122.730423,143.200745 
	C123.007462,143.302414 123.000046,143.479950 122.998787,144.272614 
	C121.957954,146.451141 120.910950,148.014526 119.693802,149.831955 
	C121.007980,153.636276 119.936188,154.750992 115.685791,154.931656 
	C113.763290,155.013367 110.821701,156.499374 110.275726,158.035446 
	C109.292419,160.801880 109.761055,163.928726 113.738556,165.019058 
	C119.081741,166.483704 124.355980,162.987732 124.838432,157.666931 
	C126.130699,157.410614 127.655724,157.559174 128.493423,156.871033 
	C135.544952,151.078430 142.372879,145.011658 149.469345,139.276413 
	C154.038467,135.583740 157.005630,129.966141 163.000610,128.001221 
	C167.078629,124.998337 171.156662,121.995453 175.611298,118.990257 
	C175.987915,118.987946 176.004211,119.004158 176.022949,119.329948 
	C176.071960,119.871170 176.102249,120.086609 175.955414,120.578476 
	C174.380692,122.019661 172.971497,123.170799 171.587326,124.351273 
	C159.278824,134.848419 146.974762,145.350739 134.418747,155.983704 
	C133.964722,156.627350 133.761337,157.138824 133.230423,157.702057 
	C131.617233,158.835388 130.331573,159.916992 129.045914,160.998596 
	C129.045914,160.998596 128.990891,160.991196 128.734756,161.059235 
	C128.368103,161.260086 128.257599,161.392899 128.147079,161.525726 
	C127.507904,161.913239 126.868729,162.300751 125.800186,162.926849 
	C124.600090,164.074005 123.829361,164.982574 123.058624,165.891144 
	C123.058624,165.891144 122.907730,165.915573 122.669724,166.031860 
	C122.131279,166.320679 121.972122,166.580032 121.954239,166.926208 
	C121.954239,166.926208 121.947243,166.979919 121.530869,166.955338 
	C118.453453,167.405884 115.792404,167.881027 112.853378,168.120987 
	C105.395485,162.915207 105.289833,157.191864 112.067734,151.897125 
	C115.168350,149.475006 117.940399,146.632248 120.861465,143.980255 
	C121.389626,143.745712 121.917786,143.511169 122.730423,143.200745 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M120.650673,143.724915 
	C117.940399,146.632248 115.168350,149.475006 112.067734,151.897125 
	C105.289833,157.191864 105.395485,162.915207 112.981010,168.397827 
	C113.107864,171.079407 112.829117,173.248947 112.531227,175.567490 
	C114.101059,175.363800 115.527512,175.178696 116.953972,174.993607 
	C114.979614,175.767502 113.005257,176.541382 111.030899,177.315277 
	C110.470322,175.347504 109.933319,173.372604 109.341888,171.414139 
	C108.849098,169.782318 108.704582,167.830017 107.695099,166.605621 
	C102.413765,160.199905 104.882912,154.075577 110.566635,150.155365 
	C112.929909,148.525345 114.917549,146.350693 118.211243,143.410202 
	C111.798569,141.679565 106.816513,140.335037 101.417229,138.995239 
	C101.000000,139.000000 101.003128,139.002945 101.003128,139.002945 
	C91.457260,133.723846 81.308403,134.294327 71.234222,136.504791 
	C61.637524,138.610474 52.203140,141.455902 42.285934,143.930145 
	C55.873238,135.148544 71.270477,132.276581 87.484383,133.421677 
	C99.183853,134.247955 110.258041,137.485977 120.650673,143.724915 
z"/>
<path fill="#6FA43A" opacity="1.000000" stroke="none" 
	d="
M129.442032,161.019867 
	C130.331573,159.916992 131.617233,158.835388 133.374725,158.187347 
	C134.907898,193.991745 135.919525,229.364212 137.096329,264.731201 
	C137.236511,268.943878 136.782562,272.585724 132.927536,275.339783 
	C131.555771,276.072052 130.476105,276.732361 129.280029,277.277161 
	C129.163620,277.161682 128.931152,276.930389 128.931152,276.930389 
	C129.886002,275.849274 130.715790,274.614899 131.818481,273.714935 
	C135.581512,270.643738 136.738449,266.857758 135.612579,262.160431 
	C135.155426,260.253235 135.586731,258.077301 134.903183,256.296356 
	C134.268143,254.641800 132.779404,253.261398 131.492584,251.928192 
	C130.544891,250.946365 129.316910,250.235046 128.213837,249.403198 
	C129.123413,248.295044 129.818527,246.636017 130.983032,246.182678 
	C134.392715,244.855286 135.181641,242.566284 135.043137,239.156784 
	C134.565262,227.392807 134.434647,215.614899 133.973221,203.850082 
	C133.454224,190.617905 132.768463,177.391296 131.989044,164.172089 
	C131.925262,163.089920 130.585190,162.083008 129.442032,161.019867 
z"/>
<path fill="#3D9713" opacity="1.000000" stroke="none" 
	d="
M24.363003,230.066010 
	C26.128996,230.830185 27.489054,231.552277 29.012783,232.361282 
	C23.322786,238.106461 18.038446,238.326370 13.572188,233.450912 
	C15.841166,228.696259 20.194361,230.251617 24.363003,230.066010 
z"/>
<path fill="#399410" opacity="1.000000" stroke="none" 
	d="
M73.758781,192.540115 
	C71.835953,192.549301 70.200302,192.608826 68.661591,192.251236 
	C67.428902,191.964783 66.313919,191.171783 65.146698,190.603577 
	C65.830612,189.452454 66.366539,187.449509 67.224525,187.300476 
	C70.599937,186.714096 74.076683,186.711029 77.515808,186.491409 
	C77.808693,187.201202 78.101578,187.910995 78.394470,188.620789 
	C76.958916,189.883972 75.523361,191.147156 73.758781,192.540115 
z"/>
<path fill="#3D9713" opacity="1.000000" stroke="none" 
	d="
M88.987213,145.034576 
	C89.493507,144.419968 89.999794,143.805344 91.102623,143.038345 
	C92.794724,142.257507 93.890289,141.629074 94.985863,141.000641 
	C95.156471,141.003250 95.327080,141.005844 95.839035,141.174835 
	C96.446228,141.228287 96.712067,141.115356 96.977905,141.002441 
	C96.977905,141.002441 97.000648,141.000610 97.402748,140.994568 
	C98.863686,141.001633 99.922531,141.014755 100.981369,141.027893 
	C101.266464,142.550278 104.925766,143.133362 101.847260,145.859039 
	C100.995552,146.505905 100.519455,146.781296 100.043350,147.056671 
	C99.579193,147.059280 99.115036,147.061874 98.092293,147.160522 
	C97.271545,147.435379 97.102013,147.678757 97.025108,147.986725 
	C94.915619,147.359177 92.806129,146.731628 90.228973,145.846405 
	C89.570198,145.310516 89.312172,145.125809 88.987213,145.034576 
z"/>
<path fill="#6BA50A" opacity="1.000000" stroke="none" 
	d="
M62.726768,255.192413 
	C63.665314,257.726196 64.327667,260.454193 64.978050,263.595123 
	C60.507366,263.898499 55.817444,260.279877 55.318176,256.061066 
	C55.171711,254.823441 56.527523,253.408020 57.238529,251.986679 
	C59.610580,252.988281 56.979549,260.472382 62.726768,255.192413 
z"/>
<path fill="#9DC028" opacity="1.000000" stroke="none" 
	d="
M118.903465,228.923309 
	C117.381920,229.093765 115.879364,229.274765 114.173653,229.180481 
	C115.804893,224.722900 115.108849,218.793762 122.656952,217.478622 
	C121.772156,221.099899 121.026009,224.153702 119.890442,227.663727 
	C119.321785,228.394669 119.142548,228.669388 118.963303,228.944122 
	C118.963310,228.944122 118.922447,228.933838 118.903465,228.923309 
z"/>
<path fill="#429E19" opacity="1.000000" stroke="none" 
	d="
M35.008980,153.979721 
	C37.800312,152.677032 40.591648,151.374344 43.889614,150.292664 
	C48.229942,150.928833 47.141121,153.686844 47.003662,155.992065 
	C45.266495,156.329926 43.529327,156.667770 41.148666,157.002441 
	C40.337013,157.002472 40.168854,157.005646 40.000690,157.008820 
	C38.577122,156.695511 37.153553,156.382202 35.363869,155.689240 
	C35.001492,154.866302 35.005234,154.423019 35.008980,153.979721 
z"/>
<path fill="#9DC028" opacity="1.000000" stroke="none" 
	d="
M58.886864,156.622009 
	C58.841530,153.455307 58.830799,150.355545 59.609566,147.468765 
	C59.878345,146.472412 62.112362,146.006210 63.448666,145.297836 
	C63.883965,146.602020 65.065758,148.312119 64.617760,149.136002 
	C63.139755,151.854141 61.072395,154.251831 58.886864,156.622009 
z"/>
<path fill="#9DC028" opacity="1.000000" stroke="none" 
	d="
M40.092476,266.054626 
	C42.918205,265.847473 45.743938,265.640289 48.692783,265.994293 
	C48.513000,267.321655 48.210102,268.087799 47.907204,268.853973 
	C47.129684,269.177032 46.352161,269.500061 45.023106,270.057007 
	C43.967060,270.833588 43.462551,271.376251 42.958038,271.918945 
	C41.499699,271.950073 39.891418,272.365265 38.636501,271.875336 
	C37.819641,271.556427 37.516968,269.920502 37.344574,268.449829 
	C38.500965,267.368256 39.296722,266.711456 40.092476,266.054626 
z"/>
<path fill="#3D9713" opacity="1.000000" stroke="none" 
	d="
M55.994659,220.010773 
	C54.642597,226.415863 49.349934,222.684906 45.958233,223.734985 
	C44.726288,224.116409 42.997017,222.891495 41.254364,221.273331 
	C43.584724,220.498062 45.915081,219.722778 49.036530,218.957001 
	C51.883301,219.314606 53.938980,219.662689 55.994659,220.010773 
z"/>
<path fill="#95BB22" opacity="1.000000" stroke="none" 
	d="
M110.007736,264.464020 
	C110.569153,263.026093 111.114685,261.999756 111.804184,260.702545 
	C116.546127,266.895782 116.958466,271.085693 112.437263,274.791229 
	C111.530060,271.112701 110.760956,267.994171 110.007736,264.464020 
z"/>
<path fill="#9DC028" opacity="1.000000" stroke="none" 
	d="
M21.763802,203.218231 
	C24.625492,205.690308 25.986959,208.234833 24.412550,211.657669 
	C18.894150,210.220673 16.561619,206.614914 17.827503,200.679871 
	C19.187302,201.521683 20.340170,202.235382 21.763802,203.218231 
z"/>
<path fill="#6FA43A" opacity="1.000000" stroke="none" 
	d="
M103.899315,289.897064 
	C103.899315,289.897064 103.987236,289.408478 104.024078,289.161865 
	C112.110657,284.942810 120.160385,280.970337 128.570633,276.964142 
	C128.931152,276.930389 129.163620,277.161682 129.185486,277.502960 
	C128.968384,278.085510 128.826477,278.374420 128.787659,278.727692 
	C128.793701,278.744354 128.773682,278.768768 128.435547,278.825378 
	C122.229263,282.182617 116.361137,285.483307 110.345749,288.743256 
	C110.198494,288.702515 109.991341,288.927185 109.613762,288.930511 
	C107.535751,289.339874 105.835320,289.745911 104.076187,290.088074 
	C104.017487,290.024170 103.899315,289.897064 103.899315,289.897064 
z"/>
<path fill="#639A3D" opacity="1.000000" stroke="none" 
	d="
M117.107033,174.686615 
	C115.527512,175.178696 114.101059,175.363800 112.531227,175.567490 
	C112.829117,173.248947 113.107864,171.079407 113.258987,168.633026 
	C115.792404,167.881027 118.453453,167.405884 121.514481,166.933762 
	C120.363007,169.417725 118.811546,171.898682 117.107033,174.686615 
z"/>
<path fill="#5DBA42" opacity="1.000000" stroke="none" 
	d="
M107.118118,214.753464 
	C106.955032,215.909225 106.742561,216.764648 106.264450,217.823303 
	C104.726440,216.243851 103.454063,214.461182 101.523109,211.755783 
	C102.304710,211.110153 103.854233,209.830200 105.381325,208.568771 
	C106.146034,211.235504 106.607384,212.844330 107.118118,214.753464 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M67.951950,230.694244 
	C64.576302,229.367477 61.177849,227.686432 57.391373,226.006821 
	C58.701317,222.365051 61.356133,223.618698 63.457283,225.168076 
	C65.246346,226.487320 66.460587,228.586075 67.951950,230.694244 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M83.921036,197.675766 
	C81.895538,199.013702 79.812416,199.981934 77.380936,201.003983 
	C77.976212,195.950287 79.644958,195.033737 83.921036,197.675766 
z"/>
<path fill="#6BA50A" opacity="1.000000" stroke="none" 
	d="
M48.115200,269.153198 
	C48.210102,268.087799 48.513000,267.321655 48.968189,266.276978 
	C50.561821,267.106750 52.003159,268.214996 53.444500,269.323242 
	C53.089764,269.875519 52.735031,270.427826 52.380295,270.980133 
	C51.027931,270.470886 49.675568,269.961670 48.115200,269.153198 
z"/>
<path fill="#399410" opacity="1.000000" stroke="none" 
	d="
M35.263767,183.976105 
	C33.641338,182.838272 31.949705,181.780884 30.454781,180.494827 
	C30.155293,180.237198 30.604933,179.108765 30.709160,178.381836 
	C31.453529,178.701309 32.197899,179.020782 33.148628,179.945419 
	C33.900009,180.704010 34.445023,180.857452 34.990036,181.010910 
	C34.990036,181.010910 35.000000,181.000000 34.965759,181.378632 
	C35.042267,182.496887 35.153015,183.236496 35.263767,183.976105 
z"/>
<path fill="#60A718" opacity="1.000000" stroke="none" 
	d="
M47.376877,155.916702 
	C47.141121,153.686844 48.229942,150.928833 44.188728,150.256836 
	C43.981213,150.000000 43.982330,149.517899 43.975903,149.277344 
	C45.523670,149.228806 47.077869,149.420822 49.422993,149.710556 
	C48.833126,151.872284 48.291611,153.856812 47.376877,155.916702 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M56.235451,219.822342 
	C53.938980,219.662689 51.883301,219.314606 49.429787,218.933640 
	C52.380032,216.242477 54.999180,216.500412 56.235451,219.822342 
z"/>
<path fill="#5DBA42" opacity="1.000000" stroke="none" 
	d="
M35.386856,183.973511 
	C35.153015,183.236496 35.042267,182.496887 34.960388,181.383850 
	C36.113327,183.145035 40.637245,179.866882 39.805939,184.645782 
	C38.132011,184.627930 36.943958,184.305328 35.755905,183.982742 
	C35.755905,183.982742 35.509949,183.970901 35.386856,183.973511 
z"/>
<path fill="#399410" opacity="1.000000" stroke="none" 
	d="
M35.860718,184.349609 
	C36.943958,184.305328 38.132011,184.627930 39.690582,184.958862 
	C40.261070,185.609314 40.461037,186.251419 40.661003,186.893524 
	C39.095848,186.167847 37.530689,185.442169 35.860718,184.349609 
z"/>
<path fill="#60A718" opacity="1.000000" stroke="none" 
	d="
M35.005714,153.990021 
	C35.005234,154.423019 35.001492,154.866302 34.999531,155.641434 
	C33.782444,155.812637 32.563576,155.652008 31.170782,155.228638 
	C31.457090,154.338074 31.917324,153.710220 32.864120,153.292328 
	C33.906414,153.666138 34.462147,153.829941 35.017879,153.993759 
	C35.017879,153.993759 35.002445,154.000305 35.005714,153.990021 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M72.752754,235.105682 
	C73.017967,236.473831 73.017967,237.949783 73.017967,239.425720 
	C72.574219,239.465836 72.130463,239.505936 71.686714,239.546051 
	C71.450905,238.617844 71.215096,237.689651 70.975067,236.388397 
	C71.476410,235.748062 71.981979,235.480774 72.752754,235.105682 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M74.617508,222.055008 
	C74.820236,223.173645 74.685928,224.352676 74.286713,225.770386 
	C74.108025,224.711197 74.194244,223.413284 74.617508,222.055008 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M94.958336,140.730286 
	C93.890289,141.629074 92.794724,142.257507 91.375092,142.902695 
	C92.344284,142.099594 93.637550,141.279755 94.958336,140.730286 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M100.986259,140.689285 
	C99.922531,141.014755 98.863686,141.001633 97.407928,140.984406 
	C98.121132,140.341812 99.231255,139.703308 100.672256,139.033875 
	C101.003128,139.002945 101.000000,139.000000 100.998444,138.998444 
	C100.994980,139.448166 100.993065,139.899414 100.986259,140.689285 
z"/>
<path fill="#639A3D" opacity="1.000000" stroke="none" 
	d="
M123.350021,165.801636 
	C123.829361,164.982574 124.600090,164.074005 125.694336,163.207458 
	C125.225708,164.070374 124.433571,164.891251 123.350021,165.801636 
z"/>
<path fill="#6BA50A" opacity="1.000000" stroke="none" 
	d="
M39.721176,266.041412 
	C39.296722,266.711456 38.500965,267.368256 37.394051,268.092834 
	C37.838551,267.449768 38.594212,266.738983 39.721176,266.041412 
z"/>
<path fill="#6BA50A" opacity="1.000000" stroke="none" 
	d="
M43.142723,272.166626 
	C43.462551,271.376251 43.967060,270.833588 44.742020,270.209839 
	C44.450775,270.890594 43.889088,271.652435 43.142723,272.166626 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M56.779926,234.163605 
	C57.325924,234.464539 57.641979,234.935806 57.970356,235.705505 
	C57.505116,235.447281 57.027550,234.890594 56.779926,234.163605 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M88.770828,145.181885 
	C89.312172,145.125809 89.570198,145.310516 89.860397,145.809708 
	C89.488663,146.186523 89.017838,146.342361 88.547012,146.498199 
	C88.549484,146.108521 88.551964,145.718872 88.770828,145.181885 
z"/>
<path fill="#6CA508" opacity="1.000000" stroke="none" 
	d="
M85.227753,201.832016 
	C84.681534,201.532730 84.363579,201.062744 84.033951,200.293945 
	C84.500191,200.550507 84.978104,201.105927 85.227753,201.832016 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M100.212410,147.274658 
	C100.519455,146.781296 100.995552,146.505905 101.727356,146.130371 
	C101.449203,146.517700 100.915337,147.005157 100.212410,147.274658 
z"/>
<path fill="#5DBA42" opacity="1.000000" stroke="none" 
	d="
M34.867210,180.738678 
	C34.445023,180.857452 33.900009,180.704010 33.213799,180.292114 
	C33.629868,180.177917 34.187126,180.322189 34.867210,180.738678 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M97.228561,147.894043 
	C97.102013,147.678757 97.271545,147.435379 97.744545,147.177597 
	C97.866333,147.396469 97.691872,147.630722 97.228561,147.894043 
z"/>
<path fill="#868814" opacity="1.000000" stroke="none" 
	d="
M119.198074,228.840942 
	C119.142548,228.669388 119.321785,228.394669 119.718262,227.993500 
	C119.767937,228.157288 119.600388,228.447525 119.198074,228.840942 
z"/>
<path fill="#70A11E" opacity="1.000000" stroke="none" 
	d="
M96.735794,141.003143 
	C96.712067,141.115356 96.446228,141.228287 96.094971,141.176712 
	C96.009560,141.012207 96.493690,141.003830 96.735794,141.003143 
z"/>
<path fill="#60A718" opacity="1.000000" stroke="none" 
	d="
M40.089638,157.176361 
	C40.168854,157.005646 40.337013,157.002472 40.757530,157.003937 
	C40.732780,157.120346 40.455685,157.232132 40.089638,157.176361 
z"/>
<path fill="#6FA43A" opacity="1.000000" stroke="none" 
	d="
M128.282898,161.597809 
	C128.257599,161.392899 128.368103,161.260086 128.734406,161.086990 
	C128.955368,161.416702 128.764893,161.624420 128.282898,161.597809 
z"/>
<path fill="#639A3D" opacity="1.000000" stroke="none" 
	d="
M122.175003,166.863007 
	C121.972122,166.580032 122.131279,166.320679 122.662399,166.107941 
	C122.850372,166.395325 122.684593,166.639343 122.175003,166.863007 
z"/>
<path fill="#A76523" opacity="1.000000" stroke="none" 
	d="
M65.885231,292.548584 
	C66.569153,290.410828 67.781685,288.735352 67.907822,286.981781 
	C69.135857,269.909729 66.439827,253.282928 61.759567,236.925934 
	C61.359848,235.528976 60.207420,234.361206 59.687660,232.980423 
	C59.274452,231.882721 59.293961,230.622131 59.013157,228.688889 
	C66.168388,230.975800 67.098076,237.058853 69.805496,241.717743 
	C70.600853,243.086380 71.624245,244.322479 72.543121,245.619324 
	C73.364014,244.023239 74.687584,242.497971 74.924103,240.819550 
	C76.446175,230.018143 77.671700,219.175140 79.161140,208.368759 
	C79.364990,206.889709 80.494385,205.538239 81.195274,204.127701 
	C81.711357,204.142166 82.227440,204.156631 82.743515,204.171097 
	C83.818497,209.177216 85.125641,214.147079 85.914749,219.197861 
	C87.138817,227.032669 87.912514,234.937546 89.115097,242.776215 
	C89.347511,244.291122 90.671906,245.638474 92.276680,248.424301 
	C97.676918,236.605621 99.843361,224.447464 111.255379,217.119949 
	C110.206390,220.823669 109.494049,223.784805 108.529022,226.661148 
	C105.802101,234.789001 101.654327,242.702896 100.521019,251.034973 
	C99.157471,261.059662 100.193733,271.432007 100.480591,281.645691 
	C100.555771,284.322479 101.702858,286.969208 102.690094,290.077545 
	C103.184563,291.027832 103.346611,291.530334 103.447830,292.278503 
	C103.482552,292.663177 103.578094,292.802155 103.654388,293.297882 
	C103.768097,294.109100 103.901039,294.563568 103.913048,295.006714 
	C103.792114,294.995422 103.570549,295.095001 103.483627,295.186768 
	C103.315010,295.563232 103.420471,295.747498 103.811432,296.192047 
	C104.231895,297.714691 104.554016,298.876587 104.855591,300.410461 
	C104.961136,301.514862 105.087234,302.247284 105.137245,303.330566 
	C105.363022,304.788605 105.664902,305.895782 105.941071,307.343323 
	C106.013260,308.126007 106.111160,308.568329 106.166542,309.267456 
	C106.185303,309.686676 106.246582,309.849091 106.277832,310.410248 
	C107.491188,317.861023 108.734596,324.913116 109.519867,332.002136 
	C105.161766,332.014435 101.261787,331.989777 97.267471,331.956177 
	C97.173141,331.947235 96.987503,331.985291 97.059799,331.741547 
	C97.086617,331.334503 97.041145,331.171234 97.072479,330.545807 
	C97.190277,326.639313 97.298447,323.194214 97.257782,319.750854 
	C97.181145,313.261230 93.705437,310.655457 86.646118,311.946259 
	C84.479370,313.229706 82.687340,314.551117 80.597755,316.179230 
	C80.152519,322.338654 80.004845,328.191345 79.441208,334.042786 
	C74.956940,335.438293 70.925560,336.965179 66.793518,338.137177 
	C65.802345,338.418335 64.476578,337.519836 63.211922,336.694824 
	C63.345825,329.148926 63.572506,322.072021 63.799194,314.995148 
	C64.502701,307.654968 65.206215,300.314789 65.885231,292.548584 
z"/>
<path fill="#DBE4EB" opacity="1.000000" stroke="none" 
	d="
M63.445892,314.942322 
	C63.572506,322.072021 63.345825,329.148926 63.103294,336.991150 
	C63.012005,338.173523 62.936558,338.590546 62.598621,339.324646 
	C61.890163,349.735046 61.489475,359.830597 60.979725,369.920654 
	C60.712585,375.208405 60.494308,380.519440 59.776218,385.754395 
	C59.439777,388.207123 57.972248,390.504700 56.587303,392.937256 
	C54.413761,392.937439 52.670250,392.872955 50.808308,392.614838 
	C50.477055,392.195099 50.222080,392.036926 49.950901,391.483948 
	C49.951576,384.540802 49.898773,378.060333 49.906219,371.579956 
	C49.928268,352.387054 49.974091,333.194122 50.004639,314.000549 
	C49.998665,313.999878 49.997066,314.009979 50.291916,313.992218 
	C51.048733,313.658905 51.510700,313.343353 51.980980,312.772949 
	C51.994209,312.348145 51.999130,312.178223 52.004051,312.008301 
	C55.996853,311.827118 59.996849,311.618286 63.445892,314.942322 
z"/>
<path fill="#F2FBBE" opacity="1.000000" stroke="none" 
	d="
M49.924953,391.946655 
	C50.222080,392.036926 50.477055,392.195099 50.384987,392.706085 
	C46.765911,393.607300 45.781342,392.151764 45.932091,388.934418 
	C46.133282,384.640503 45.879162,380.325256 45.896141,375.557953 
	C45.971684,368.208282 45.971684,361.319794 45.971684,354.431335 
	C45.362549,354.128571 44.753410,353.825806 44.144276,353.523041 
	C41.037811,356.957031 37.931351,360.391052 34.608444,363.940186 
	C34.126419,364.268127 33.950855,364.539276 33.574230,365.051086 
	C28.324970,372.645935 24.335939,380.196838 29.937984,389.444519 
	C33.921886,395.497803 39.078552,399.480438 45.900146,401.131470 
	C45.900146,401.131470 45.955875,401.126282 46.134857,401.405457 
	C57.200535,404.083069 68.087234,406.481537 78.973938,408.880005 
	C79.408493,408.963623 79.843063,409.047180 80.933334,409.364258 
	C85.066559,409.746582 88.544090,409.895386 92.021614,410.044220 
	C92.443794,410.100525 92.865974,410.156799 93.948944,410.412415 
	C99.767487,410.840973 104.925247,411.070221 110.083008,411.299469 
	C108.728531,411.869263 107.362122,412.952606 106.021622,412.921448 
	C99.629112,412.772827 93.243652,412.321472 86.426651,412.007568 
	C79.291550,411.356323 72.585487,410.675293 65.111565,409.842468 
	C61.562332,408.803436 58.780960,407.916199 55.999588,407.028992 
	C50.931454,405.362915 45.863319,403.696838 40.261223,401.698059 
	C37.155354,399.568146 34.583450,397.770905 32.011543,395.973633 
	C30.888630,394.732941 29.765717,393.492279 28.206608,391.786133 
	C26.859455,389.211060 25.948498,387.101440 25.037544,384.991791 
	C23.581369,372.071747 30.392870,362.935913 39.093639,354.784302 
	C40.295761,353.658081 42.409355,353.504761 44.833782,352.918549 
	C46.038071,352.966949 46.509548,352.997833 46.985832,353.480988 
	C47.238308,360.695526 47.645233,367.456970 47.680626,374.220337 
	C47.706512,379.166870 47.081654,384.117737 47.137897,389.062256 
	C47.148949,390.034180 48.949635,390.985718 49.924953,391.946655 
z"/>
<path fill="#F6FBF7" opacity="1.000000" stroke="none" 
	d="
M49.950897,391.483948 
	C48.949635,390.985718 47.148949,390.034180 47.137897,389.062256 
	C47.081654,384.117737 47.706512,379.166870 47.680626,374.220337 
	C47.645233,367.456970 47.238308,360.695526 46.991585,353.009155 
	C46.993015,345.959076 46.855377,339.829102 47.032433,333.708191 
	C47.213100,327.462555 47.702950,321.225800 48.057438,314.985168 
	C48.354500,314.884674 48.595615,314.707916 49.139229,314.233459 
	C49.668659,314.008453 49.839634,314.004852 50.010612,314.001221 
	C49.974091,333.194122 49.928268,352.387054 49.906219,371.579956 
	C49.898773,378.060333 49.951576,384.540802 49.950897,391.483948 
z"/>
<path fill="#E8EAEA" opacity="1.000000" stroke="none" 
	d="
M47.650711,315.065918 
	C47.702950,321.225800 47.213100,327.462555 47.032433,333.708191 
	C46.855377,339.829102 46.993015,345.959076 46.986782,352.556885 
	C46.509548,352.997833 46.038071,352.966949 45.214859,352.875580 
	C45.238968,342.076813 45.565399,331.336334 46.038296,320.602325 
	C46.119034,318.769623 46.826206,316.964539 47.650711,315.065918 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M32.007500,396.391052 
	C34.583450,397.770905 37.155354,399.568146 39.898796,401.664398 
	C33.441490,404.631012 32.098286,403.772675 32.007500,396.391052 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M24.692215,385.042847 
	C25.948498,387.101440 26.859455,389.211060 27.927025,391.639282 
	C26.320387,391.147858 24.248917,390.648743 22.954597,389.365723 
	C22.476744,388.891998 23.829224,386.571869 24.692215,385.042847 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M56.042488,407.385895 
	C58.780960,407.916199 61.562332,408.803436 64.671677,409.846741 
	C62.925877,412.260315 59.323540,411.347015 56.042488,407.385895 
z"/>
<path fill="#FDFEFA" opacity="1.000000" stroke="none" 
	d="
M39.721809,408.798401 
	C39.072372,408.152618 38.746346,407.456818 38.420311,406.761017 
	C38.836918,406.684296 39.253532,406.607605 39.670143,406.530884 
	C39.795166,407.270020 39.920193,408.009186 39.721809,408.798401 
z"/>
<path fill="#E8EAEA" opacity="1.000000" stroke="none" 
	d="
M50.004639,314.000549 
	C49.839634,314.004852 49.668659,314.008453 49.241631,314.030762 
	C49.752705,313.367828 50.519833,312.686249 51.645504,312.006470 
	C51.999130,312.178223 51.994209,312.348145 51.685944,312.786804 
	C50.920750,313.373657 50.458904,313.691803 49.997063,314.009979 
	C49.997066,314.009979 49.998665,313.999878 50.004639,314.000549 
z"/>
<path fill="#33980D" opacity="1.000000" stroke="none" 
	d="
M419.284729,389.949493 
	C419.249084,390.415619 419.213440,390.881714 419.280609,391.687225 
	C424.232574,392.363342 429.081726,392.700073 434.376892,393.121826 
	C434.355316,393.681183 433.887665,394.155548 432.917786,394.807434 
	C431.928680,395.311493 431.441772,395.638062 430.954895,395.964630 
	C430.954895,395.964630 430.996643,396.001434 430.685516,395.989349 
	C429.885925,396.287354 429.397461,396.597443 428.908997,396.907532 
	C428.908997,396.907562 428.984955,396.975616 428.565186,396.935516 
	C425.249786,397.605377 422.376678,398.429688 419.454865,399.007263 
	C395.580414,403.726837 371.316223,404.305359 347.148163,404.711517 
	C311.774261,405.305969 276.380005,404.691620 240.994446,404.592438 
	C240.551544,404.528778 240.108658,404.465118 238.892548,404.331177 
	C233.436523,404.300018 228.753677,404.339142 223.632629,404.288452 
	C221.136200,404.272217 219.078003,404.345734 217.019806,404.419250 
	C214.613281,404.338226 212.206741,404.257202 208.899979,404.151978 
	C184.859238,404.074188 161.718582,404.052063 138.578369,403.939636 
	C134.099045,403.917877 129.623505,403.461517 125.142616,403.348267 
	C115.391457,403.101837 105.637978,402.947388 95.885445,402.755371 
	C95.534431,402.750671 95.183418,402.745941 94.226913,402.548889 
	C86.734291,401.867676 79.847176,401.378784 72.960052,400.889893 
	C72.625740,400.837219 72.291428,400.784546 71.593353,400.550232 
	C71.147690,400.222229 71.065796,400.075806 70.804733,399.704803 
	C69.781990,399.568604 68.938416,399.657013 67.715248,399.779999 
	C66.573318,399.796143 65.810982,399.777710 65.048645,399.759277 
	C65.048645,399.759308 65.025131,399.409668 64.752464,398.941620 
	C62.933136,398.362000 61.386478,398.250458 59.481636,398.131226 
	C58.413605,398.024506 57.703758,397.925507 57.007507,397.442871 
	C57.924534,396.375336 58.804592,395.657349 59.737610,395.016418 
	C60.786701,394.295746 61.883587,393.644653 62.967789,392.973145 
	C62.975895,392.982971 62.961952,392.961334 63.364204,392.891846 
	C64.176460,386.517670 64.443626,380.198975 65.058662,373.914337 
	C65.351921,370.917755 66.306450,367.985870 67.273178,364.844238 
	C71.682800,363.150604 75.780685,361.637390 79.927185,360.580872 
	C79.975792,371.593811 79.975792,382.150055 79.975792,392.882843 
	C84.766876,392.882843 88.873039,392.882843 93.420792,392.957642 
	C95.003685,392.100555 97.123077,391.181763 97.135796,390.234741 
	C97.271164,380.154877 97.072258,370.070557 97.062523,359.593903 
	C97.206680,357.852509 97.253571,356.504700 97.635635,355.164429 
	C98.636597,355.163574 99.302391,355.155151 99.986679,355.535767 
	C100.076874,357.211304 100.148552,358.497833 100.172615,360.186890 
	C101.176399,368.028015 104.104118,374.307709 111.123383,378.401978 
	C112.323318,379.502350 113.373314,380.353485 114.485291,381.645081 
	C114.692070,385.403961 114.836884,388.722351 115.190605,392.418427 
	C120.598885,392.849792 125.798241,392.903534 131.313263,392.852600 
	C132.683823,391.555267 133.738708,390.362549 135.601074,388.256805 
	C134.293900,387.402832 133.083191,386.611877 131.900543,385.518616 
	C132.028748,384.825012 132.128860,384.433685 132.633087,384.044006 
	C137.373581,383.363678 141.709961,382.681732 146.199158,382.346466 
	C146.901001,384.218781 147.745987,385.706512 147.947311,387.276703 
	C148.777313,393.750275 151.077530,395.157501 158.482101,393.133911 
	C161.183029,393.077148 163.446274,393.077148 165.792282,393.077148 
	C165.873093,391.311951 165.925232,390.173187 166.019379,388.773102 
	C166.036896,388.337036 166.012390,388.162292 165.969345,387.559204 
	C166.074417,385.685822 166.198029,384.240753 166.702942,382.784668 
	C172.301895,381.825836 177.519531,380.877991 182.846588,380.395691 
	C182.955994,384.784637 182.955994,388.708069 182.955994,392.880981 
	C187.717331,392.880981 191.814560,392.880981 196.348541,392.963135 
	C199.077652,393.347351 200.483154,392.699921 200.338577,390.029877 
	C200.244003,388.283722 200.396118,386.524231 200.657196,384.487976 
	C206.626160,381.190674 211.833237,383.255341 217.104752,386.311890 
	C217.412994,388.529236 217.592270,390.359039 217.847137,392.476929 
	C218.283463,392.931030 218.644196,393.097076 219.310211,393.486755 
	C224.960175,394.359497 230.295639,395.285828 235.655960,395.464020 
	C237.006241,395.508881 238.546112,393.257263 239.784302,391.869537 
	C240.430817,391.144958 240.610825,390.004089 241.404480,388.974731 
	C244.875336,388.555969 247.943237,388.213654 251.312103,387.926514 
	C252.019257,387.964813 252.425446,387.947906 252.948669,388.199890 
	C253.131042,388.653076 253.196350,388.837341 253.148560,389.371582 
	C253.356949,390.736908 253.678406,391.752289 254.388611,392.860413 
	C255.836517,392.905579 256.895660,392.857971 258.391632,392.911255 
	C261.211273,392.959412 263.594086,392.906738 266.379700,392.944916 
	C271.372803,394.069458 270.316406,390.217224 271.574463,388.017181 
	C277.570160,387.040405 283.135406,386.041626 288.822449,385.368317 
	C289.052185,386.143372 289.160065,386.592987 289.168884,387.399963 
	C289.134796,388.489532 289.199738,389.221680 289.193054,390.293945 
	C289.188568,391.089752 289.255646,391.545410 289.453247,392.392242 
	C293.714172,392.805695 297.844543,392.827972 302.355835,392.940002 
	C303.902435,392.672089 305.068207,392.314453 306.513397,391.782623 
	C307.844055,391.431702 308.895294,391.254974 309.979279,391.463257 
	C311.448059,392.893921 312.825867,394.734894 314.330261,394.844879 
	C320.254639,395.278076 326.299469,395.975128 332.260437,393.130646 
	C332.757263,392.992737 332.958893,392.948425 333.570984,392.942261 
	C335.623322,392.877441 337.265198,392.774506 339.362549,392.775940 
	C343.542755,392.890076 347.267426,392.899811 351.211670,393.224182 
	C356.707245,396.504517 361.830139,394.394867 367.177856,392.905334 
	C367.614838,392.487671 367.850647,392.234344 368.497498,391.988525 
	C371.754852,391.282104 374.761627,390.928650 377.402435,389.753021 
	C380.048981,388.574799 382.330261,386.576050 384.811096,385.385376 
	C385.940826,388.334534 381.873779,393.896545 388.995667,392.816833 
	C391.372192,392.887665 393.748749,392.958496 396.999481,393.042236 
	C401.100677,393.609100 402.297272,392.325043 402.075012,389.020752 
	C401.820251,385.233826 402.022949,381.416260 402.023651,377.612000 
	C402.024994,370.228180 402.024048,362.844391 402.024048,355.147736 
	C408.106445,355.147736 413.526764,355.147736 418.947083,355.147736 
	C418.985413,355.567200 419.023743,355.986633 419.056580,357.169067 
	C419.128967,368.604492 419.206879,379.277008 419.284729,389.949493 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M241.155731,404.916382 
	C276.380005,404.691620 311.774261,405.305969 347.148163,404.711517 
	C371.316223,404.305359 395.580414,403.726837 419.454865,399.007263 
	C422.376678,398.429688 425.249786,397.605377 428.549194,396.887085 
	C423.021667,398.582764 417.192841,401.108307 411.143158,401.862579 
	C355.938995,408.745575 300.450623,405.132080 245.078888,405.962219 
	C243.828827,405.980957 242.571136,405.492310 241.155731,404.916382 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M242.709839,410.662903 
	C213.969849,410.672363 184.947952,410.402313 155.462341,410.054626 
	C158.000595,409.318726 160.994400,408.139465 164.005951,408.092255 
	C177.292679,407.883881 190.593811,407.691803 203.871841,408.089874 
	C216.735229,408.475464 229.576797,409.589355 242.709839,410.662903 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M96.160721,403.096313 
	C105.637978,402.947388 115.391457,403.101837 125.142616,403.348267 
	C129.623505,403.461517 134.099045,403.917877 138.578369,403.939636 
	C161.718582,404.052063 184.859238,404.074188 208.450806,404.217468 
	C201.234009,404.591949 193.566803,404.895813 185.897842,405.146698 
	C184.242615,405.200836 182.581192,405.023285 180.922577,405.022095 
	C159.658722,405.007050 138.393692,405.129333 117.131699,404.927704 
	C110.229042,404.862274 103.334335,403.957886 96.160721,403.096313 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M110.483444,411.242798 
	C104.925247,411.070221 99.767487,410.840973 94.267799,410.378845 
	C94.460754,409.792969 94.997032,409.129730 95.530334,409.132080 
	C111.181831,409.201996 126.833038,409.338196 142.770828,409.782410 
	C135.811264,410.387939 128.565475,410.682617 121.318893,410.956207 
	C119.494736,411.025085 117.668037,411.026459 115.381134,411.182983 
	C113.574471,411.266846 112.229187,411.226501 110.483444,411.242798 
z"/>
<path fill="#439320" opacity="1.000000" stroke="none" 
	d="
M62.959686,392.963318 
	C61.883587,393.644653 60.786701,394.295746 59.737610,395.016418 
	C58.804592,395.657349 57.924534,396.375336 56.681309,397.492249 
	C55.902210,397.948334 55.462898,397.971497 55.023590,397.994659 
	C55.023590,397.994659 54.611126,397.894104 54.130516,397.643677 
	C50.521980,396.501404 47.394047,395.609589 44.106461,394.530334 
	C43.689987,394.121643 43.393536,393.988922 43.031021,393.732727 
	C43.004585,393.520752 42.978287,393.094269 43.143616,392.706726 
	C43.859440,387.818207 45.642708,383.217041 42.211456,378.828735 
	C42.192936,378.619415 42.232685,378.201050 42.525608,378.120972 
	C43.819218,377.367004 44.819908,376.693085 45.820595,376.019165 
	C45.879162,380.325256 46.133282,384.640503 45.932091,388.934418 
	C45.781342,392.151764 46.765911,393.607300 50.503410,392.899719 
	C52.670250,392.872955 54.413761,392.937439 57.003471,393.026886 
	C59.553005,393.022339 61.256351,392.992828 62.959686,392.963318 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M288.754181,412.695129 
	C274.542877,412.499542 260.081879,412.021515 245.291306,411.294556 
	C245.512970,410.718384 246.070938,410.094971 246.614395,410.107361 
	C259.332520,410.397308 272.050262,410.722229 284.764160,411.154816 
	C286.023743,411.197632 287.258301,411.975830 288.754181,412.695129 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M345.922424,415.610504 
	C334.552429,415.528503 323.090424,415.048767 311.287231,414.336609 
	C315.307190,413.739258 319.670227,413.058960 324.029083,413.084686 
	C330.299805,413.121704 336.570679,413.625671 342.833313,414.050751 
	C343.853943,414.120056 344.832458,414.809814 345.922424,415.610504 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M78.742569,408.610291 
	C68.087234,406.481537 57.200535,404.083069 46.114536,401.386230 
	C50.229996,402.065704 54.541325,403.059021 58.860313,404.017853 
	C65.407814,405.471375 71.960648,406.900848 78.742569,408.610291 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M45.908245,400.772583 
	C39.078552,399.480438 33.921886,395.497803 29.997196,389.392700 
	C29.990404,389.005035 30.107050,388.899414 30.438553,388.970154 
	C32.254311,390.268768 33.910515,391.341583 35.187046,392.756836 
	C37.849976,395.709106 40.871197,397.564178 45.000111,397.000000 
	C45.000000,397.000000 44.999847,397.000000 44.999847,397.000000 
	C45.305344,398.137909 45.610844,399.275818 45.908245,400.772583 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M308.803101,413.633728 
	C303.871948,413.845581 298.744904,413.714325 293.274841,413.352936 
	C298.156921,413.178741 303.382080,413.234711 308.803101,413.633728 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M73.219879,401.201111 
	C79.847176,401.378784 86.734291,401.867676 93.970337,402.610962 
	C87.372749,402.414337 80.426224,401.963318 73.219879,401.201111 
z"/>
<path fill="#639A3D" opacity="1.000000" stroke="none" 
	d="
M436.492279,391.769043 
	C436.142120,388.249207 436.091278,384.555817 436.154602,380.405396 
	C439.188141,383.707550 440.340210,387.546021 436.492279,391.769043 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M224.070847,404.378235 
	C228.753677,404.339142 233.436523,404.300018 238.565659,404.420837 
	C239.011963,404.580811 238.961227,404.821503 238.650482,404.948517 
	C237.892899,405.380493 237.446075,405.685486 236.999237,405.990417 
	C233.306961,405.991577 229.614670,405.992706 225.374756,405.774200 
	C224.575012,405.162445 224.322937,404.770355 224.070847,404.378235 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M91.791801,409.736755 
	C88.544090,409.895386 85.066559,409.746582 81.265884,409.347717 
	C84.482491,409.208191 88.022240,409.318726 91.791801,409.736755 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M44.266121,394.717773 
	C47.394047,395.609589 50.521980,396.501404 53.918427,397.652405 
	C51.398403,397.613373 48.609863,397.315186 45.410583,397.008484 
	C44.999847,397.000000 45.000000,397.000000 44.893623,396.741516 
	C44.520145,395.993347 44.253052,395.503662 44.055939,394.939819 
	C44.125916,394.865723 44.266125,394.717743 44.266121,394.717773 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M223.632629,404.288452 
	C224.322937,404.770355 224.575012,405.162445 224.914215,405.773621 
	C222.421692,405.693420 219.842056,405.394196 217.141113,404.757111 
	C219.078003,404.345734 221.136200,404.272217 223.632629,404.288452 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M55.191597,398.326233 
	C55.462898,397.971497 55.902210,397.948334 56.667717,397.875824 
	C57.703758,397.925507 58.413605,398.024506 59.735504,398.361389 
	C61.235603,398.812439 62.123646,399.025604 63.011692,399.238800 
	C60.460999,399.045135 57.910305,398.851471 55.191597,398.326233 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M444.799744,405.113953 
	C444.123779,406.191254 443.073486,407.250214 441.699829,408.340027 
	C442.392761,407.279114 443.409088,406.187378 444.799744,405.113953 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M70.983894,399.929382 
	C71.065796,400.075806 71.147690,400.222229 71.342072,400.594666 
	C69.467079,400.701141 67.479599,400.581573 65.270386,400.110626 
	C65.810982,399.777710 66.573318,399.796143 68.067825,399.866730 
	C69.527962,399.922424 70.255928,399.925903 70.983894,399.929382 
z"/>
<path fill="#439320" opacity="1.000000" stroke="none" 
	d="
M63.335571,399.192444 
	C62.123646,399.025604 61.235603,398.812439 60.093689,398.369080 
	C61.386478,398.250458 62.933136,398.362000 64.722107,398.769104 
	C64.529427,399.091766 64.094437,399.118927 63.335571,399.192444 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M429.221130,396.922089 
	C429.397461,396.597443 429.885925,396.287354 430.678101,395.962555 
	C430.498932,396.277435 430.016113,396.607056 429.221130,396.922089 
z"/>
<path fill="#A0DB31" opacity="1.000000" stroke="none" 
	d="
M431.265930,395.960815 
	C431.441772,395.638062 431.928680,395.311493 432.720642,394.965698 
	C432.542816,395.283325 432.059906,395.620178 431.265930,395.960815 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M237.285873,405.975952 
	C237.446075,405.685486 237.892899,405.380493 238.621704,405.068085 
	C238.459961,405.360931 238.016235,405.661194 237.285873,405.975952 
z"/>
<path fill="#FBFDED" opacity="1.000000" stroke="none" 
	d="
M115.071716,412.512360 
	C117.029388,412.122070 119.086678,412.088196 121.583771,412.091919 
	C120.041748,415.177399 117.657883,414.498932 115.071716,412.512360 
z"/>
<path fill="#F2D7D5" opacity="1.000000" stroke="none" 
	d="
M134.669388,155.851532 
	C146.974762,145.350739 159.278824,134.848419 171.587326,124.351273 
	C172.971497,123.170799 174.380692,122.019661 176.203827,120.485229 
	C176.836548,119.842735 177.043732,119.569923 177.263184,119.286758 
	C177.275482,119.276421 177.303604,119.298920 177.590485,119.190079 
	C178.599686,118.392807 179.321991,117.704369 180.044296,117.015930 
	C180.044296,117.015930 179.998871,116.996552 180.264832,116.959702 
	C180.727142,116.635155 180.923492,116.347443 181.119843,116.059738 
	C181.119843,116.059738 181.040970,116.040283 181.324280,116.017967 
	C181.830368,115.697609 182.053131,115.399574 182.275894,115.101532 
	C182.540222,115.080826 182.765320,114.975922 183.332382,114.515472 
	C184.133316,113.817535 184.553040,113.390938 184.972778,112.964340 
	C184.972778,112.964348 185.000000,112.999695 185.241669,112.928978 
	C185.675095,112.578041 185.866852,112.297821 186.058609,112.017609 
	C186.058609,112.017609 186.005600,111.995834 186.302277,111.945633 
	C187.135513,111.334938 187.672073,110.774437 188.514679,110.090775 
	C190.595642,108.355278 192.370529,106.742966 194.145416,105.130646 
	C194.145416,105.130646 194.006012,105.059967 194.366699,105.051811 
	C195.458588,104.341545 196.189804,103.639427 196.921005,102.937317 
	C196.921005,102.937317 196.988403,103.003860 197.281860,102.862846 
	C199.738647,100.826370 201.901962,98.930908 204.065277,97.035446 
	C204.065277,97.035446 204.008881,96.999420 204.340805,96.936577 
	C206.827362,94.953300 208.981979,93.032867 211.136597,91.112434 
	C211.136597,91.112434 211.018112,91.044357 211.378693,91.013870 
	C213.185562,89.672958 214.631866,88.362534 216.078186,87.052116 
	C216.078186,87.052124 216.015717,87.008614 216.293671,86.993721 
	C216.760773,86.677544 216.949890,86.376259 217.139023,86.074974 
	C217.139023,86.074974 217.043915,86.068619 217.386337,86.035110 
	C218.830246,85.003288 219.931747,84.004982 221.033234,83.006676 
	C221.033234,83.006676 220.997925,82.993088 221.263428,82.958618 
	C221.727325,82.637192 221.925705,82.350227 222.124100,82.063263 
	C222.124100,82.063263 222.042725,82.045898 222.318527,82.024200 
	C222.790894,81.708878 222.987457,81.415253 223.184021,81.121628 
	C223.184021,81.121635 223.066238,81.115349 223.439514,81.068825 
	C226.910477,78.374695 230.008163,75.727081 233.105865,73.079468 
	C233.105865,73.079468 233.034653,73.025055 233.313049,73.015717 
	C233.785599,72.705795 233.979752,72.405205 234.173889,72.104607 
	C234.173889,72.104607 234.072495,72.097374 234.406464,72.070587 
	C235.507065,71.367973 236.273697,70.692139 237.040344,70.016312 
	C237.040344,70.016319 237.001144,69.994675 237.273895,69.968323 
	C237.732071,69.643684 237.917511,69.345406 238.102936,69.047119 
	C238.102936,69.047119 238.018631,69.034744 238.364075,68.997032 
	C240.200821,67.701393 241.692108,66.443459 243.183411,65.185524 
	C243.183411,65.185524 242.992813,65.139755 243.444504,65.110626 
	C249.393402,61.512360 255.091629,61.831722 260.895935,64.202606 
	C260.895935,64.202606 260.937134,64.088570 261.048218,64.398026 
	C262.373352,65.548218 263.587433,66.388947 264.801514,67.229675 
	C264.801514,67.229675 264.902954,67.143227 264.915710,67.414017 
	C265.235016,67.865112 265.541595,68.045418 265.848175,68.225723 
	C265.848175,68.225723 265.876068,68.126945 265.914886,68.398277 
	C266.256287,68.850426 266.558899,69.031250 266.861481,69.212074 
	C266.861481,69.212074 266.886017,69.114166 266.921997,69.387085 
	C267.268036,69.835159 267.578156,70.010300 267.888245,70.185448 
	C267.888245,70.185448 267.906830,70.091171 267.938019,70.368179 
	C268.279144,70.821625 268.589111,70.998055 268.899048,71.174484 
	C268.899048,71.174484 268.920624,71.055458 268.986206,71.393684 
	C270.001251,72.530624 270.950775,73.329338 271.900269,74.128052 
	C271.900269,74.128044 271.964966,74.051071 271.990479,74.323349 
	C272.321930,74.774361 272.627869,74.953094 272.933777,75.131836 
	C272.933777,75.131828 272.946991,75.034111 272.991943,75.333908 
	C273.341980,76.091148 273.647095,76.548592 273.517212,77.012047 
	C270.132721,76.636353 266.997375,76.768173 264.267334,75.780052 
	C254.740906,72.331978 246.867401,75.795662 239.340744,81.108253 
	C228.789886,88.555443 218.089066,95.810249 207.817368,103.626953 
	C198.464600,110.744362 189.496674,118.381882 180.547028,126.010574 
	C171.585846,133.649109 163.079712,141.834625 153.942963,149.249054 
	C148.511459,153.656677 142.239044,157.012650 134.669388,155.851532 
z"/>
<path fill="#DC024B" opacity="1.000000" stroke="none" 
	d="
M162.862015,127.680733 
	C157.005630,129.966141 154.038467,135.583740 149.469345,139.276413 
	C142.372879,145.011658 135.544952,151.078430 128.493423,156.871033 
	C127.655724,157.559174 126.130699,157.410614 124.838432,157.666931 
	C124.355980,162.987732 119.081741,166.483704 113.738556,165.019058 
	C109.761055,163.928726 109.292419,160.801880 110.275726,158.035446 
	C110.821701,156.499374 113.763290,155.013367 115.685791,154.931656 
	C119.936188,154.750992 121.007980,153.636276 119.693802,149.831955 
	C120.910950,148.014526 121.957954,146.451141 123.015747,144.538605 
	C123.791473,143.805618 124.556404,143.421783 125.996368,143.045380 
	C132.122421,144.070267 134.553543,141.420395 133.113861,136.030701 
	C133.113861,136.030701 133.142303,135.623917 133.186554,135.426331 
	C138.944778,130.901123 144.658752,126.573524 150.858398,122.373604 
	C152.284134,123.825455 153.224228,125.149612 153.867935,126.056290 
	C159.329132,124.162270 160.515167,121.187515 158.955246,116.954285 
	C158.955246,116.954285 158.972244,116.960258 158.959686,116.950592 
	C159.715805,116.958984 160.484497,116.977051 161.914734,117.125656 
	C163.389893,118.167366 164.203491,119.078545 165.017090,119.989723 
	C164.252533,122.446564 163.487976,124.903404 162.862015,127.680733 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M273.952179,77.006042 
	C273.647095,76.548592 273.341980,76.091148 272.954407,75.365883 
	C274.334320,75.667114 276.116943,75.881432 277.205719,76.864326 
	C283.089783,82.176224 288.824799,87.654579 294.567963,93.120964 
	C302.576294,100.743370 310.565247,108.386459 318.514008,116.070831 
	C319.802521,117.316483 320.840271,118.821487 321.991699,120.613914 
	C321.989441,121.021225 321.841339,121.173630 321.528442,121.056183 
	C305.814453,106.620720 290.413330,92.302696 274.932068,77.746933 
	C274.568787,77.356010 274.285614,77.202820 274.002167,77.024811 
	C274.001862,76.999985 273.952179,77.006042 273.952179,77.006042 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M260.787201,63.838394 
	C255.091629,61.831722 249.393402,61.512360 243.533752,65.151215 
	C246.019318,59.979912 254.789444,59.104885 260.787201,63.838394 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M188.827225,87.796555 
	C192.613983,84.528229 196.400742,81.259903 200.511108,77.894516 
	C197.753723,81.093887 194.672729,84.390305 190.931458,87.833366 
	C189.789856,87.918861 189.308548,87.857712 188.827225,87.796555 
z"/>
<path fill="#D5074A" opacity="1.000000" stroke="none" 
	d="
M188.846405,87.802124 
	C189.308548,87.857712 189.789856,87.918861 190.635590,87.987648 
	C192.124802,89.402748 193.350143,90.744293 194.333557,92.244446 
	C194.854630,93.039330 194.955307,94.109810 195.245758,95.055870 
	C194.187454,94.927956 192.941727,95.105522 192.115814,94.598816 
	C190.947296,93.881935 190.088669,92.659904 189.038422,90.908768 
	C188.935455,89.379822 188.891663,88.595894 188.847870,87.811974 
	C188.847870,87.811974 188.865570,87.807701 188.846405,87.802124 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M232.732620,73.044357 
	C230.008163,75.727081 226.910477,78.374695 223.484070,81.107460 
	C224.346313,76.353676 228.422318,74.759605 232.732620,73.044357 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M340.944580,137.742126 
	C339.194275,136.762573 337.444855,135.429016 335.246826,133.687439 
	C333.800751,132.259430 332.803223,131.239426 331.895233,130.122879 
	C331.984741,130.026337 332.167969,129.837219 332.167969,129.837219 
	C335.093781,132.354202 338.019623,134.871170 340.944580,137.742126 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M348.929932,144.753113 
	C346.346954,142.964905 343.760742,140.821121 341.041443,138.369598 
	C343.581116,140.173737 346.253906,142.285645 348.929932,144.753113 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M324.147003,122.831642 
	C326.758087,124.951347 329.369202,127.071053 332.074127,129.513977 
	C332.167969,129.837219 331.984741,130.026337 331.583466,130.004181 
	C328.749054,127.694275 326.315887,125.406509 323.949585,123.047653 
	C324.016388,122.976570 324.147003,122.831642 324.147003,122.831642 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M165.389206,119.982895 
	C164.203491,119.078545 163.389893,118.167366 162.298584,117.122543 
	C163.877884,117.146675 165.734909,117.304443 167.591919,117.462204 
	C166.981720,118.300156 166.371521,119.138107 165.389206,119.982895 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M210.756607,91.068069 
	C208.981979,93.032867 206.827362,94.953300 204.351746,96.968193 
	C203.995834,92.790176 207.235382,91.958611 210.756607,91.068069 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M203.690186,97.012596 
	C201.901962,98.930908 199.738647,100.826370 197.257233,102.822617 
	C197.106369,98.841484 200.249954,97.957756 203.690186,97.012596 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M360.937378,154.758499 
	C360.511780,154.983795 360.120117,154.823639 359.436401,154.528534 
	C357.785095,153.167175 356.425812,151.940781 354.958130,150.418320 
	C357.854156,150.151016 360.575378,150.587708 360.937378,154.758499 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M354.897217,149.796143 
	C352.981476,148.665222 351.082336,147.169525 349.039856,145.374542 
	C350.902283,146.527298 352.908020,147.979340 354.897217,149.796143 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M193.775452,105.081375 
	C192.370529,106.742966 190.595642,108.355278 188.435181,110.009781 
	C188.049591,110.051964 187.890244,109.890274 187.928314,109.576653 
	C187.981079,108.841209 187.995758,108.419395 188.010437,107.997574 
	C189.808792,107.009087 191.607132,106.020592 193.775452,105.081375 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M188.530029,87.901520 
	C188.891663,88.595894 188.935455,89.379822 188.976257,90.554611 
	C188.420578,91.120422 187.867889,91.295349 186.767944,91.726440 
	C185.464279,91.978668 184.707901,91.974739 183.951508,91.970810 
	C185.371750,90.644226 186.791977,89.317642 188.530029,87.901520 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M242.806198,65.114883 
	C241.692108,66.443459 240.200821,67.701393 238.387924,69.032333 
	C239.520554,67.751648 240.974762,66.397949 242.806198,65.114883 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M215.704895,87.022552 
	C214.631866,88.362534 213.185562,89.672958 211.422028,91.066696 
	C212.513748,89.764336 213.922684,88.378670 215.704895,87.022552 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M264.841980,66.848663 
	C263.587433,66.388947 262.373352,65.548218 260.997681,64.431557 
	C262.184875,64.926300 263.533661,65.696976 264.841980,66.848663 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M220.660339,82.992897 
	C219.931747,84.004982 218.830246,85.003288 217.414764,86.073349 
	C218.162994,85.089783 219.225220,84.034447 220.660339,82.992897 
z"/>
<path fill="#D5074A" opacity="1.000000" stroke="none" 
	d="
M183.967438,91.983261 
	C184.707901,91.974739 185.464279,91.978668 186.598114,91.968475 
	C186.614380,93.534904 186.553482,95.618523 184.051086,93.091644 
	C183.999771,92.324677 183.987152,92.143112 183.974548,91.961540 
	C183.974548,91.961540 183.983368,91.995728 183.967438,91.983261 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M271.936584,73.739746 
	C270.950775,73.329338 270.001251,72.530624 268.944153,71.437180 
	C269.882019,71.878777 270.927460,72.615105 271.936584,73.739746 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M236.672806,70.001602 
	C236.273697,70.692139 235.507065,71.367973 234.435669,72.112183 
	C234.855682,71.449348 235.580475,70.718124 236.672806,70.001602 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M204.390839,75.030579 
	C204.757767,74.332413 205.489609,73.631767 206.520325,72.853317 
	C206.131378,73.526367 205.443558,74.277229 204.390839,75.030579 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M196.562180,102.946106 
	C196.189804,103.639427 195.458588,104.341545 194.423965,105.104996 
	C194.814819,104.429184 195.509094,103.692032 196.562180,102.946106 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M179.673569,116.995438 
	C179.321991,117.704369 178.599686,118.392807 177.506042,118.819702 
	C177.115662,118.054070 177.096603,117.549965 177.077530,117.045868 
	C177.819305,117.022232 178.561066,116.998589 179.673569,116.995438 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M160.319107,113.958107 
	C160.489731,113.413765 160.980408,112.830170 161.739075,112.131927 
	C161.551102,112.651146 161.095123,113.284996 160.319107,113.958107 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M188.129105,110.132950 
	C187.672073,110.774437 187.135513,111.334938 186.313980,111.971779 
	C186.002213,111.875099 185.975418,111.702087 185.962036,111.018494 
	C185.986633,110.339470 185.997864,110.171036 186.009079,110.002609 
	C186.009079,110.002609 186.489212,109.971977 186.959564,109.955139 
	C187.429901,109.938301 187.890244,109.890274 187.890244,109.890274 
	C187.890244,109.890274 188.049591,110.051964 188.129105,110.132950 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M187.928314,109.576653 
	C187.890244,109.890274 187.429901,109.938301 187.198410,109.926659 
	C187.152069,109.380867 187.337234,108.846718 187.766418,108.155075 
	C187.995758,108.419395 187.981079,108.841209 187.928314,109.576653 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M184.638367,112.993752 
	C184.553040,113.390938 184.133316,113.817535 183.424683,114.349991 
	C183.525192,113.978287 183.914581,113.500725 184.638367,112.993752 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M176.815308,117.187248 
	C177.096603,117.549965 177.115662,118.054070 177.219162,118.928543 
	C177.303604,119.298920 177.275482,119.276421 176.957062,119.187073 
	C176.427139,119.066528 176.215668,119.035339 176.004211,119.004158 
	C176.004211,119.004158 175.987915,118.987946 175.978104,118.977966 
	C176.163223,118.421532 176.358154,117.875084 176.815308,117.187248 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M180.860870,116.126396 
	C180.923492,116.347443 180.727142,116.635155 180.268066,116.984184 
	C180.204193,116.761353 180.403046,116.477203 180.860870,116.126396 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M207.061523,72.806122 
	C206.991333,72.562111 207.196869,72.274574 207.672394,71.930710 
	C207.740662,72.170448 207.538940,72.466522 207.061523,72.806122 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M268.831055,70.902069 
	C268.589111,70.998055 268.279144,70.821625 267.891266,70.379242 
	C268.129913,70.285416 268.446503,70.457542 268.831055,70.902069 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M267.820190,69.911133 
	C267.578156,70.010300 267.268036,69.835159 266.873199,69.399994 
	C267.109680,69.305588 267.430939,69.471199 267.820190,69.911133 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M237.845749,69.115875 
	C237.917511,69.345406 237.732071,69.643684 237.275406,69.990654 
	C237.198975,69.754433 237.393768,69.469521 237.845749,69.115875 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M266.803772,68.932907 
	C266.558899,69.031250 266.256287,68.850426 265.865479,68.412079 
	C266.100159,68.320938 266.423096,68.487335 266.803772,68.932907 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M265.793152,67.945145 
	C265.541595,68.045418 265.235016,67.865112 264.849426,67.420914 
	C265.092957,67.326202 265.415558,67.495392 265.793152,67.945145 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M322.065979,120.947540 
	C322.436676,121.013199 322.690765,121.205460 322.954712,121.726425 
	C323.004578,122.002220 322.851746,122.149467 322.616486,122.067345 
	C322.085571,121.791649 321.905609,121.521118 321.841339,121.173630 
	C321.841339,121.173630 321.989441,121.021225 322.065979,120.947540 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M323.082611,121.930328 
	C323.438904,122.008316 323.679077,122.204796 324.014069,122.639755 
	C324.147003,122.831642 324.016388,122.976570 323.712677,122.965134 
	C323.223236,122.685616 323.037506,122.417542 322.851746,122.149460 
	C322.851746,122.149467 323.004578,122.002220 323.082611,121.930328 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M185.811646,112.088272 
	C185.866852,112.297821 185.675095,112.578041 185.226364,112.912704 
	C185.167816,112.697739 185.366241,112.428329 185.811646,112.088272 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M181.958145,92.884750 
	C181.973343,92.805878 182.446594,92.835358 182.683777,92.838058 
	C182.787796,93.152824 182.654633,93.464882 182.316559,93.935997 
	C182.111633,94.095047 181.917938,93.916222 181.850739,93.631638 
	C181.783539,93.347046 181.782532,92.955292 181.782532,92.955292 
	C181.782532,92.955292 181.942947,92.963615 181.958145,92.884750 
z"/>
<path fill="#D5074A" opacity="1.000000" stroke="none" 
	d="
M181.200897,96.885605 
	C181.080902,96.665131 181.272430,96.400871 181.769043,96.084679 
	C181.802536,96.241302 181.615295,96.510994 181.200897,96.885605 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M183.720520,92.023453 
	C183.987152,92.143112 183.999771,92.324677 184.004395,92.780907 
	C183.813980,93.040314 183.631546,93.025070 183.177094,92.962387 
	C183.092209,92.638428 183.279358,92.361893 183.720520,92.023453 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M216.875916,86.141434 
	C216.949890,86.376259 216.760773,86.677544 216.300354,87.031197 
	C216.223633,86.791672 216.418213,86.499786 216.875916,86.141434 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M185.840027,110.080872 
	C185.997864,110.171036 185.986633,110.339470 185.932983,110.757500 
	C185.745697,110.823425 185.582382,110.650024 185.470276,110.448196 
	C185.445145,110.402969 185.599335,110.258133 185.840027,110.080872 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M221.865692,82.127563 
	C221.925705,82.350227 221.727325,82.637192 221.263351,82.977547 
	C221.200928,82.751244 221.404114,82.471550 221.865692,82.127563 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M222.911316,81.174133 
	C222.987457,81.415253 222.790894,81.708878 222.326263,82.065079 
	C222.251678,81.827316 222.445160,81.526978 222.911316,81.174133 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M181.991745,115.157654 
	C182.053131,115.399574 181.830368,115.697609 181.328094,116.058411 
	C181.268265,115.818703 181.487930,115.516235 181.991745,115.157654 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M201.072723,77.815704 
	C200.994278,77.573898 201.188538,77.274422 201.654938,76.913193 
	C201.733185,77.153633 201.539307,77.455833 201.072723,77.815704 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M202.118301,76.856438 
	C202.056320,76.634178 202.257889,76.349770 202.720123,75.998123 
	C202.781158,76.218689 202.581497,76.506485 202.118301,76.856438 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M203.176178,75.904274 
	C203.122589,75.694817 203.314728,75.411903 203.767761,75.079758 
	C203.826416,75.297287 203.624176,75.564056 203.176178,75.904274 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M272.858582,74.867737 
	C272.627869,74.953094 272.321930,74.774361 271.940918,74.331726 
	C272.171661,74.246437 272.477539,74.425041 272.858582,74.867737 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M233.905609,72.165634 
	C233.979752,72.405205 233.785599,72.705795 233.319275,73.060097 
	C233.243835,72.818092 233.440582,72.522377 233.905609,72.165634 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M322.616486,122.067337 
	C323.037506,122.417542 323.223236,122.685616 323.645874,123.036224 
	C326.315887,125.406509 328.749054,127.694275 331.493958,130.100739 
	C332.803223,131.239426 333.800751,132.259430 334.942444,133.550659 
	C334.437988,140.817917 333.789368,147.813904 332.578369,154.799500 
	C323.805450,143.758209 311.917603,142.860031 300.271790,145.297958 
	C289.628998,147.525925 284.642578,156.175735 283.510132,167.021149 
	C281.615143,167.019119 280.156982,166.999924 278.894287,166.651581 
	C279.888702,163.877792 280.687653,161.433136 281.501221,158.943787 
	C278.319336,158.213745 276.303497,163.572601 274.096985,158.415253 
	C271.160187,154.043930 269.092834,156.160583 266.810547,159.141296 
	C265.125122,160.156235 264.183929,161.917542 262.529510,158.976166 
	C261.058105,156.360199 258.170959,155.599945 255.740189,159.226410 
	C255.344498,161.091995 255.218384,162.747879 255.092270,164.403763 
	C256.083160,164.227448 257.074036,164.051117 258.326721,164.028809 
	C259.129456,164.780106 259.670349,165.377426 259.928650,166.141205 
	C259.461243,166.904572 259.276489,167.501465 259.005219,168.315277 
	C258.918701,168.532211 258.722107,168.955887 258.378906,169.067596 
	C258.254730,171.470932 258.473785,173.762589 258.762695,176.494324 
	C258.908234,179.613800 258.983948,182.293182 258.780945,185.198090 
	C258.909332,186.966476 259.316437,188.509308 259.787537,190.487320 
	C259.924377,193.273651 259.997162,195.624802 259.765045,198.249969 
	C259.931671,203.012192 260.403198,207.500412 260.933289,212.357117 
	C261.027100,213.470505 261.062378,214.215408 260.787842,215.236664 
	C260.475647,219.402206 257.978821,223.668976 262.605652,226.925568 
	C267.657745,226.259689 272.308594,225.586411 277.232605,224.937820 
	C277.975677,224.739899 278.445618,224.517273 279.327454,224.182602 
	C281.030914,223.785141 282.322144,223.253555 283.614136,223.251862 
	C298.765198,223.232071 313.916351,223.291824 329.188812,223.485840 
	C329.519073,223.823395 329.762268,223.913910 330.058044,223.920670 
	C330.076416,223.928726 330.117218,223.919525 330.117249,223.919525 
	C330.058990,228.765930 330.000732,233.612320 329.661652,238.834518 
	C329.244720,239.079681 329.108612,238.949036 328.866028,238.625198 
	C328.558563,238.195801 328.304016,238.059784 327.658386,237.922333 
	C316.988708,231.835754 306.337463,229.638382 294.694031,235.122223 
	C285.099854,239.975830 281.624023,247.905762 281.994568,258.176880 
	C281.884918,258.395294 281.806793,258.877747 281.606018,258.944672 
	C281.405212,259.011597 281.017242,259.181000 280.556885,259.100769 
	C272.890198,259.343994 265.683868,259.667450 257.974457,260.013489 
	C259.433014,277.886230 260.821167,294.895782 261.802124,311.934692 
	C259.925293,312.308136 258.455658,312.652222 256.643799,313.053894 
	C254.526566,314.830811 252.751572,316.550232 250.847366,318.280579 
	C250.718155,318.291534 250.545212,318.097565 250.210663,318.090759 
	C249.283997,318.303894 248.691849,318.523834 247.819427,318.896027 
	C247.120544,319.069031 246.701965,319.089783 245.966156,319.097717 
	C245.117920,319.355255 244.586929,319.625641 243.784439,320.120361 
	C240.670105,321.887573 237.827255,323.430389 234.979904,324.605469 
	C234.909164,322.934692 234.633026,321.601746 234.813812,320.333954 
	C235.509308,315.457031 233.397537,312.731201 228.739548,311.541290 
	C228.821609,310.809509 228.826080,310.387085 228.901230,309.637787 
	C228.964569,308.866547 228.957245,308.422180 229.013641,307.611237 
	C229.044327,306.503510 229.011276,305.762360 229.029251,304.556580 
	C229.674301,287.522278 230.268356,270.952637 230.910721,253.035492 
	C226.914520,254.250519 223.874435,255.174850 220.512680,255.862411 
	C219.915207,249.176453 218.152954,243.312637 213.333359,238.296951 
	C211.626205,236.856155 210.069580,235.711929 208.452423,234.564819 
	C208.391891,234.561951 208.317825,234.657852 208.203064,234.402466 
	C207.724258,234.080963 207.360214,234.014847 206.768524,233.666840 
	C196.841156,229.897446 187.086121,227.628662 176.705551,232.052460 
	C175.554764,232.669846 174.725967,233.279602 173.573257,233.981277 
	C172.301041,234.994507 171.349854,235.912872 170.404907,236.837616 
	C164.089401,243.018188 162.634460,251.159927 160.854614,259.290588 
	C154.340546,259.538422 148.293121,259.854187 142.065201,260.179352 
	C143.116089,278.220184 144.126938,295.573822 144.776489,313.040497 
	C141.650833,314.141876 138.886505,315.130219 136.116425,315.722870 
	C136.737549,313.837555 137.924820,312.339233 137.901764,310.859772 
	C137.732910,300.021301 137.384277,289.183563 136.861664,278.356750 
	C136.821899,277.533081 135.006271,276.795135 133.829910,275.816254 
	C133.502335,275.499390 133.360977,275.383606 133.219620,275.267822 
	C136.782562,272.585724 137.236511,268.943878 137.096329,264.731201 
	C135.919525,229.364212 134.907898,193.991745 133.702240,158.135620 
	C133.761337,157.138824 133.964722,156.627350 134.418747,155.983704 
	C142.239044,157.012650 148.511459,153.656677 153.942963,149.249054 
	C163.079712,141.834625 171.585846,133.649109 180.547028,126.010574 
	C189.496674,118.381882 198.464600,110.744362 207.817368,103.626953 
	C218.089066,95.810249 228.789886,88.555443 239.340744,81.108253 
	C246.867401,75.795662 254.740906,72.331978 264.267334,75.780052 
	C266.997375,76.768173 270.132721,76.636353 273.517212,77.012047 
	C273.952179,77.006042 274.001862,76.999985 274.102356,77.244644 
	C274.406799,77.761963 274.676544,77.927086 275.012177,77.984680 
	C290.413330,92.302696 305.814453,106.620720 321.528442,121.056183 
	C321.905609,121.521118 322.085571,121.791649 322.616486,122.067337 
M187.569839,143.160950 
	C187.271362,143.326935 187.070938,143.572830 186.661026,144.482147 
	C185.065247,145.977753 183.469467,147.473373 181.170563,149.078613 
	C180.326233,151.155899 179.352844,153.191727 178.669113,155.320587 
	C177.740891,158.210739 177.606384,161.550125 176.014557,163.958694 
	C175.084732,165.365631 171.978149,165.333969 169.230072,166.129135 
	C169.490189,163.410202 169.623093,162.021103 169.743362,160.764008 
	C166.279984,158.464737 162.657120,159.078873 161.465363,162.119278 
	C159.761429,166.466293 156.408188,166.210297 153.198959,166.937271 
	C151.473389,167.328140 149.695251,167.487061 147.213837,167.890015 
	C146.475815,167.881073 145.737778,167.872131 144.682388,167.462311 
	C144.122665,167.612427 143.562927,167.762527 142.206390,168.135666 
	C141.815460,169.454269 141.020203,170.796234 141.094849,172.087936 
	C142.062439,188.831589 143.130463,205.569641 144.252350,222.303802 
	C144.355484,223.842117 145.001343,225.344070 145.082169,225.655762 
	C159.508209,224.787048 173.037033,223.620636 186.587463,223.254883 
	C200.244766,222.886246 214.023712,221.976669 227.931412,227.546936 
	C227.931412,223.418533 228.040741,220.609467 227.900024,217.813004 
	C227.764740,215.124420 228.344727,213.006027 231.770905,211.775803 
	C231.782898,210.144440 232.041824,208.465500 231.739151,206.894775 
	C231.420456,205.240875 230.564987,203.690414 230.001984,201.249985 
	C229.680893,199.163742 229.359802,197.077515 228.977783,194.477386 
	C229.010742,194.308060 229.043686,194.138748 229.593109,193.514877 
	C229.452286,192.010529 229.311447,190.506180 229.062500,188.256256 
	C229.075439,187.504211 229.088379,186.752182 229.645599,185.389404 
	C230.355286,178.820007 231.064972,172.250610 231.779755,164.900955 
	C231.844345,163.602798 231.908936,162.304626 232.460632,160.661179 
	C232.003433,159.438614 231.546234,158.216064 230.930008,156.615646 
	C230.930008,156.615646 230.886566,156.208008 230.868881,155.709671 
	C230.763855,155.372665 230.551971,155.128387 230.017822,154.594757 
	C230.017822,154.594757 229.911911,154.169113 229.840286,153.659790 
	C229.597366,153.429047 229.354446,153.198303 228.604233,152.532028 
	C224.583588,146.366043 219.132599,141.994278 211.678421,138.998276 
	C204.426285,135.914581 197.609406,137.386002 190.633209,140.523682 
	C190.131012,141.060577 189.628815,141.597458 189.053741,142.140305 
	C189.053741,142.140305 189.115112,142.101242 188.615829,142.226074 
	C188.422333,142.498169 188.228851,142.770279 188.000000,143.000000 
	C188.000000,143.000000 188.039963,143.037857 187.569839,143.160950 
z"/>
<path fill="#A36E0A" opacity="1.000000" stroke="none" 
	d="
M328.972504,238.818405 
	C329.108612,238.949036 329.244720,239.079681 329.638306,239.328598 
	C331.928497,244.331772 333.961212,249.216675 335.985474,254.842682 
	C336.028534,256.044464 336.080078,256.505157 336.079956,257.385437 
	C335.940430,259.485809 335.852570,261.166565 335.565247,263.036499 
	C335.199310,263.739990 335.032806,264.254364 334.663086,264.907837 
	C334.192291,265.254150 333.998047,265.515808 333.589050,265.690918 
	C332.333038,264.279449 331.365112,263.008942 330.679474,262.109009 
	C329.634125,262.718292 328.486267,263.956726 327.653229,263.772400 
	C324.381012,263.048309 321.207977,261.876038 317.931641,260.526489 
	C316.848907,259.857483 315.832642,259.532135 314.822815,259.224792 
	C314.829254,259.242767 314.791016,259.241272 314.833710,258.872375 
	C311.440430,252.562347 308.004486,246.621216 304.568512,240.680084 
	C305.426605,245.530045 304.471313,250.884918 309.001251,254.705536 
	C310.128418,255.656219 310.407898,257.612000 311.034485,259.073456 
	C310.991913,259.041107 311.093079,259.075439 310.738647,259.025696 
	C305.208740,254.523575 300.706665,249.717346 301.559357,241.977295 
	C301.104614,245.283844 300.078674,248.995819 301.377563,251.503677 
	C302.834412,254.316559 300.708862,259.384033 305.983368,260.339417 
	C306.030823,261.317688 306.119720,261.967224 306.315582,263.398651 
	C301.189697,262.101532 296.361664,260.879761 291.431915,259.632263 
	C290.598999,262.096588 289.770935,264.546539 288.617188,267.000610 
	C287.162628,267.046783 286.033752,267.088867 284.855347,266.855865 
	C284.601501,266.061249 284.397278,265.541748 284.563293,264.899963 
	C285.282837,260.448761 285.749329,256.125305 285.952454,251.789536 
	C286.217468,246.133453 286.802338,240.884705 293.596191,239.072998 
	C295.213165,238.641800 297.792084,237.584351 295.040924,235.101562 
	C306.337463,229.638382 316.988708,231.835754 327.732483,238.147583 
	C328.341309,238.779083 328.617462,238.893753 328.972504,238.818405 
M295.402954,241.288040 
	C294.268646,242.858688 293.134308,244.429337 291.891693,245.722412 
	C291.805725,245.673141 291.719757,245.623856 291.633789,245.574585 
	C291.755859,245.716385 291.877930,245.858185 292.090820,246.784790 
	C292.673309,249.190048 293.338226,251.578964 293.819366,254.004333 
	C294.583344,257.855408 297.239746,259.012726 301.124695,259.134216 
	C296.379028,253.797989 294.405060,247.868576 296.289612,240.895630 
	C296.190918,240.927719 296.092194,240.959808 295.402954,241.288040 
M317.744781,240.996033 
	C325.701599,243.897903 326.278687,251.519135 328.624725,258.009125 
	C329.446075,250.560898 326.200867,242.822220 319.913635,238.670456 
	C317.007538,236.751434 313.793732,234.323700 309.846710,236.141479 
	C312.425201,239.149063 314.773773,241.888474 317.850922,245.477707 
	C317.408997,243.190750 317.198303,242.100250 317.000000,240.999817 
	C317.000000,240.999817 317.010132,240.987183 317.744781,240.996033 
z"/>
<path fill="#DAE4EB" opacity="1.000000" stroke="none" 
	d="
M322.998779,313.011658 
	C331.126373,314.272064 333.951416,317.510956 333.994812,325.668701 
	C334.018555,330.131317 334.001282,334.594147 333.607056,339.614227 
	C333.213684,345.157715 333.215759,350.143860 333.178986,355.460083 
	C333.261108,356.208405 333.382111,356.626678 333.361389,357.351624 
	C333.103882,358.093658 332.988129,358.528931 332.488068,359.067932 
	C331.732025,363.863342 331.566162,368.582916 330.936920,373.239838 
	C330.130432,379.208588 328.951111,385.127014 327.693542,390.815369 
	C326.034973,389.694489 324.658508,388.740692 323.180450,387.985840 
	C322.191864,387.480927 321.058807,387.258789 319.994080,386.447723 
	C319.669922,376.421478 319.190704,366.859741 319.053101,357.293152 
	C318.886475,345.704742 318.879608,334.109711 319.098022,322.523132 
	C319.151733,319.674042 320.348022,316.846436 321.274597,313.899780 
	C322.019653,313.530975 322.509216,313.271332 322.998779,313.011658 
z"/>
<path fill="#653B0D" opacity="1.000000" stroke="none" 
	d="
M283.946991,167.003998 
	C284.642578,156.175735 289.628998,147.525925 300.271790,145.297958 
	C311.917603,142.860031 323.805450,143.758209 332.213074,155.040222 
	C333.641052,157.830215 334.871887,160.369080 336.207153,163.368393 
	C335.870087,166.247269 335.428619,168.665726 334.920807,171.205704 
	C334.854462,171.327240 334.898315,171.602692 334.595551,171.514069 
	C331.588470,168.091995 328.884125,164.758545 325.467682,160.547287 
	C324.822266,165.101929 324.424133,167.911377 323.949463,171.261093 
	C321.536591,167.846848 319.562836,165.053955 317.335327,161.901993 
	C316.303406,165.289581 315.447174,168.100479 314.590912,170.911392 
	C314.166382,170.919220 313.741852,170.927063 313.317322,170.934906 
	C311.762878,167.753296 310.208435,164.571686 308.478455,161.030792 
	C307.223602,164.219498 306.114716,167.037399 305.005798,169.855301 
	C304.651093,169.869339 304.296417,169.883377 303.941711,169.897430 
	C303.102295,167.179321 302.262878,164.461227 301.267639,161.238541 
	C300.239288,164.154709 299.320953,166.758881 298.402618,169.363068 
	C298.195251,169.343689 297.987854,169.324295 297.780487,169.304916 
	C297.780487,166.464340 297.780487,163.623764 297.780487,161.688660 
	C293.665558,164.773636 289.316376,168.034256 284.981995,171.031036 
	C284.793274,170.499207 284.589722,170.231171 284.262634,169.744019 
	C284.074677,169.370026 284.010284,169.215149 284.027527,168.720825 
	C284.055084,167.922256 284.001038,167.463120 283.946991,167.003998 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M262.209320,311.905365 
	C260.821167,294.895782 259.433014,277.886230 257.974457,260.013489 
	C265.683868,259.667450 272.890198,259.343994 280.446747,259.477783 
	C275.950104,260.292297 271.108398,260.771729 266.255096,260.973755 
	C260.972992,261.193665 259.774536,262.235809 260.030518,267.516541 
	C260.205750,271.131531 260.726654,274.728271 260.994904,278.340485 
	C261.690796,287.710083 262.288605,297.087280 263.051788,306.451172 
	C263.146240,307.609711 264.005615,308.705841 264.998413,310.917999 
	C264.998413,305.231567 264.841431,301.005646 265.092621,296.804169 
	C265.169556,295.517578 266.366150,294.297974 267.056519,293.510101 
	C267.077209,297.459625 267.092957,300.946747 267.105194,304.433868 
	C267.115417,307.336975 266.894531,310.562378 271.293274,309.959534 
	C274.746521,309.486298 278.965271,309.711945 278.045197,303.956512 
	C277.419830,300.044373 277.271484,296.056000 277.292114,292.123413 
	C281.816437,297.445923 280.060303,303.560028 279.328400,309.224976 
	C279.155426,310.564026 274.495911,311.310760 271.918427,312.373932 
	C271.692841,312.466980 271.602966,312.888916 271.451599,313.155365 
	C275.249054,316.276764 288.603729,314.467896 290.940643,310.494568 
	C291.168304,310.107544 291.047455,309.515564 291.331818,309.005981 
	C291.575226,308.993225 292.064667,308.980957 292.037048,308.947174 
	C292.009399,308.913391 292.077271,308.858521 292.307220,309.194183 
	C297.568604,310.698883 302.550690,312.253326 307.648224,312.906097 
	C311.249420,313.367249 315.839569,315.488617 318.250183,309.902161 
	C318.714539,309.488037 318.943909,309.209656 319.495911,308.949890 
	C320.249634,308.947266 320.680725,308.926056 321.405121,308.896667 
	C322.089447,308.896576 322.480469,308.904694 323.124390,308.861938 
	C323.594757,308.555969 323.812225,308.300812 324.221619,307.784180 
	C325.576630,306.929077 326.739746,306.335510 328.170349,306.015320 
	C330.209473,311.782990 335.591949,309.113159 338.774658,311.233459 
	C336.864014,311.515411 335.159302,311.515411 333.454559,311.515411 
	C333.261169,312.040039 333.067749,312.564667 332.874329,313.089294 
	C336.255951,314.511993 339.637604,315.934723 344.015594,317.776611 
	C344.015594,311.955048 343.888702,307.538788 344.089233,303.137451 
	C344.153412,301.728546 345.084045,300.359131 345.618286,298.971649 
	C345.618286,305.488647 345.618286,312.005676 345.618286,318.522675 
	C345.025055,318.886169 344.431793,319.249695 343.838562,319.613190 
	C339.695618,317.612335 335.625916,315.434265 331.380951,313.680420 
	C329.021820,312.705658 326.370270,312.438507 323.083069,311.898376 
	C321.173187,312.584351 320.030731,313.224335 318.900879,313.876343 
	C318.913513,313.888336 318.925873,313.908234 318.676544,313.984192 
	C318.247070,314.345978 318.066956,314.631775 317.932312,314.905151 
	C317.977783,314.892761 317.887573,314.865265 317.437622,314.907410 
	C313.300781,315.045685 309.613892,315.141846 305.707825,315.164429 
	C305.488708,315.090790 305.034149,315.006226 304.892639,314.715302 
	C303.839722,313.936432 302.928284,313.448517 302.005371,312.977386 
	C301.993927,312.994202 302.032257,312.981537 301.772278,312.671967 
	C298.676147,312.576050 295.840057,312.789673 292.764618,313.069702 
	C292.525299,313.136108 292.092529,313.379822 291.821411,313.424652 
	C291.316803,313.947479 291.083252,314.425476 290.909119,314.905579 
	C290.968567,314.907745 290.867523,314.845001 290.424683,314.876648 
	C283.342896,315.555725 276.703949,316.203156 270.012634,316.503479 
	C267.376587,314.739410 264.792938,313.322388 262.209320,311.905365 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M283.510132,167.021149 
	C284.001038,167.463120 284.055084,167.922256 283.871735,168.848206 
	C283.884949,169.531067 284.135559,169.747101 284.386200,169.963135 
	C284.589722,170.231171 284.793274,170.499207 285.011047,171.348877 
	C284.333466,172.940887 283.641693,173.951263 282.619019,174.789154 
	C281.561218,172.859146 280.834351,171.101654 279.969574,169.010635 
	C274.622467,169.010635 269.163483,169.041992 263.705078,168.997742 
	C260.863739,168.974716 259.862152,170.503571 260.012604,173.092148 
	C260.376831,179.358780 260.686737,185.630417 261.202759,191.884827 
	C261.307220,193.150833 262.223267,194.349884 263.229370,196.633423 
	C263.954041,191.893417 264.482330,188.437729 265.284546,185.283875 
	C265.698730,187.735611 265.708008,189.905884 266.037109,192.026505 
	C266.191406,193.020889 266.724701,194.341324 267.500641,194.778534 
	C269.431396,195.866409 275.879547,193.121780 277.412231,191.012527 
	C278.102478,192.293869 278.476624,193.515656 278.865326,194.785019 
	C280.233093,194.859207 281.614166,194.934113 282.982239,195.248291 
	C282.969269,195.487564 282.923981,195.964844 282.614319,195.973755 
	C281.893463,196.020142 281.482208,196.057617 280.817322,196.153931 
	C280.321411,196.455627 280.079163,196.698486 279.645782,196.951096 
	C279.454651,196.960861 279.076111,197.017365 278.647339,197.008728 
	C274.029388,197.499908 269.840179,197.999710 265.087067,198.566788 
	C265.726746,200.149063 266.310028,201.591782 266.888245,203.384628 
	C266.942505,204.198318 267.001801,204.661880 267.063660,205.444397 
	C267.104706,206.186829 267.143127,206.610321 267.115417,207.355133 
	C267.100647,208.108078 267.152069,208.539688 267.180298,209.381393 
	C267.435547,212.193405 267.713989,214.595337 267.980469,217.327301 
	C267.987549,218.096603 268.006561,218.535873 267.639771,218.913055 
	C266.135620,214.252533 265.017273,209.654114 263.898895,205.055695 
	C263.395660,205.147552 262.892395,205.239426 262.389160,205.331284 
	C262.880219,211.200760 263.210693,217.093323 264.047760,222.913025 
	C264.163818,223.719986 266.828949,224.788162 268.233276,224.680679 
	C277.780975,223.949905 287.315155,222.113907 296.838165,222.207993 
	C310.893524,222.346893 325.089050,220.063034 339.036346,223.909180 
	C342.101807,224.754517 344.099365,223.401871 344.065308,219.693695 
	C344.041779,217.132019 344.657288,214.564453 345.024170,212.463120 
	C345.063995,217.290543 345.066925,221.654388 345.069855,226.018219 
	C342.117920,225.693527 339.157776,225.428391 336.217133,225.022003 
	C334.433380,224.775467 332.679810,224.310501 330.514954,223.931595 
	C330.117218,223.919525 330.076416,223.928726 329.969879,223.725204 
	C329.637177,223.295120 329.371887,223.231415 329.067505,223.330582 
	C313.916351,223.291824 298.765198,223.232071 283.614136,223.251862 
	C282.322144,223.253555 281.030914,223.785141 279.056335,224.129349 
	C277.902069,224.429810 277.430786,224.671463 276.959473,224.913116 
	C272.308594,225.586411 267.657745,226.259689 262.559052,226.477386 
	C261.773376,222.334625 261.435486,218.647461 261.097626,214.960312 
	C261.062378,214.215408 261.027100,213.470505 260.981934,211.897781 
	C260.671326,206.705292 260.370636,202.340622 260.069946,197.975952 
	C259.997162,195.624802 259.924377,193.273651 259.860779,190.106094 
	C259.599884,187.850647 259.329773,186.411591 259.059631,184.972549 
	C258.983948,182.293182 258.908234,179.613800 258.847595,176.056274 
	C258.815826,173.104034 258.768951,171.029953 258.722107,168.955887 
	C258.722107,168.955887 258.918701,168.532211 259.463989,168.289001 
	C265.651093,167.739746 271.292908,167.433670 277.229706,167.098907 
	C277.916046,167.040375 278.307434,167.010544 278.698822,166.980713 
	C280.156982,166.999924 281.615143,167.019119 283.510132,167.021149 
z"/>
<path fill="#E8A042" opacity="1.000000" stroke="none" 
	d="
M345.708160,298.638977 
	C345.084045,300.359131 344.153412,301.728546 344.089233,303.137451 
	C343.888702,307.538788 344.015594,311.955048 344.015594,317.776611 
	C339.637604,315.934723 336.255951,314.511993 332.874329,313.089294 
	C333.067749,312.564667 333.261169,312.040039 333.454559,311.515411 
	C335.159302,311.515411 336.864014,311.515411 338.973694,311.111938 
	C339.623779,310.509247 339.820099,310.269897 340.277252,309.769043 
	C340.728241,306.027039 340.869476,302.506409 340.989136,298.572693 
	C340.951782,296.781097 340.936096,295.402649 341.190430,293.809937 
	C339.957916,290.623413 332.821411,289.326447 328.533508,291.134644 
	C327.441223,290.572357 326.642975,290.175262 325.703796,289.562622 
	C325.013000,288.691956 324.463165,288.036835 324.050842,286.980408 
	C324.395111,284.346802 324.601837,282.114502 324.845886,279.874023 
	C324.883240,279.865845 324.923767,279.930695 325.258850,279.916260 
	C326.039886,279.240723 326.485870,278.579620 326.966675,277.947388 
	C327.001465,277.976257 326.931000,277.928650 327.317139,277.961609 
	C328.255707,279.122559 328.863190,280.227448 329.346741,281.384155 
	C329.972748,282.881775 330.499939,284.420746 331.035278,285.970093 
	C331.000916,285.998322 331.058258,285.930145 331.102417,286.301361 
	C333.100220,286.783997 335.053864,286.895386 337.374664,286.998596 
	C338.856323,286.669128 339.970856,286.347931 341.448761,285.939423 
	C341.867645,283.563843 341.923126,281.275574 341.974060,278.656097 
	C342.007050,277.885132 342.044586,277.445343 342.352905,276.733826 
	C343.073517,273.311584 343.523346,270.161102 344.228912,267.007202 
	C344.655151,267.002563 344.825684,267.001282 345.000977,267.468628 
	C345.004242,270.740906 344.933197,273.546570 345.014923,276.347778 
	C345.215240,283.211548 345.486084,290.073242 345.728516,296.935791 
	C345.751709,297.392639 345.774872,297.849487 345.708160,298.638977 
z"/>
<path fill="#C1D4CC" opacity="1.000000" stroke="none" 
	d="
M333.217865,355.130005 
	C333.215759,350.143860 333.213684,345.157715 333.608856,340.082123 
	C334.673492,340.661804 335.319244,341.883667 336.011719,341.910736 
	C340.639862,342.091583 345.277954,342.017731 350.409119,342.502686 
	C350.791840,347.124084 350.678253,351.269409 350.146912,355.300232 
	C344.225403,355.167206 338.721619,355.148590 333.217865,355.130005 
z"/>
<path fill="#F5D5B5" opacity="1.000000" stroke="none" 
	d="
M345.458252,225.916016 
	C345.066925,221.654388 345.063995,217.290543 345.050293,212.064896 
	C345.056488,210.139297 345.073517,209.075531 345.338318,207.727600 
	C346.506378,203.449539 346.115234,200.432281 340.450256,201.054077 
	C338.082062,200.713913 336.094910,200.378403 334.029419,199.682816 
	C333.804840,197.880844 333.658600,196.438950 333.895264,194.996399 
	C336.511566,194.664032 338.744995,194.332352 341.254700,194.283417 
	C344.766541,195.862915 346.006287,194.810379 346.079193,191.296371 
	C346.275238,181.850769 346.742584,172.410828 347.102173,162.968628 
	C347.678802,163.621841 348.767334,164.285721 348.754028,164.926666 
	C348.592743,172.677216 348.325256,180.427048 347.956696,188.170959 
	C347.826935,190.897995 348.198547,192.906540 351.308533,193.827545 
	C352.394623,194.149170 353.773865,195.916260 353.722595,196.959534 
	C353.671112,198.006973 352.181580,199.453705 351.011566,199.887650 
	C347.578369,201.160919 346.778687,203.501892 346.936707,206.876495 
	C347.161255,211.672409 347.076294,216.489624 346.931763,221.291977 
	C346.886139,222.808762 346.225739,224.307053 345.458252,225.916016 
z"/>
<path fill="#F5FBF3" opacity="1.000000" stroke="none" 
	d="
M350.564667,355.414703 
	C350.678253,351.269409 350.791840,347.124084 350.865784,342.516693 
	C350.884064,335.089020 350.643555,328.101685 351.150024,321.168854 
	C351.346466,318.479706 353.231781,315.913910 354.662354,313.119080 
	C354.322540,322.194916 353.187683,331.440399 353.125793,340.693054 
	C353.031860,354.734955 353.653748,368.782684 354.054413,382.825592 
	C354.105408,384.612549 354.654694,386.385315 354.603333,388.576538 
	C353.454590,389.024292 352.675690,389.060120 351.774536,389.019592 
	C351.470001,388.877777 351.282928,388.858398 350.873016,388.536316 
	C350.611359,386.714630 350.546326,385.242004 350.527832,383.768768 
	C350.420319,375.208435 350.323761,366.647949 350.408051,357.789856 
	C350.583252,356.799713 350.573944,356.107208 350.564667,355.414703 
z"/>
<path fill="#DCA55B" opacity="1.000000" stroke="none" 
	d="
M346.814880,162.676010 
	C346.742584,172.410828 346.275238,181.850769 346.079193,191.296371 
	C346.006287,194.810379 344.766541,195.862915 341.412720,193.898132 
	C341.577942,187.153091 341.861450,181.076096 342.401062,175.202942 
	C343.421082,175.398300 344.845947,175.447144 344.852417,175.372955 
	C345.022644,173.409943 344.876556,171.412338 345.127472,169.464828 
	C345.849060,163.862946 345.404358,163.635162 340.003143,164.899124 
	C336.011353,165.833267 337.339325,168.783630 336.564514,171.111633 
	C335.828552,171.087799 335.407837,171.085983 334.987152,171.084167 
	C335.428619,168.665726 335.870087,166.247269 336.520233,163.538010 
	C339.995148,162.959259 343.261383,162.671326 346.814880,162.676010 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M345.833008,296.484131 
	C345.486084,290.073242 345.215240,283.211548 345.014923,276.347778 
	C344.933197,273.546570 345.004242,270.740906 345.007751,267.007507 
	C345.009735,262.863556 345.009735,259.649384 345.009735,255.749863 
	C341.498840,256.230713 338.815216,256.598297 336.131592,256.965851 
	C336.080078,256.505157 336.028534,256.044464 336.031647,255.239380 
	C339.490753,254.429886 342.895233,253.964767 345.937531,253.549149 
	C345.937531,267.772034 345.937531,281.902252 345.833008,296.484131 
z"/>
<path fill="#F6ECD6" opacity="1.000000" stroke="none" 
	d="
M318.888275,313.864319 
	C320.030731,313.224335 321.173187,312.584351 322.663727,311.952148 
	C323.008698,312.135162 323.005524,312.310394 323.000580,312.748657 
	C322.509216,313.271332 322.019653,313.530975 320.918274,313.893311 
	C319.833710,313.952087 319.360992,313.908203 318.888275,313.864319 
z"/>
<path fill="#BED5D0" opacity="1.000000" stroke="none" 
	d="
M418.992828,354.675110 
	C413.526764,355.147736 408.106445,355.147736 402.024048,355.147736 
	C402.024048,362.844391 402.024994,370.228180 402.023651,377.612000 
	C402.022949,381.416260 401.820251,385.233826 402.075012,389.020752 
	C402.297272,392.325043 401.100677,393.609100 397.440155,392.968719 
	C397.661438,389.507690 398.639984,386.158264 398.904144,382.753387 
	C399.465912,375.511749 399.629211,368.239899 400.006653,360.983063 
	C400.375122,353.897552 400.806824,346.815277 401.425537,339.404480 
	C407.098145,339.355011 412.556000,339.632660 418.476257,339.897034 
	C418.960022,340.074249 418.981323,340.264801 418.529175,340.729980 
	C413.093048,341.004578 408.130371,341.004578 403.167725,341.004578 
	C403.173615,341.329102 403.179474,341.653625 403.185364,341.978149 
	C408.460724,341.978149 413.736115,341.978149 419.011475,341.978149 
	C419.020508,346.052917 419.029541,350.127716 418.992828,354.675110 
z"/>
<path fill="#F3FBF1" opacity="1.000000" stroke="none" 
	d="
M388.992371,392.360596 
	C381.873779,393.896545 385.940826,388.334534 384.918457,384.939056 
	C384.888702,377.637024 384.791016,371.229767 384.792114,364.399231 
	C384.855835,361.086700 384.820740,358.197449 384.855347,354.831085 
	C384.627716,349.619629 384.330353,344.885315 384.032990,340.150970 
	C384.358795,337.370056 384.684601,334.589142 384.964111,331.064789 
	C384.868805,329.899628 384.819763,329.477905 384.770691,329.056183 
	C384.802460,327.713867 384.834229,326.371552 384.856628,324.632843 
	C385.671356,321.280060 386.495483,318.323639 387.657715,315.186768 
	C388.326874,340.638977 388.657959,366.271667 388.992371,392.360596 
z"/>
<path fill="#BED4CB" opacity="1.000000" stroke="none" 
	d="
M383.568604,340.109375 
	C384.330353,344.885315 384.627716,349.619629 384.407043,354.754944 
	C378.697418,355.520081 373.505829,355.884277 368.245422,356.696350 
	C368.146576,368.756500 368.116547,380.368774 368.086487,391.981018 
	C367.850647,392.234344 367.614838,392.487671 366.807434,392.857971 
	C364.743408,392.376617 363.250916,391.778229 361.758453,391.179810 
	C362.504883,390.004517 363.814697,388.872772 363.909393,387.647095 
	C364.734619,376.968903 365.402069,366.277191 365.989166,355.582489 
	C366.284149,350.209015 366.315430,344.821045 366.724365,339.193848 
	C372.356598,339.321289 377.730408,339.694550 383.568604,340.109375 
z"/>
<path fill="#5FA426" opacity="1.000000" stroke="none" 
	d="
M361.388672,391.147552 
	C363.250916,391.778229 364.743408,392.376617 366.606262,393.022339 
	C361.830139,394.394867 356.707245,396.504517 351.208740,392.866760 
	C351.046906,391.479523 351.107544,390.764374 351.333252,389.919556 
	C351.776123,389.641937 351.908936,389.410614 351.896759,389.095947 
	C352.675690,389.060120 353.454590,389.024292 354.625549,388.999573 
	C357.018036,389.712280 359.018494,390.413788 361.388672,391.147552 
z"/>
<path fill="#F2D7D5" opacity="1.000000" stroke="none" 
	d="
M181.614441,93.024155 
	C181.782532,92.955292 181.783539,93.347046 181.755234,93.540771 
	C181.726929,93.734505 181.366196,93.754402 181.191650,93.708420 
	C181.087433,93.417778 181.230515,93.227974 181.614441,93.024155 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M419.528198,389.622498 
	C419.206879,379.277008 419.128967,368.604492 419.143799,357.472717 
	C419.496552,361.706940 419.914795,366.398376 419.977020,371.094543 
	C420.057404,377.159546 419.853271,383.228333 419.528198,389.622498 
z"/>
<path fill="#D8E4E6" opacity="1.000000" stroke="none" 
	d="
M419.013336,341.740967 
	C413.736115,341.978149 408.460724,341.978149 403.185364,341.978149 
	C403.179474,341.653625 403.173615,341.329102 403.167725,341.004578 
	C408.130371,341.004578 413.093048,341.004578 418.521851,341.017456 
	C418.987976,341.030304 419.015167,341.503815 419.013336,341.740967 
z"/>
<path fill="#B5D622" opacity="1.000000" stroke="none" 
	d="
M384.440674,328.979645 
	C384.819763,329.477905 384.868805,329.899628 384.847656,330.634064 
	C384.220642,331.473877 383.281921,331.933411 383.174683,332.540009 
	C382.761292,334.877472 383.548492,338.229309 379.748535,337.906006 
	C376.270599,337.610138 371.629944,337.428711 369.809021,335.259735 
	C368.191620,333.333130 369.177979,328.590118 370.074982,325.387054 
	C370.765198,322.922424 379.362274,323.989014 382.158325,326.708191 
	C382.858459,327.389069 383.462433,328.168793 384.440674,328.979645 
z"/>
<path fill="#429E19" opacity="1.000000" stroke="none" 
	d="
M34.852207,153.747040 
	C34.462147,153.829941 33.906414,153.666138 33.184891,153.257507 
	C33.574917,153.175217 34.130726,153.337753 34.852207,153.747040 
z"/>
<path fill="#DA034A" opacity="1.000000" stroke="none" 
	d="
M158.565720,116.953140 
	C160.515167,121.187515 159.329132,124.162270 153.867935,126.056290 
	C153.224228,125.149612 152.284134,123.825455 151.155258,122.219482 
	C151.655090,120.901329 152.343689,119.864983 153.139343,118.538452 
	C153.739731,118.008827 154.233047,117.769409 154.896332,117.311539 
	C156.102951,117.046059 157.139572,116.999031 158.565720,116.953140 
z"/>
<path fill="#CF0C47" opacity="1.000000" stroke="none" 
	d="
M132.937714,136.302246 
	C134.553543,141.420395 132.122421,144.070267 126.316025,143.008881 
	C126.646301,141.915054 127.331955,140.865173 128.121399,139.521301 
	C128.721573,138.986755 129.217972,138.746201 129.875000,138.277374 
	C130.944275,137.557312 131.852921,137.065552 132.937714,136.302246 
z"/>
<path fill="#6FA43A" opacity="1.000000" stroke="none" 
	d="
M103.508659,292.032806 
	C103.346611,291.530334 103.184563,291.027832 103.033722,290.254517 
	C103.044930,289.983704 103.474510,289.958923 103.686913,289.927979 
	C103.899315,289.897064 104.017487,290.024170 103.915680,290.388855 
	C103.712143,291.179932 103.610397,291.606384 103.508659,292.032806 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M176.022949,119.329948 
	C176.215668,119.035339 176.427139,119.066528 176.944763,119.197411 
	C177.043732,119.569923 176.836548,119.842735 176.380966,120.208794 
	C176.102249,120.086609 176.071960,119.871170 176.022949,119.329948 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M128.781631,278.711029 
	C128.826477,278.374420 128.968384,278.085510 129.301880,277.618439 
	C130.476105,276.732361 131.555771,276.072052 132.927536,275.339783 
	C133.360977,275.383606 133.502335,275.499390 133.773956,276.187988 
	C132.196686,277.410889 130.489166,278.060944 128.781631,278.711029 
z"/>
<path fill="#FEFEFD" opacity="1.000000" stroke="none" 
	d="
M128.787659,278.727692 
	C130.489166,278.060944 132.196686,277.410889 133.960175,276.389099 
	C135.006271,276.795135 136.821899,277.533081 136.861664,278.356750 
	C137.384277,289.183563 137.732910,300.021301 137.901764,310.859772 
	C137.924820,312.339233 136.737549,313.837555 136.107498,315.706116 
	C136.104340,316.085052 136.091873,316.097595 135.737183,316.051331 
	C134.263168,316.323761 133.143845,316.642426 131.871765,316.649292 
	C128.591858,311.257233 125.604095,310.151611 119.607056,311.999817 
	C115.049057,312.667816 114.160194,315.749451 114.021622,319.262451 
	C113.912102,322.039154 113.999596,324.823608 113.999596,327.604614 
	C113.152885,327.701202 112.306183,327.797791 111.459473,327.894409 
	C110.640495,324.388702 109.842918,320.877808 108.995270,317.379059 
	C108.394821,314.900665 107.726669,312.438721 106.986977,309.770691 
	C106.723831,309.307739 106.498444,309.120636 106.209068,309.010651 
	C106.111160,308.568329 106.013260,308.126007 106.209305,307.138306 
	C106.073288,305.388550 105.643311,304.184113 105.213333,302.979706 
	C105.087234,302.247284 104.961136,301.514862 105.141632,300.212402 
	C104.976822,298.100922 104.505402,296.559448 104.033981,295.018005 
	C103.901039,294.563568 103.768097,294.109100 104.038544,293.295654 
	C106.291725,291.600159 108.141533,290.263672 109.991333,288.927185 
	C109.991341,288.927185 110.198494,288.702515 110.784119,288.813232 
	C117.115005,286.346802 122.876846,283.804626 128.566254,281.109558 
	C128.924591,280.939819 128.717636,279.576691 128.773682,278.768768 
	C128.773682,278.768768 128.793701,278.744354 128.787659,278.727692 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M128.435547,278.825378 
	C128.717636,279.576691 128.924591,280.939819 128.566254,281.109558 
	C122.876846,283.804626 117.115005,286.346802 110.931374,288.853973 
	C116.361137,285.483307 122.229263,282.182617 128.435547,278.825378 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M109.613754,288.930511 
	C108.141533,290.263672 106.291725,291.600159 104.057777,292.938904 
	C103.578094,292.802155 103.482552,292.663177 103.447830,292.278503 
	C103.610397,291.606384 103.712143,291.179932 103.974380,290.452728 
	C105.835320,289.745911 107.535751,289.339874 109.613754,288.930511 
z"/>
<path fill="#DBE4EB" opacity="1.000000" stroke="none" 
	d="
M97.300461,355.156860 
	C97.253571,356.504700 97.206680,357.852509 96.667374,359.682007 
	C95.786255,361.819702 95.190010,363.457672 95.043709,365.134888 
	C94.291924,373.753510 93.655754,382.382233 92.532272,391.008667 
	C89.408012,391.015442 86.730690,391.020966 84.037834,390.556396 
	C83.688515,379.316437 83.220177,368.548523 83.057922,357.776062 
	C82.887627,346.469391 82.666824,335.142365 83.190056,323.857697 
	C83.375908,319.849274 85.682419,315.939178 87.020851,311.984192 
	C93.705437,310.655457 97.181145,313.261230 97.257782,319.750854 
	C97.298447,323.194214 97.190277,326.639313 96.904083,330.628540 
	C96.597458,331.281158 96.458511,331.448242 96.487785,331.486511 
	C96.629562,331.671753 96.816544,331.822388 96.987503,331.985291 
	C96.987503,331.985291 97.173141,331.947235 97.158951,332.379639 
	C97.175491,335.162292 97.206230,337.512573 96.898148,340.140137 
	C96.806366,345.330597 97.053413,350.243713 97.300461,355.156860 
z"/>
<path fill="#F4F9EE" opacity="1.000000" stroke="none" 
	d="
M107.088730,309.969421 
	C107.726669,312.438721 108.394821,314.900665 108.995270,317.379059 
	C109.842918,320.877808 110.640495,324.388702 111.459473,327.894409 
	C112.306183,327.797791 113.152885,327.701202 113.999596,327.604614 
	C113.999596,324.823608 113.912102,322.039154 114.021622,319.262451 
	C114.160194,315.749451 115.049057,312.667816 119.606827,312.388672 
	C115.810112,320.828522 116.899010,329.535706 117.120018,338.049316 
	C117.553482,354.746674 118.300316,371.436798 119.046158,388.124207 
	C119.187798,391.292999 117.867615,392.323425 114.981689,392.040771 
	C114.836884,388.722351 114.692070,385.403961 114.642517,381.186829 
	C114.711960,371.981750 114.686134,363.675323 114.750763,354.886841 
	C114.825584,349.497223 114.809959,344.589691 114.796890,339.276245 
	C115.328056,335.077301 114.865059,331.976715 109.977997,331.965179 
	C108.734596,324.913116 107.491188,317.861023 106.473480,310.407867 
	C106.699165,310.006775 107.088730,309.969421 107.088730,309.969421 
z"/>
<path fill="#F6FCF3" opacity="1.000000" stroke="none" 
	d="
M86.646118,311.946259 
	C85.682419,315.939178 83.375908,319.849274 83.190056,323.857697 
	C82.666824,335.142365 82.887627,346.469391 83.057922,357.776062 
	C83.220177,368.548523 83.688515,379.316437 83.792633,390.805664 
	C82.723564,389.948761 81.196724,388.390106 81.155975,386.793518 
	C80.884918,376.173706 80.931763,365.545807 80.889297,354.463226 
	C80.880035,350.023926 80.856750,346.041931 80.861488,341.728241 
	C80.524353,340.895599 80.159187,340.394623 79.841476,339.572876 
	C79.952492,338.824554 80.016068,338.397034 80.383591,337.686218 
	C80.756798,330.226135 80.826057,323.049316 80.895317,315.872498 
	C82.687340,314.551117 84.479370,313.229706 86.646118,311.946259 
z"/>
<path fill="#B4CD38" opacity="1.000000" stroke="none" 
	d="
M109.519867,332.002136 
	C114.865059,331.976715 115.328056,335.077301 114.353104,339.374207 
	C108.350151,339.872986 102.793556,339.867920 97.236969,339.862823 
	C97.206230,337.512573 97.175491,335.162292 97.253281,332.388580 
	C101.261787,331.989777 105.161766,332.014435 109.519867,332.002136 
z"/>
<path fill="#A68F23" opacity="1.000000" stroke="none" 
	d="
M80.079636,337.969513 
	C80.016068,338.397034 79.952492,338.824554 79.391190,339.530823 
	C73.646370,339.839569 68.399284,339.869507 63.109978,339.666504 
	C63.067764,339.433533 62.861118,339.007568 62.861115,339.007568 
	C62.936558,338.590546 63.012005,338.173523 63.196075,337.460205 
	C64.476578,337.519836 65.802345,338.418335 66.793518,338.137177 
	C70.925560,336.965179 74.956940,335.438293 79.417114,334.373016 
	C79.899200,335.792816 79.989418,336.881165 80.079636,337.969513 
z"/>
<path fill="#D8EACF" opacity="1.000000" stroke="none" 
	d="
M80.383591,337.686218 
	C79.989418,336.881165 79.899200,335.792816 79.833076,334.374268 
	C80.004845,328.191345 80.152519,322.338654 80.597755,316.179230 
	C80.826057,323.049316 80.756798,330.226135 80.383591,337.686218 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M103.913048,295.006714 
	C104.505402,296.559448 104.976822,298.100922 105.162186,299.840393 
	C104.554016,298.876587 104.231895,297.714691 103.840866,295.996490 
	C103.771950,295.440155 103.570549,295.095001 103.570549,295.095001 
	C103.570549,295.095001 103.792114,294.995422 103.913048,295.006714 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M105.137238,303.330566 
	C105.643311,304.184113 106.073288,305.388550 106.235016,306.797974 
	C105.664902,305.895782 105.363022,304.788605 105.137238,303.330566 
z"/>
<path fill="#C1D5D3" opacity="1.000000" stroke="none" 
	d="
M97.059799,331.741547 
	C96.816544,331.822388 96.629562,331.671753 96.487785,331.486511 
	C96.458511,331.448242 96.597458,331.281158 96.827263,331.090698 
	C97.041145,331.171234 97.086617,331.334503 97.059799,331.741547 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M106.986977,309.770691 
	C107.088730,309.969421 106.699165,310.006775 106.503517,310.009155 
	C106.246582,309.849091 106.185303,309.686676 106.166542,309.267456 
	C106.498444,309.120636 106.723831,309.307739 106.986977,309.770691 
z"/>
<path fill="#6FA43A" opacity="1.000000" stroke="none" 
	d="
M103.483627,295.186768 
	C103.570549,295.095001 103.771950,295.440155 103.742516,295.635712 
	C103.420471,295.747498 103.315010,295.563232 103.483627,295.186768 
z"/>
<path fill="#C0D4D4" opacity="1.000000" stroke="none" 
	d="
M62.598625,339.324646 
	C62.861118,339.007568 63.067764,339.433533 63.137703,340.071594 
	C64.655403,341.140900 66.090355,341.885010 67.553307,341.944885 
	C71.974190,342.125793 76.405945,342.041077 80.833458,342.059937 
	C80.856750,346.041931 80.880035,350.023926 80.609497,354.504761 
	C80.129089,355.035980 79.942520,355.068329 79.303879,355.073547 
	C76.885422,355.025513 74.918961,354.981110 72.952675,354.987640 
	C61.988216,355.024017 61.988232,355.028229 63.350655,366.692322 
	C63.161732,375.743530 63.061840,384.352448 62.961952,392.961334 
	C62.961952,392.961334 62.975895,392.982971 62.967796,392.973145 
	C61.256351,392.992828 59.553005,393.022339 57.433495,392.962219 
	C57.972248,390.504700 59.439777,388.207123 59.776218,385.754395 
	C60.494308,380.519440 60.712585,375.208405 60.979725,369.920654 
	C61.489475,359.830597 61.890163,349.735046 62.598625,339.324646 
z"/>
<path fill="#F6FBF7" opacity="1.000000" stroke="none" 
	d="
M50.291912,313.992218 
	C50.458904,313.691803 50.920750,313.373657 51.677635,313.041656 
	C51.510700,313.343353 51.048733,313.658905 50.291912,313.992218 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M45.896141,375.557953 
	C44.819908,376.693085 43.819218,377.367004 42.458935,378.065125 
	C42.099339,378.089325 41.971172,377.977631 42.029007,377.595612 
	C42.722996,374.726685 43.821781,372.252228 43.889080,369.750000 
	C43.995419,365.795868 43.410015,361.823120 43.129723,358.025574 
	C40.717842,359.834839 37.889225,361.956726 35.002708,364.014282 
	C34.944813,363.949921 34.824871,363.825043 34.824879,363.825043 
	C37.931351,360.391052 41.037811,356.957031 44.144276,353.523041 
	C44.753410,353.825806 45.362549,354.128571 45.971684,354.431335 
	C45.971684,361.319794 45.971684,368.208282 45.896141,375.557953 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M29.931194,389.056854 
	C24.335939,380.196838 28.324970,372.645935 33.629128,365.101227 
	C33.975109,364.968994 34.088730,365.064972 34.060516,365.451691 
	C33.570961,367.214294 33.139774,368.601196 32.642208,369.963867 
	C31.472948,373.165985 29.718380,376.277466 29.247097,379.575165 
	C28.817533,382.580902 29.765978,385.783539 30.107052,388.899414 
	C30.107050,388.899414 29.990404,389.005035 29.931194,389.056854 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M33.920212,364.918823 
	C33.950855,364.539276 34.126419,364.268127 34.608437,363.940186 
	C34.824871,363.825043 34.944813,363.949921 34.881264,364.215485 
	C34.620625,364.732971 34.377632,364.927612 34.088730,365.064972 
	C34.088730,365.064972 33.975109,364.968994 33.920212,364.918823 
z"/>
<path fill="#DAE5E9" opacity="1.000000" stroke="none" 
	d="
M305.034149,315.006226 
	C305.034149,315.006226 305.488708,315.090790 305.728851,315.607178 
	C306.015869,319.821899 306.062744,323.520233 306.110504,327.431732 
	C306.111420,327.644867 305.958984,328.043976 305.699463,328.239990 
	C305.583221,329.614471 305.726440,330.792999 305.939758,332.410278 
	C306.094849,335.201294 306.179840,337.553589 305.916687,340.160095 
	C305.747864,345.303070 305.927185,350.191803 306.073669,355.468872 
	C305.922180,356.887604 305.803558,357.918060 305.324341,359.080902 
	C304.649200,360.097626 304.117523,360.966492 304.053406,361.868591 
	C303.319458,372.192902 302.656708,382.522247 301.974976,392.850250 
	C297.844543,392.827972 293.714172,392.805695 289.854309,392.372375 
	C293.708954,392.156067 293.014954,389.517395 292.984955,387.483459 
	C292.683563,367.060486 292.212494,346.638824 292.104523,326.215118 
	C292.081482,321.853455 293.331573,317.485046 294.439758,313.083679 
	C297.266968,313.025543 299.649628,313.003540 302.032257,312.981537 
	C302.032257,312.981537 301.993927,312.994202 302.044922,313.329773 
	C303.075317,314.112274 304.054749,314.559235 305.034149,315.006226 
z"/>
<path fill="#D9E5E9" opacity="1.000000" stroke="none" 
	d="
M115.190605,392.418427 
	C117.867615,392.323425 119.187798,391.292999 119.046158,388.124207 
	C118.300316,371.436798 117.553482,354.746674 117.120018,338.049316 
	C116.899010,329.535706 115.810112,320.828522 119.985291,312.384277 
	C125.604095,310.151611 128.591858,311.257233 131.865204,317.017029 
	C132.032227,318.185547 132.053055,318.674500 132.063370,319.392059 
	C132.052856,319.620636 131.865585,320.038147 131.605881,320.294556 
	C131.432571,322.659668 131.518967,324.768372 131.677902,327.302673 
	C131.635925,331.633514 131.521423,335.538818 131.286240,339.910645 
	C130.780762,345.152588 130.273254,349.921936 130.037811,354.704712 
	C129.606567,363.465302 129.423050,372.238922 128.909744,380.993835 
	C128.827438,382.397400 127.570076,383.732056 126.497910,385.104858 
	C125.153961,386.364868 124.168022,387.618591 123.182083,388.872314 
	C124.533348,389.607147 125.875885,390.358643 127.238449,391.071899 
	C128.480209,391.721893 129.743698,392.330444 130.997604,392.957245 
	C125.798241,392.903534 120.598885,392.849792 115.190605,392.418427 
z"/>
<path fill="#DAE4EA" opacity="1.000000" stroke="none" 
	d="
M261.802124,311.934692 
	C264.792938,313.322388 267.376587,314.739410 269.990082,316.956451 
	C270.067627,319.252655 270.115295,320.748840 270.165771,322.588867 
	C270.106659,323.621582 270.044739,324.310364 269.692017,325.298035 
	C268.942444,339.809113 268.626984,354.027985 267.970062,368.231079 
	C267.589844,376.451874 266.659607,384.647217 265.976929,392.854034 
	C263.594086,392.906738 261.211273,392.959412 258.234436,392.597412 
	C257.635559,390.592224 257.662720,389.000885 257.620972,387.411377 
	C257.087372,367.092651 256.426270,346.776001 256.107178,326.453979 
	C256.047089,322.626862 257.395813,318.786469 257.949432,314.931396 
	C258.033691,314.344604 257.326935,313.644196 256.986023,312.996338 
	C258.455658,312.652222 259.925293,312.308136 261.802124,311.934692 
z"/>
<path fill="#94CC14" opacity="1.000000" stroke="none" 
	d="
M288.700623,385.042847 
	C283.135406,386.041626 277.570160,387.040405 271.478149,387.558044 
	C270.908966,377.822845 270.738464,368.565735 270.959503,359.317963 
	C270.993500,357.896149 272.786407,355.355652 273.840210,355.316162 
	C278.907898,355.126251 284.001129,355.616852 289.075684,356.344757 
	C289.153137,363.569366 289.240234,370.315063 289.240967,377.279419 
	C289.154602,377.498108 289.323914,377.936829 289.232849,378.375610 
	C288.994690,380.890533 288.847656,382.966675 288.700623,385.042847 
z"/>
<path fill="#94CC16" opacity="1.000000" stroke="none" 
	d="
M368.497498,391.988525 
	C368.116547,380.368774 368.146576,368.756500 368.604492,356.628784 
	C375.721558,356.375031 380.301788,360.429138 384.693329,364.822540 
	C384.791016,371.229767 384.888702,377.637024 384.878967,384.490601 
	C382.330261,386.576050 380.048981,388.574799 377.402435,389.753021 
	C374.761627,390.928650 371.754852,391.282104 368.497498,391.988525 
z"/>
<path fill="#AED328" opacity="1.000000" stroke="none" 
	d="
M216.975769,385.924408 
	C211.833237,383.255341 206.626160,381.190674 200.547516,384.062805 
	C199.956329,376.230438 199.694321,368.540527 199.433746,360.557953 
	C199.395203,359.678955 199.355209,359.092621 199.643036,358.310181 
	C201.255890,357.099152 202.501907,355.279968 203.833038,355.215363 
	C208.383240,354.994720 212.963379,355.390686 217.554779,355.980682 
	C217.626328,361.542877 217.675156,366.675812 217.529602,372.080261 
	C217.467850,373.624420 217.600494,374.897095 217.703445,376.576233 
	C217.441101,379.963257 217.208435,382.943848 216.975769,385.924408 
z"/>
<path fill="#96CD13" opacity="1.000000" stroke="none" 
	d="
M351.168182,390.049225 
	C351.107544,390.764374 351.046906,391.479523 350.989197,392.552124 
	C347.267426,392.899811 343.542755,392.890076 339.468872,392.459961 
	C340.371307,390.632751 342.189178,389.427826 342.775360,387.783752 
	C345.176544,381.048950 341.876068,377.001892 333.412537,375.512024 
	C333.172211,369.689880 333.022278,364.327057 332.872345,358.964233 
	C332.988129,358.528931 333.103882,358.093658 333.729065,357.277161 
	C339.685181,355.705170 345.112152,354.779602 350.223541,358.087524 
	C350.323761,366.647949 350.420319,375.208435 350.527832,383.768768 
	C350.546326,385.242004 350.611359,386.714630 350.650391,388.689270 
	C350.819977,389.477081 350.994080,389.763153 351.168182,390.049225 
z"/>
<path fill="#96CC19" opacity="1.000000" stroke="none" 
	d="
M146.046356,381.999786 
	C141.709961,382.681732 137.373581,383.363678 132.542847,383.581146 
	C132.022461,375.154572 132.058777,367.191528 131.936798,359.230927 
	C131.893616,356.411926 132.638016,354.852631 135.833328,355.043640 
	C140.131287,355.300537 144.445129,355.291718 148.770660,355.830780 
	C148.744400,364.494720 148.699600,372.724854 148.375977,381.007843 
	C147.413528,381.373749 146.729950,381.686768 146.046356,381.999786 
z"/>
<path fill="#95CB18" opacity="1.000000" stroke="none" 
	d="
M182.737167,379.930176 
	C177.519531,380.877991 172.301895,381.825836 166.631317,382.347534 
	C166.129883,374.325378 166.154999,366.727875 165.995377,359.134247 
	C165.934494,356.237061 166.824982,354.887787 169.948914,355.037964 
	C174.232910,355.243835 178.533264,355.109161 182.869232,355.437073 
	C183.018143,356.167358 183.124451,356.581024 183.158539,357.250061 
	C183.146790,357.671753 183.207230,357.838074 183.199188,358.467590 
	C183.167450,365.636810 183.204178,372.342743 183.096603,379.258575 
	C182.880585,379.622345 182.808868,379.776276 182.737167,379.930176 
z"/>
<path fill="#BFD3CB" opacity="1.000000" stroke="none" 
	d="
M289.085266,355.865845 
	C284.001129,355.616852 278.907898,355.126251 273.840210,355.316162 
	C272.786407,355.355652 270.993500,357.896149 270.959503,359.317963 
	C270.738464,368.565735 270.908966,377.822845 271.047668,387.536041 
	C270.316406,390.217224 271.372803,394.069458 266.379730,392.944916 
	C266.659607,384.647217 267.589844,376.451874 267.970062,368.231079 
	C268.626984,354.027985 268.942444,339.809113 269.881561,325.294220 
	C270.361938,324.991577 270.740967,324.993561 270.589905,325.375122 
	C270.451294,326.834442 270.463806,327.912201 270.387115,329.246033 
	C270.354462,329.673920 270.410950,329.845673 270.400940,330.450226 
	C270.384918,333.204834 270.435364,335.526642 270.408630,338.253235 
	C271.751587,339.149231 273.159302,340.015533 274.594086,340.063080 
	C279.399292,340.222260 284.214233,340.088837 289.179382,340.408813 
	C289.250671,345.788696 289.167969,350.827271 289.085266,355.865845 
M278.471008,341.117645 
	C276.377106,341.117645 274.283203,341.117645 272.189301,341.117645 
	C272.216156,341.443604 272.243042,341.769531 272.269928,342.095490 
	C274.505096,341.920654 276.740295,341.745850 278.471008,341.117645 
M286.245270,341.055267 
	C285.814056,341.175110 285.382843,341.294983 284.951660,341.414825 
	C285.052216,341.631958 285.152802,341.849091 285.253387,342.066223 
	C285.757019,341.755249 286.260651,341.444305 286.245270,341.055267 
z"/>
<path fill="#C1D5C9" opacity="1.000000" stroke="none" 
	d="
M148.752151,355.396912 
	C144.445129,355.291718 140.131287,355.300537 135.833328,355.043640 
	C132.638016,354.852631 131.893616,356.411926 131.936798,359.230927 
	C132.058777,367.191528 132.022461,375.154572 132.138763,383.579529 
	C132.128860,384.433685 132.028748,384.825012 131.513351,385.541687 
	C129.684021,385.610901 128.269974,385.354736 126.855919,385.098572 
	C127.570076,383.732056 128.827438,382.397400 128.909744,380.993835 
	C129.423050,372.238922 129.606567,363.465302 130.037811,354.704712 
	C130.273254,349.921936 130.780762,345.152588 131.706345,340.027588 
	C137.822327,339.427856 143.397522,339.177673 148.999634,339.400818 
	C148.935074,345.048370 148.843613,350.222656 148.752151,355.396912 
z"/>
<path fill="#C1D7CC" opacity="1.000000" stroke="none" 
	d="
M182.826614,355.120422 
	C178.533264,355.109161 174.232910,355.243835 169.948914,355.037964 
	C166.824982,354.887787 165.934494,356.237061 165.995377,359.134247 
	C166.154999,366.727875 166.129883,374.325378 166.250015,382.358521 
	C166.198029,384.240753 166.074417,385.685822 165.684662,387.383118 
	C162.790939,386.204102 160.163345,384.772827 157.990997,383.239838 
	C159.871017,382.933655 161.295807,382.729218 162.781891,382.515991 
	C163.213699,378.660004 163.788376,375.223389 163.949265,371.767517 
	C164.396286,362.164734 164.637650,352.552429 165.062866,342.948456 
	C165.108704,341.913116 165.827225,340.907593 166.682953,339.876740 
	C172.406708,339.863495 177.681213,339.861786 183.125122,340.248688 
	C183.138550,345.464996 182.982574,350.292725 182.826614,355.120422 
M181.484604,341.581207 
	C181.484604,341.581207 181.417679,341.470642 181.484604,341.581207 
z"/>
<path fill="#96CD11" opacity="1.000000" stroke="none" 
	d="
M251.011108,387.871338 
	C247.943237,388.213654 244.875336,388.555969 241.049774,388.952393 
	C238.880142,388.348236 237.468185,387.689941 236.240662,386.750427 
	C235.579803,381.745056 234.776749,377.434875 241.531555,375.880463 
	C243.417374,375.446533 245.495941,371.643677 245.737274,369.212646 
	C246.143845,365.117432 245.152283,360.883423 244.726700,356.479523 
	C250.817322,354.932220 251.995728,355.742554 252.004501,362.593018 
	C252.008972,366.088776 252.128403,369.589905 251.976242,373.079041 
	C251.761032,378.014435 251.342651,382.941010 251.011108,387.871338 
z"/>
<path fill="#C1D5D3" opacity="1.000000" stroke="none" 
	d="
M96.898148,340.140137 
	C102.793556,339.867920 108.350151,339.872986 114.350540,339.780151 
	C114.809959,344.589691 114.825584,349.497223 114.325798,354.766754 
	C109.196320,355.134766 104.582245,355.140747 99.968178,355.146729 
	C99.302391,355.155151 98.636597,355.163574 97.635635,355.164429 
	C97.053413,350.243713 96.806366,345.330597 96.898148,340.140137 
z"/>
<path fill="#AFD32C" opacity="1.000000" stroke="none" 
	d="
M251.312103,387.926514 
	C251.342651,382.941010 251.761032,378.014435 251.976242,373.079041 
	C252.128403,369.589905 252.008972,366.088776 252.004501,362.593018 
	C251.995728,355.742554 250.817322,354.932220 244.726700,356.479523 
	C245.152283,360.883423 246.143845,365.117432 245.737274,369.212646 
	C245.495941,371.643677 243.417374,375.446533 241.531555,375.880463 
	C234.776749,377.434875 235.579803,381.745056 235.968567,386.734985 
	C235.330902,386.996918 235.149750,386.993073 234.926971,386.522858 
	C234.906662,377.273346 235.022934,368.488953 234.901535,359.707825 
	C234.854492,356.305176 236.112213,354.859528 239.547058,354.995422 
	C244.015015,355.172211 248.492447,355.109497 253.020355,355.606628 
	C252.993805,366.685913 252.912720,377.308472 252.831619,387.931000 
	C252.425446,387.947906 252.019257,387.964813 251.312103,387.926514 
z"/>
<path fill="#F3FCED" opacity="1.000000" stroke="none" 
	d="
M252.948669,388.199890 
	C252.912720,377.308472 252.993805,366.685913 253.296143,355.284424 
	C253.314270,349.593536 253.111145,344.681549 252.911438,339.315460 
	C252.931122,333.267670 253.068985,327.669739 252.872574,322.083557 
	C252.827148,320.791626 251.638947,319.539886 250.976593,318.269653 
	C252.751572,316.550232 254.526566,314.830811 256.643799,313.053894 
	C257.326935,313.644196 258.033691,314.344604 257.949432,314.931396 
	C257.395813,318.786469 256.047089,322.626862 256.107178,326.453979 
	C256.426270,346.776001 257.087372,367.092651 257.620972,387.411377 
	C257.662720,389.000885 257.635559,390.592224 257.797607,392.496521 
	C256.895660,392.857971 255.836517,392.905579 254.414825,392.527405 
	C253.788742,391.074921 253.525192,390.048279 253.261658,389.021606 
	C253.196350,388.837341 253.131042,388.653076 252.948669,388.199890 
z"/>
<path fill="#F7FCF6" opacity="1.000000" stroke="none" 
	d="
M289.075653,356.344757 
	C289.167969,350.827271 289.250671,345.788696 289.208008,340.123779 
	C289.032715,339.313385 288.982788,339.129364 289.009521,338.624451 
	C289.072479,337.865173 289.058746,337.426758 289.135864,336.541534 
	C289.170258,334.235626 289.113800,332.376587 289.280518,330.200378 
	C289.958282,324.870514 290.412903,319.857758 290.867523,314.845001 
	C290.867523,314.845001 290.968567,314.907745 291.170349,314.831207 
	C291.612244,314.296387 291.852386,313.838104 292.092529,313.379822 
	C292.092529,313.379822 292.525299,313.136108 293.012390,313.098785 
	C293.499512,313.061462 293.995148,313.119751 293.995148,313.119751 
	C293.331573,317.485046 292.081482,321.853455 292.104523,326.215118 
	C292.212494,346.638824 292.683563,367.060486 292.984955,387.483459 
	C293.014954,389.517395 293.708954,392.156067 289.723785,391.981232 
	C289.255646,391.545410 289.188568,391.089752 289.400391,390.103210 
	C289.542206,388.729095 289.405090,387.885834 289.267975,387.042572 
	C289.160065,386.592987 289.052185,386.143372 288.822449,385.368317 
	C288.847656,382.966675 288.994690,380.890533 289.355835,378.293793 
	C289.728088,377.465302 289.647217,377.227814 289.327301,377.060760 
	C289.240234,370.315063 289.153137,363.569366 289.075653,356.344757 
z"/>
<path fill="#F4FCF0" opacity="1.000000" stroke="none" 
	d="
M217.723999,371.808746 
	C217.675156,366.675812 217.626328,361.542877 217.689789,355.479309 
	C217.842804,349.716064 217.883499,344.883423 217.867950,339.818420 
	C217.654327,339.292053 217.420624,339.083710 217.058685,338.522217 
	C217.056686,335.726990 217.106583,333.370575 217.527466,330.912994 
	C218.199661,325.813904 218.500885,320.815918 218.885666,315.896545 
	C218.969223,315.975098 218.822495,315.798798 219.188049,315.862885 
	C220.047180,315.934387 220.540741,315.941772 221.023834,316.386627 
	C220.678345,322.612732 220.046082,328.403137 220.079819,334.189606 
	C220.127167,342.314636 220.680008,350.436401 220.997391,358.560303 
	C221.411972,369.173157 221.807739,379.786713 222.201141,390.679749 
	C222.082764,391.316803 221.974838,391.674042 221.457611,392.021912 
	C219.956055,392.071320 218.863800,392.130096 217.771545,392.188873 
	C217.592270,390.359039 217.412994,388.529236 217.104736,386.311890 
	C217.208435,382.943848 217.441101,379.963257 217.806717,376.146729 
	C217.867798,374.143402 217.795898,372.976074 217.723999,371.808746 
z"/>
<path fill="#91CE0C" opacity="1.000000" stroke="none" 
	d="
M110.973450,378.152771 
	C104.104118,374.307709 101.176399,368.028015 100.410843,359.930847 
	C102.016258,355.182526 105.411125,357.407196 107.879356,357.003937 
	C111.699341,356.379852 113.322975,358.164429 113.084801,362.087067 
	C112.854393,365.882050 113.234322,369.717316 112.942970,373.503876 
	C112.820580,375.094604 111.661339,376.605560 110.973450,378.152771 
z"/>
<path fill="#9AC544" opacity="1.000000" stroke="none" 
	d="
M305.684937,358.948486 
	C305.803558,357.918060 305.922180,356.887604 306.518677,355.466339 
	C309.993347,355.069122 312.990173,355.062744 316.041626,355.376831 
	C316.175507,356.123291 316.254761,356.549286 316.220337,357.370880 
	C316.189972,358.850555 316.273285,359.934631 316.309082,361.413757 
	C316.294281,362.867218 316.327026,363.925629 316.242889,365.426819 
	C316.205780,368.915375 316.285522,371.961182 316.319153,375.464752 
	C316.430084,379.551422 316.587158,383.180328 316.549011,387.045044 
	C316.296265,387.476593 316.238708,387.672394 315.844666,387.943054 
	C315.048950,388.007690 314.589752,387.997406 314.101379,387.520203 
	C314.063141,378.435211 314.175323,369.813599 313.924652,361.202606 
	C313.883270,359.780548 312.252747,358.404816 311.356628,357.007660 
	C310.229248,358.460297 308.185791,359.876831 308.126984,361.371521 
	C307.807770,369.479767 307.858154,377.611206 308.116608,385.723938 
	C308.153534,386.883392 310.014038,387.984741 310.994934,389.405212 
	C310.621674,390.157715 310.284088,390.617981 309.946503,391.078247 
	C308.895294,391.254974 307.844055,391.431702 306.404358,391.335510 
	C305.905548,380.357880 305.795258,369.653168 305.684937,358.948486 
z"/>
<path fill="#66A627" opacity="1.000000" stroke="none" 
	d="
M157.535767,383.341583 
	C160.163345,384.772827 162.790939,386.204102 165.703217,387.811432 
	C166.012390,388.162292 166.036896,388.337036 165.717224,388.975952 
	C162.567444,390.640350 159.761795,391.840637 157.300827,392.893463 
	C157.175552,392.843384 157.609985,393.017029 158.044418,393.190674 
	C151.077530,395.157501 148.777313,393.750275 147.947311,387.276703 
	C147.745987,385.706512 146.901001,384.218781 146.199158,382.346466 
	C146.729950,381.686768 147.413528,381.373749 148.492401,381.404724 
	C149.892700,385.809143 151.112289,386.063782 155.389618,383.103119 
	C156.308304,383.222931 156.922028,383.282257 157.535767,383.341583 
z"/>
<path fill="#AFD336" opacity="1.000000" stroke="none" 
	d="
M333.502960,375.971375 
	C341.876068,377.001892 345.176544,381.048950 342.775360,387.783752 
	C342.189178,389.427826 340.371307,390.632751 339.013367,392.355591 
	C337.265198,392.774506 335.623322,392.877441 333.557495,392.483093 
	C333.256653,386.647614 333.379822,381.309509 333.502960,375.971375 
z"/>
<path fill="#AFD43A" opacity="1.000000" stroke="none" 
	d="
M63.439682,366.250031 
	C61.988232,355.028229 61.988216,355.024017 72.952675,354.987640 
	C74.918961,354.981110 76.885422,355.025513 79.364197,355.487610 
	C79.877251,357.327271 79.877914,358.725739 79.878571,360.124207 
	C75.780685,361.637390 71.682800,363.150604 66.959503,364.859009 
	C65.369293,365.452850 64.404488,365.851440 63.439682,366.250031 
z"/>
<path fill="#60A81C" opacity="1.000000" stroke="none" 
	d="
M221.866913,392.031281 
	C221.974838,391.674042 222.082764,391.316803 222.468613,390.473938 
	C225.163422,388.976166 227.580307,387.964081 230.298584,387.028564 
	C232.056183,387.066528 233.512405,387.027893 234.968613,386.989258 
	C235.149750,386.993073 235.330902,386.996918 235.784149,387.016235 
	C237.468185,387.689941 238.880142,388.348236 240.646805,389.028870 
	C240.610825,390.004089 240.430817,391.144958 239.784302,391.869537 
	C238.546112,393.257263 237.006241,395.508881 235.655960,395.464020 
	C230.295639,395.285828 224.960175,394.359497 219.644836,393.398132 
	C220.405106,392.734375 221.136002,392.382812 221.866913,392.031281 
z"/>
<path fill="#60A718" opacity="1.000000" stroke="none" 
	d="
M314.130585,387.987122 
	C314.589752,387.997406 315.048950,388.007690 316.062622,387.882874 
	C316.926849,387.580292 317.050323,387.323212 317.362793,387.000183 
	C318.488800,386.985931 319.239624,386.947998 319.990417,386.910095 
	C321.058807,387.258789 322.191864,387.480927 323.180450,387.985840 
	C324.658508,388.740692 326.034973,389.694489 327.697998,391.199707 
	C329.282654,392.298584 330.623962,392.761383 331.965271,393.224213 
	C326.299469,395.975128 320.254639,395.278076 314.330261,394.844879 
	C312.825867,394.734894 311.448059,392.893921 309.979279,391.463257 
	C310.284088,390.617981 310.621674,390.157715 311.289276,389.247803 
	C312.456360,388.527832 313.293488,388.257477 314.130585,387.987122 
z"/>
<path fill="#C1D4CC" opacity="1.000000" stroke="none" 
	d="
M332.260437,393.130646 
	C330.623962,392.761383 329.282654,392.298584 327.936890,391.451416 
	C328.951111,385.127014 330.130432,379.208588 330.936920,373.239838 
	C331.566162,368.582916 331.732025,363.863342 332.488068,359.067932 
	C333.022278,364.327057 333.172211,369.689880 333.412537,375.512024 
	C333.379822,381.309509 333.256653,386.647614 333.146973,392.444916 
	C332.958893,392.948425 332.757263,392.992737 332.260437,393.130646 
z"/>
<path fill="#C2D5CD" opacity="1.000000" stroke="none" 
	d="
M305.324341,359.080902 
	C305.795258,369.653168 305.905548,380.357880 306.124908,391.509705 
	C305.068207,392.314453 303.902435,392.672089 302.355835,392.940002 
	C302.656708,382.522247 303.319458,372.192902 304.053406,361.868591 
	C304.117523,360.966492 304.649200,360.097626 305.324341,359.080902 
z"/>
<path fill="#C1D5D3" opacity="1.000000" stroke="none" 
	d="
M92.979218,391.007416 
	C93.655754,382.382233 94.291924,373.753510 95.043709,365.134888 
	C95.190010,363.457672 95.786255,361.819702 96.570114,360.075562 
	C97.072258,370.070557 97.271164,380.154877 97.135796,390.234741 
	C97.123077,391.181763 95.003685,392.100555 93.427444,392.645020 
	C92.988068,391.840881 92.983643,391.424164 92.979218,391.007416 
z"/>
<path fill="#9BC94F" opacity="1.000000" stroke="none" 
	d="
M111.123390,378.401978 
	C111.661339,376.605560 112.820580,375.094604 112.942970,373.503876 
	C113.234322,369.717316 112.854393,365.882050 113.084801,362.087067 
	C113.322975,358.164429 111.699341,356.379852 107.879356,357.003937 
	C105.411125,357.407196 102.016258,355.182526 100.458466,359.528320 
	C100.148552,358.497833 100.076874,357.211304 99.986679,355.535736 
	C104.582245,355.140747 109.196320,355.134766 114.235352,355.248840 
	C114.686134,363.675323 114.711960,371.981750 114.580544,380.746399 
	C113.373314,380.353485 112.323318,379.502350 111.123390,378.401978 
z"/>
<path fill="#C1D5CA" opacity="1.000000" stroke="none" 
	d="
M199.432327,360.850586 
	C199.694321,368.540527 199.956329,376.230438 200.328033,384.345520 
	C200.396118,386.524231 200.244003,388.283722 200.338577,390.029877 
	C200.483154,392.699921 199.077652,393.347351 196.348511,392.751709 
	C195.911697,392.458130 195.889145,392.036011 195.978912,391.622498 
	C196.716888,382.886230 197.307388,374.558228 198.058441,366.244781 
	C198.223267,364.420227 198.959320,362.647278 199.432327,360.850586 
z"/>
<path fill="#D8EACF" opacity="1.000000" stroke="none" 
	d="
M92.532272,391.008667 
	C92.983643,391.424164 92.988068,391.840881 92.985847,392.570251 
	C88.873039,392.882843 84.766876,392.882843 79.975792,392.882843 
	C79.975792,382.150055 79.975792,371.593811 79.927185,360.580872 
	C79.877914,358.725739 79.877251,357.327271 79.816269,355.514740 
	C79.942520,355.068329 80.129089,355.035980 80.595459,354.962097 
	C80.931763,365.545807 80.884918,376.173706 81.155975,386.793518 
	C81.196724,388.390106 82.723564,389.948761 83.808167,391.275696 
	C86.730690,391.020966 89.408012,391.015442 92.532272,391.008667 
z"/>
<path fill="#439320" opacity="1.000000" stroke="none" 
	d="
M63.350655,366.692322 
	C64.404488,365.851440 65.369293,365.452850 66.647781,365.039490 
	C66.306450,367.985870 65.351921,370.917755 65.058662,373.914337 
	C64.443626,380.198975 64.176460,386.517670 63.364204,392.891846 
	C63.061840,384.352448 63.161732,375.743530 63.350655,366.692322 
z"/>
<path fill="#64A42F" opacity="1.000000" stroke="none" 
	d="
M126.497910,385.104858 
	C128.269974,385.354736 129.684021,385.610901 131.485260,385.843994 
	C133.083191,386.611877 134.293900,387.402832 135.601074,388.256805 
	C133.738708,390.362549 132.683823,391.555267 131.313263,392.852600 
	C129.743698,392.330444 128.480209,391.721893 127.238449,391.071899 
	C125.875885,390.358643 124.533348,389.607147 123.182083,388.872314 
	C124.168022,387.618591 125.153961,386.364868 126.497910,385.104858 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M195.889145,392.036011 
	C195.889145,392.036011 195.911697,392.458130 195.911743,392.669556 
	C191.814560,392.880981 187.717331,392.880981 182.955994,392.880981 
	C182.955994,388.708069 182.955994,384.784637 182.846588,380.395691 
	C182.808868,379.776276 182.880585,379.622345 183.348969,379.573547 
	C183.840881,382.645691 183.662155,385.659668 184.158752,388.557983 
	C184.373932,389.813751 185.981049,390.830994 187.406464,391.974152 
	C190.534409,392.006470 193.211777,392.021240 195.889145,392.036011 
z"/>
<path fill="#C1D7CC" opacity="1.000000" stroke="none" 
	d="
M158.482101,393.133911 
	C157.609985,393.017029 157.175552,392.843384 157.300827,392.893463 
	C159.761795,391.840637 162.567444,390.640350 165.675232,389.237244 
	C165.925232,390.173187 165.873093,391.311951 165.792282,393.077148 
	C163.446274,393.077148 161.183029,393.077148 158.482101,393.133911 
z"/>
<path fill="#C1D5CA" opacity="1.000000" stroke="none" 
	d="
M221.457611,392.021912 
	C221.136002,392.382812 220.405106,392.734375 219.339554,393.174500 
	C218.644196,393.097076 218.283463,392.931030 217.847137,392.476929 
	C218.863800,392.130096 219.956055,392.071320 221.457611,392.021912 
z"/>
<path fill="#C3D7C7" opacity="1.000000" stroke="none" 
	d="
M253.148560,389.371582 
	C253.525192,390.048279 253.788742,391.074921 254.026062,392.434631 
	C253.678406,391.752289 253.356949,390.736908 253.148560,389.371582 
z"/>
<path fill="#439320" opacity="1.000000" stroke="none" 
	d="
M70.804733,399.704773 
	C70.255928,399.925903 69.527962,399.922424 68.447418,399.832184 
	C68.938416,399.657013 69.781990,399.568604 70.804733,399.704773 
z"/>
<path fill="#DAE5E9" opacity="1.000000" stroke="none" 
	d="
M289.168884,387.399963 
	C289.405090,387.885834 289.542206,388.729095 289.471985,389.763092 
	C289.199738,389.221680 289.134796,388.489532 289.168884,387.399963 
z"/>
<path fill="#319708" opacity="1.000000" stroke="none" 
	d="
M42.229980,379.038025 
	C45.642708,383.217041 43.859440,387.818207 42.825813,392.670837 
	C41.918217,392.979279 41.493748,392.936096 41.057911,392.911621 
	C41.046539,392.930298 41.077015,392.935120 40.933765,392.645996 
	C37.578308,389.918854 37.842461,386.451874 38.011566,383.004700 
	C37.999329,383.000000 38.016048,383.020203 38.272423,382.929352 
	C38.973896,382.530518 39.418995,382.222565 40.122341,381.814941 
	C40.601524,381.226318 40.822464,380.737366 41.092201,379.957336 
	C41.140846,379.469452 41.140694,379.272675 41.132881,379.104889 
	C41.125225,379.133881 41.173981,379.168915 41.439857,379.151337 
	C41.880482,379.101868 42.055229,379.069946 42.229980,379.038025 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M41.069279,392.892944 
	C41.493748,392.936096 41.918217,392.979279 42.660484,393.058350 
	C42.978287,393.094269 43.004585,393.520752 43.006092,393.759064 
	C43.007599,393.997375 42.960121,394.052155 42.663353,394.011414 
	C41.934151,393.611450 41.501713,393.252197 41.069279,392.892944 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M43.032524,393.971069 
	C43.393536,393.988922 43.689987,394.121643 44.106468,394.530334 
	C44.266125,394.717743 44.125916,394.865723 43.839905,394.832642 
	C43.284435,394.607239 43.086510,394.358124 42.960121,394.052155 
	C42.960121,394.052155 43.007599,393.997375 43.032524,393.971069 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M42.211456,378.828735 
	C42.055229,379.069946 41.880482,379.101868 41.476791,378.898010 
	C41.488956,378.434052 41.730068,378.205841 41.971176,377.977631 
	C41.971172,377.977631 42.099339,378.089325 42.166012,378.145203 
	C42.232685,378.201050 42.192936,378.619415 42.211456,378.828735 
z"/>
<path fill="#B5D622" opacity="1.000000" stroke="none" 
	d="
M42.029011,377.595612 
	C41.730068,378.205841 41.488956,378.434052 41.210915,378.915588 
	C41.173981,379.168915 41.125225,379.133881 40.876678,379.188171 
	C40.441730,379.536957 40.255333,379.831482 40.100327,380.132080 
	C40.131721,380.138153 40.157574,380.196655 39.800915,380.185089 
	C38.968189,381.122406 38.492123,382.071320 38.016052,383.020203 
	C38.016048,383.020203 37.999329,383.000000 37.676682,383.178284 
	C34.689766,388.501587 36.054317,392.012329 41.077015,392.935120 
	C41.077015,392.935120 41.046539,392.930298 41.057907,392.911621 
	C41.501713,393.252197 41.934151,393.611450 42.663353,394.011414 
	C43.086510,394.358124 43.284435,394.607239 43.769928,394.906738 
	C44.253052,395.503662 44.520145,395.993347 44.893730,396.741516 
	C40.871197,397.564178 37.849976,395.709106 35.187046,392.756836 
	C33.910515,391.341583 32.254311,390.268768 30.438553,388.970154 
	C29.765978,385.783539 28.817533,382.580902 29.247097,379.575165 
	C29.718380,376.277466 31.472948,373.165985 32.642208,369.963867 
	C33.139774,368.601196 33.570961,367.214294 34.060516,365.451691 
	C34.377632,364.927612 34.620625,364.732971 34.939156,364.279846 
	C37.889225,361.956726 40.717842,359.834839 43.129723,358.025574 
	C43.410015,361.823120 43.995419,365.795868 43.889080,369.750000 
	C43.821781,372.252228 42.722996,374.726685 42.029011,377.595612 
z"/>
<path fill="#F2D7D5" opacity="1.000000" stroke="none" 
	d="
M274.932068,77.746933 
	C274.676544,77.927086 274.406799,77.761963 274.102661,77.269470 
	C274.285614,77.202820 274.568787,77.356010 274.932068,77.746933 
z"/>
<path fill="#FAD9BF" opacity="1.000000" stroke="none" 
	d="
M147.940842,167.750641 
	C149.695251,167.487061 151.473389,167.328140 153.198959,166.937271 
	C156.408188,166.210297 159.761429,166.466293 161.465363,162.119278 
	C162.657120,159.078873 166.279984,158.464737 169.743362,160.764008 
	C169.623093,162.021103 169.490189,163.410202 169.230072,166.129135 
	C171.978149,165.333969 175.084732,165.365631 176.014557,163.958694 
	C177.606384,161.550125 177.740891,158.210739 178.669113,155.320587 
	C179.352844,153.191727 180.326233,151.155899 181.646179,149.398285 
	C187.888672,150.778397 193.655533,151.838852 199.663757,152.943695 
	C199.663757,157.529602 199.663757,162.299561 199.663757,168.227097 
	C201.321152,166.138687 202.104843,165.151199 202.900162,164.536652 
	C203.727783,167.297516 204.543777,169.685440 205.555298,172.645569 
	C206.447327,170.973068 207.016418,169.906052 207.641724,168.733627 
	C208.914032,172.624374 210.110855,176.284302 211.479355,180.469254 
	C212.609055,179.178085 213.125137,178.588226 213.767944,177.853546 
	C214.627716,181.052887 215.395233,183.908859 215.988129,187.046463 
	C214.891891,187.896515 213.970306,188.464951 212.642334,189.023514 
	C211.151901,189.003662 210.067886,188.993668 208.979370,188.609192 
	C208.643112,187.322617 208.575119,186.055771 207.924713,185.572083 
	C206.560608,184.557617 204.890137,183.955139 203.345459,183.183502 
	C202.921616,184.307266 201.826355,186.022415 202.198578,186.445038 
	C203.361435,187.765320 205.008072,188.739456 206.619858,189.540665 
	C207.177811,189.818008 208.182877,189.195969 208.989456,189.347961 
	C208.993423,190.437668 208.991638,191.164139 208.647171,191.813568 
	C205.875763,191.801010 203.447037,191.865524 200.822327,191.888153 
	C200.626343,191.846268 200.226303,191.871506 199.972748,191.637238 
	C198.823822,191.254211 197.928467,191.105484 197.018036,190.978333 
	C197.002960,190.999924 197.025024,190.953506 196.812286,190.703857 
	C195.757233,190.300003 194.914902,190.145798 193.798706,189.908447 
	C193.025772,189.551239 192.526733,189.277176 191.753418,188.836212 
	C190.716507,188.379242 189.602066,187.611221 189.245895,187.873383 
	C185.859406,190.365997 184.319321,188.237213 183.099701,185.250992 
	C185.879379,179.956787 180.327057,178.368530 178.987885,174.617279 
	C178.065872,171.794556 177.269196,169.324493 176.428696,166.718567 
	C174.907135,166.930450 173.362137,167.145584 171.152588,167.453278 
	C173.062851,170.229385 174.689636,172.593521 176.301636,175.312592 
	C176.247498,176.367249 176.208191,177.066971 175.857010,177.915894 
	C174.024094,179.025177 172.503036,179.985245 170.713287,180.760071 
	C169.628983,179.699493 168.813370,178.824158 167.952377,177.617233 
	C167.290939,176.182007 166.674881,175.078354 166.047806,173.605118 
	C165.038513,169.156342 163.337357,166.613235 158.253723,167.723999 
	C154.970886,168.441299 151.388809,167.789124 147.940842,167.750641 
M180.677292,162.993011 
	C181.318283,164.558197 181.701981,166.336960 182.732727,167.578583 
	C183.120193,168.045349 185.836716,167.561417 186.039917,166.992477 
	C186.454605,165.831375 186.366394,163.701080 185.600845,163.103958 
	C184.599213,162.322678 182.741760,162.638596 180.677292,162.993011 
z"/>
<path fill="#67340E" opacity="1.000000" stroke="none" 
	d="
M213.483871,238.593506 
	C218.152954,243.312637 219.915207,249.176453 220.541290,256.061646 
	C220.891571,256.497681 220.880997,256.899506 220.893982,256.917236 
	C220.906982,256.934967 220.930008,256.904846 220.618652,257.089661 
	C220.138428,258.210785 219.969543,259.147156 219.621033,260.333923 
	C219.302704,261.380280 219.164017,262.176208 218.873016,263.185974 
	C218.529541,263.876740 218.338394,264.353729 218.029343,265.128876 
	C217.826843,265.838470 217.742249,266.249878 217.761963,266.676697 
	C217.866272,266.692108 217.656342,266.712158 217.449402,266.845520 
	C217.043427,267.268402 216.988922,267.582977 216.963867,268.102661 
	C216.848770,268.282715 216.810532,268.708405 216.549988,268.951233 
	C215.790970,270.780884 215.292511,272.367767 214.546936,274.193787 
	C213.599564,276.289337 213.047287,278.223358 212.132690,279.967407 
	C211.714462,280.764923 209.990356,281.812164 209.861328,281.689697 
	C206.866745,278.847168 202.956161,281.696442 199.909729,279.637756 
	C202.044952,276.308899 204.544876,273.534973 206.088165,270.304108 
	C208.233871,265.812012 206.590912,261.430267 203.189514,260.217133 
	C200.811081,259.368835 198.557404,258.170746 195.922211,257.003052 
	C194.466553,256.946198 193.336670,257.015167 192.288452,257.108246 
	C192.370117,257.132355 192.238815,257.023926 192.386597,256.640503 
	C190.741043,253.267044 188.947708,250.276978 186.523636,246.235260 
	C186.173706,248.881363 186.007324,250.139496 185.560333,251.228867 
	C184.639420,250.271957 183.999130,249.483826 182.830658,248.045517 
	C182.294678,251.215607 181.901993,253.538132 181.247330,257.410126 
	C178.671692,254.058655 176.836975,251.671295 174.727448,248.926331 
	C174.727448,251.458862 174.727448,253.664612 174.727448,256.629730 
	C172.889587,255.272079 171.672165,254.372757 169.375061,252.675858 
	C168.547913,257.528259 167.826263,261.761810 167.005875,266.317963 
	C166.853500,267.087524 166.799850,267.534485 166.490906,268.184998 
	C163.078568,271.511230 161.478745,267.263031 158.985825,266.599762 
	C158.787827,265.966766 158.667725,265.607330 158.755478,264.943420 
	C159.749298,262.878784 160.535263,261.118652 161.321243,259.358551 
	C162.634460,251.159927 164.089401,243.018188 170.404907,236.837616 
	C171.349854,235.912872 172.301041,234.994507 173.606720,234.017426 
	C173.964081,233.961624 174.031036,234.033813 174.033661,234.390137 
	C173.204071,236.641785 172.371857,238.537125 171.539642,240.432465 
	C171.852600,240.808441 172.165543,241.184418 172.478500,241.560379 
	C174.406403,240.212051 176.334305,238.863724 178.654953,237.457321 
	C180.370224,237.341446 181.692734,237.283646 183.300659,237.452682 
	C185.719696,237.536469 187.853348,237.393417 190.302460,237.235428 
	C191.410461,237.482971 192.203018,237.745453 193.150879,238.324600 
	C195.303772,239.348923 197.301361,240.056595 199.583984,240.811798 
	C200.236511,240.974167 200.604019,241.088989 201.079651,241.393158 
	C201.381851,241.816849 201.628159,241.963501 201.959915,242.020035 
	C201.993118,242.017609 201.964828,242.077881 201.998047,242.417877 
	C203.064270,243.447189 204.097244,244.136520 205.117126,244.844391 
	C205.104019,244.862930 205.133041,244.897842 205.212891,245.153931 
	C205.607498,245.549210 205.922287,245.688385 206.421295,245.888763 
	C206.605515,245.949966 206.935593,246.154373 206.947815,246.496597 
	C207.656616,247.540329 208.353195,248.241837 209.026077,248.958984 
	C209.002380,248.974640 209.057419,248.965851 209.028809,249.355927 
	C210.260193,252.670380 211.520203,255.594772 212.780212,258.519135 
	C213.399506,258.350739 214.018784,258.182343 214.638062,258.013947 
	C214.638062,255.142456 214.850037,252.251129 214.583893,249.404526 
	C214.258423,245.923492 213.532120,242.479950 213.105057,238.913208 
	C213.230972,238.806229 213.483871,238.593506 213.483871,238.593506 
z"/>
<path fill="#E0A352" opacity="1.000000" stroke="none" 
	d="
M147.577332,167.820328 
	C151.388809,167.789124 154.970886,168.441299 158.253723,167.723999 
	C163.337357,166.613235 165.038513,169.156342 165.700027,173.561768 
	C163.300919,172.480927 161.238556,171.073822 159.176193,169.666733 
	C161.595444,181.983215 161.871964,192.457184 159.070648,197.214096 
	C161.828552,199.611435 163.475861,202.414551 160.648346,205.742996 
	C160.235336,201.702484 159.263718,199.259384 154.508667,200.075287 
	C150.326309,200.792908 148.395554,202.481934 149.084122,206.860886 
	C149.570602,209.954742 149.544678,213.135056 150.128876,216.204163 
	C150.311203,217.161957 151.662079,218.494019 152.608017,218.611923 
	C158.315964,219.323441 160.968384,216.740295 160.890472,210.950623 
	C160.877167,209.962830 160.988144,208.973358 161.408020,208.050751 
	C166.222565,213.799789 164.534515,218.965652 157.650040,219.907059 
	C154.079163,220.395355 150.386505,219.992996 146.615677,219.992996 
	C145.851425,223.895126 147.437988,224.813416 153.103592,223.881409 
	C158.445435,223.002640 163.854340,222.273331 169.255676,222.074753 
	C177.384705,221.775894 185.533585,222.054947 193.672195,221.948883 
	C195.367386,221.926788 197.054672,221.297119 198.745590,220.947449 
	C198.674332,220.296555 198.603073,219.645660 198.531815,218.994766 
	C192.745972,218.994766 186.948715,219.208572 181.179260,218.901138 
	C178.450394,218.755722 175.774689,217.612595 173.535797,216.915436 
	C182.303406,216.933563 190.610977,217.026215 198.916168,216.925156 
	C200.607101,216.904587 202.289154,216.155212 204.231277,215.836197 
	C204.664322,215.968109 204.841385,216.005646 205.263611,216.287109 
	C205.994278,215.573486 206.867264,214.628296 206.898224,213.656281 
	C207.032425,209.445007 206.920685,205.225906 206.935944,200.734680 
	C206.996323,200.276108 207.019119,200.092514 207.428864,199.944336 
	C209.312439,200.320740 210.809067,200.661713 212.267960,201.459839 
	C211.931656,206.613419 211.633087,211.309845 211.326569,216.216095 
	C211.470276,216.712616 211.706375,216.830231 212.415283,216.913177 
	C215.654785,217.206451 219.371841,218.496948 221.093353,217.179611 
	C222.570175,216.049515 221.764557,211.936661 221.962799,208.805908 
	C221.951187,207.316360 221.945068,206.163742 222.101440,205.323532 
	C223.924469,209.607483 226.030319,212.965271 231.190842,212.113098 
	C228.344727,213.006027 227.764740,215.124420 227.900024,217.813004 
	C228.040741,220.609467 227.931412,223.418533 227.931412,227.546936 
	C214.023712,221.976669 200.244766,222.886246 186.587463,223.254883 
	C173.037033,223.620636 159.508209,224.787048 145.082169,225.655762 
	C145.001343,225.344070 144.355484,223.842117 144.252350,222.303802 
	C143.130463,205.569641 142.062439,188.831589 141.094849,172.087936 
	C141.020203,170.796234 141.815460,169.454269 142.937347,168.074921 
	C144.112122,167.963852 144.555939,167.913528 144.999756,167.863190 
	C145.737778,167.872131 146.475815,167.881073 147.577332,167.820328 
M158.905792,188.537338 
	C158.580551,184.926865 158.254318,181.316467 157.930420,177.705872 
	C157.715790,175.313431 157.497025,172.676590 154.157288,173.193909 
	C151.313980,173.634338 147.484741,173.137482 147.893417,178.130615 
	C148.203003,181.913040 148.049316,185.731537 148.174942,189.531555 
	C148.263763,192.218842 148.213394,195.298538 152.213852,194.787109 
	C155.389053,194.381210 159.751801,195.017288 158.905792,188.537338 
z"/>
<path fill="#DAE4EB" opacity="1.000000" stroke="none" 
	d="
M229.997192,386.951965 
	C227.580307,387.964081 225.163422,388.976166 222.479065,390.194122 
	C221.807739,379.786713 221.411972,369.173157 220.997391,358.560303 
	C220.680008,350.436401 220.127167,342.314636 220.079819,334.189606 
	C220.046082,328.403137 220.678345,322.612732 221.297928,316.178131 
	C223.731018,314.326111 225.879562,313.120056 228.184204,311.874634 
	C228.340317,311.835266 228.661972,311.850616 228.661972,311.850616 
	C233.397537,312.731201 235.509308,315.457031 234.813812,320.333954 
	C234.633026,321.601746 234.909164,322.934692 234.949112,324.864380 
	C234.853989,325.650665 234.785172,325.810272 234.347824,326.087830 
	C233.655197,327.755005 233.016464,329.314697 233.067749,330.851379 
	C233.205246,334.969940 234.033249,339.093658 233.878326,343.188477 
	C233.447250,354.582031 232.761917,365.969574 231.941269,377.342468 
	C231.707977,380.575562 230.667496,383.750397 229.997192,386.951965 
z"/>
<path fill="#E9A03D" opacity="1.000000" stroke="none" 
	d="
M267.050018,293.047699 
	C266.366150,294.297974 265.169556,295.517578 265.092621,296.804169 
	C264.841431,301.005646 264.998413,305.231567 264.998413,310.917999 
	C264.005615,308.705841 263.146240,307.609711 263.051788,306.451172 
	C262.288605,297.087280 261.690796,287.710083 260.994904,278.340485 
	C260.726654,274.728271 260.205750,271.131531 260.030518,267.516541 
	C259.774536,262.235809 260.972992,261.193665 266.255096,260.973755 
	C271.108398,260.771729 275.950104,260.292297 280.907104,259.558044 
	C281.017242,259.181000 281.405212,259.011597 281.833557,258.975281 
	C282.261932,258.938995 282.720490,258.933502 282.777863,259.329529 
	C283.287842,261.491119 283.740448,263.256683 284.193054,265.022247 
	C284.397278,265.541748 284.601501,266.061249 284.598816,267.084412 
	C283.914154,269.098816 283.436432,270.609619 282.635071,272.080444 
	C281.877075,272.023285 281.442657,272.006073 280.842224,271.687500 
	C279.145966,269.594482 277.615784,267.802856 275.841553,265.980103 
	C275.597504,265.948944 275.105865,265.928223 275.048706,265.731750 
	C274.991547,265.535309 274.917999,265.132751 274.887665,264.749847 
	C271.553772,264.528870 267.947113,263.925629 265.036133,265.078674 
	C262.390411,266.126648 263.571716,283.681671 266.009491,285.996460 
	C266.000000,286.001343 266.007080,285.981750 266.092682,286.220551 
	C266.455292,286.639191 266.732300,286.819031 267.004639,286.999573 
	C267.000000,287.000214 267.001801,286.991119 267.059082,287.351440 
	C270.446564,289.048950 273.517120,289.039978 276.001221,285.998749 
	C276.000000,286.000000 275.996826,286.002228 276.293091,285.985992 
	C276.744507,285.303406 276.899689,284.637085 277.033325,283.650635 
	C277.009064,282.902832 277.006317,282.475128 277.266235,281.831482 
	C278.037689,280.347107 278.546478,279.078705 279.364990,277.882263 
	C280.094116,278.014282 280.513489,278.074310 280.967590,278.318665 
	C281.002319,278.502991 281.084473,278.869019 281.111389,279.239441 
	C282.174103,280.011444 283.209869,280.413055 284.440063,280.860474 
	C284.634491,280.906342 285.002930,281.061005 285.091187,281.264069 
	C285.350006,281.721375 285.578552,281.902344 285.918823,282.238892 
	C285.972565,282.467682 286.075958,282.926208 286.047791,283.379150 
	C286.711609,291.332794 287.302460,298.845856 288.215973,306.319489 
	C288.338440,307.321289 290.090790,308.123871 291.088379,309.018707 
	C291.047455,309.515564 291.168304,310.107544 290.940643,310.494568 
	C288.603729,314.467896 275.249054,316.276764 271.451599,313.155365 
	C271.602966,312.888916 271.692841,312.466980 271.918427,312.373932 
	C274.495911,311.310760 279.155426,310.564026 279.328400,309.224976 
	C280.060303,303.560028 281.816437,297.445923 277.310730,292.115173 
	C276.947083,292.085266 276.921753,292.087128 276.887756,291.698730 
	C273.078156,289.042297 269.801361,289.588928 267.031433,293.044312 
	C267.021881,293.039185 267.050018,293.047699 267.050018,293.047699 
z"/>
<path fill="#DCA356" opacity="1.000000" stroke="none" 
	d="
M159.063721,266.873352 
	C161.478745,267.263031 163.078568,271.511230 166.804886,268.259949 
	C167.657486,268.448853 167.940826,268.766327 168.157013,269.391663 
	C168.084976,270.119019 168.080139,270.538605 168.039948,271.229736 
	C167.989273,271.683014 167.973953,271.864746 167.568726,271.982239 
	C165.673706,271.609131 164.168610,271.300293 163.052582,271.071289 
	C159.276489,277.033234 167.502365,286.995636 155.175018,288.791504 
	C160.408783,290.804718 161.995651,290.383148 164.902893,287.337646 
	C165.939377,286.251862 167.677521,285.835876 168.956604,285.404144 
	C167.947723,286.852448 167.077591,288.010773 166.005722,289.422424 
	C164.238571,291.122589 162.673172,292.569366 160.994980,293.644104 
	C157.809219,293.256409 154.668823,292.902374 151.716171,293.490631 
	C151.071609,293.619049 150.888016,297.110199 150.861603,299.057648 
	C150.816956,302.348145 151.067245,305.642670 151.172211,309.256714 
	C152.050537,310.350220 152.950134,311.122528 153.560211,312.006744 
	C152.236359,312.770996 151.202057,313.423370 149.900757,313.802124 
	C148.135117,313.328186 146.636459,313.127838 145.137802,312.927490 
	C144.126938,295.573822 143.116089,278.220184 142.065201,260.179352 
	C148.293121,259.854187 154.340546,259.538422 160.854614,259.290588 
	C160.535263,261.118652 159.749298,262.878784 158.362335,264.880249 
	C149.125519,265.180634 148.867737,265.373627 149.066986,273.500824 
	C149.143723,276.631622 149.633789,279.750366 149.784576,282.881866 
	C149.901291,285.306000 150.400436,287.517059 153.404770,286.684662 
	C156.123123,285.931458 160.643616,287.281555 160.043503,281.697205 
	C159.514832,276.777649 159.375168,271.816254 159.063721,266.873352 
M148.111969,294.188568 
	C148.887726,292.768341 149.663467,291.348083 150.284866,290.210449 
	C148.894089,287.378601 147.620224,284.784790 146.346344,282.191010 
	C145.957840,282.353729 145.569336,282.516449 145.180817,282.679199 
	C145.614990,288.580078 146.049164,294.480988 146.483337,300.381866 
	C146.998566,300.356750 147.513794,300.331635 148.029022,300.306519 
	C148.029022,298.530701 148.029022,296.754883 148.111969,294.188568 
z"/>
<path fill="#97CB1A" opacity="1.000000" stroke="none" 
	d="
M144.776489,313.040497 
	C146.636459,313.127838 148.135117,313.328186 149.700470,314.172668 
	C149.502335,322.853699 149.237518,330.890594 148.972717,338.927490 
	C143.397522,339.177673 137.822327,339.427856 131.827026,339.561066 
	C131.521423,335.538818 131.635925,331.633514 131.829285,326.902618 
	C131.893951,324.064056 131.879761,322.051086 131.865570,320.038147 
	C131.865585,320.038147 132.052856,319.620636 132.371887,319.291138 
	C133.824554,318.006989 134.958221,317.052307 136.091888,316.097595 
	C136.091873,316.097595 136.104340,316.085052 136.113251,316.101807 
	C138.886505,315.130219 141.650833,314.141876 144.776489,313.040497 
z"/>
<path fill="#E58B2D" opacity="1.000000" stroke="none" 
	d="
M216.162750,186.764847 
	C215.395233,183.908859 214.627716,181.052887 213.767944,177.853546 
	C213.125137,178.588226 212.609055,179.178085 211.479355,180.469254 
	C210.110855,176.284302 208.914032,172.624374 207.641724,168.733627 
	C207.016418,169.906052 206.447327,170.973068 205.555298,172.645569 
	C204.543777,169.685440 203.727783,167.297516 202.933472,164.169830 
	C202.299576,162.616699 201.644028,161.803314 201.016266,160.669846 
	C201.369232,159.560120 201.694427,158.770462 202.375183,158.049088 
	C205.200546,162.397537 207.640213,166.695602 210.162918,170.944382 
	C210.736328,171.910095 211.472412,173.290878 212.347549,173.477539 
	C216.214218,174.302216 216.119293,177.091766 216.055359,180.351135 
	C218.678314,184.205231 223.424454,186.881760 220.118759,192.314621 
	C225.202805,193.954025 226.724106,192.890518 227.060196,188.607697 
	C227.443665,183.720932 223.572983,181.825531 221.121002,178.666260 
	C221.529022,176.894058 221.778854,175.455643 222.268890,174.017517 
	C222.509109,174.017807 222.990005,174.029831 223.210342,174.213043 
	C228.369324,168.759598 228.642624,158.444351 223.655991,154.141006 
	C223.429245,155.196350 223.242752,156.064346 222.768982,156.710999 
	C220.405701,150.685013 219.834763,143.888596 212.551468,141.505661 
	C212.239700,141.403656 212.138641,140.657623 212.016815,140.028198 
	C212.094177,139.844788 212.212906,139.464813 212.212906,139.464813 
	C219.132599,141.994278 224.583588,146.366043 228.882416,153.010422 
	C229.411026,153.715576 229.661469,153.942352 229.911911,154.169113 
	C229.911911,154.169113 230.017822,154.594757 230.126007,155.037201 
	C230.451645,155.722427 230.669113,155.965225 230.886566,156.208008 
	C230.886566,156.208008 230.930008,156.615646 230.971161,157.162277 
	C231.332703,158.808090 231.653122,159.907272 231.973541,161.006470 
	C231.908936,162.304626 231.844345,163.602798 231.547119,165.597733 
	C230.576752,172.863052 229.839035,179.431595 229.101318,186.000137 
	C229.088379,186.752182 229.075439,187.504211 229.024399,189.036041 
	C229.016403,191.200363 229.046509,192.584885 229.076614,193.969421 
	C229.043686,194.138748 229.010742,194.308060 228.856201,194.934082 
	C227.505997,195.272217 226.273422,195.184830 225.049637,195.028488 
	C222.643219,194.721039 220.241714,194.375259 217.960068,193.665833 
	C218.172028,190.897720 221.533981,187.546219 216.162750,186.764847 
z"/>
<path fill="#96CB19" opacity="1.000000" stroke="none" 
	d="
M234.716370,325.969879 
	C234.785172,325.810272 234.853989,325.650665 234.953613,325.232117 
	C237.827255,323.430389 240.670105,321.887573 244.095886,320.150726 
	C245.213684,319.674652 245.748535,319.392578 246.283371,319.110535 
	C246.701965,319.089783 247.120544,319.069031 248.148468,318.957428 
	C249.353592,318.610229 249.949402,318.353912 250.545227,318.097595 
	C250.545212,318.097565 250.718155,318.291534 250.847382,318.280579 
	C251.638947,319.539886 252.827148,320.791626 252.872574,322.083557 
	C253.068985,327.669739 252.931122,333.267670 252.450104,339.348206 
	C247.383987,339.879822 242.768524,340.132904 238.191238,339.822723 
	C237.046982,339.745178 235.252045,337.958374 235.092773,336.776978 
	C234.613541,333.222290 234.793518,329.578766 234.716370,325.969879 
z"/>
<path fill="#DFA453" opacity="1.000000" stroke="none" 
	d="
M218.147247,264.830688 
	C218.338394,264.353729 218.529541,263.876740 219.275177,263.192139 
	C222.633774,263.598419 225.437897,264.212311 228.256882,264.829468 
	C229.364090,257.446594 228.031113,255.876297 221.859039,256.932281 
	C221.544113,256.986176 221.207504,256.913361 220.880997,256.899506 
	C220.880997,256.899506 220.891571,256.497681 220.862976,256.298431 
	C223.874435,255.174850 226.914520,254.250519 230.910721,253.035492 
	C230.268356,270.952637 229.674301,287.522278 228.785522,304.735657 
	C228.643829,306.245514 228.796875,307.111664 228.949921,307.977783 
	C228.957245,308.422180 228.964569,308.866547 228.493164,309.643463 
	C225.030487,310.259583 225.041702,308.308502 224.957397,306.148895 
	C224.798874,302.086945 224.421829,298.033478 223.969315,293.769379 
	C223.540497,293.326294 223.278015,293.090332 222.932953,292.507629 
	C220.911667,292.059998 218.972992,291.959076 216.667297,291.917175 
	C215.559448,292.038208 214.818619,292.100189 213.949463,291.847504 
	C213.542908,290.696411 213.264633,289.859985 213.312195,288.870300 
	C215.923981,287.925934 218.172638,286.618683 220.503571,286.450256 
	C225.489517,286.089996 226.407120,285.014984 224.937149,279.498535 
	C225.217712,277.463501 225.427887,275.845947 225.686295,274.236176 
	C226.890930,266.731628 225.787094,265.348724 218.147247,264.830688 
z"/>
<path fill="#F3D7B7" opacity="1.000000" stroke="none" 
	d="
M217.838165,194.045334 
	C220.241714,194.375259 222.643219,194.721039 225.049637,195.028488 
	C226.273422,195.184830 227.505997,195.272217 228.886658,195.191040 
	C229.359802,197.077515 229.680893,199.163742 229.608002,201.668289 
	C227.146332,202.680878 225.078674,203.275162 222.861160,203.544281 
	C220.771530,201.674271 218.919556,200.714600 217.489838,203.696838 
	C215.621597,202.616043 213.963654,201.809357 212.305710,201.002686 
	C210.809067,200.661713 209.312439,200.320740 207.425507,199.957550 
	C207.035202,199.935333 207.031448,199.915085 207.038422,199.603729 
	C207.053391,198.877258 207.061417,198.462158 207.060883,198.022980 
	C207.052338,197.998917 207.043228,198.047470 207.301834,197.831024 
	C208.036911,195.706589 208.513367,193.798599 208.989838,191.890625 
	C208.991638,191.164139 208.993423,190.437668 208.997604,189.355591 
	C209.000000,189.000000 208.983856,188.983688 208.983856,188.983673 
	C210.067886,188.993668 211.151901,189.003662 212.498505,189.298248 
	C211.216446,194.753189 214.984055,193.879593 217.838165,194.045334 
z"/>
<path fill="#D89146" opacity="1.000000" stroke="none" 
	d="
M200.988480,160.989929 
	C201.644028,161.803314 202.299576,162.616699 202.921844,163.796890 
	C202.104843,165.151199 201.321152,166.138687 199.663757,168.227097 
	C199.663757,162.299561 199.663757,157.529602 199.663757,152.943695 
	C193.655533,151.838852 187.888672,150.778397 181.997742,149.343475 
	C183.469467,147.473373 185.065247,145.977753 187.054749,144.135223 
	C187.770676,143.636734 187.967834,143.386581 188.039963,143.037857 
	C188.039963,143.037857 188.000000,143.000000 188.269730,142.963074 
	C188.731339,142.651169 188.923218,142.376205 189.115112,142.101242 
	C189.115112,142.101242 189.053741,142.140305 189.376648,142.093994 
	C190.131332,141.690018 190.563095,141.332352 190.994843,140.974686 
	C197.609406,137.386002 204.426285,135.914581 211.945663,139.231537 
	C212.212906,139.464813 212.094177,139.844788 211.695679,139.964539 
	C210.861710,140.056000 210.426254,140.027725 209.617981,139.827377 
	C204.215927,138.728287 199.198624,137.877304 194.630981,142.053925 
	C191.460556,144.247284 188.606567,146.288605 185.752579,148.329941 
	C186.051254,148.933289 186.349945,149.536636 186.648621,150.139984 
	C191.752747,150.139984 196.856857,150.139984 201.913086,150.518585 
	C201.336960,154.576950 200.808716,158.256714 200.280457,161.936493 
	C200.492126,161.785233 200.734360,161.661240 200.903320,161.471878 
	C200.995697,161.368317 200.964157,161.154190 200.988480,160.989929 
z"/>
<path fill="#AE7D25" opacity="1.000000" stroke="none" 
	d="
M284.563293,264.899963 
	C283.740448,263.256683 283.287842,261.491119 282.758545,259.125244 
	C282.568787,258.255127 282.376251,258.066315 282.104218,257.958496 
	C281.624023,247.905762 285.099854,239.975830 294.694031,235.122223 
	C297.792084,237.584351 295.213165,238.641800 293.596191,239.072998 
	C286.802338,240.884705 286.217468,246.133453 285.952454,251.789536 
	C285.749329,256.125305 285.282837,260.448761 284.563293,264.899963 
z"/>
<path fill="#C9B2BD" opacity="1.000000" stroke="none" 
	d="
M260.211273,165.974731 
	C259.670349,165.377426 259.129456,164.780106 258.339600,163.704742 
	C259.600800,160.734100 257.866364,159.845215 256.009766,159.016724 
	C258.170959,155.599945 261.058105,156.360199 262.529510,158.976166 
	C264.183929,161.917542 265.125122,160.156235 267.129639,159.179321 
	C268.134338,159.723816 268.509644,160.378738 268.568756,161.082016 
	C267.915985,166.635742 264.990692,167.844086 260.211273,165.974731 
z"/>
<path fill="#E38728" opacity="1.000000" stroke="none" 
	d="
M223.011017,203.869446 
	C225.078674,203.275162 227.146332,202.680878 229.579025,202.090668 
	C230.564987,203.690414 231.420456,205.240875 231.739151,206.894775 
	C232.041824,208.465500 231.782898,210.144440 231.480865,211.944458 
	C226.030319,212.965271 223.924469,209.607483 222.342102,205.197113 
	C222.617188,204.462006 222.814102,204.165741 223.011017,203.869446 
z"/>
<path fill="#DAA66C" opacity="1.000000" stroke="none" 
	d="
M224.135651,293.976501 
	C224.421829,298.033478 224.798874,302.086945 224.957397,306.148895 
	C225.041702,308.308502 225.030487,310.259583 228.422516,309.970306 
	C228.826080,310.387085 228.821609,310.809509 228.739548,311.541290 
	C228.661972,311.850616 228.340317,311.835266 227.754745,311.843567 
	C223.811752,311.951141 220.001038,311.091797 218.822495,315.798798 
	C218.822495,315.798798 218.969223,315.975098 218.520325,315.918945 
	C216.389191,315.535431 214.706955,315.208069 213.254272,314.640533 
	C214.809677,313.930115 216.135544,313.459930 217.682220,312.911407 
	C215.716400,311.391418 214.275558,310.277344 212.913818,309.086151 
	C212.992935,309.009064 213.152756,308.856506 213.561417,308.893188 
	C216.700165,309.348083 219.430237,309.766296 223.058868,310.322144 
	C223.446136,304.443512 223.790894,299.210022 224.135651,293.976501 
z"/>
<path fill="#7FABBD" opacity="1.000000" stroke="none" 
	d="
M278.894287,166.651581 
	C278.307434,167.010544 277.916046,167.040375 276.863525,167.060455 
	C272.827972,166.468430 270.169769,165.088348 270.071472,161.175262 
	C272.621002,161.856140 273.949615,161.206619 274.147400,158.761215 
	C276.303497,163.572601 278.319336,158.213745 281.501221,158.943787 
	C280.687653,161.433136 279.888702,163.877792 278.894287,166.651581 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M177.027542,232.044815 
	C187.086121,227.628662 196.841156,229.897446 206.904770,233.912827 
	C207.618408,234.513107 207.968109,234.585480 208.317825,234.657852 
	C208.317825,234.657852 208.391891,234.561951 208.264786,235.002045 
	C208.017593,236.058792 207.897507,236.675430 207.443481,237.189514 
	C199.038300,231.682068 190.462219,229.488754 180.600891,232.976639 
	C179.170746,233.015305 178.132019,233.058624 177.067902,232.838226 
	C177.037537,232.397919 177.032532,232.221359 177.027542,232.044815 
z"/>
<path fill="#E5D0B9" opacity="1.000000" stroke="none" 
	d="
M269.728302,161.238892 
	C270.169769,165.088348 272.827972,166.468430 276.568573,167.089142 
	C271.292908,167.433670 265.651093,167.739746 259.550476,168.072083 
	C259.276489,167.501465 259.461243,166.904572 259.928650,166.141205 
	C264.990692,167.844086 267.915985,166.635742 268.778381,161.139725 
	C269.304230,161.149094 269.728302,161.238892 269.728302,161.238892 
z"/>
<path fill="#6C9AAE" opacity="1.000000" stroke="none" 
	d="
M270.071472,161.175262 
	C269.728302,161.238892 269.304230,161.149094 269.094604,161.091385 
	C268.509644,160.378738 268.134338,159.723816 267.439972,159.030869 
	C269.092834,156.160583 271.160187,154.043930 274.096985,158.415253 
	C273.949615,161.206619 272.621002,161.856140 270.071472,161.175262 
z"/>
<path fill="#FDE2B2" opacity="1.000000" stroke="none" 
	d="
M229.373459,185.694763 
	C229.839035,179.431595 230.576752,172.863052 231.544556,165.987854 
	C231.064972,172.250610 230.355286,178.820007 229.373459,185.694763 
z"/>
<path fill="#FBE6C6" opacity="1.000000" stroke="none" 
	d="
M260.787842,215.236664 
	C261.435486,218.647461 261.773376,222.334625 262.157837,226.469971 
	C257.978821,223.668976 260.475647,219.402206 260.787842,215.236664 
z"/>
<path fill="#FBE6C6" opacity="1.000000" stroke="none" 
	d="
M259.765045,198.249969 
	C260.370636,202.340622 260.671326,206.705292 260.923401,211.529297 
	C260.403198,207.500412 259.931671,203.012192 259.765045,198.249969 
z"/>
<path fill="#FBE6C6" opacity="1.000000" stroke="none" 
	d="
M255.740189,159.226410 
	C257.866364,159.845215 259.600800,160.734100 258.077820,163.550751 
	C257.074036,164.051117 256.083160,164.227448 255.092270,164.403763 
	C255.218384,162.747879 255.344498,161.091995 255.740189,159.226410 
z"/>
<path fill="#67340E" opacity="1.000000" stroke="none" 
	d="
M207.777435,237.292068 
	C207.897507,236.675430 208.017593,236.058792 208.325317,235.004913 
	C210.069580,235.711929 211.626205,236.856155 213.333359,238.296951 
	C213.483871,238.593506 213.230972,238.806229 212.718918,238.917068 
	C211.180496,239.002319 210.154114,238.976730 208.966980,238.736847 
	C208.463287,238.112396 208.120346,237.702225 207.777435,237.292068 
z"/>
<path fill="#FBE6C6" opacity="1.000000" stroke="none" 
	d="
M258.378906,169.067596 
	C258.768951,171.029953 258.815826,173.104034 258.777771,175.616165 
	C258.473785,173.762589 258.254730,171.470932 258.378906,169.067596 
z"/>
<path fill="#FBE6C6" opacity="1.000000" stroke="none" 
	d="
M258.780945,185.198090 
	C259.329773,186.411591 259.599884,187.850647 259.796753,189.670914 
	C259.316437,188.509308 258.909332,186.966476 258.780945,185.198090 
z"/>
<path fill="#FDE2B2" opacity="1.000000" stroke="none" 
	d="
M232.217087,160.833832 
	C231.653122,159.907272 231.332703,158.808090 231.050659,157.351196 
	C231.546234,158.216064 232.003433,159.438614 232.217087,160.833832 
z"/>
<path fill="#67340E" opacity="1.000000" stroke="none" 
	d="
M176.705551,232.052460 
	C177.032532,232.221359 177.037537,232.397919 177.052643,233.088806 
	C177.098846,233.767181 177.134949,233.931244 177.139481,234.099579 
	C177.107895,234.103882 177.092819,234.165833 176.710617,234.121216 
	C175.562622,234.062317 174.796829,234.048065 174.031036,234.033813 
	C174.031036,234.033813 173.964081,233.961624 173.930634,233.925476 
	C174.725967,233.279602 175.554764,232.669846 176.705551,232.052460 
z"/>
<path fill="#FDE2B2" opacity="1.000000" stroke="none" 
	d="
M229.334869,193.742157 
	C229.046509,192.584885 229.016403,191.200363 229.078461,189.408829 
	C229.311447,190.506180 229.452286,192.010529 229.334869,193.742157 
z"/>
<path fill="#FAD9BF" opacity="1.000000" stroke="none" 
	d="
M190.814026,140.749176 
	C190.563095,141.332352 190.131332,141.690018 189.413086,142.091003 
	C189.628815,141.597458 190.131012,141.060577 190.814026,140.749176 
z"/>
<path fill="#DAA66C" opacity="1.000000" stroke="none" 
	d="
M229.013641,307.611237 
	C228.796875,307.111664 228.643829,306.245514 228.734497,305.200287 
	C229.011276,305.762360 229.044327,306.503510 229.013641,307.611237 
z"/>
<path fill="#F3FCED" opacity="1.000000" stroke="none" 
	d="
M245.966156,319.097717 
	C245.748535,319.392578 245.213684,319.674652 244.367371,319.926331 
	C244.586929,319.625641 245.117920,319.355255 245.966156,319.097717 
z"/>
<path fill="#FAD9BF" opacity="1.000000" stroke="none" 
	d="
M144.841064,167.662750 
	C144.555939,167.913528 144.112122,167.963852 143.335754,167.963409 
	C143.562927,167.762527 144.122665,167.612427 144.841064,167.662750 
z"/>
<path fill="#EFDDD3" opacity="1.000000" stroke="none" 
	d="
M277.232605,224.937820 
	C277.430786,224.671463 277.902069,224.429810 278.644470,224.241394 
	C278.445618,224.517273 277.975677,224.739899 277.232605,224.937820 
z"/>
<path fill="#F3FCED" opacity="1.000000" stroke="none" 
	d="
M250.210678,318.090759 
	C249.949402,318.353912 249.353592,318.610229 248.428741,318.805176 
	C248.691849,318.523834 249.283997,318.303894 250.210678,318.090759 
z"/>
<path fill="#EFDDD3" opacity="1.000000" stroke="none" 
	d="
M329.188812,223.485840 
	C329.371887,223.231415 329.637177,223.295120 329.951508,223.717148 
	C329.762268,223.913910 329.519073,223.823395 329.188812,223.485840 
z"/>
<path fill="#67340E" opacity="1.000000" stroke="none" 
	d="
M208.203064,234.402466 
	C207.968109,234.585480 207.618408,234.513107 207.132446,234.194733 
	C207.360214,234.014847 207.724258,234.080963 208.203064,234.402466 
z"/>
<path fill="#EFDDD3" opacity="1.000000" stroke="none" 
	d="
M328.866028,238.625198 
	C328.617462,238.893753 328.341309,238.779083 328.070007,238.249146 
	C328.304016,238.059784 328.558563,238.195801 328.866028,238.625198 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M281.994568,258.176880 
	C282.376251,258.066315 282.568787,258.255127 282.701172,258.729187 
	C282.720490,258.933502 282.261932,258.938995 282.034363,258.908386 
	C281.806793,258.877747 281.884918,258.395294 281.994568,258.176880 
z"/>
<path fill="#FAD9BF" opacity="1.000000" stroke="none" 
	d="
M188.865479,142.163666 
	C188.923218,142.376205 188.731339,142.651169 188.287415,142.984253 
	C188.228851,142.770279 188.422333,142.498169 188.865479,142.163666 
z"/>
<path fill="#FAD9BF" opacity="1.000000" stroke="none" 
	d="
M187.804901,143.099396 
	C187.967834,143.386581 187.770676,143.636734 187.208527,143.843491 
	C187.070938,143.572830 187.271362,143.326935 187.804901,143.099396 
z"/>
<path fill="#FDE2B2" opacity="1.000000" stroke="none" 
	d="
M229.876099,153.914459 
	C229.661469,153.942352 229.411026,153.715576 229.136047,153.228180 
	C229.354446,153.198303 229.597366,153.429047 229.876099,153.914459 
z"/>
<path fill="#FDE2B2" opacity="1.000000" stroke="none" 
	d="
M230.877716,155.958832 
	C230.669113,155.965225 230.451645,155.722427 230.233704,155.228241 
	C230.551971,155.128387 230.763855,155.372665 230.877716,155.958832 
z"/>
<path fill="#F7D5BB" opacity="1.000000" stroke="none" 
	d="
M285.002930,281.061005 
	C285.002930,281.061005 284.634491,280.906342 284.383911,280.642395 
	C284.133301,280.378479 284.016541,279.941589 283.887299,279.722321 
	C283.461182,279.349670 283.164276,279.196320 282.569214,279.022003 
	C281.875519,278.957031 281.479980,278.913025 281.084473,278.869019 
	C281.084473,278.869019 281.002319,278.502991 280.964142,277.978729 
	C279.934113,277.003479 278.942291,276.552490 277.642639,276.078430 
	C276.927063,276.007965 276.519348,275.960510 276.075806,275.557007 
	C275.840607,272.776672 270.634552,270.983734 275.342590,267.973877 
	C275.557343,267.980042 275.986755,267.937866 276.021484,268.282104 
	C277.706879,269.747162 279.357574,270.868011 281.008270,271.988831 
	C281.442657,272.006073 281.877075,272.023285 282.710144,272.412109 
	C285.445587,274.212280 287.782288,275.640869 290.119019,277.069427 
	C290.429199,276.663727 290.739380,276.258026 291.049591,275.852356 
	C289.913452,274.410461 288.777313,272.968597 287.842590,271.782379 
	C288.233368,270.082581 288.588135,268.539551 288.942902,266.996490 
	C289.770935,264.546539 290.598999,262.096588 291.431915,259.632263 
	C296.361664,260.879761 301.189697,262.101532 306.315582,263.398651 
	C306.119720,261.967224 306.030823,261.317688 306.328613,260.364868 
	C308.174530,259.732880 309.633820,259.404175 311.093079,259.075439 
	C311.093079,259.075439 310.991913,259.041107 311.407288,259.042603 
	C312.812103,259.109802 313.801544,259.175537 314.791016,259.241272 
	C314.791016,259.241272 314.829254,259.242767 314.971008,259.508362 
	C316.074524,260.139343 317.036316,260.504730 317.998108,260.870148 
	C321.207977,261.876038 324.381012,263.048309 327.653229,263.772400 
	C328.486267,263.956726 329.634125,262.718292 330.679474,262.109009 
	C331.365112,263.008942 332.333038,264.279449 333.606262,266.046722 
	C332.919769,268.607025 331.928009,270.670593 330.695984,272.946472 
	C329.629761,274.075287 328.803802,274.991699 327.985382,275.538574 
	C328.163757,272.439545 327.129333,270.455048 324.189178,270.820343 
	C323.399231,270.918488 322.095520,273.253082 322.355286,274.194794 
	C323.043701,276.690582 325.068939,277.215027 327.428009,276.048248 
	C327.567352,275.979340 327.752197,276.002319 327.828613,276.242920 
	C327.471283,276.978424 327.201141,277.453552 326.931000,277.928650 
	C326.931000,277.928650 327.001465,277.976257 326.643433,277.991730 
	C325.831543,278.648376 325.377655,279.289551 324.923767,279.930695 
	C324.923767,279.930695 324.883240,279.865845 324.463135,279.901642 
	C320.414307,280.903778 316.785553,281.870148 312.697235,282.905334 
	C306.325531,282.296967 300.413452,281.619812 294.094910,280.896118 
	C293.997467,281.065277 293.435730,282.040710 292.513916,282.961731 
	C290.066620,282.285980 287.979401,281.664612 285.669617,281.034424 
	C285.447052,281.025635 285.002930,281.061005 285.002930,281.061005 
M317.153748,276.674500 
	C316.593201,276.232300 316.046112,275.429077 315.469879,275.407593 
	C312.177063,275.284760 308.877350,275.347931 304.838593,275.347931 
	C305.494629,276.414368 306.117737,278.825775 307.454224,279.324127 
	C310.785217,280.566193 314.533020,281.382874 317.153748,276.674500 
M294.123627,270.160858 
	C293.726440,271.530304 293.102539,272.897766 293.090302,274.270660 
	C293.087219,274.616852 295.321686,275.545502 296.024292,275.195526 
	C296.938690,274.740021 298.056793,273.031464 297.805695,272.305267 
	C297.467224,271.326416 295.907898,270.769775 294.123627,270.160858 
z"/>
<path fill="#C4CEBF" opacity="1.000000" stroke="none" 
	d="
M330.936249,272.734131 
	C331.928009,270.670593 332.919769,268.607025 333.894318,266.187683 
	C333.998047,265.515808 334.192291,265.254150 335.082275,264.997498 
	C340.385742,264.740540 342.769226,266.596954 342.022278,271.631958 
	C341.762726,273.381714 342.046509,275.212036 342.082092,277.005554 
	C342.044586,277.445343 342.007050,277.885132 341.702667,278.937408 
	C341.319031,281.708832 341.202209,283.867767 341.085388,286.026703 
	C339.970856,286.347931 338.856323,286.669128 337.107239,286.727234 
	C334.667877,286.286163 332.863068,286.108154 331.058258,285.930145 
	C331.058258,285.930145 331.000916,285.998322 331.013794,285.511780 
	C330.996552,280.928192 330.966400,276.831177 330.936249,272.734131 
M339.946014,269.219574 
	C337.937225,265.792969 336.049438,266.263123 334.993896,269.710266 
	C333.905426,273.265076 333.529114,277.032837 332.544678,280.626221 
	C331.799591,283.345825 332.402985,284.787201 335.324493,284.965942 
	C337.882629,285.122498 340.004608,284.764435 339.986694,281.454376 
	C339.966156,277.664429 340.017975,273.874054 339.946014,269.219574 
z"/>
<path fill="#B3780D" opacity="1.000000" stroke="none" 
	d="
M316.987579,241.009766 
	C317.198303,242.100250 317.408997,243.190750 317.850922,245.477707 
	C314.773773,241.888474 312.425201,239.149063 309.846710,236.141479 
	C313.793732,234.323700 317.007538,236.751434 319.913635,238.670456 
	C326.200867,242.822220 329.446075,250.560898 328.624786,258.009094 
	C326.278687,251.519135 325.701599,243.897903 317.320831,240.851959 
	C316.807495,240.659637 316.718109,240.611374 316.628723,240.563110 
	C316.748352,240.711990 316.867981,240.860870 316.987579,241.009766 
z"/>
<path fill="#E9A03D" opacity="1.000000" stroke="none" 
	d="
M336.079956,257.385437 
	C338.815216,256.598297 341.498840,256.230713 345.009735,255.749863 
	C345.009735,259.649384 345.009735,262.863556 345.002991,266.538879 
	C344.825684,267.001282 344.655151,267.002563 343.950012,266.792969 
	C340.865143,265.337189 338.314911,264.092255 335.764679,262.847351 
	C335.852570,261.166565 335.940430,259.485809 336.079956,257.385437 
z"/>
<path fill="#B3780D" opacity="1.000000" stroke="none" 
	d="
M292.045410,246.392395 
	C293.134308,244.429337 294.268646,242.858688 295.701477,241.144012 
	C296.000000,241.000000 296.008545,241.006546 296.008545,241.006546 
	C294.405060,247.868576 296.379028,253.797989 301.124695,259.134216 
	C297.239746,259.012726 294.583344,257.855408 293.819366,254.004333 
	C293.338226,251.578964 292.673309,249.190048 292.045410,246.392395 
z"/>
<path fill="#B17B16" opacity="1.000000" stroke="none" 
	d="
M314.833710,258.872375 
	C313.801544,259.175537 312.812103,259.109802 311.449829,259.074951 
	C310.407898,257.612000 310.128418,255.656219 309.001251,254.705536 
	C304.471313,250.884918 305.426605,245.530045 304.568512,240.680084 
	C308.004486,246.621216 311.440430,252.562347 314.833710,258.872375 
z"/>
<path fill="#B47B12" opacity="1.000000" stroke="none" 
	d="
M310.738647,259.025696 
	C309.633820,259.404175 308.174530,259.732880 306.370026,260.036133 
	C300.708862,259.384033 302.834412,254.316559 301.377563,251.503677 
	C300.078674,248.995819 301.104614,245.283844 301.559357,241.977295 
	C300.706665,249.717346 305.208740,254.523575 310.738647,259.025696 
z"/>
<path fill="#F6D58D" opacity="1.000000" stroke="none" 
	d="
M288.617188,267.000610 
	C288.588135,268.539551 288.233368,270.082581 287.842590,271.782379 
	C288.777313,272.968597 289.913452,274.410461 291.049591,275.852356 
	C290.739380,276.258026 290.429199,276.663727 290.119019,277.069427 
	C287.782288,275.640869 285.445587,274.212280 283.033752,272.452057 
	C283.436432,270.609619 283.914154,269.098816 284.648407,267.359497 
	C286.033752,267.088867 287.162628,267.046783 288.617188,267.000610 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M335.565247,263.036499 
	C338.314911,264.092255 340.865143,265.337189 343.694275,266.796326 
	C343.523346,270.161102 343.073517,273.311584 342.352905,276.733826 
	C342.046509,275.212036 341.762726,273.381714 342.022278,271.631958 
	C342.769226,266.596954 340.385742,264.740540 335.285522,264.858368 
	C335.032806,264.254364 335.199310,263.739990 335.565247,263.036499 
z"/>
<path fill="#B17B16" opacity="1.000000" stroke="none" 
	d="
M317.931641,260.526489 
	C317.036316,260.504730 316.074524,260.139343 314.964569,259.490356 
	C315.832642,259.532135 316.848907,259.857483 317.931641,260.526489 
z"/>
<path fill="#B3780D" opacity="1.000000" stroke="none" 
	d="
M295.996765,240.995941 
	C296.092194,240.959808 296.190918,240.927719 296.149078,240.951080 
	C296.008545,241.006546 296.000000,241.000000 295.996765,240.995941 
z"/>
<path fill="#B3780D" opacity="1.000000" stroke="none" 
	d="
M291.945862,245.861206 
	C291.877930,245.858185 291.755859,245.716385 291.633789,245.574585 
	C291.719757,245.623856 291.805725,245.673141 291.945862,245.861206 
z"/>
<path fill="#F6FDF4" opacity="1.000000" stroke="none" 
	d="
M319.994080,386.447723 
	C319.239624,386.947998 318.488800,386.985931 317.316040,386.937805 
	C316.894104,386.851776 316.744232,386.809235 316.744232,386.809235 
	C316.587158,383.180328 316.430084,379.551422 316.536499,375.141144 
	C316.653229,371.234528 316.506500,368.109283 316.359741,364.984039 
	C316.327026,363.925629 316.294281,362.867218 316.506409,361.169800 
	C316.612183,359.345642 316.473114,358.160461 316.334045,356.975281 
	C316.254761,356.549286 316.175507,356.123291 316.072144,354.919250 
	C316.294922,350.094910 316.541809,346.048584 316.852905,341.652832 
	C316.720581,340.198517 316.524048,339.093719 316.272827,337.548798 
	C316.273010,334.746521 316.327911,332.384308 316.570679,329.819366 
	C316.839539,329.073334 316.920532,328.530029 317.003540,327.531250 
	C317.299561,323.005585 317.593567,318.935425 317.887573,314.865265 
	C317.887573,314.865265 317.977783,314.892761 318.183044,314.830292 
	C318.567535,314.481293 318.746704,314.194763 318.925873,313.908234 
	C318.925873,313.908234 318.913513,313.888336 318.900879,313.876312 
	C319.360992,313.908203 319.833710,313.952087 320.662750,314.002502 
	C320.348022,316.846436 319.151733,319.674042 319.098022,322.523132 
	C318.879608,334.109711 318.886475,345.704742 319.053101,357.293152 
	C319.190704,366.859741 319.669922,376.421478 319.994080,386.447723 
z"/>
<path fill="#AFD336" opacity="1.000000" stroke="none" 
	d="
M350.408051,357.789856 
	C345.112152,354.779602 339.685181,355.705170 333.870789,356.970459 
	C333.382111,356.626678 333.261108,356.208405 333.178986,355.460083 
	C338.721619,355.148590 344.225403,355.167206 350.146912,355.300232 
	C350.573944,356.107208 350.583252,356.799713 350.408051,357.789856 
z"/>
<path fill="#F8CFB1" opacity="1.000000" stroke="none" 
	d="
M282.949951,174.961639 
	C283.641693,173.951263 284.333466,172.940887 284.996216,171.612686 
	C289.316376,168.034256 293.665558,164.773636 297.780487,161.688660 
	C297.780487,163.623764 297.780487,166.464340 297.780487,169.304916 
	C297.987854,169.324295 298.195251,169.343689 298.402618,169.363068 
	C299.320953,166.758881 300.239288,164.154709 301.267639,161.238541 
	C302.262878,164.461227 303.102295,167.179321 303.941711,169.897430 
	C304.296417,169.883377 304.651093,169.869339 305.005798,169.855301 
	C306.114716,167.037399 307.223602,164.219498 308.478455,161.030792 
	C310.208435,164.571686 311.762878,167.753296 313.317322,170.934906 
	C313.741852,170.927063 314.166382,170.919220 314.590912,170.911392 
	C315.447174,168.100479 316.303406,165.289581 317.335327,161.901993 
	C319.562836,165.053955 321.536591,167.846848 323.949463,171.261093 
	C324.424133,167.911377 324.822266,165.101929 325.467682,160.547287 
	C328.884125,164.758545 331.588470,168.091995 334.624664,171.963242 
	C334.992676,173.985214 335.028809,175.469421 335.041687,176.982254 
	C335.018402,177.010895 334.964691,177.061600 334.612396,177.085449 
	C332.839905,179.382187 331.419678,181.655060 329.779846,184.031601 
	C329.560242,184.135254 329.087524,184.244095 329.062042,183.826508 
	C329.279877,180.476669 327.667694,179.053925 324.993011,179.169388 
	C324.010864,179.211792 322.525757,180.329849 322.236664,181.258255 
	C321.443604,183.805145 322.334229,185.769165 325.224518,186.575684 
	C322.469238,188.635239 319.790100,190.343903 316.742462,192.098999 
	C315.358612,192.636856 313.737030,192.885025 313.426361,193.659225 
	C311.415253,198.670837 309.176727,196.843887 306.541840,194.257172 
	C305.688141,193.419052 304.257416,193.168625 302.975708,192.324295 
	C299.131592,189.292099 293.036194,190.585526 291.438354,184.990173 
	C292.851959,184.984619 294.184204,185.384735 294.760651,184.895065 
	C296.030823,183.816147 297.370544,182.402771 297.754333,180.890457 
	C297.920654,180.235184 295.452240,177.999893 294.782654,178.230515 
	C293.319305,178.734497 291.988281,180.195435 291.101501,181.583740 
	C290.577972,182.403336 291.007080,183.831406 290.694092,184.845428 
	C288.587494,182.448227 286.794617,180.191422 284.877869,177.652435 
	C284.152618,176.567383 283.551300,175.764511 282.949951,174.961639 
z"/>
<path fill="#C8CCB3" opacity="1.000000" stroke="none" 
	d="
M335.064941,176.953613 
	C335.028809,175.469421 334.992676,173.985214 334.927429,172.051849 
	C334.898315,171.602692 334.854462,171.327240 334.920807,171.205704 
	C335.407837,171.085983 335.828552,171.087799 336.669678,171.320312 
	C337.259247,171.826904 337.492859,172.027802 337.910767,172.488098 
	C339.328430,173.261017 340.626343,173.699524 341.960144,174.358246 
	C341.996094,174.578445 342.144958,174.999100 342.144958,174.999100 
	C341.861450,181.076096 341.577942,187.153091 341.136414,193.615387 
	C338.744995,194.332352 336.511566,194.664032 333.736786,194.681992 
	C332.476440,193.496765 331.757507,192.625244 331.024872,191.346909 
	C330.673950,188.602707 330.336731,186.265320 329.999512,183.927948 
	C331.419678,181.655060 332.839905,179.382187 334.573486,177.456085 
	C333.949097,181.080994 332.267639,184.381912 332.367004,187.628311 
	C332.422028,189.425720 335.457031,191.131882 337.148773,192.879181 
	C338.105133,191.297409 339.577148,189.812637 339.897217,188.111160 
	C340.380219,185.543503 339.937683,182.816788 340.107635,180.171753 
	C340.382233,175.897888 338.588226,175.138657 335.064941,176.953613 
z"/>
<path fill="#F8CFB1" opacity="1.000000" stroke="none" 
	d="
M284.262634,169.744019 
	C284.135559,169.747101 283.884949,169.531067 283.790100,169.187653 
	C284.010284,169.215149 284.074677,169.370026 284.262634,169.744019 
z"/>
<path fill="#B60B06" opacity="1.000000" stroke="none" 
	d="
M292.873993,283.016144 
	C293.435730,282.040710 293.997467,281.065277 294.094910,280.896118 
	C300.413452,281.619812 306.325531,282.296967 313.077393,282.976257 
	C317.249207,284.446136 320.581268,285.913910 323.913330,287.381683 
	C324.463165,288.036835 325.013000,288.691956 325.448975,289.821686 
	C325.165405,291.492645 324.995728,292.688995 324.583740,294.134888 
	C322.954895,294.957520 321.568390,295.530640 319.621460,296.335388 
	C319.621460,298.263306 319.621460,300.714539 319.507111,303.470154 
	C319.385071,304.206207 319.377411,304.637848 319.244995,305.439331 
	C319.137939,306.849854 319.155609,307.890564 319.173279,308.931274 
	C318.943909,309.209656 318.714539,309.488037 317.821960,309.833618 
	C308.798248,309.553375 300.437775,309.205933 292.077271,308.858521 
	C292.077271,308.858521 292.009399,308.913391 292.106720,308.580688 
	C294.216309,302.037842 296.228607,295.827698 298.100098,290.052124 
	C295.789856,288.161499 293.911438,286.624237 292.239807,284.879089 
	C292.589081,284.119507 292.731537,283.567841 292.873993,283.016144 
z"/>
<path fill="#F7F0D7" opacity="1.000000" stroke="none" 
	d="
M290.424683,314.876648 
	C290.412903,319.857758 289.958282,324.870514 288.898315,330.291199 
	C286.564392,331.146210 284.835785,331.593262 282.959595,331.751068 
	C281.574615,330.276855 280.337219,329.091919 279.017517,327.665955 
	C278.649902,327.254730 278.364685,327.084503 277.950317,326.624176 
	C276.615936,325.495270 275.410645,324.656464 274.006348,323.547607 
	C272.592560,322.933380 271.377747,322.589203 270.162964,322.244995 
	C270.115295,320.748840 270.067627,319.252655 270.042480,317.303528 
	C276.703949,316.203156 283.342896,315.555725 290.424683,314.876648 
z"/>
<path fill="#FCD35F" opacity="1.000000" stroke="none" 
	d="
M292.033020,285.087006 
	C293.911438,286.624237 295.789856,288.161499 298.100098,290.052124 
	C296.228607,295.827698 294.216309,302.037842 292.134338,308.614471 
	C292.064667,308.980957 291.575226,308.993225 291.331787,309.005981 
	C290.090790,308.123871 288.338440,307.321289 288.215973,306.319489 
	C287.302460,298.845856 286.711609,291.332794 286.407654,283.399963 
	C288.541473,283.674225 290.287231,284.380615 292.033020,285.087006 
z"/>
<path fill="#B1D6E3" opacity="1.000000" stroke="none" 
	d="
M276.928467,292.093506 
	C277.271484,296.056000 277.419830,300.044373 278.045197,303.956512 
	C278.965271,309.711945 274.746521,309.486298 271.293274,309.959534 
	C266.894531,310.562378 267.115417,307.336975 267.105194,304.433868 
	C267.092957,300.946747 267.077209,297.459625 267.056519,293.510101 
	C267.050018,293.047699 267.021881,293.039185 267.438293,293.039459 
	C270.877075,292.722198 273.899414,292.404663 276.921753,292.087097 
	C276.921753,292.087128 276.947083,292.085266 276.928467,292.093506 
z"/>
<path fill="#F6ECD6" opacity="1.000000" stroke="none" 
	d="
M317.437653,314.907410 
	C317.593567,318.935425 317.299561,323.005585 316.729980,327.536194 
	C316.272827,328.014343 316.091309,328.032043 315.516205,327.967712 
	C312.118286,327.663330 309.113922,327.440948 306.109589,327.218597 
	C306.062744,323.520233 306.015869,319.821899 305.947998,315.680786 
	C309.613892,315.141846 313.300781,315.045685 317.437653,314.907410 
z"/>
<path fill="#F9DBBB" opacity="1.000000" stroke="none" 
	d="
M324.826019,293.885345 
	C324.995728,292.688995 325.165405,291.492645 325.589905,290.037231 
	C326.642975,290.175262 327.441223,290.572357 328.578796,291.386047 
	C329.225983,292.188538 329.533875,292.574432 329.875366,293.229187 
	C329.942566,293.675598 329.976135,293.853088 330.009247,294.465942 
	C329.971527,296.943390 329.934235,298.985474 329.652222,301.243805 
	C328.905945,302.887329 328.404388,304.314636 327.902832,305.741913 
	C326.739746,306.335510 325.576630,306.929077 323.988953,307.869263 
	C323.271729,308.386810 323.040741,308.619141 322.871460,308.912811 
	C322.480469,308.904694 322.089447,308.896576 321.233826,308.637268 
	C320.302704,307.280518 319.836212,306.174988 319.369751,305.069489 
	C319.377411,304.637848 319.385071,304.206207 319.875122,303.427307 
	C326.659454,302.354004 324.605469,297.566986 324.826019,293.885345 
z"/>
<path fill="#C4CEBF" opacity="1.000000" stroke="none" 
	d="
M330.009705,294.030579 
	C329.976135,293.853088 329.942566,293.675598 330.172607,292.948212 
	C333.931000,292.940308 337.425690,293.482239 340.920410,294.024170 
	C340.936096,295.402649 340.951782,296.781097 340.706604,298.867767 
	C340.286316,303.047455 340.126923,306.518951 339.967529,309.990448 
	C339.820099,310.269897 339.623779,310.509247 339.179565,310.830017 
	C335.591949,309.113159 330.209473,311.782990 328.170349,306.015320 
	C328.404388,304.314636 328.905945,302.887329 329.925537,301.444244 
	C329.834564,306.879883 330.962921,308.844269 335.312073,308.800049 
	C339.975250,308.752716 338.672119,304.902985 338.957306,302.184387 
	C339.060394,301.201752 338.883972,300.188538 338.999634,299.208710 
	C339.449524,295.397430 337.947601,293.548004 333.979523,293.939636 
	C332.666016,294.069244 331.333527,294.005768 330.009705,294.030579 
z"/>
<path fill="#F49348" opacity="1.000000" stroke="none" 
	d="
M292.307220,309.194153 
	C300.437775,309.205933 308.798248,309.553375 317.586975,309.969330 
	C315.839569,315.488617 311.249420,313.367249 307.648224,312.906097 
	C302.550690,312.253326 297.568604,310.698883 292.307220,309.194153 
z"/>
<path fill="#F6ECD6" opacity="1.000000" stroke="none" 
	d="
M294.439758,313.083679 
	C293.995148,313.119751 293.499512,313.061462 293.251709,313.032410 
	C295.840057,312.789673 298.676147,312.576050 301.772278,312.671967 
	C299.649628,313.003540 297.266968,313.025543 294.439758,313.083679 
z"/>
<path fill="#F6ECD6" opacity="1.000000" stroke="none" 
	d="
M304.892639,314.715302 
	C304.054749,314.559235 303.075317,314.112274 302.056396,313.312927 
	C302.928284,313.448517 303.839722,313.936432 304.892639,314.715302 
z"/>
<path fill="#F4C076" opacity="1.000000" stroke="none" 
	d="
M319.244995,305.439331 
	C319.836212,306.174988 320.302704,307.280518 320.940491,308.645416 
	C320.680725,308.926056 320.249634,308.947266 319.495911,308.949890 
	C319.155609,307.890564 319.137939,306.849854 319.244995,305.439331 
z"/>
<path fill="#F7F0D7" opacity="1.000000" stroke="none" 
	d="
M291.821411,313.424652 
	C291.852386,313.838104 291.612244,314.296387 291.110901,314.829041 
	C291.083252,314.425476 291.316803,313.947479 291.821411,313.424652 
z"/>
<path fill="#F4C076" opacity="1.000000" stroke="none" 
	d="
M323.124390,308.861938 
	C323.040741,308.619141 323.271729,308.386810 323.797058,308.130737 
	C323.812225,308.300812 323.594757,308.555969 323.124390,308.861938 
z"/>
<path fill="#F6ECD6" opacity="1.000000" stroke="none" 
	d="
M318.676544,313.984192 
	C318.746704,314.194763 318.567535,314.481293 318.137573,314.842712 
	C318.066956,314.631775 318.247070,314.345978 318.676544,313.984192 
z"/>
<path fill="#E7A33D" opacity="1.000000" stroke="none" 
	d="
M345.090515,208.011749 
	C345.073517,209.075531 345.056488,210.139297 345.013367,211.601303 
	C344.657288,214.564453 344.041779,217.132019 344.065308,219.693695 
	C344.099365,223.401871 342.101807,224.754517 339.036346,223.909180 
	C325.089050,220.063034 310.893524,222.346893 296.838165,222.207993 
	C287.315155,222.113907 277.780975,223.949905 268.233276,224.680679 
	C266.828949,224.788162 264.163818,223.719986 264.047760,222.913025 
	C263.210693,217.093323 262.880219,211.200760 262.389160,205.331284 
	C262.892395,205.239426 263.395660,205.147552 263.898895,205.055695 
	C265.017273,209.654114 266.135620,214.252533 267.630157,218.912811 
	C268.006348,218.974640 268.022034,218.958221 268.166962,219.279327 
	C272.033966,220.633804 275.732147,221.572418 278.266876,216.986252 
	C278.708038,216.614899 278.896027,216.350937 279.372742,215.970215 
	C279.812988,214.414886 280.069122,212.921844 279.963257,211.454926 
	C279.717804,208.055237 279.756775,204.907364 284.148560,203.668076 
	C284.921204,203.332382 285.486389,203.204239 286.181213,203.263031 
	C286.516357,203.682343 286.763611,203.854889 287.088440,204.378387 
	C287.420685,207.220367 287.418457,209.740967 288.111023,212.053513 
	C288.634766,213.802292 290.045868,215.285339 291.526428,216.905243 
	C302.530640,216.923828 313.070465,216.923828 323.234222,216.923828 
	C323.484528,211.612350 323.697083,207.102493 323.927277,202.217712 
	C325.986755,202.217712 327.426453,202.217712 328.728668,202.557770 
	C328.067627,206.548370 327.371643,210.187836 327.135681,213.856873 
	C327.072723,214.835648 328.417694,215.904984 329.334229,216.984131 
	C329.552185,217.035522 329.948639,217.243774 330.040955,217.589111 
	C333.407471,219.662842 336.685181,222.118515 339.955688,217.666687 
	C339.980682,217.446136 340.152985,217.037003 340.530487,216.934906 
	C341.480530,213.409576 342.053040,209.986343 342.685974,206.201981 
	C343.624573,206.908417 344.357544,207.460083 345.090515,208.011749 
z"/>
<path fill="#E7A140" opacity="1.000000" stroke="none" 
	d="
M282.619019,174.789154 
	C283.551300,175.764511 284.152618,176.567383 284.851013,178.115509 
	C285.318176,183.860260 285.688324,188.859787 285.790863,194.035599 
	C285.226990,194.522339 284.930695,194.832779 284.360657,195.124725 
	C283.723053,195.073837 283.359131,195.041428 282.995239,195.009018 
	C281.614166,194.934113 280.233093,194.859207 278.865326,194.785019 
	C278.476624,193.515656 278.102478,192.293869 277.373535,190.690094 
	C277.024139,189.874771 277.029572,189.441437 277.311707,188.749054 
	C277.113129,185.984451 276.637878,183.478867 276.114868,180.580795 
	C276.034882,179.134460 276.002655,178.080612 276.243774,176.806412 
	C275.984558,175.407440 275.452026,174.228806 274.701416,172.993042 
	C274.483398,172.935913 274.053406,172.800430 273.960114,172.466675 
	C270.962372,170.154037 268.760620,171.176743 266.647400,173.857300 
	C265.912994,174.302887 265.454987,174.649551 264.703186,175.026428 
	C264.083740,175.364258 263.758148,175.671875 263.432556,175.979492 
	C263.897614,176.341583 264.362671,176.703674 264.869690,177.503265 
	C264.944641,180.287857 264.977631,182.634949 265.010651,184.982040 
	C264.482330,188.437729 263.954041,191.893417 263.229370,196.633423 
	C262.223267,194.349884 261.307220,193.150833 261.202759,191.884827 
	C260.686737,185.630417 260.376831,179.358780 260.012604,173.092148 
	C259.862152,170.503571 260.863739,168.974716 263.705078,168.997742 
	C269.163483,169.041992 274.622467,169.010635 279.969574,169.010635 
	C280.834351,171.101654 281.561218,172.859146 282.619019,174.789154 
z"/>
<path fill="#A9D8F0" opacity="1.000000" stroke="none" 
	d="
M267.181549,207.033813 
	C267.143127,206.610321 267.104706,206.186829 267.507721,205.415558 
	C277.140991,204.900162 277.144012,204.900192 277.039276,213.922348 
	C277.027924,214.900757 276.955597,215.878464 276.610229,216.926788 
	C275.899628,217.052536 275.490387,217.107986 274.641876,217.089020 
	C272.132568,217.008804 270.062469,217.003036 267.992432,216.997253 
	C267.713989,214.595337 267.435547,212.193405 267.369568,209.231049 
	C267.448517,208.125015 267.315033,207.579422 267.181549,207.033813 
z"/>
<path fill="#E5D0B9" opacity="1.000000" stroke="none" 
	d="
M276.911560,216.856506 
	C276.955597,215.878464 277.027924,214.900757 277.039276,213.922348 
	C277.144012,204.900192 277.140991,204.900162 267.505127,205.096619 
	C267.001801,204.661880 266.942505,204.198318 267.222961,203.228943 
	C269.919128,201.809784 272.337128,201.025467 274.610809,199.939026 
	C276.201416,199.178955 277.594696,198.005951 279.076111,197.017365 
	C279.076111,197.017365 279.454651,196.960861 279.902740,196.894104 
	C280.590881,196.583267 280.830933,196.339172 281.070984,196.095078 
	C281.482208,196.057617 281.893463,196.020142 282.635559,196.394928 
	C284.433502,200.757690 280.422302,201.601242 278.666382,204.186325 
	C278.595276,208.339355 278.809723,212.191864 279.024170,216.044357 
	C278.896027,216.350937 278.708038,216.614899 277.974670,216.921875 
	C277.296570,216.957169 277.104065,216.906830 276.911560,216.856506 
z"/>
<path fill="#C5CDB9" opacity="1.000000" stroke="none" 
	d="
M275.970398,177.026764 
	C276.002655,178.080612 276.034882,179.134460 276.048340,181.008179 
	C276.364716,184.221390 276.699860,186.614731 277.035004,189.008087 
	C277.029572,189.441437 277.024139,189.874771 277.057434,190.630554 
	C275.879547,193.121780 269.431396,195.866409 267.500641,194.778534 
	C266.724701,194.341324 266.191406,193.020889 266.037109,192.026505 
	C265.708008,189.905884 265.698730,187.735611 265.284546,185.283875 
	C264.977631,182.634949 264.944641,180.287857 264.948334,177.163239 
	C264.989044,175.922562 264.993011,175.459381 264.997009,174.996216 
	C265.454987,174.649551 265.912994,174.302887 267.045959,173.915405 
	C269.831787,173.516541 271.942596,173.158493 274.053406,172.800430 
	C274.053406,172.800430 274.483398,172.935913 274.718292,173.352356 
	C275.292236,174.854797 275.631317,175.940781 275.970398,177.026764 
M274.988129,189.286224 
	C274.656799,185.687378 274.579407,182.036880 273.863434,178.516251 
	C273.594360,177.193222 271.600372,175.076874 270.820221,175.249863 
	C269.378387,175.569550 267.362427,177.178528 267.167328,178.486359 
	C266.661011,181.880707 266.766479,185.430054 267.140778,188.861603 
	C267.297516,190.298630 268.935303,192.819412 269.580750,192.722519 
	C271.459961,192.440445 273.178070,191.085159 274.988129,189.286224 
z"/>
<path fill="#F7CE97" opacity="1.000000" stroke="none" 
	d="
M278.647339,197.008728 
	C277.594696,198.005951 276.201416,199.178955 274.610809,199.939026 
	C272.337128,201.025467 269.919128,201.809784 267.228027,202.878815 
	C266.310028,201.591782 265.726746,200.149063 265.087067,198.566788 
	C269.840179,197.999710 274.029388,197.499908 278.647339,197.008728 
z"/>
<path fill="#F7CE97" opacity="1.000000" stroke="none" 
	d="
M278.951874,203.885788 
	C280.422302,201.601242 284.433502,200.757690 282.945190,196.386017 
	C282.923981,195.964844 282.969269,195.487564 282.982239,195.248291 
	C283.359131,195.041428 283.723053,195.073837 284.464050,195.514526 
	C285.244659,198.307236 285.648132,200.691666 286.051575,203.076111 
	C285.486389,203.204239 284.921204,203.332382 283.734802,203.680969 
	C281.726318,203.896210 280.339111,203.890991 278.951874,203.885788 
z"/>
<path fill="#E5D0B9" opacity="1.000000" stroke="none" 
	d="
M267.980469,217.327301 
	C270.062469,217.003036 272.132568,217.008804 274.367493,217.327576 
	C272.362213,218.079773 270.192139,218.518982 268.022034,218.958221 
	C268.022034,218.958221 268.006348,218.974640 268.015961,218.974884 
	C268.006561,218.535873 267.987549,218.096603 267.980469,217.327301 
z"/>
<path fill="#E5D0B9" opacity="1.000000" stroke="none" 
	d="
M267.115417,207.355133 
	C267.315033,207.579422 267.448517,208.125015 267.392761,208.820953 
	C267.152069,208.539688 267.100647,208.108078 267.115417,207.355133 
z"/>
<path fill="#F7CE97" opacity="1.000000" stroke="none" 
	d="
M280.817322,196.153931 
	C280.830933,196.339172 280.590881,196.583267 280.093872,196.884338 
	C280.079163,196.698486 280.321411,196.455627 280.817322,196.153931 
z"/>
<path fill="#F9C974" opacity="1.000000" stroke="none" 
	d="
M324.050842,286.980408 
	C320.581268,285.913910 317.249207,284.446136 313.536987,282.907440 
	C316.785553,281.870148 320.414307,280.903778 324.425781,279.909821 
	C324.601837,282.114502 324.395111,284.346802 324.050842,286.980408 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M330.695984,272.946472 
	C330.966400,276.831177 330.996552,280.928192 331.048157,285.483582 
	C330.499939,284.420746 329.972748,282.881775 329.346741,281.384155 
	C328.863190,280.227448 328.255707,279.122559 327.317139,277.961609 
	C327.201141,277.453552 327.471283,276.978424 327.867340,276.243652 
	C327.993286,275.984039 327.977875,275.908142 327.977875,275.908142 
	C328.803802,274.991699 329.629761,274.075287 330.695984,272.946472 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M341.190430,293.809937 
	C337.425690,293.482239 333.931000,292.940308 330.139038,292.679321 
	C329.533875,292.574432 329.225983,292.188538 328.872803,291.551239 
	C332.821411,289.326447 339.957916,290.623413 341.190430,293.809937 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M340.277252,309.769043 
	C340.126923,306.518951 340.286316,303.047455 340.728241,299.280884 
	C340.869476,302.506409 340.728241,306.027039 340.277252,309.769043 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M341.448761,285.939423 
	C341.202209,283.867767 341.319031,281.708832 341.707214,279.268585 
	C341.923126,281.275574 341.867645,283.563843 341.448761,285.939423 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M331.102417,286.301361 
	C332.863068,286.108154 334.667877,286.286163 336.740112,286.735474 
	C335.053864,286.895386 333.100220,286.783997 331.102417,286.301361 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M325.258850,279.916260 
	C325.377655,279.289551 325.831543,278.648376 326.608643,277.962891 
	C326.485870,278.579620 326.039886,279.240723 325.258850,279.916260 
z"/>
<path fill="#95BB12" opacity="1.000000" stroke="none" 
	d="
M286.181213,203.263031 
	C285.648132,200.691666 285.244659,198.307236 284.737793,195.533005 
	C284.930695,194.832779 285.226990,194.522339 286.225311,194.103516 
	C292.315094,193.546555 297.702881,193.097977 303.090637,192.649384 
	C304.257416,193.168625 305.688141,193.419052 306.541840,194.257172 
	C309.176727,196.843887 311.415253,198.670837 313.426361,193.659225 
	C313.737030,192.885025 315.358612,192.636856 317.194824,192.138763 
	C322.356689,192.005966 326.697601,191.879837 331.038544,191.753723 
	C331.757507,192.625244 332.476440,193.496765 333.353882,194.682678 
	C333.658600,196.438950 333.804840,197.880844 333.848938,199.972427 
	C332.544403,201.180161 331.341980,201.738190 329.824554,202.222473 
	C329.295074,202.171707 329.080597,202.194717 328.866150,202.217712 
	C327.426453,202.217712 325.986755,202.217712 323.927277,202.217712 
	C323.697083,207.102493 323.484528,211.612350 323.234222,216.923828 
	C313.070465,216.923828 302.530640,216.923828 291.569641,216.544617 
	C292.488068,212.035400 293.827637,207.905426 295.503876,202.737518 
	C292.050323,203.240189 289.551453,203.603897 287.052582,203.967621 
	C286.763611,203.854889 286.516357,203.682343 286.181213,203.263031 
z"/>
<path fill="#AFD7E5" opacity="1.000000" stroke="none" 
	d="
M330.139526,202.296234 
	C331.341980,201.738190 332.544403,201.180161 333.927307,200.332520 
	C336.094910,200.378403 338.082062,200.713913 340.449829,201.472076 
	C340.604614,206.942169 340.378784,211.989594 340.152985,217.037003 
	C340.152985,217.037003 339.980682,217.446136 339.526306,217.698364 
	C336.030853,217.714981 332.989746,217.479370 329.948639,217.243774 
	C329.948639,217.243774 329.552185,217.035522 329.318085,216.532700 
	C329.435852,211.451996 329.787689,206.874115 330.139526,202.296234 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M340.530487,216.934906 
	C340.378784,211.989594 340.604614,206.942169 340.830872,201.476746 
	C346.115234,200.432281 346.506378,203.449539 345.338318,207.727600 
	C344.357544,207.460083 343.624573,206.908417 342.685974,206.201981 
	C342.053040,209.986343 341.480530,213.409576 340.530487,216.934906 
z"/>
<path fill="#C1D4CC" opacity="1.000000" stroke="none" 
	d="
M351.333252,389.919556 
	C350.994080,389.763153 350.819977,389.477081 350.868500,389.038055 
	C351.282928,388.858398 351.470001,388.877777 351.774536,389.019592 
	C351.908936,389.410614 351.776123,389.641937 351.333252,389.919556 
z"/>
<path fill="#E9A03D" opacity="1.000000" stroke="none" 
	d="
M337.790985,172.153671 
	C337.492859,172.027802 337.259247,171.826904 336.984985,171.342331 
	C337.339325,168.783630 336.011353,165.833267 340.003143,164.899124 
	C345.404358,163.635162 345.849060,163.862946 345.127472,169.464828 
	C344.876556,171.412338 345.022644,173.409943 344.852417,175.372955 
	C344.845947,175.447144 343.421082,175.398300 342.401062,175.202942 
	C342.144958,174.999100 341.996094,174.578445 341.930359,173.973022 
	C340.506775,172.962967 339.148865,172.558319 337.790985,172.153671 
z"/>
<path fill="#479322" opacity="1.000000" stroke="none" 
	d="
M384.792114,364.399231 
	C380.301788,360.429138 375.721558,356.375031 368.673279,356.180908 
	C373.505829,355.884277 378.697418,355.520081 384.337341,355.232056 
	C384.820740,358.197449 384.855835,361.086700 384.792114,364.399231 
z"/>
<path fill="#FDF0E1" opacity="1.000000" stroke="none" 
	d="
M135.737183,316.051331 
	C134.958221,317.052307 133.824554,318.006989 132.382385,319.062561 
	C132.053055,318.674500 132.032227,318.185547 132.017975,317.328857 
	C133.143845,316.642426 134.263168,316.323761 135.737183,316.051331 
z"/>
<path fill="#D8EACF" opacity="1.000000" stroke="none" 
	d="
M80.861488,341.728210 
	C76.405945,342.041077 71.974190,342.125793 67.553307,341.944885 
	C66.090355,341.885010 64.655403,341.140900 63.179920,340.304535 
	C68.399284,339.869507 73.646370,339.839569 79.343742,339.851624 
	C80.159187,340.394623 80.524353,340.895599 80.861488,341.728210 
z"/>
<path fill="#C2D5CD" opacity="1.000000" stroke="none" 
	d="
M316.788696,342.002289 
	C316.541809,346.048584 316.294922,350.094910 316.017517,354.598785 
	C312.990173,355.062744 309.993347,355.069122 306.551514,355.078003 
	C305.927185,350.191803 305.747864,345.303070 306.124390,340.140686 
	C306.680267,339.867065 307.069061,340.024872 307.128601,340.414795 
	C310.388336,341.203918 313.588501,341.603119 316.788696,342.002289 
z"/>
<path fill="#99C82A" opacity="1.000000" stroke="none" 
	d="
M307.069061,340.024841 
	C307.069061,340.024872 306.680267,339.867065 306.472534,339.886475 
	C306.179840,337.553589 306.094849,335.201294 306.031799,332.025146 
	C306.022156,330.148804 305.990570,329.096405 305.958984,328.043976 
	C305.958984,328.043976 306.111420,327.644867 306.110504,327.431732 
	C309.113922,327.440948 312.118286,327.663330 315.542969,328.308716 
	C316.103149,329.161896 316.242981,329.592010 316.382812,330.022125 
	C316.327911,332.384308 316.273010,334.746521 316.077423,337.911194 
	C312.980835,339.150757 310.024963,339.587799 307.069061,340.024841 
z"/>
<path fill="#C2D5CD" opacity="1.000000" stroke="none" 
	d="
M305.699463,328.239990 
	C305.990570,329.096405 306.022156,330.148804 305.961731,331.586365 
	C305.726440,330.792999 305.583221,329.614471 305.699463,328.239990 
z"/>
<path fill="#C1D5C9" opacity="1.000000" stroke="none" 
	d="
M131.605865,320.294556 
	C131.879761,322.051086 131.893951,324.064056 131.756744,326.477051 
	C131.518967,324.768372 131.432571,322.659668 131.605865,320.294556 
z"/>
<path fill="#A4C55D" opacity="1.000000" stroke="none" 
	d="
M270.165771,322.588867 
	C271.377747,322.589203 272.592560,322.933380 273.696899,323.621674 
	C272.637970,324.308380 271.689484,324.650970 270.740967,324.993561 
	C270.740967,324.993561 270.361938,324.991577 270.172363,324.995361 
	C270.044739,324.310364 270.106659,323.621582 270.165771,322.588867 
z"/>
<path fill="#DAE5E9" opacity="1.000000" stroke="none" 
	d="
M289.240967,377.279419 
	C289.647217,377.227814 289.728088,377.465302 289.446899,377.855042 
	C289.323914,377.936829 289.154602,377.498108 289.240967,377.279419 
z"/>
<path fill="#DAE5EA" opacity="1.000000" stroke="none" 
	d="
M195.978912,391.622498 
	C193.211777,392.021240 190.534409,392.006470 187.427002,391.510620 
	C186.967773,382.221710 186.901245,373.413849 186.917419,364.606079 
	C186.947754,348.072754 187.025940,331.539459 187.440338,314.954712 
	C191.581070,310.953644 195.330292,315.026550 199.319397,314.985901 
	C199.540985,315.018768 199.961014,315.174591 199.968964,315.609406 
	C200.045395,317.800751 200.113876,319.557251 200.201691,321.506714 
	C200.221024,321.699646 200.047974,322.046692 199.743744,322.297363 
	C199.591049,325.335724 199.742584,328.123413 199.982101,331.353210 
	C200.063416,334.188263 200.056732,336.581268 199.737701,339.256653 
	C199.388626,345.861481 199.351913,352.183868 199.315216,358.506256 
	C199.355209,359.092621 199.395203,359.678955 199.433746,360.557953 
	C198.959320,362.647278 198.223267,364.420227 198.058441,366.244781 
	C197.307388,374.558228 196.716888,382.886230 195.978912,391.622498 
z"/>
<path fill="#C1D5CA" opacity="1.000000" stroke="none" 
	d="
M199.643036,358.310181 
	C199.351913,352.183868 199.388626,345.861481 200.119629,339.416351 
	C206.131790,339.497345 211.449646,339.701080 217.056442,339.943054 
	C217.538330,340.004517 217.731262,340.027679 217.924194,340.050812 
	C217.883499,344.883423 217.842804,349.716064 217.667099,355.050049 
	C212.963379,355.390686 208.383240,354.994720 203.833038,355.215363 
	C202.501907,355.279968 201.255890,357.099152 199.643036,358.310181 
M202.389587,340.994659 
	C201.931717,341.122894 201.473831,341.251099 199.758636,341.731415 
	C205.672287,341.731415 210.304611,341.731415 214.936935,341.731415 
	C214.941574,341.483917 214.946198,341.236389 214.950836,340.988892 
	C211.033417,340.988892 207.116013,340.988892 202.389587,340.994659 
z"/>
<path fill="#C1D5CA" opacity="1.000000" stroke="none" 
	d="
M217.529602,372.080261 
	C217.795898,372.976074 217.867798,374.143402 217.836411,375.740234 
	C217.600494,374.897095 217.467850,373.624420 217.529602,372.080261 
z"/>
<path fill="#F0FAE5" opacity="1.000000" stroke="none" 
	d="
M148.999634,339.400818 
	C149.237518,330.890594 149.502335,322.853699 149.967438,314.446259 
	C151.202057,313.423370 152.236359,312.770996 153.937439,312.068237 
	C155.756638,312.330048 156.909058,312.642334 157.677917,312.968079 
	C155.547577,314.900848 153.583496,316.674561 152.154861,318.806976 
	C151.534515,319.732971 151.978943,321.397644 152.002167,322.725708 
	C152.327042,341.296387 152.615097,359.868011 153.082718,378.435150 
	C153.121796,379.986816 154.388367,381.507507 155.084656,383.042603 
	C151.112289,386.063782 149.892700,385.809143 148.771255,381.351868 
	C148.699600,372.724854 148.744400,364.494720 148.770660,355.830750 
	C148.843613,350.222656 148.935074,345.048370 148.999634,339.400818 
z"/>
<path fill="#F7FCF6" opacity="1.000000" stroke="none" 
	d="
M187.084229,315.006165 
	C187.025940,331.539459 186.947754,348.072754 186.917419,364.606079 
	C186.901245,373.413849 186.967773,382.221710 186.976440,391.493073 
	C185.981049,390.830994 184.373932,389.813751 184.158752,388.557983 
	C183.662155,385.659668 183.840881,382.645691 183.493286,379.363647 
	C183.204178,372.342743 183.167450,365.636810 183.344284,358.370483 
	C183.448822,357.538300 183.339783,357.266479 183.230743,356.994659 
	C183.124451,356.581024 183.018143,356.167358 182.869232,355.437073 
	C182.982574,350.292725 183.138550,345.464996 183.185806,340.035706 
	C183.077087,339.434143 183.291153,339.044861 183.554199,339.000244 
	C183.668137,338.282288 183.519028,337.608978 183.299866,336.625854 
	C183.279648,335.898376 183.329514,335.480652 183.683426,334.970703 
	C183.786774,333.565277 183.586060,332.252167 183.300201,330.544647 
	C183.077362,328.811493 182.939697,327.472717 183.123520,325.870056 
	C183.922836,322.030548 184.400665,318.454895 184.920959,314.941895 
	C184.963409,315.004517 184.890259,314.876221 185.253784,314.922668 
	C186.106277,314.981476 186.595261,314.993835 187.084229,315.006165 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M183.158539,357.250061 
	C183.339783,357.266479 183.448822,357.538300 183.412750,357.907227 
	C183.207230,357.838074 183.146790,357.671753 183.158539,357.250061 
z"/>
<path fill="#99C82A" opacity="1.000000" stroke="none" 
	d="
M270.589905,325.375122 
	C271.689484,324.650970 272.637970,324.308380 273.895935,323.891724 
	C275.410645,324.656464 276.615936,325.495270 277.896332,326.688232 
	C277.971436,327.042389 277.868408,327.174561 277.495239,327.113464 
	C275.900238,326.856445 274.678406,326.660522 273.031097,326.396362 
	C273.031097,330.831451 273.031097,334.892303 272.760895,338.871216 
	C271.822388,338.475647 271.154114,338.162048 270.485809,337.848419 
	C270.435364,335.526642 270.384918,333.204834 270.500397,330.339172 
	C270.795288,329.486481 270.731934,329.218018 270.476288,328.989929 
	C270.463806,327.912201 270.451294,326.834442 270.589905,325.375122 
z"/>
<path fill="#A4C55D" opacity="1.000000" stroke="none" 
	d="
M270.408630,338.253235 
	C271.154114,338.162048 271.822388,338.475647 273.217743,338.888977 
	C277.622314,338.981750 281.299866,338.974792 285.372925,338.985962 
	C286.823242,338.984467 287.878082,338.964874 288.932892,338.945312 
	C288.982788,339.129364 289.032715,339.313385 289.054016,339.782471 
	C284.214233,340.088837 279.399292,340.222260 274.594086,340.063080 
	C273.159302,340.015533 271.751587,339.149231 270.408630,338.253235 
z"/>
<path fill="#DAE5E9" opacity="1.000000" stroke="none" 
	d="
M278.723236,341.344330 
	C276.740295,341.745850 274.505096,341.920654 272.269928,342.095490 
	C272.243042,341.769531 272.216156,341.443604 272.189301,341.117645 
	C274.283203,341.117645 276.377106,341.117645 278.723236,341.344330 
z"/>
<path fill="#DAE5E9" opacity="1.000000" stroke="none" 
	d="
M286.504761,341.094299 
	C286.260651,341.444305 285.757019,341.755249 285.253387,342.066223 
	C285.152802,341.849091 285.052216,341.631958 284.951660,341.414825 
	C285.382843,341.294983 285.814056,341.175110 286.504761,341.094299 
z"/>
<path fill="#A4C55D" opacity="1.000000" stroke="none" 
	d="
M270.387115,329.246033 
	C270.731934,329.218018 270.795288,329.486481 270.566895,329.906311 
	C270.410950,329.845673 270.354462,329.673920 270.387115,329.246033 
z"/>
<path fill="#DAE4EA" opacity="1.000000" stroke="none" 
	d="
M155.389618,383.103119 
	C154.388367,381.507507 153.121796,379.986816 153.082718,378.435150 
	C152.615097,359.868011 152.327042,341.296387 152.002167,322.725708 
	C151.978943,321.397644 151.534515,319.732971 152.154861,318.806976 
	C153.583496,316.674561 155.547577,314.900848 158.102478,312.965424 
	C160.038834,312.857178 161.167084,312.765076 162.470215,312.753418 
	C162.645096,312.833832 162.994019,312.998840 163.085266,313.235382 
	C163.460663,313.637238 163.744827,313.802551 164.016571,313.984467 
	C164.004166,314.001068 164.036804,313.971832 164.064026,314.389526 
	C164.646637,319.513367 165.202011,324.219513 165.804230,329.384949 
	C165.978622,333.192230 166.106171,336.540253 166.233704,339.888245 
	C165.827225,340.907593 165.108704,341.913116 165.062866,342.948456 
	C164.637650,352.552429 164.396286,362.164734 163.949265,371.767517 
	C163.788376,375.223389 163.213699,378.660004 162.781891,382.515991 
	C161.295807,382.729218 159.871017,382.933655 157.990997,383.239838 
	C156.922028,383.282257 156.308304,383.222931 155.389618,383.103119 
z"/>
<path fill="#98CA1E" opacity="1.000000" stroke="none" 
	d="
M166.682953,339.876740 
	C166.106171,336.540253 165.978622,333.192230 165.935913,328.945465 
	C166.059341,323.705078 166.097931,319.363464 166.438019,315.052368 
	C167.141800,315.084076 167.544098,315.085236 168.094406,315.390961 
	C172.105759,319.022064 175.922638,322.405762 179.877472,325.619720 
	C180.548462,326.165009 181.812164,325.981018 182.802032,326.133972 
	C182.939697,327.472717 183.077362,328.811493 183.164215,330.944031 
	C183.202042,332.846191 183.290710,333.954559 183.379379,335.062958 
	C183.329514,335.480652 183.279648,335.898376 183.170013,336.966980 
	C183.170547,338.093567 183.230850,338.569214 183.291153,339.044861 
	C183.291153,339.044861 183.077087,339.434143 183.016418,339.647095 
	C177.681213,339.861786 172.406708,339.863495 166.682953,339.876740 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M181.451141,341.525940 
	C181.417679,341.470642 181.484604,341.581207 181.451141,341.525940 
z"/>
<path fill="#C3D7C7" opacity="1.000000" stroke="none" 
	d="
M234.347839,326.087830 
	C234.793518,329.578766 234.613541,333.222290 235.092773,336.776978 
	C235.252045,337.958374 237.046982,339.745178 238.191238,339.822723 
	C242.768524,340.132904 247.383987,339.879822 252.446671,339.802368 
	C253.111145,344.681549 253.314270,349.593536 253.241608,354.827667 
	C248.492447,355.109497 244.015015,355.172211 239.547058,354.995422 
	C236.112213,354.859528 234.854492,356.305176 234.901535,359.707825 
	C235.022934,368.488953 234.906662,377.273346 234.926971,386.522858 
	C233.512405,387.027893 232.056183,387.066528 230.298584,387.028564 
	C230.667496,383.750397 231.707977,380.575562 231.941269,377.342468 
	C232.761917,365.969574 233.447250,354.582031 233.878326,343.188477 
	C234.033249,339.093658 233.205246,334.969940 233.067749,330.851379 
	C233.016464,329.314697 233.655197,327.755005 234.347839,326.087830 
z"/>
<path fill="#99C82A" opacity="1.000000" stroke="none" 
	d="
M289.009521,338.624451 
	C287.878082,338.964874 286.823242,338.984467 285.377258,338.643036 
	C284.250854,337.004608 283.542755,335.710571 282.774384,334.453430 
	C281.478516,332.333160 280.144501,330.236298 278.896088,328.075439 
	C278.965668,328.021301 279.099854,327.906952 279.099854,327.906952 
	C280.337219,329.091919 281.574615,330.276855 283.166565,332.045471 
	C286.714264,332.369232 288.114288,334.381500 289.045044,336.988373 
	C289.058746,337.426758 289.072479,337.865173 289.009521,338.624451 
z"/>
<path fill="#A4C55D" opacity="1.000000" stroke="none" 
	d="
M289.135864,336.541534 
	C288.114288,334.381500 286.714264,332.369232 283.314148,332.334717 
	C284.835785,331.593262 286.564392,331.146210 288.675171,330.608337 
	C289.113800,332.376587 289.170258,334.235626 289.135864,336.541534 
z"/>
<path fill="#97CB1A" opacity="1.000000" stroke="none" 
	d="
M216.767487,339.904816 
	C211.449646,339.701080 206.131790,339.497345 200.432007,339.133911 
	C200.056732,336.581268 200.063416,334.188263 200.094177,330.925049 
	C200.094833,327.385437 200.071396,324.716064 200.047974,322.046692 
	C200.047974,322.046692 200.221024,321.699646 200.636841,321.418365 
	C202.846375,321.083191 204.643417,320.931305 206.433258,320.991943 
	C215.347351,321.293915 216.600906,322.487854 217.156479,331.014130 
	C217.106583,333.370575 217.056686,335.726990 216.947144,338.748962 
	C216.847488,339.577942 216.807480,339.741394 216.767487,339.904816 
z"/>
<path fill="#F2F0CD" opacity="1.000000" stroke="none" 
	d="
M217.527466,330.912994 
	C216.600906,322.487854 215.347351,321.293915 206.433258,320.991943 
	C204.643417,320.931305 202.846375,321.083191 200.617493,321.225433 
	C200.113876,319.557251 200.045395,317.800751 200.425369,315.560364 
	C203.914368,315.003510 206.954895,314.930542 210.373505,314.875580 
	C211.509308,314.889313 212.267014,314.885010 213.024719,314.880737 
	C214.706955,315.208069 216.389191,315.535431 218.436768,315.840363 
	C218.500885,320.815918 218.199661,325.813904 217.527466,330.912994 
z"/>
<path fill="#F2F0CD" opacity="1.000000" stroke="none" 
	d="
M219.188049,315.862885 
	C220.001038,311.091797 223.811752,311.951141 227.598633,311.882935 
	C225.879562,313.120056 223.731018,314.326111 221.308380,315.740662 
	C220.540741,315.941772 220.047180,315.934387 219.188049,315.862885 
z"/>
<path fill="#D8EACF" opacity="1.000000" stroke="none" 
	d="
M217.056442,339.943054 
	C216.807480,339.741394 216.847488,339.577942 216.999023,339.187744 
	C217.420624,339.083710 217.654327,339.292053 217.867950,339.818420 
	C217.731262,340.027679 217.538330,340.004517 217.056442,339.943054 
z"/>
<path fill="#95CE0C" opacity="1.000000" stroke="none" 
	d="
M314.101379,387.520203 
	C313.293488,388.257477 312.456360,388.527832 311.324890,388.955566 
	C310.014038,387.984741 308.153534,386.883392 308.116608,385.723938 
	C307.858154,377.611206 307.807770,369.479767 308.126984,361.371521 
	C308.185791,359.876831 310.229248,358.460297 311.356628,357.007660 
	C312.252747,358.404816 313.883270,359.780548 313.924652,361.202606 
	C314.175323,369.813599 314.063141,378.435211 314.101379,387.520203 
z"/>
<path fill="#D9EFBB" opacity="1.000000" stroke="none" 
	d="
M316.242859,365.426819 
	C316.506500,368.109283 316.653229,371.234528 316.582642,374.683380 
	C316.285522,371.961182 316.205780,368.915375 316.242859,365.426819 
z"/>
<path fill="#D9EFBB" opacity="1.000000" stroke="none" 
	d="
M316.220337,357.370880 
	C316.473114,358.160461 316.612183,359.345642 316.553925,360.774780 
	C316.273285,359.934631 316.189972,358.850555 316.220337,357.370880 
z"/>
<path fill="#D9EFBB" opacity="1.000000" stroke="none" 
	d="
M316.549011,387.045044 
	C316.744232,386.809235 316.894104,386.851776 316.940826,386.914154 
	C317.050323,387.323212 316.926849,387.580292 316.399170,387.807983 
	C316.238708,387.672394 316.296265,387.476593 316.549011,387.045044 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M40.933765,392.645996 
	C36.054317,392.012329 34.689766,388.501587 37.688919,383.182983 
	C37.842461,386.451874 37.578308,389.918854 40.933765,392.645996 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M38.272423,382.929352 
	C38.492123,382.071320 38.968189,381.122406 39.759838,380.471924 
	C40.004978,381.151733 39.934540,381.533173 39.864098,381.914612 
	C39.418995,382.222565 38.973896,382.530518 38.272423,382.929352 
z"/>
<path fill="#439320" opacity="1.000000" stroke="none" 
	d="
M40.122345,381.814941 
	C39.934540,381.533173 40.004978,381.151733 40.116497,380.483459 
	C40.157574,380.196655 40.131721,380.138153 40.343422,380.165619 
	C40.555122,380.193085 41.043400,380.248413 41.043400,380.248413 
	C40.822464,380.737366 40.601524,381.226318 40.122345,381.814941 
z"/>
<path fill="#B3D52E" opacity="1.000000" stroke="none" 
	d="
M41.092201,379.957336 
	C41.043400,380.248413 40.555122,380.193085 40.312027,380.159546 
	C40.255333,379.831482 40.441730,379.536957 40.884335,379.159180 
	C41.140694,379.272675 41.140846,379.469452 41.092201,379.957336 
z"/>
<path fill="#B974BC" opacity="1.000000" stroke="none" 
	d="
M170.981979,180.945297 
	C172.503036,179.985245 174.024094,179.025177 176.166748,178.047760 
	C178.554886,180.611435 180.321411,183.192459 182.027435,186.189957 
	C182.888626,188.081100 183.790253,190.776199 184.735931,190.791748 
	C187.156067,190.831543 189.595810,189.678864 192.027710,189.003098 
	C192.526733,189.277176 193.025772,189.551239 193.987869,190.178436 
	C195.308960,190.672211 196.166992,190.812851 197.025024,190.953491 
	C197.025024,190.953506 197.002960,190.999924 197.225677,191.264191 
	C198.374359,191.642822 199.300339,191.757156 200.226303,191.871506 
	C200.226303,191.871506 200.626343,191.846268 201.070679,192.191101 
	C203.211945,192.858810 204.908859,193.181671 206.777130,193.537140 
	C206.874039,195.179733 206.958633,196.613602 207.043228,198.047470 
	C207.043228,198.047470 207.052338,197.998917 206.742905,198.020599 
	C205.921814,198.370239 205.410156,198.698212 204.521042,199.045746 
	C199.596619,200.665100 201.563004,204.685822 200.539307,207.635223 
	C191.505524,205.990234 182.852875,204.446899 174.199860,202.497742 
	C174.690948,197.290558 176.939560,192.358414 173.072510,187.519226 
	C172.329590,185.089920 171.655777,183.017609 170.981979,180.945297 
z"/>
<path fill="#EBA03A" opacity="1.000000" stroke="none" 
	d="
M173.074905,216.921524 
	C175.774689,217.612595 178.450394,218.755722 181.179260,218.901138 
	C186.948715,219.208572 192.745972,218.994766 198.531815,218.994766 
	C198.603073,219.645660 198.674332,220.296555 198.745590,220.947449 
	C197.054672,221.297119 195.367386,221.926788 193.672195,221.948883 
	C185.533585,222.054947 177.384705,221.775894 169.255676,222.074753 
	C163.854340,222.273331 158.445435,223.002640 153.103592,223.881409 
	C147.437988,224.813416 145.851425,223.895126 146.615677,219.992996 
	C150.386505,219.992996 154.079163,220.395355 157.650040,219.907059 
	C164.534515,218.965652 166.222565,213.799789 161.341644,207.727295 
	C160.912888,206.898270 160.916794,206.458817 160.920715,206.019348 
	C163.475861,202.414551 161.828552,199.611435 159.070648,197.214096 
	C161.871964,192.457184 161.595444,181.983215 159.176193,169.666733 
	C161.238556,171.073822 163.300919,172.480927 165.711044,173.931366 
	C166.674881,175.078354 167.290939,176.182007 167.687225,177.810181 
	C167.374176,179.187241 167.280899,180.039764 167.133850,181.324768 
	C168.106430,190.198288 169.132812,198.639328 170.134644,207.481598 
	C170.095383,208.954987 170.080673,210.027145 169.885010,211.149719 
	C169.824295,211.433182 169.944580,211.666229 170.062805,212.295959 
	C170.671631,214.109329 171.282486,215.526047 172.188141,216.959930 
	C172.679428,216.964600 172.875900,216.952118 173.067505,216.950928 
	C173.062637,216.962234 173.074905,216.921524 173.074905,216.921524 
z"/>
<path fill="#D68B56" opacity="1.000000" stroke="none" 
	d="
M182.087936,185.773483 
	C180.321411,183.192459 178.554886,180.611435 176.478607,177.898560 
	C176.208191,177.066971 176.247498,176.367249 176.646484,175.323151 
	C177.708496,174.975845 178.410873,174.972900 179.113235,174.969971 
	C180.327057,178.368530 185.879379,179.956787 182.892807,185.314377 
	C182.508957,185.680893 182.087936,185.773483 182.087936,185.773483 
z"/>
<path fill="#D1CAB7" opacity="1.000000" stroke="none" 
	d="
M217.960068,193.665833 
	C214.984055,193.879593 211.216446,194.753189 212.904892,189.308136 
	C213.970306,188.464951 214.891891,187.896515 215.988113,187.046463 
	C221.533981,187.546219 218.172028,190.897720 217.960068,193.665833 
z"/>
<path fill="#F2B8B6" opacity="1.000000" stroke="none" 
	d="
M208.991852,188.992371 
	C208.182877,189.195969 207.177811,189.818008 206.619858,189.540665 
	C205.008072,188.739456 203.361435,187.765320 202.198578,186.445038 
	C201.826355,186.022415 202.921616,184.307266 203.345459,183.183502 
	C204.890137,183.955139 206.560608,184.557617 207.924713,185.572083 
	C208.575119,186.055771 208.643112,187.322617 208.979370,188.609207 
	C208.983856,188.983688 209.000000,189.000000 208.991852,188.992371 
z"/>
<path fill="#D89146" opacity="1.000000" stroke="none" 
	d="
M178.987885,174.617279 
	C178.410873,174.972900 177.708496,174.975845 176.661285,174.968231 
	C174.689636,172.593521 173.062851,170.229385 171.152588,167.453278 
	C173.362137,167.145584 174.907135,166.930450 176.428696,166.718567 
	C177.269196,169.324493 178.065872,171.794556 178.987885,174.617279 
z"/>
<path fill="#F2B8B6" opacity="1.000000" stroke="none" 
	d="
M180.964874,162.737762 
	C182.741760,162.638596 184.599213,162.322678 185.600845,163.103958 
	C186.366394,163.701080 186.454605,165.831375 186.039917,166.992477 
	C185.836716,167.561417 183.120193,168.045349 182.732727,167.578583 
	C181.701981,166.336960 181.318283,164.558197 180.964874,162.737762 
z"/>
<path fill="#FADFF1" opacity="1.000000" stroke="none" 
	d="
M182.027435,186.189957 
	C182.087936,185.773483 182.508957,185.680893 182.715851,185.617508 
	C184.319321,188.237213 185.859406,190.365997 189.245895,187.873383 
	C189.602066,187.611221 190.716507,188.379242 191.753418,188.836212 
	C189.595810,189.678864 187.156067,190.831543 184.735931,190.791748 
	C183.790253,190.776199 182.888626,188.081100 182.027435,186.189957 
z"/>
<path fill="#F8CC8A" opacity="1.000000" stroke="none" 
	d="
M167.187622,180.892273 
	C167.280899,180.039764 167.374176,179.187241 167.732605,178.141785 
	C168.813370,178.824158 169.628983,179.699493 170.713287,180.760071 
	C171.655777,183.017609 172.329590,185.089920 173.002213,187.582092 
	C173.001038,188.001938 172.863571,188.127853 172.533600,188.030182 
	C170.531616,185.585770 168.859619,183.239029 167.187622,180.892273 
z"/>
<path fill="#B980A1" opacity="1.000000" stroke="none" 
	d="
M207.301834,197.831024 
	C206.958633,196.613602 206.874039,195.179733 206.777130,193.537140 
	C204.908859,193.181671 203.211945,192.858810 201.266663,192.233002 
	C203.447037,191.865524 205.875763,191.801010 208.647156,191.813568 
	C208.513367,193.798599 208.036911,195.706589 207.301834,197.831024 
z"/>
<path fill="#B980A1" opacity="1.000000" stroke="none" 
	d="
M196.812286,190.703842 
	C196.166992,190.812851 195.308960,190.672211 194.261749,190.261581 
	C194.914902,190.145798 195.757233,190.300003 196.812286,190.703842 
z"/>
<path fill="#B980A1" opacity="1.000000" stroke="none" 
	d="
M199.972748,191.637238 
	C199.300339,191.757156 198.374359,191.642822 197.240753,191.242615 
	C197.928467,191.105484 198.823822,191.254211 199.972748,191.637238 
z"/>
<path fill="#F8D7C0" opacity="1.000000" stroke="none" 
	d="
M168.075287,270.958160 
	C168.080139,270.538605 168.084976,270.119019 168.545166,269.374664 
	C170.273621,268.609680 171.979523,268.503174 172.666977,267.611481 
	C173.121750,267.021576 172.115265,265.305084 171.740143,264.014343 
	C169.727859,264.874298 168.416245,265.434845 167.104614,265.995361 
	C167.826263,261.761810 168.547913,257.528259 169.375061,252.675858 
	C171.672165,254.372757 172.889587,255.272079 174.727448,256.629730 
	C174.727448,253.664612 174.727448,251.458862 174.727448,248.926331 
	C176.836975,251.671295 178.671692,254.058655 181.247330,257.410126 
	C181.901993,253.538132 182.294678,251.215607 182.830658,248.045517 
	C183.999130,249.483826 184.639420,250.271957 185.616089,251.560944 
	C188.047913,253.715836 190.143356,255.369888 192.238815,257.023926 
	C192.238815,257.023926 192.370117,257.132355 192.409363,257.363647 
	C193.715057,257.439575 194.981537,257.284210 196.248001,257.128876 
	C198.557404,258.170746 200.811081,259.368835 203.189514,260.217133 
	C206.590912,261.430267 208.233871,265.812012 206.088165,270.304108 
	C204.544876,273.534973 202.044952,276.308899 199.577515,279.628296 
	C197.804245,280.628448 196.425232,281.286926 194.718002,282.069092 
	C192.227097,282.777771 190.064392,283.362732 187.766296,283.699280 
	C187.086182,283.326996 186.541473,283.203094 185.994843,283.043610 
	C185.992905,283.008026 185.922989,283.023102 185.869751,282.655426 
	C183.915359,281.868591 182.014221,281.449432 180.035004,280.677063 
	C175.996384,277.201965 172.035828,274.080078 168.075287,270.958160 
z"/>
<path fill="#DE1474" opacity="1.000000" stroke="none" 
	d="
M207.443481,237.189514 
	C208.120346,237.702225 208.463287,238.112396 209.057892,239.075562 
	C211.998657,243.539963 213.957413,247.608704 212.384262,252.859009 
	C211.073486,251.325104 210.065460,250.145477 209.057419,248.965851 
	C209.057419,248.965851 209.002380,248.974640 209.003174,248.643738 
	C208.314529,247.593353 207.625061,246.873856 206.935593,246.154373 
	C206.935593,246.154373 206.605515,245.949966 206.300507,245.665756 
	C205.708008,245.220306 205.420517,245.059067 205.133041,244.897842 
	C205.133041,244.897842 205.104019,244.862930 205.062988,244.520203 
	C204.002914,243.477600 202.983871,242.777740 201.964828,242.077881 
	C201.964828,242.077881 201.993118,242.017609 201.903290,241.786758 
	C201.608368,241.257950 201.327728,241.140579 200.971527,241.203812 
	C200.604019,241.088989 200.236511,240.974167 199.442810,240.488770 
	C197.009598,239.414764 195.002579,238.711349 192.995560,238.007935 
	C192.203018,237.745453 191.410461,237.482971 189.877472,237.124207 
	C187.096436,237.093887 185.055847,237.159866 183.015259,237.225845 
	C181.692734,237.283646 180.370224,237.341446 178.378052,237.187729 
	C177.503220,236.039413 177.298019,235.102631 177.092819,234.165833 
	C177.092819,234.165833 177.107895,234.103882 177.487595,234.091721 
	C178.908951,233.713470 179.950623,233.347366 180.992294,232.981293 
	C190.462219,229.488754 199.038300,231.682068 207.443481,237.189514 
z"/>
<path fill="#B1D3DC" opacity="1.000000" stroke="none" 
	d="
M158.985825,266.599762 
	C159.375168,271.816254 159.514832,276.777649 160.043503,281.697205 
	C160.643616,287.281555 156.123123,285.931458 153.404770,286.684662 
	C150.400436,287.517059 149.901291,285.306000 149.784576,282.881866 
	C149.633789,279.750366 149.143723,276.631622 149.066986,273.500824 
	C148.867737,265.373627 149.125519,265.180634 158.154480,265.184753 
	C158.667725,265.607330 158.787827,265.966766 158.985825,266.599762 
z"/>
<path fill="#A8D8F4" opacity="1.000000" stroke="none" 
	d="
M216.810532,268.708405 
	C216.810532,268.708405 216.848770,268.282715 217.179214,267.973022 
	C217.558548,267.346252 217.607452,267.029205 217.656342,266.712158 
	C217.656342,266.712158 217.866272,266.692108 218.203430,266.777771 
	C221.939880,266.743713 224.115021,267.487640 224.142532,271.770813 
	C224.173325,276.564606 222.817154,279.417847 216.810532,279.524963 
	C216.810532,275.861359 216.810532,272.284882 216.810532,268.708405 
z"/>
<path fill="#C2CFC3" opacity="1.000000" stroke="none" 
	d="
M216.549988,268.951233 
	C216.810532,272.284882 216.810532,275.861359 216.810532,279.524963 
	C222.817154,279.417847 224.173325,276.564606 224.142532,271.770813 
	C224.115021,267.487640 221.939880,266.743713 218.099121,266.762360 
	C217.742249,266.249878 217.826843,265.838470 218.029358,265.128876 
	C225.787094,265.348724 226.890930,266.731628 225.686295,274.236176 
	C225.427887,275.845947 225.217712,277.463501 224.568207,279.518036 
	C221.079437,280.930420 218.007782,281.902222 214.953903,282.428772 
	C214.912476,279.307190 214.853256,276.630920 214.794037,273.954620 
	C215.292511,272.367767 215.790970,270.780884 216.549988,268.951233 
z"/>
<path fill="#E7B380" opacity="1.000000" stroke="none" 
	d="
M214.546936,274.193787 
	C214.853256,276.630920 214.912476,279.307190 214.587646,282.475098 
	C212.526169,283.324646 210.848724,283.682556 208.722351,284.007599 
	C205.175491,283.694763 202.077591,283.414795 198.611176,283.099304 
	C197.504440,283.097412 196.766174,283.131012 195.820786,282.998230 
	C195.424515,282.536346 195.235367,282.240875 195.046219,281.945404 
	C196.425232,281.286926 197.804245,280.628448 199.515472,279.979431 
	C202.956161,281.696442 206.866745,278.847168 209.861328,281.689697 
	C209.990356,281.812164 211.714462,280.764923 212.132690,279.967407 
	C213.047287,278.223358 213.599564,276.289337 214.546936,274.193787 
z"/>
<path fill="#E9A03D" opacity="1.000000" stroke="none" 
	d="
M220.893982,256.917236 
	C221.207504,256.913361 221.544113,256.986176 221.859039,256.932281 
	C228.031113,255.876297 229.364090,257.446594 228.256882,264.829468 
	C225.437897,264.212311 222.633774,263.598419 219.427490,262.978333 
	C219.164017,262.176208 219.302704,261.380280 219.923752,260.129578 
	C220.580750,258.751495 220.755371,257.828156 220.930008,256.904846 
	C220.930008,256.904846 220.906982,256.934967 220.893982,256.917236 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M209.028809,249.355927 
	C210.065460,250.145477 211.073486,251.325104 212.384262,252.859009 
	C213.957413,247.608704 211.998657,243.539963 209.218658,239.289856 
	C210.154114,238.976730 211.180496,239.002319 212.593002,239.024048 
	C213.532120,242.479950 214.258423,245.923492 214.583893,249.404526 
	C214.850037,252.251129 214.638062,255.142456 214.638062,258.013947 
	C214.018784,258.182343 213.399506,258.350739 212.780212,258.519135 
	C211.520203,255.594772 210.260193,252.670380 209.028809,249.355927 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M176.710617,234.121216 
	C177.298019,235.102631 177.503220,236.039413 177.985321,237.245804 
	C176.334305,238.863724 174.406403,240.212051 172.478500,241.560379 
	C172.165543,241.184418 171.852600,240.808441 171.539642,240.432465 
	C172.371857,238.537125 173.204071,236.641785 174.033661,234.390137 
	C174.796829,234.048065 175.562622,234.062317 176.710617,234.121216 
z"/>
<path fill="#6E4118" opacity="1.000000" stroke="none" 
	d="
M192.386597,256.640503 
	C190.143356,255.369888 188.047913,253.715836 185.896698,251.729706 
	C186.007324,250.139496 186.173706,248.881363 186.523636,246.235260 
	C188.947708,250.276978 190.741043,253.267044 192.386597,256.640503 
z"/>
<path fill="#F7B5AE" opacity="1.000000" stroke="none" 
	d="
M167.005890,266.317963 
	C168.416245,265.434845 169.727859,264.874298 171.740143,264.014343 
	C172.115265,265.305084 173.121750,267.021576 172.666977,267.611481 
	C171.979523,268.503174 170.273621,268.609680 168.612335,269.066833 
	C167.940826,268.766327 167.657486,268.448853 167.060150,268.056396 
	C166.799850,267.534485 166.853500,267.087524 167.005890,266.317963 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M193.150879,238.324600 
	C195.002579,238.711349 197.009598,239.414764 199.157791,240.441223 
	C197.301361,240.056595 195.303772,239.348923 193.150879,238.324600 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M183.300659,237.452682 
	C185.055847,237.159866 187.096436,237.093887 189.562012,237.139130 
	C187.853348,237.393417 185.719696,237.536469 183.300659,237.452682 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M201.998047,242.417877 
	C202.983871,242.777740 204.002914,243.477600 205.076096,244.501648 
	C204.097244,244.136520 203.064270,243.447189 201.998047,242.417877 
z"/>
<path fill="#6E4118" opacity="1.000000" stroke="none" 
	d="
M195.922211,257.003052 
	C194.981537,257.284210 193.715057,257.439575 192.327698,257.339539 
	C193.336670,257.015167 194.466553,256.946198 195.922211,257.003052 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M206.947815,246.496597 
	C207.625061,246.873856 208.314529,247.593353 209.026886,248.628082 
	C208.353195,248.241837 207.656616,247.540329 206.947815,246.496597 
z"/>
<path fill="#DFA453" opacity="1.000000" stroke="none" 
	d="
M220.618652,257.089661 
	C220.755371,257.828156 220.580750,258.751495 220.103394,259.879150 
	C219.969543,259.147156 220.138428,258.210785 220.618652,257.089661 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M201.079651,241.393158 
	C201.327728,241.140579 201.608368,241.257950 201.870087,241.789185 
	C201.628159,241.963501 201.381851,241.816849 201.079651,241.393158 
z"/>
<path fill="#82262F" opacity="1.000000" stroke="none" 
	d="
M205.212891,245.153931 
	C205.420517,245.059067 205.708008,245.220306 206.116272,245.604553 
	C205.922287,245.688385 205.607498,245.549210 205.212891,245.153931 
z"/>
<path fill="#C2CFC3" opacity="1.000000" stroke="none" 
	d="
M217.449402,266.845520 
	C217.607452,267.029205 217.558548,267.346252 217.294312,267.792938 
	C216.988922,267.582977 217.043427,267.268402 217.449402,266.845520 
z"/>
<path fill="#BA74B5" opacity="1.000000" stroke="none" 
	d="
M203.975311,215.741821 
	C202.289154,216.155212 200.607101,216.904587 198.916168,216.925156 
	C190.610977,217.026215 182.303406,216.933563 173.535797,216.915436 
	C173.074905,216.921524 173.062637,216.962234 173.058090,216.553726 
	C172.957748,215.086288 172.861938,214.027374 172.824814,212.560623 
	C173.257828,209.824600 173.632156,207.496399 174.404541,205.134369 
	C183.444977,206.730377 192.087372,208.360229 201.031036,210.152802 
	C202.213318,212.124283 203.094315,213.933044 203.975311,215.741821 
z"/>
<path fill="#B2D5DE" opacity="1.000000" stroke="none" 
	d="
M158.917145,188.981018 
	C159.751801,195.017288 155.389053,194.381210 152.213852,194.787109 
	C148.213394,195.298538 148.263763,192.218842 148.174942,189.531555 
	C148.049316,185.731537 148.203003,181.913040 147.893417,178.130615 
	C147.484741,173.137482 151.313980,173.634338 154.157288,173.193909 
	C157.497025,172.676590 157.715790,175.313431 157.930420,177.705872 
	C158.254318,181.316467 158.580551,184.926865 158.917145,188.981018 
z"/>
<path fill="#B3D4DE" opacity="1.000000" stroke="none" 
	d="
M160.648346,205.742996 
	C160.916794,206.458817 160.912888,206.898270 160.975342,207.661194 
	C160.988144,208.973358 160.877167,209.962830 160.890472,210.950623 
	C160.968384,216.740295 158.315964,219.323441 152.608017,218.611923 
	C151.662079,218.494019 150.311203,217.161957 150.128876,216.204163 
	C149.544678,213.135056 149.570602,209.954742 149.084122,206.860886 
	C148.395554,202.481934 150.326309,200.792908 154.508667,200.075287 
	C159.263718,199.259384 160.235336,201.702484 160.648346,205.742996 
z"/>
<path fill="#B1D6E3" opacity="1.000000" stroke="none" 
	d="
M212.267960,201.459839 
	C213.963654,201.809357 215.621597,202.616043 217.658051,204.001740 
	C219.347137,206.101471 220.657715,207.622177 221.968307,209.142868 
	C221.764557,211.936661 222.570175,216.049515 221.093353,217.179611 
	C219.371841,218.496948 215.654785,217.206451 212.343506,216.749573 
	C211.750290,216.241714 211.567352,216.093262 211.334534,216.006256 
	C211.633087,211.309845 211.931656,206.613419 212.267960,201.459839 
z"/>
<path fill="#FED563" opacity="1.000000" stroke="none" 
	d="
M206.898392,201.009644 
	C206.920685,205.225906 207.032425,209.445007 206.898224,213.656281 
	C206.867264,214.628296 205.994278,215.573486 205.262817,215.875732 
	C204.492828,213.704483 204.013657,212.171219 203.436508,210.675766 
	C201.608444,205.939255 203.012909,201.975494 206.898392,201.009644 
z"/>
<path fill="#EEC290" opacity="1.000000" stroke="none" 
	d="
M206.935944,200.734680 
	C203.012909,201.975494 201.608444,205.939255 203.436508,210.675766 
	C204.013657,212.171219 204.492828,213.704483 205.017654,215.631805 
	C204.841385,216.005646 204.664322,215.968109 204.231277,215.836197 
	C203.094315,213.933044 202.213318,212.124283 201.104187,209.779236 
	C200.890854,208.740921 200.905655,208.238892 200.920441,207.736847 
	C201.563004,204.685822 199.596619,200.665100 204.713226,199.284454 
	C205.865753,199.640778 206.448593,199.777939 207.031448,199.915085 
	C207.031448,199.915085 207.035202,199.935333 207.038559,199.922119 
	C207.019119,200.092514 206.996323,200.276108 206.935944,200.734680 
z"/>
<path fill="#C79353" opacity="1.000000" stroke="none" 
	d="
M221.962799,208.805908 
	C220.657715,207.622177 219.347137,206.101471 217.868347,204.275864 
	C218.919556,200.714600 220.771530,201.674271 222.861160,203.544281 
	C222.814102,204.165741 222.617188,204.462006 222.179596,204.884705 
	C221.945068,206.163742 221.951187,207.316360 221.962799,208.805908 
z"/>
<path fill="#F3D7B7" opacity="1.000000" stroke="none" 
	d="
M211.326569,216.216095 
	C211.567352,216.093262 211.750290,216.241714 211.955124,216.615204 
	C211.706375,216.830231 211.470276,216.712616 211.326569,216.216095 
z"/>
<path fill="#D7D5C3" opacity="1.000000" stroke="none" 
	d="
M275.127838,267.967682 
	C270.634552,270.983734 275.840607,272.776672 276.049988,275.970093 
	C276.374542,278.508606 276.689056,280.278015 277.003540,282.047424 
	C277.006317,282.475128 277.009064,282.902832 276.781982,283.821045 
	C276.367035,284.875122 276.181946,285.438690 275.996826,286.002228 
	C275.996826,286.002228 276.000000,286.000000 275.575134,285.980286 
	C272.434143,286.304108 269.717957,286.647614 267.001801,286.991089 
	C267.001801,286.991119 267.000000,287.000214 266.903992,286.772156 
	C266.608673,286.260284 266.341705,286.072845 266.007080,285.981750 
	C266.007080,285.981750 266.000000,286.001343 266.021118,285.534424 
	C266.019379,281.057983 265.996521,277.048492 266.274841,273.319336 
	C266.715729,276.075317 267.069275,278.562073 266.940887,281.023712 
	C266.782074,284.069672 267.918274,285.115967 270.968689,285.081818 
	C273.898804,285.049011 275.688538,284.179291 274.931732,281.115784 
	C274.357544,278.791382 272.995392,276.670563 272.100952,274.412750 
	C271.609680,273.172668 271.068390,271.834747 271.076477,270.544250 
	C271.086609,268.932129 271.638733,267.323395 272.194977,265.507812 
	C273.261414,265.245880 274.089691,265.189331 274.917999,265.132751 
	C274.917999,265.132751 274.991547,265.535309 275.001465,266.071472 
	C275.050201,267.060974 275.089020,267.514313 275.127838,267.967682 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M274.887665,264.749847 
	C274.089691,265.189331 273.261414,265.245880 271.766846,265.554382 
	C264.961517,265.076904 265.710114,269.229858 265.973694,273.039001 
	C265.996521,277.048492 266.019379,281.057983 266.030609,285.529510 
	C263.571716,283.681671 262.390411,266.126648 265.036133,265.078674 
	C267.947113,263.925629 271.553772,264.528870 274.887665,264.749847 
z"/>
<path fill="#D8A863" opacity="1.000000" stroke="none" 
	d="
M276.887756,291.698700 
	C273.899414,292.404663 270.877075,292.722198 267.447876,293.044586 
	C269.801361,289.588928 273.078156,289.042297 276.887756,291.698700 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M267.059082,287.351440 
	C269.717957,286.647614 272.434143,286.304108 275.576355,285.979065 
	C273.517120,289.039978 270.446564,289.048950 267.059082,287.351440 
z"/>
<path fill="#FCD8AC" opacity="1.000000" stroke="none" 
	d="
M280.842224,271.687469 
	C279.357574,270.868011 277.706879,269.747162 276.032593,267.961304 
	C276.034546,266.867920 276.060089,266.439575 276.085602,266.011230 
	C277.615784,267.802856 279.145966,269.594482 280.842224,271.687469 
z"/>
<path fill="#FCD7A4" opacity="1.000000" stroke="none" 
	d="
M292.239807,284.879089 
	C290.287231,284.380615 288.541473,283.674225 286.435822,282.947021 
	C286.075958,282.926208 285.972565,282.467682 285.950562,281.997864 
	C285.928558,281.528046 285.892212,281.043243 285.892212,281.043243 
	C287.979401,281.664612 290.066620,282.285980 292.513916,282.961731 
	C292.731537,283.567841 292.589081,284.119507 292.239807,284.879089 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M277.266235,281.831482 
	C276.689056,280.278015 276.374542,278.508606 276.085815,276.326111 
	C276.519348,275.960510 276.927063,276.007965 277.765991,276.302979 
	C278.483185,276.970428 278.769226,277.390381 279.055237,277.810303 
	C278.546478,279.078705 278.037689,280.347107 277.266235,281.831482 
z"/>
<path fill="#FCD7A4" opacity="1.000000" stroke="none" 
	d="
M279.364990,277.882263 
	C278.769226,277.390381 278.483185,276.970428 278.073822,276.325989 
	C278.942291,276.552490 279.934113,277.003479 280.929382,277.794434 
	C280.513489,278.074310 280.094116,278.014282 279.364990,277.882263 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M281.111389,279.239441 
	C281.479980,278.913025 281.875519,278.957031 282.704498,279.237244 
	C283.430817,279.629517 283.723694,279.785553 284.016541,279.941589 
	C284.016541,279.941589 284.133301,280.378479 284.189453,280.596558 
	C283.209869,280.413055 282.174103,280.011444 281.111389,279.239441 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M276.293091,285.985992 
	C276.181946,285.438690 276.367035,284.875122 276.803528,284.141174 
	C276.899689,284.637085 276.744507,285.303406 276.293091,285.985992 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M275.841553,265.980103 
	C276.060089,266.439575 276.034546,266.867920 275.997894,267.617065 
	C275.986755,267.937866 275.557343,267.980042 275.342590,267.973877 
	C275.089020,267.514313 275.050201,267.060974 275.058594,266.267914 
	C275.105865,265.928223 275.597504,265.948944 275.841553,265.980103 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M266.092682,286.220551 
	C266.341705,286.072845 266.608673,286.260284 266.908630,286.771484 
	C266.732300,286.819031 266.455292,286.639191 266.092682,286.220551 
z"/>
<path fill="#D2A965" opacity="1.000000" stroke="none" 
	d="
M285.669617,281.034424 
	C285.892212,281.043243 285.928558,281.528046 285.896851,281.769073 
	C285.578552,281.902344 285.350006,281.721375 285.091187,281.264069 
	C285.002930,281.061005 285.447052,281.025635 285.669617,281.034424 
z"/>
<path fill="#B5D5DF" opacity="1.000000" stroke="none" 
	d="
M151.193512,308.935486 
	C151.067245,305.642670 150.816956,302.348145 150.861603,299.057648 
	C150.888016,297.110199 151.071609,293.619049 151.716171,293.490631 
	C154.668823,292.902374 157.809219,293.256409 160.947601,294.012085 
	C159.266678,298.288513 156.781754,301.740723 160.838608,305.492920 
	C161.423569,306.033936 160.942368,307.727661 160.744461,308.966003 
	C160.534348,309.044067 160.099503,309.152618 159.710297,309.136414 
	C156.611893,309.058655 153.902710,308.997070 151.193512,308.935486 
z"/>
<path fill="#F1619F" opacity="1.000000" stroke="none" 
	d="
M180.113068,281.030273 
	C182.014221,281.449432 183.915359,281.868591 185.497925,282.651672 
	C184.432343,283.021454 183.685349,283.027344 182.534027,283.018066 
	C178.143860,284.324829 180.585175,287.536926 180.043854,290.238037 
	C179.937805,291.055115 179.892227,291.523804 179.832932,292.227539 
	C179.819229,292.462616 179.897430,292.927032 179.915298,292.925476 
	C179.933167,292.923950 179.913986,292.951569 179.633423,293.099335 
	C178.582352,294.466400 177.811874,295.685669 176.755615,296.737885 
	C176.408234,291.894653 171.589966,294.737732 169.871399,292.136841 
	C168.564484,290.920227 167.385971,290.044647 166.207458,289.169067 
	C167.077591,288.010773 167.947723,286.852448 168.959290,285.393005 
	C169.100708,285.091888 169.118942,285.081848 169.490143,285.066101 
	C173.278580,283.710358 176.695816,282.370300 180.113068,281.030273 
z"/>
<path fill="#EAA03B" opacity="1.000000" stroke="none" 
	d="
M169.098038,285.103027 
	C167.677521,285.835876 165.939377,286.251862 164.902893,287.337646 
	C161.995651,290.383148 160.408783,290.804718 155.175018,288.791504 
	C167.502365,286.995636 159.276489,277.033234 163.052582,271.071289 
	C164.168610,271.300293 165.673706,271.609131 167.615875,272.393188 
	C168.408279,276.939514 168.763611,281.010681 169.118942,285.081848 
	C169.118942,285.081848 169.100708,285.091888 169.098038,285.103027 
z"/>
<path fill="#E7CFAB" opacity="1.000000" stroke="none" 
	d="
M166.005722,289.422424 
	C167.385971,290.044647 168.564484,290.920227 169.882507,292.423462 
	C169.999069,293.639038 169.976105,294.226929 169.615005,294.896240 
	C166.262955,297.103333 163.145203,298.957367 163.281570,303.539886 
	C163.333893,305.297943 161.777786,307.103851 160.954590,308.887939 
	C160.942368,307.727661 161.423569,306.033936 160.838608,305.492920 
	C156.781754,301.740723 159.266678,298.288513 161.060364,294.384155 
	C162.673172,292.569366 164.238571,291.122589 166.005722,289.422424 
z"/>
<path fill="#FDD373" opacity="1.000000" stroke="none" 
	d="
M169.490143,285.066101 
	C168.763611,281.010681 168.408279,276.939514 168.005798,272.457428 
	C167.973953,271.864746 167.989273,271.683014 168.039948,271.229736 
	C172.035828,274.080078 175.996384,277.201965 180.035004,280.677063 
	C176.695816,282.370300 173.278580,283.710358 169.490143,285.066101 
z"/>
<path fill="#EAA03B" opacity="1.000000" stroke="none" 
	d="
M148.070496,294.583801 
	C148.029022,296.754883 148.029022,298.530701 148.029022,300.306519 
	C147.513794,300.331635 146.998566,300.356750 146.483337,300.381866 
	C146.049164,294.480988 145.614990,288.580078 145.180817,282.679199 
	C145.569336,282.516449 145.957840,282.353729 146.346344,282.191010 
	C147.620224,284.784790 148.894089,287.378601 150.284866,290.210449 
	C149.663467,291.348083 148.887726,292.768341 148.070496,294.583801 
z"/>
<path fill="#E7CFAB" opacity="1.000000" stroke="none" 
	d="
M151.172211,309.256714 
	C153.902710,308.997070 156.611893,309.058655 159.732285,309.443542 
	C160.860764,310.735565 161.578049,311.704285 162.295319,312.673004 
	C161.167084,312.765076 160.038834,312.857178 158.486038,312.951935 
	C156.909058,312.642334 155.756638,312.330048 154.226974,311.956299 
	C152.950134,311.122528 152.050537,310.350220 151.172211,309.256714 
z"/>
<path fill="#F67F09" opacity="1.000000" stroke="none" 
	d="
M201.960999,150.139984 
	C196.856857,150.139984 191.752747,150.139984 186.648621,150.139984 
	C186.349945,149.536636 186.051254,148.933289 185.752579,148.329941 
	C188.606567,146.288605 191.460556,144.247284 195.067703,142.088348 
	C200.544174,141.313629 205.267487,140.656525 209.990799,139.999435 
	C210.426254,140.027725 210.861710,140.056000 211.618301,140.147934 
	C212.138641,140.657623 212.239700,141.403656 212.551468,141.505661 
	C219.834763,143.888596 220.405701,150.685013 222.759521,156.726227 
	C223.037338,156.962784 223.054871,156.977692 223.040115,157.316681 
	C223.357468,158.431534 223.689575,159.207397 224.019516,160.421249 
	C223.661896,164.239319 223.306427,167.619385 222.630371,171.047394 
	C222.216080,172.069290 222.122375,173.043274 222.028687,174.017242 
	C221.778854,175.455643 221.529022,176.894058 220.761353,178.705856 
	C218.838577,179.384552 217.433609,179.689835 216.028656,179.995117 
	C216.119293,177.091766 216.214218,174.302216 212.347549,173.477539 
	C211.472412,173.290878 210.736328,171.910095 210.162918,170.944382 
	C207.640213,166.695602 205.200546,162.397537 202.382904,157.670837 
	C203.554199,153.356934 205.073364,149.489548 206.592514,145.622162 
	C206.315460,145.431107 206.038406,145.240067 205.761353,145.049011 
	C204.494568,146.746002 203.227783,148.442993 201.960999,150.139984 
z"/>
<path fill="#F4800D" opacity="1.000000" stroke="none" 
	d="
M216.055359,180.351135 
	C217.433609,179.689835 218.838577,179.384552 220.603180,179.039673 
	C223.572983,181.825531 227.443665,183.720932 227.060196,188.607697 
	C226.724106,192.890518 225.202805,193.954025 220.118759,192.314621 
	C223.424454,186.881760 218.678314,184.205231 216.055359,180.351135 
z"/>
<path fill="#FEA343" opacity="1.000000" stroke="none" 
	d="
M222.950974,170.999466 
	C223.306427,167.619385 223.661896,164.239319 224.022217,160.085052 
	C223.703018,158.533127 223.378952,157.755417 223.054871,156.977692 
	C223.054871,156.977692 223.037338,156.962784 223.046799,156.947556 
	C223.242752,156.064346 223.429245,155.196350 223.655991,154.141006 
	C228.642624,158.444351 228.369324,168.759598 223.220840,173.833466 
	C222.990997,172.513580 222.970993,171.756531 222.950974,170.999466 
z"/>
<path fill="#F89B38" opacity="1.000000" stroke="none" 
	d="
M201.913101,150.518585 
	C203.227783,148.442993 204.494568,146.746002 205.761353,145.049011 
	C206.038406,145.240067 206.315460,145.431107 206.592514,145.622162 
	C205.073364,149.489548 203.554199,153.356934 202.027344,157.602570 
	C201.694427,158.770462 201.369232,159.560120 201.016266,160.669846 
	C200.964157,161.154190 200.995697,161.368317 200.903320,161.471878 
	C200.734360,161.661240 200.492126,161.785233 200.280457,161.936493 
	C200.808716,158.256714 201.336960,154.576950 201.913101,150.518585 
z"/>
<path fill="#F4800D" opacity="1.000000" stroke="none" 
	d="
M222.630371,171.047394 
	C222.970993,171.756531 222.990997,172.513580 223.000504,173.650238 
	C222.990005,174.029831 222.509109,174.017807 222.268890,174.017517 
	C222.122375,173.043274 222.216080,172.069290 222.630371,171.047394 
z"/>
<path fill="#B1D6E3" opacity="1.000000" stroke="none" 
	d="
M223.969315,293.769379 
	C223.790894,299.210022 223.446136,304.443512 223.058868,310.322144 
	C219.430237,309.766296 216.700165,309.348083 213.501129,308.445435 
	C213.380722,302.694702 213.729248,297.428436 214.077774,292.162170 
	C214.818619,292.100189 215.559448,292.038208 217.037201,292.010345 
	C219.521240,292.314423 221.268402,292.584381 223.015549,292.854340 
	C223.278015,293.090332 223.540497,293.326294 223.969315,293.769379 
z"/>
<path fill="#F5CBAE" opacity="1.000000" stroke="none" 
	d="
M209.171280,284.040466 
	C210.848724,283.682556 212.526169,283.324646 214.569870,282.920410 
	C218.007782,281.902222 221.079437,280.930420 224.520020,279.939117 
	C226.407120,285.014984 225.489517,286.089996 220.503571,286.450256 
	C218.172638,286.618683 215.923981,287.925934 212.958649,288.864227 
	C211.807617,289.022797 211.335968,289.034149 210.835663,288.700714 
	C210.261780,286.917419 209.716522,285.478943 209.171280,284.040466 
z"/>
<path fill="#DAA66C" opacity="1.000000" stroke="none" 
	d="
M210.864304,289.045502 
	C211.335968,289.034149 211.807617,289.022797 212.632828,289.017517 
	C213.264633,289.859985 213.542908,290.696411 213.949463,291.847473 
	C213.729248,297.428436 213.380722,302.694702 213.092468,308.408752 
	C213.152756,308.856506 212.992935,309.009064 212.549561,308.935120 
	C212.018066,305.205994 211.876373,301.551025 211.859085,297.895508 
	C211.845673,295.060486 213.235138,291.425690 208.029327,291.743622 
	C208.016617,291.524017 208.027832,291.083252 208.275513,290.896637 
	C209.303558,290.155182 210.083939,289.600342 210.864304,289.045502 
z"/>
<path fill="#DAA66C" opacity="1.000000" stroke="none" 
	d="
M222.932953,292.507629 
	C221.268402,292.584381 219.521240,292.314423 217.404205,291.951294 
	C218.972992,291.959076 220.911667,292.059998 222.932953,292.507629 
z"/>
<path fill="#B980A1" opacity="1.000000" stroke="none" 
	d="
M207.038422,199.603729 
	C206.448593,199.777939 205.865753,199.640778 205.090698,199.264908 
	C205.410156,198.698212 205.921814,198.370239 206.751450,198.044662 
	C207.061417,198.462158 207.053391,198.877258 207.038422,199.603729 
z"/>
<path fill="#E58B2D" opacity="1.000000" stroke="none" 
	d="
M209.617981,139.827377 
	C205.267487,140.656525 200.544174,141.313629 195.384125,141.936310 
	C199.198624,137.877304 204.215927,138.728287 209.617981,139.827377 
z"/>
<path fill="#E99D42" opacity="1.000000" stroke="none" 
	d="
M208.042053,291.963226 
	C213.235138,291.425690 211.845673,295.060486 211.859085,297.895508 
	C211.876373,301.551025 212.018066,305.205994 212.470444,309.012207 
	C214.275558,310.277344 215.716400,311.391418 217.682220,312.911407 
	C216.135544,313.459930 214.809677,313.930115 213.254272,314.640533 
	C212.267014,314.885010 211.509308,314.889313 210.084076,314.622009 
	C205.871490,313.690765 202.326447,313.031097 198.781387,312.371429 
	C198.853836,312.073120 198.926285,311.774841 198.998734,311.476532 
	C201.610138,311.000031 204.221542,310.523560 207.076385,309.874756 
	C207.269241,308.775421 207.218658,307.848358 207.444092,306.623169 
	C207.827408,301.537750 207.934723,296.750488 208.042053,291.963226 
z"/>
<path fill="#B72555" opacity="1.000000" stroke="none" 
	d="
M180.600891,232.976624 
	C179.950623,233.347366 178.908951,233.713470 177.519180,234.087433 
	C177.134949,233.931244 177.098846,233.767181 177.078018,233.352539 
	C178.132019,233.058624 179.170746,233.015305 180.600891,232.976624 
z"/>
<path fill="#CF6B6B" opacity="1.000000" stroke="none" 
	d="
M317.126404,277.077881 
	C314.533020,281.382874 310.785217,280.566193 307.454224,279.324127 
	C306.117737,278.825775 305.494629,276.414368 304.838593,275.347931 
	C308.877350,275.347931 312.177063,275.284760 315.469879,275.407593 
	C316.046112,275.429077 316.593201,276.232300 317.126404,277.077881 
z"/>
<path fill="#F2B8B6" opacity="1.000000" stroke="none" 
	d="
M327.954590,275.983276 
	C327.752197,276.002319 327.567352,275.979340 327.428009,276.048248 
	C325.068939,277.215027 323.043701,276.690582 322.355286,274.194794 
	C322.095520,273.253082 323.399231,270.918488 324.189178,270.820343 
	C327.129333,270.455048 328.163757,272.439545 327.985382,275.538574 
	C327.977875,275.908142 327.993286,275.984039 327.954590,275.983276 
z"/>
<path fill="#F2B8B6" opacity="1.000000" stroke="none" 
	d="
M294.498047,270.096405 
	C295.907898,270.769775 297.467224,271.326416 297.805695,272.305267 
	C298.056793,273.031464 296.938690,274.740021 296.024292,275.195526 
	C295.321686,275.545502 293.087219,274.616852 293.090302,274.270660 
	C293.102539,272.897766 293.726440,271.530304 294.498047,270.096405 
z"/>
<path fill="#FCD7A4" opacity="1.000000" stroke="none" 
	d="
M283.887299,279.722321 
	C283.723694,279.785553 283.430817,279.629517 283.002686,279.258209 
	C283.164276,279.196320 283.461182,279.349670 283.887299,279.722321 
z"/>
<path fill="#A6D8F4" opacity="1.000000" stroke="none" 
	d="
M339.992188,269.651733 
	C340.017975,273.874054 339.966156,277.664429 339.986694,281.454376 
	C340.004608,284.764435 337.882629,285.122498 335.324493,284.965942 
	C332.402985,284.787201 331.799591,283.345825 332.544678,280.626221 
	C333.529114,277.032837 333.905426,273.265076 334.993896,269.710266 
	C336.049438,266.263123 337.937225,265.792969 339.992188,269.651733 
z"/>
<path fill="#A36E0A" opacity="1.000000" stroke="none" 
	d="
M316.993774,241.004791 
	C316.867981,240.860870 316.748352,240.711990 316.628723,240.563110 
	C316.718109,240.611374 316.807495,240.659637 316.953491,240.847534 
	C317.010132,240.987183 317.000000,240.999817 316.993774,241.004791 
z"/>
<path fill="#D9EFBB" opacity="1.000000" stroke="none" 
	d="
M307.128601,340.414795 
	C310.024963,339.587799 312.980835,339.150757 316.132141,338.351318 
	C316.524048,339.093719 316.720581,340.198517 316.852905,341.652832 
	C313.588501,341.603119 310.388336,341.203918 307.128601,340.414795 
z"/>
<path fill="#D9EFBB" opacity="1.000000" stroke="none" 
	d="
M316.570679,329.819366 
	C316.242981,329.592010 316.103149,329.161896 315.936554,328.390747 
	C316.091309,328.032043 316.272827,328.014343 316.727936,327.991699 
	C316.920532,328.530029 316.839539,329.073334 316.570679,329.819366 
z"/>
<path fill="#F8DB6C" opacity="1.000000" stroke="none" 
	d="
M302.975708,192.324280 
	C297.702881,193.097977 292.315094,193.546555 286.492889,193.927216 
	C285.688324,188.859787 285.318176,183.860260 284.974915,178.397690 
	C286.794617,180.191422 288.587494,182.448227 290.690186,184.852524 
	C291.000000,185.000031 291.014130,184.992188 291.014130,184.992188 
	C293.036194,190.585526 299.131592,189.292099 302.975708,192.324280 
z"/>
<path fill="#DAB153" opacity="1.000000" stroke="none" 
	d="
M331.024872,191.346909 
	C326.697601,191.879837 322.356689,192.005966 317.563354,192.092331 
	C319.790100,190.343903 322.469238,188.635239 325.450928,186.680939 
	C326.864838,185.704895 327.976196,184.974503 329.087524,184.244110 
	C329.087524,184.244095 329.560242,184.135254 329.779877,184.031601 
	C330.336731,186.265320 330.673950,188.602707 331.024872,191.346909 
z"/>
<path fill="#F2B8B6" opacity="1.000000" stroke="none" 
	d="
M291.003906,184.992920 
	C291.007080,183.831406 290.577972,182.403336 291.101501,181.583740 
	C291.988281,180.195435 293.319305,178.734497 294.782654,178.230515 
	C295.452240,177.999893 297.920654,180.235184 297.754333,180.890457 
	C297.370544,182.402771 296.030823,183.816147 294.760651,184.895065 
	C294.184204,185.384735 292.851959,184.984619 291.438354,184.990173 
	C291.014130,184.992188 291.000000,185.000031 291.003906,184.992920 
z"/>
<path fill="#F7B5AE" opacity="1.000000" stroke="none" 
	d="
M329.062042,183.826523 
	C327.976196,184.974503 326.864838,185.704895 325.527069,186.330048 
	C322.334229,185.769165 321.443604,183.805145 322.236664,181.258255 
	C322.525757,180.329849 324.010864,179.211792 324.993011,179.169388 
	C327.667694,179.053925 329.279877,180.476669 329.062042,183.826523 
z"/>
<path fill="#A9D8F0" opacity="1.000000" stroke="none" 
	d="
M335.041687,176.982254 
	C338.588226,175.138657 340.382233,175.897888 340.107635,180.171753 
	C339.937683,182.816788 340.380219,185.543503 339.897217,188.111160 
	C339.577148,189.812637 338.105133,191.297409 337.148773,192.879181 
	C335.457031,191.131882 332.422028,189.425720 332.367004,187.628311 
	C332.267639,184.381912 333.949097,181.080994 334.925781,177.432220 
	C334.964691,177.061600 335.018402,177.010895 335.041687,176.982254 
z"/>
<path fill="#DCA55B" opacity="1.000000" stroke="none" 
	d="
M337.910767,172.488098 
	C339.148865,172.558319 340.506775,172.962967 341.894440,173.752838 
	C340.626343,173.699524 339.328430,173.261017 337.910767,172.488098 
z"/>
<path fill="#F4C076" opacity="1.000000" stroke="none" 
	d="
M324.583740,294.134888 
	C324.605469,297.566986 326.659454,302.354004 319.989441,303.122925 
	C319.621460,300.714539 319.621460,298.263306 319.621460,296.335388 
	C321.568390,295.530640 322.954895,294.957520 324.583740,294.134888 
z"/>
<path fill="#99C82A" opacity="1.000000" stroke="none" 
	d="
M278.025452,326.978333 
	C278.364685,327.084503 278.649902,327.254730 279.017517,327.665955 
	C279.099854,327.906952 278.965668,328.021301 278.715973,327.938171 
	C278.235840,327.655548 278.036560,327.428741 277.868408,327.174561 
	C277.868408,327.174561 277.971436,327.042389 278.025452,326.978333 
z"/>
<path fill="#A8D8F4" opacity="1.000000" stroke="none" 
	d="
M330.009247,294.465942 
	C331.333527,294.005768 332.666016,294.069244 333.979523,293.939636 
	C337.947601,293.548004 339.449524,295.397430 338.999634,299.208710 
	C338.883972,300.188538 339.060394,301.201752 338.957306,302.184387 
	C338.672119,304.902985 339.975250,308.752716 335.312073,308.800049 
	C330.962921,308.844269 329.834564,306.879883 330.170258,301.227997 
	C329.934235,298.985474 329.971527,296.943390 330.009247,294.465942 
z"/>
<path fill="#F2DE54" opacity="1.000000" stroke="none" 
	d="
M287.088440,204.378403 
	C289.551453,203.603897 292.050323,203.240189 295.503876,202.737518 
	C293.827637,207.905426 292.488068,212.035400 291.105286,216.526016 
	C290.045868,215.285339 288.634766,213.802292 288.111023,212.053513 
	C287.418457,209.740967 287.420685,207.220367 287.088440,204.378403 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M278.666382,204.186325 
	C280.339111,203.890991 281.726318,203.896210 283.527344,203.888535 
	C279.756775,204.907364 279.717804,208.055237 279.963257,211.454926 
	C280.069122,212.921844 279.812988,214.414886 279.372742,215.970215 
	C278.809723,212.191864 278.595276,208.339355 278.666382,204.186325 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M329.824524,202.222473 
	C329.787689,206.874115 329.435852,211.451996 329.100128,216.481308 
	C328.417694,215.904984 327.072723,214.835648 327.135681,213.856873 
	C327.371643,210.187836 328.067627,206.548370 328.728668,202.557770 
	C329.080597,202.194717 329.295074,202.171707 329.824524,202.222473 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M268.166962,219.279327 
	C270.192139,218.518982 272.362213,218.079773 274.806702,217.402008 
	C275.490387,217.107986 275.899628,217.052536 276.610229,216.926788 
	C277.104065,216.906830 277.296570,216.957169 277.781311,217.071899 
	C275.732147,221.572418 272.033966,220.633804 268.166962,219.279327 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M330.040955,217.589111 
	C332.989746,217.479370 336.030853,217.714981 339.501312,217.918915 
	C336.685181,222.118515 333.407471,219.662842 330.040955,217.589111 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M277.311707,188.749054 
	C276.699860,186.614731 276.364716,184.221390 276.096130,181.400665 
	C276.637878,183.478867 277.113129,185.984451 277.311707,188.749054 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M273.960114,172.466675 
	C271.942596,173.158493 269.831787,173.516541 267.322418,173.816498 
	C268.760620,171.176743 270.962372,170.154037 273.960114,172.466675 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M276.243774,176.806412 
	C275.631317,175.940781 275.292236,174.854797 274.936340,173.409500 
	C275.452026,174.228806 275.984558,175.407440 276.243774,176.806412 
z"/>
<path fill="#D8A762" opacity="1.000000" stroke="none" 
	d="
M264.703186,175.026428 
	C264.993011,175.459381 264.989044,175.922562 264.906372,176.725754 
	C264.362671,176.703674 263.897614,176.341583 263.432556,175.979492 
	C263.758148,175.671875 264.083740,175.364258 264.703186,175.026428 
z"/>
<path fill="#A6D8F4" opacity="1.000000" stroke="none" 
	d="
M274.973755,189.718475 
	C273.178070,191.085159 271.459961,192.440445 269.580750,192.722519 
	C268.935303,192.819412 267.297516,190.298630 267.140778,188.861603 
	C266.766479,185.430054 266.661011,181.880707 267.167328,178.486359 
	C267.362427,177.178528 269.378387,175.569550 270.820221,175.249863 
	C271.600372,175.076874 273.594360,177.193222 273.863434,178.516251 
	C274.579407,182.036880 274.656799,185.687378 274.973755,189.718475 
z"/>
<path fill="#DAA66C" opacity="1.000000" stroke="none" 
	d="
M206.832947,310.047058 
	C204.221542,310.523560 201.610138,311.000031 198.998734,311.476532 
	C198.926285,311.774841 198.853836,312.073120 198.781387,312.371429 
	C202.326447,313.031097 205.871490,313.690765 209.705978,314.604004 
	C206.954895,314.930542 203.914368,315.003510 200.417419,315.125549 
	C199.961014,315.174591 199.540985,315.018768 199.138184,314.659637 
	C193.294662,310.596710 188.380508,310.801056 184.890259,314.876221 
	C184.890259,314.876221 184.963409,315.004517 184.466339,314.965546 
	C179.008072,314.962219 174.046890,314.997864 169.357788,314.727722 
	C174.047043,314.093506 178.464233,313.765015 182.881439,313.436523 
	C182.844849,312.721130 182.808258,312.005768 182.771667,311.290375 
	C181.002594,311.290375 179.221283,311.412384 177.469879,311.236511 
	C176.650879,311.154266 175.889694,310.496277 175.557907,310.050354 
	C186.286652,310.016693 196.559799,310.031891 206.832947,310.047058 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M185.253784,314.922668 
	C188.380508,310.801056 193.294662,310.596710 198.916595,314.626770 
	C195.330292,315.026550 191.581070,310.953644 187.440338,314.954712 
	C186.595261,314.993835 186.106277,314.981476 185.253784,314.922668 
z"/>
<path fill="#F2F0CD" opacity="1.000000" stroke="none" 
	d="
M199.743744,322.297363 
	C200.071396,324.716064 200.094833,327.385437 200.006195,330.482971 
	C199.742584,328.123413 199.591049,325.335724 199.743744,322.297363 
z"/>
<path fill="#D8EACF" opacity="1.000000" stroke="none" 
	d="
M202.794098,340.991760 
	C207.116013,340.988892 211.033417,340.988892 214.950836,340.988892 
	C214.946198,341.236389 214.941574,341.483917 214.936935,341.731415 
	C210.304611,341.731415 205.672287,341.731415 199.758636,341.731415 
	C201.473831,341.251099 201.931717,341.122894 202.794098,340.991760 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M169.085709,315.033478 
	C174.046890,314.997864 179.008072,314.962219 184.423874,314.902924 
	C184.400665,318.454895 183.922836,322.030548 183.123520,325.870056 
	C181.812164,325.981018 180.548462,326.165009 179.877472,325.619720 
	C175.922638,322.405762 172.105759,319.022064 168.381042,315.403107 
	C168.708328,315.084961 168.897018,315.059235 169.085709,315.033478 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M183.683426,334.970703 
	C183.290710,333.954559 183.202042,332.846191 183.249359,331.338409 
	C183.586060,332.252167 183.786774,333.565277 183.683426,334.970703 
z"/>
<path fill="#EFEECA" opacity="1.000000" stroke="none" 
	d="
M183.554199,339.000244 
	C183.230850,338.569214 183.170547,338.093567 183.240082,337.276794 
	C183.519028,337.608978 183.668137,338.282288 183.554199,339.000244 
z"/>
<path fill="#95CE0C" opacity="1.000000" stroke="none" 
	d="
M277.495239,327.113464 
	C278.036560,327.428741 278.235840,327.655548 278.646393,327.992310 
	C280.144501,330.236298 281.478516,332.333160 282.774384,334.453430 
	C283.542755,335.710571 284.250854,337.004608 284.981750,338.624939 
	C281.299866,338.974792 277.622314,338.981750 273.487946,338.970947 
	C273.031097,334.892303 273.031097,330.831451 273.031097,326.396362 
	C274.678406,326.660522 275.900238,326.856445 277.495239,327.113464 
z"/>
<path fill="#E7A245" opacity="1.000000" stroke="none" 
	d="
M169.357788,314.727722 
	C168.897018,315.059235 168.708328,315.084961 168.233032,315.098511 
	C167.544098,315.085236 167.141800,315.084076 166.162537,314.940643 
	C165.069321,314.522858 164.553055,314.247345 164.036804,313.971832 
	C164.036804,313.971832 164.004166,314.001068 163.935699,313.740601 
	C163.576157,313.319702 163.285095,313.159271 162.994019,312.998840 
	C162.994019,312.998840 162.645096,312.833832 162.470215,312.753418 
	C161.578049,311.704285 160.860764,310.735565 160.121490,309.459717 
	C160.099503,309.152618 160.534348,309.044067 160.744476,308.966003 
	C161.777786,307.103851 163.333893,305.297943 163.281570,303.539886 
	C163.145203,298.957367 166.262955,297.103333 169.903625,295.191528 
	C171.419510,299.942902 172.308624,304.480408 173.371658,309.236816 
	C174.064484,309.670166 174.583405,309.884674 175.102310,310.099182 
	C175.889694,310.496277 176.650879,311.154266 177.469879,311.236511 
	C179.221283,311.412384 181.002594,311.290375 182.771667,311.290375 
	C182.808258,312.005768 182.844849,312.721130 182.881439,313.436523 
	C178.464233,313.765015 174.047043,314.093506 169.357788,314.727722 
z"/>
<path fill="#E7CFAB" opacity="1.000000" stroke="none" 
	d="
M164.064026,314.389526 
	C164.553055,314.247345 165.069321,314.522858 165.861053,314.910095 
	C166.097931,319.363464 166.059341,323.705078 165.889069,328.486206 
	C165.202011,324.219513 164.646637,319.513367 164.064026,314.389526 
z"/>
<path fill="#E7CFAB" opacity="1.000000" stroke="none" 
	d="
M163.085266,313.235382 
	C163.285095,313.159271 163.576157,313.319702 163.948105,313.723999 
	C163.744827,313.802551 163.460663,313.637238 163.085266,313.235382 
z"/>
<path fill="#F8CC8A" opacity="1.000000" stroke="none" 
	d="
M174.006485,205.168198 
	C173.632156,207.496399 173.257828,209.824600 172.611816,212.828430 
	C172.191208,214.650299 172.042267,215.796524 171.893341,216.942764 
	C171.282486,215.526047 170.671631,214.109329 170.058502,212.096451 
	C170.056244,211.500275 170.065979,211.099304 170.065979,211.099304 
	C170.080673,210.027145 170.095383,208.954987 170.496460,207.363129 
	C171.543091,200.604904 172.203339,194.366379 172.863586,188.127853 
	C172.863571,188.127853 173.001038,188.001938 173.071335,187.939087 
	C176.939560,192.358414 174.690948,197.290558 174.084641,202.866516 
	C173.982025,204.150131 173.994263,204.659164 174.006485,205.168198 
z"/>
<path fill="#8B4793" opacity="1.000000" stroke="none" 
	d="
M174.404541,205.134369 
	C173.994263,204.659164 173.982025,204.150131 174.085022,203.272339 
	C182.852875,204.446899 191.505524,205.990234 200.539307,207.635223 
	C200.905655,208.238892 200.890854,208.740921 200.802917,209.616516 
	C192.087372,208.360229 183.444977,206.730377 174.404541,205.134369 
z"/>
<path fill="#FCD35F" opacity="1.000000" stroke="none" 
	d="
M172.533600,188.030182 
	C172.203339,194.366379 171.543091,200.604904 170.521027,206.961884 
	C169.132812,198.639328 168.106430,190.198288 167.133850,181.324768 
	C168.859619,183.239029 170.531616,185.585770 172.533600,188.030182 
z"/>
<path fill="#E0A352" opacity="1.000000" stroke="none" 
	d="
M172.188141,216.959930 
	C172.042267,215.796524 172.191208,214.650299 172.553131,213.236252 
	C172.861938,214.027374 172.957748,215.086288 173.062958,216.542419 
	C172.875900,216.952118 172.679428,216.964600 172.188141,216.959930 
z"/>
<path fill="#FCD35F" opacity="1.000000" stroke="none" 
	d="
M169.885010,211.149719 
	C170.065979,211.099304 170.056244,211.500275 170.060547,211.699783 
	C169.944580,211.666229 169.824295,211.433182 169.885010,211.149719 
z"/>
<path fill="#FA5BB6" opacity="1.000000" stroke="none" 
	d="
M182.938354,283.033203 
	C183.685349,283.027344 184.432343,283.021454 185.551163,283.019348 
	C185.922989,283.023102 185.992905,283.008026 186.164810,283.262451 
	C186.858383,283.660461 187.380035,283.804077 187.901703,283.947693 
	C190.064392,283.362732 192.227097,282.777771 194.718002,282.069092 
	C195.235367,282.240875 195.424515,282.536346 195.875763,283.392761 
	C196.405212,287.962708 196.672562,291.971680 196.614227,296.166290 
	C191.182938,299.116425 187.451187,298.686523 183.961838,294.502869 
	C183.640121,290.395905 183.289246,286.714569 182.938354,283.033203 
z"/>
<path fill="#F1619F" opacity="1.000000" stroke="none" 
	d="
M187.766296,283.699280 
	C187.380035,283.804077 186.858383,283.660461 186.166748,283.298035 
	C186.541473,283.203094 187.086182,283.326996 187.766296,283.699280 
z"/>
<path fill="#F561A5" opacity="1.000000" stroke="none" 
	d="
M210.835663,288.700714 
	C210.083939,289.600342 209.303558,290.155182 207.853149,290.852295 
	C202.911804,290.234985 202.671402,292.905334 202.702026,295.977203 
	C202.477692,295.957245 202.027237,295.960693 202.011047,295.970825 
	C201.994843,295.980988 202.031799,295.985992 201.958344,295.735138 
	C201.594559,295.303131 201.304230,295.122009 201.029144,294.949341 
	C201.044388,294.957825 201.056641,294.917084 201.004944,294.518402 
	C200.295410,290.458099 199.637543,286.796448 198.979675,283.134827 
	C202.077591,283.414795 205.175491,283.694763 208.722351,284.007568 
	C209.716522,285.478943 210.261780,286.917419 210.835663,288.700714 
z"/>
<path fill="#D2137B" opacity="1.000000" stroke="none" 
	d="
M198.611176,283.099304 
	C199.637543,286.796448 200.295410,290.458099 200.651245,294.576813 
	C199.212784,295.349487 198.076355,295.665100 196.939911,295.980682 
	C196.672562,291.971680 196.405212,287.962708 196.082886,283.559143 
	C196.766174,283.131012 197.504440,283.097412 198.611176,283.099304 
z"/>
<path fill="#AFD7E9" opacity="1.000000" stroke="none" 
	d="
M266.274841,273.319336 
	C265.710114,269.229858 264.961517,265.076904 271.528748,265.759766 
	C271.638733,267.323395 271.086609,268.932129 271.076477,270.544250 
	C271.068390,271.834747 271.609680,273.172668 272.100952,274.412750 
	C272.995392,276.670563 274.357544,278.791382 274.931732,281.115784 
	C275.688538,284.179291 273.898804,285.049011 270.968689,285.081818 
	C267.918274,285.115967 266.782074,284.069672 266.940887,281.023712 
	C267.069275,278.562073 266.715729,276.075317 266.274841,273.319336 
z"/>
<path fill="#DD0579" opacity="1.000000" stroke="none" 
	d="
M196.614227,296.166290 
	C198.076355,295.665100 199.212784,295.349487 200.702942,294.975494 
	C201.056641,294.917084 201.044388,294.957825 201.125244,295.187286 
	C201.481323,295.606506 201.756561,295.796234 202.031799,295.985992 
	C202.031799,295.985992 201.994843,295.980988 201.993988,296.382202 
	C202.715088,300.251831 203.437012,303.720215 204.328293,308.002197 
	C196.044312,308.002197 188.608871,307.856567 181.182877,308.058472 
	C176.934662,308.173981 176.427780,306.380951 177.513016,302.851410 
	C178.509171,299.611694 179.128586,296.256104 179.913986,292.951569 
	C179.913986,292.951569 179.933167,292.923950 180.101990,292.855713 
	C180.129410,292.522461 179.988022,292.257477 179.846649,291.992462 
	C179.892227,291.523804 179.937805,291.055115 180.386292,290.313660 
	C181.837036,291.670074 182.884857,293.299255 183.932678,294.928467 
	C187.451187,298.686523 191.182938,299.116425 196.614227,296.166290 
z"/>
<path fill="#CA1A5E" opacity="1.000000" stroke="none" 
	d="
M179.633423,293.099335 
	C179.128586,296.256104 178.509171,299.611694 177.513016,302.851410 
	C176.427780,306.380951 176.934662,308.173981 181.182877,308.058472 
	C188.608871,307.856567 196.044312,308.002197 204.328293,308.002197 
	C203.437012,303.720215 202.715088,300.251831 202.010193,296.372070 
	C202.027237,295.960693 202.477692,295.957245 202.791489,296.338135 
	C204.459549,300.119781 205.813812,303.520569 207.168076,306.921326 
	C207.218658,307.848358 207.269241,308.775421 207.076385,309.874756 
	C196.559799,310.031891 186.286652,310.016693 175.557907,310.050354 
	C174.583405,309.884674 174.064484,309.670166 173.596375,308.963928 
	C174.778564,304.616455 175.909973,300.760712 177.041382,296.904968 
	C177.811874,295.685669 178.582352,294.466400 179.633423,293.099335 
z"/>
<path fill="#FDCB6E" opacity="1.000000" stroke="none" 
	d="
M176.755615,296.737885 
	C175.909973,300.760712 174.778564,304.616455 173.422455,308.745056 
	C172.308624,304.480408 171.419510,299.942902 170.241776,295.110077 
	C169.976105,294.226929 169.999069,293.639038 170.010925,292.764526 
	C171.589966,294.737732 176.408234,291.894653 176.755615,296.737885 
z"/>
<path fill="#CA1A5E" opacity="1.000000" stroke="none" 
	d="
M183.961853,294.502869 
	C182.884857,293.299255 181.837036,291.670074 180.446777,289.965271 
	C180.585175,287.536926 178.143860,284.324829 182.534027,283.018066 
	C183.289246,286.714569 183.640121,290.395905 183.961853,294.502869 
z"/>
<path fill="#CA1A5E" opacity="1.000000" stroke="none" 
	d="
M179.832947,292.227539 
	C179.988022,292.257477 180.129410,292.522461 180.084106,292.857239 
	C179.897430,292.927032 179.819229,292.462616 179.832947,292.227539 
z"/>
<path fill="#E58B2D" opacity="1.000000" stroke="none" 
	d="
M223.040115,157.316681 
	C223.378952,157.755417 223.703018,158.533127 224.024384,159.647064 
	C223.689575,159.207397 223.357468,158.431534 223.040115,157.316681 
z"/>
<path fill="#FDC677" opacity="1.000000" stroke="none" 
	d="
M207.444092,306.623169 
	C205.813812,303.520569 204.459549,300.119781 203.015823,296.358093 
	C202.671402,292.905334 202.911804,290.234985 207.605469,291.038910 
	C208.027832,291.083252 208.016617,291.524017 208.029327,291.743622 
	C207.934723,296.750488 207.827408,301.537750 207.444092,306.623169 
z"/>
<path fill="#CA1A5E" opacity="1.000000" stroke="none" 
	d="
M201.958344,295.735138 
	C201.756561,295.796234 201.481323,295.606506 201.109985,295.178802 
	C201.304230,295.122009 201.594559,295.303131 201.958344,295.735138 
z"/>
</svg>
  );
}

export default SvgComponent;
