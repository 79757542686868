import React from "react";
import styled from "styled-components";
// Assets
import RollerIcon from "../../assets/svg/Services/RollerIcon";
import MonitorIcon from "../../assets/svg/Services/MonitorIcon";
import BrowserIcon from "../../assets/svg/Services/BrowserIcon";
import PrinterIcon from "../../assets/svg/Services/PrinterIcon";

export default function ServiceBox({ icon, title, subtitle }) {
  let getIcon;

  if (typeof icon === "string") {
    switch (icon) {
      case "roller":
        getIcon = <RollerIcon />;
        break;
      case "monitor":
        getIcon = <MonitorIcon />;
        break;
      case "browser":
        getIcon = <BrowserIcon />;
        break;
      case "printer":
        getIcon = <PrinterIcon />;
        break;

    }
  } else {
    // Assuming icon is an imported image
    getIcon = <img src={icon} alt={`${title} icon`} />;
  }

  return (
    <Wrapper className="flex flexColumn">
      <IconStyle>{getIcon}</IconStyle>
      <TitleStyle className="font20 extraBold">{title}</TitleStyle>
      <SubtitleStyle className="font13">{subtitle}</SubtitleStyle>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const IconStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px; /* Adjust as needed */
  margin-bottom: 20px;
`;
const TitleStyle = styled.h2`
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
`;
const SubtitleStyle = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
`;
